import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const Truck: React.FC<React.PWC<IconProps>> = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke={color}
      d="M4 15h-.7a1.7 1.7 0 0 1-1.6-1.7V5.4a1.7 1.7 0 0 1 1.6-1.7h8.2A1.7 1.7 0 0 1 13 5.4v5.8m.1-4.4h2.3a1 1 0 0 1 .8.4l1.9 2.5.3.9v4.2a.2.2 0 0 1-.2.1h-2.5"
    />
    <path
      stroke={color}
      d="M13.8 16.7a1.9 1.9 0 1 0 0-3.7 1.9 1.9 0 0 0 0 3.7Zm-7.9 0a1.9 1.9 0 1 0 0-3.7 1.9 1.9 0 0 0 0 3.7Zm6-1.7H7.6M5 8l1.9 1.9L10 6.5"
    />
  </svg>
)
