import PropTypes from 'prop-types'
import React from 'react'

export const Send = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5758 3.14769L20.8355 3.40757C20.9793 3.53877 21.0338 3.74163 20.9753 3.92735L14.9436 20.6701C14.8721 20.868 14.6844 20.9999 14.4742 21H14.3144C14.1183 21 13.9397 20.8872 13.8551 20.7101L10.4697 13.5232L3.2896 10.1347C3.11267 10.05 3.00004 9.87117 2.99999 9.67488V9.51495C2.98231 9.29005 3.11734 9.08117 3.32954 9.00516L20.0565 3.00775C20.1127 2.99742 20.1702 2.99742 20.2263 3.00775C20.3566 3.00696 20.482 3.05718 20.5758 3.14769ZM12.9863 14.123L14.2146 16.7818L18.1292 5.85652L7.2142 9.77483L9.87055 11.0043C10.6097 11.388 11.5091 11.2725 12.1274 10.7144L13.8151 9.1551C14.008 8.97105 14.3113 8.97105 14.5042 9.1551L14.8837 9.53494C15.0675 9.728 15.0675 10.0316 14.8837 10.2246L13.3258 11.9139C12.7736 12.5123 12.6392 13.3861 12.9863 14.123Z"
      fill={color}
    />
  </svg>
)

Send.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
