import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const ChevronFullDown: React.FC<IconProps> = ({ color, size, fill }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.13797 10.0601C8.04967 9.96617 8 9.83837 8 9.70505C8 9.57173 8.04967 9.44393 8.13797 9.35005L8.32454 9.15005C8.4123 9.05239 8.53389 8.99809 8.66037 9.00005H15.3396C15.4661 8.99809 15.5877 9.05239 15.6755 9.15005L15.862 9.35005C15.9503 9.44393 16 9.57173 16 9.70505C16 9.83837 15.9503 9.96617 15.862 10.0601L12.3265 13.8501C12.2419 13.9467 12.1237 14.0016 12 14.0016C11.8763 14.0016 11.7581 13.9467 11.6735 13.8501L8.13797 10.0601Z"
      fill={color}
    />
  </svg>
)
