import PropTypes from 'prop-types'
import React from 'react'

export const Fail = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.33325 8.00004C1.33325 4.31814 4.31802 1.33337 7.99992 1.33337C9.76803 1.33337 11.4637 2.03575 12.714 3.286C13.9642 4.53624 14.6666 6.23193 14.6666 8.00004C14.6666 11.6819 11.6818 14.6667 7.99992 14.6667C4.31802 14.6667 1.33325 11.6819 1.33325 8.00004ZM10.9999 10.3984C10.9999 10.3317 10.9732 10.2678 10.9259 10.2208L8.70513 8.00006L10.9259 5.77928C10.9732 5.73233 10.9999 5.6684 10.9999 5.60172C10.9999 5.53504 10.9732 5.47112 10.9259 5.42416L10.5758 5.07404C10.5288 5.02669 10.4649 5.00006 10.3982 5.00006C10.3315 5.00006 10.2676 5.02669 10.2207 5.07404L7.99988 7.29481L5.7791 5.07404C5.73214 5.02669 5.66822 5.00006 5.60154 5.00006C5.53486 5.00006 5.47093 5.02669 5.42398 5.07404L5.07385 5.42416C5.02651 5.47112 4.99988 5.53504 4.99988 5.60172C4.99988 5.6684 5.02651 5.73233 5.07385 5.77928L7.29463 8.00006L5.07385 10.2208C5.02651 10.2678 4.99988 10.3317 4.99988 10.3984C4.99988 10.4651 5.02651 10.529 5.07385 10.576L5.42398 10.9261C5.47093 10.9734 5.53486 11.0001 5.60154 11.0001C5.66822 11.0001 5.73214 10.9734 5.7791 10.9261L7.99988 8.70531L10.2207 10.9261C10.2676 10.9734 10.3315 11.0001 10.3982 11.0001C10.4649 11.0001 10.5288 10.9734 10.5758 10.9261L10.9259 10.576C10.9732 10.529 10.9999 10.4651 10.9999 10.3984Z"
      fill={color}
    />
  </svg>
)

Fail.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
