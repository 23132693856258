import styled from 'styled-components'
import {
  type FlexboxProps,
  type GridGapProps,
  flexbox,
  style,
  type TextAlignProps,
  textAlign,
  type ColorProps,
} from 'styled-system'
import { EXCLUDE_STYLED_SYSTEM_PROPS } from 'utils/styledSystem'
import { Box } from './Box'

// This makes gap use the space defined in the theme.
// https://github.com/styled-system/styled-system/blob/v3.1.11/src/index.js#L67
const gap = style({
  prop: 'gap',
  cssProperty: 'gap',
  key: 'flex-gap',
})

type FlexProps = FlexboxProps &
  GridGapProps &
  TextAlignProps &
  React.ComponentProps<typeof Box> & {
    gap?: GridGapProps['gridGap']
  } & {
    hover?: ColorProps
  }

export const Flex: React.FC<React.PWC<FlexProps>> = styled(Box).withConfig(
  EXCLUDE_STYLED_SYSTEM_PROPS
)`
  display: ${(props) => props.display ?? 'flex'};
  ${flexbox}
  ${gap}
  ${textAlign}
`
