import { GA4_PARAMS, type TransformationProps } from '../types'
import { findPropertyInTree } from '../utils'

export interface ClickTransformationContextData {
  text: string
  url?: string
  name?: string
}

type ClickTransformationProps = TransformationProps<
  ClickTransformationContextData,
  'contextData' | 'parent'
>

export const ClickTransformation = ({
  contextData,
  parent,
}: ClickTransformationProps) => {
  const { text, url = '', name = '' } = contextData

  return {
    page_area: findPropertyInTree({
      keyName: GA4_PARAMS.PAGE_AREA,
      contextData,
      parent,
    }),
    text,
    url,
    name,
  }
}
