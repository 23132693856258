import PropTypes from 'prop-types'
import React from 'react'

export const Share = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2002 2.00031C12.4002 2.00031 12.6002 2.10031 12.7002 2.20031L17.8002 7.40031C18.0002 7.60031 18.0002 7.90031 17.8002 8.10031L17.1002 8.80031C17.0002 9.00031 16.6002 9.00031 16.4002 8.70031L13.0002 5.30031L13.0002 15.5003C13.0002 15.8003 12.8002 16.0003 12.5002 16.0003L11.5002 16.0003C11.2002 16.0003 11.0002 15.8003 11.0002 15.5003L11.0002 5.30031L7.6002 8.7003C7.4002 8.9003 7.1002 8.9003 6.9002 8.7003L6.2002 8.00031C6.0002 7.8003 6.0002 7.50031 6.2002 7.30031L11.3002 2.20031C11.4002 2.10031 11.6002 2.00031 11.8002 2.00031H12.2002ZM4 11.0003C3.44772 11.0003 3 11.448 3 12.0003V15.5003V16.0003V19.0003C3 20.1003 3.9 21.0003 5 21.0003H19C20.1 21.0003 21 20.1003 21 19.0003V16.0003V15.5003V12.0003C21 11.448 20.5523 11.0003 20 11.0003C19.4477 11.0003 19 11.448 19 12.0003V15.5003V16.0003V19.0003H5V16.0003V15.5003V12.0003C5 11.448 4.55228 11.0003 4 11.0003Z"
      fill={color}
    />
  </svg>
)

Share.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
