import { gql } from '@apollo/client'

export const CLIENT_SIGN_UP_MUTATION = gql`
  mutation clientSignUp($input: ClientSignUpInput) {
    clientSignUp(input: $input) {
      id
      loginEmail
      contactEmail
      firstName
      lastName
      status
      authToken
      isEmailVerified
    }
  }
`

export const USER_PROFILE_QUERY = gql`
  query userMe {
    userMe {
      ... on Client {
        id
        loginEmail
        contactEmail
        firstName
        lastName
        fullName
        timezone
        isEmailVerified
        phoneNumber
        businessName
        cards {
          stripeId
          last4
          expiryYear
          expiryMonth
        }
        addresses {
          zipCode
          city
          addressLine1
          addressLine2
          countryState
          countryIsoCode
          isPrimary
        }
        credits {
          total
          currencyIsoCode
          calculatedPrices {
            total
            currencyIsoCode
          }
        }
        createdAtUtc {
          isoFormat
        }
        clientActivities {
          isClientShowroomActive
          isClientSessionActive
        }
      }
      ... on ExpertUser {
        id
        expert {
          id
          profileName
          businessName
          slug
          countryIsoCode
          isStripeOnboarded
          isStripeAccountActivated
          currencyIsoCode
          isConsultationRecapFormEnabled
          createdAtUtc {
            isoFormat
          }
          isContractAccepted
          capabilities
          expertShowroomCollection {
            id
          }
        }
        featureFlags {
          id
          label
        }
        calendar {
          email
          id
          provider
        }
        email
        status
        title
        zoomConnectedEmail
        notificationEmails {
          id
          email
        }
        expertFirstName: firstName
        expertLastName: lastName
        timezone
        profileImageUrl
        isMain
        isZoomAccountActivated
        zoomAccountType
        onboardingToken
        capabilities
        createdAtUtc {
          isoFormat
        }
        cards {
          stripeId
          last4
          expiryYear
          expiryMonth
        }
      }
      ... on Admin {
        id
        email
        name
        createdAtUtc {
          isoFormat
        }
      }
    }
  }
`

export const FIX_USER_CLAIMS_MUTATION = gql`
  mutation revalidateFirebaseClaims($email: String!) {
    revalidateFirebaseClaims(email: $email)
  }
`
