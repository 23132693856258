import { ellipsis, transparentize } from 'polished'
import styled, { css, keyframes } from 'styled-components'
import {
  color,
  type ColorProps,
  space,
  type SpaceProps,
  style,
  system,
  typography,
  type TypographyProps as StyledSystemTypographyProps,
} from 'styled-system'
import {
  COLOR_INTENT,
  DEVICE,
  FONT_SIZE,
  FONT_STACK,
  FONT_WEIGHT,
  RADIUS,
  HEADING_VARIANT,
  mq,
  LINE_HEIGHT,
} from 'Theme'
import { EXCLUDE_STYLED_SYSTEM_PROPS } from 'utils/styledSystem'

const textFunctions = [space, color, typography]

const pulseEffectAnimation = keyframes`
  0% {
    background-color: ${transparentize(0.3, COLOR_INTENT.SKELETON.FOREGROUND)}
  }
  50% {
    background-color: ${transparentize(0.6, COLOR_INTENT.SKELETON.FOREGROUND)}
  }
  100% {
    background-color: ${transparentize(0.3, COLOR_INTENT.SKELETON.FOREGROUND)}
  }
`

const loadingEffectFunction = ({ isLoading }) =>
  isLoading &&
  css`
    color: transparent;
    border-radius: ${RADIUS.PX_4};
    padding: 0 ${RADIUS.PX_4};
    animation: ${pulseEffectAnimation} 1.3s ease-out infinite;
  `

const ellipsisFunction = ({ isEllipsis }) => isEllipsis && ellipsis()

type FontSize = (typeof FONT_SIZE)[keyof typeof FONT_SIZE]
type ResponsiveFontSize = {
  MOBILE?: FontSize
  TABLET?: FontSize
  DESKTOP?: FontSize
  TV?: FontSize
}

type HeadingVariants = (typeof HEADING_VARIANT)[keyof typeof HEADING_VARIANT]

export interface TypographyProps
  extends SpaceProps,
    ColorProps,
    StyledSystemTypographyProps {
  isEllipsis?: boolean
  isLoading?: boolean
  variant?: HeadingVariants
  fontFamily?: (typeof FONT_STACK)[keyof typeof FONT_STACK]
  fontSize?: FontSize | ResponsiveFontSize
  fontWeight?: (typeof FONT_WEIGHT)[keyof typeof FONT_WEIGHT]
}

// Adds missing property whiteSpace to Text component
// https://github.com/styled-system/styled-system/blob/v3.1.11/src/index.js#L67
const whiteSpace = style({
  prop: 'whiteSpace',
  cssProperty: 'white-space',
  key: 'white-space',
})

export const Text = styled('span').withConfig(
  EXCLUDE_STYLED_SYSTEM_PROPS
)<TypographyProps>`
  ${textFunctions}
  ${ellipsisFunction}
  ${loadingEffectFunction}
  ${system({ textTransform: true })}
  ${whiteSpace}
`

Text.defaultProps = {
  fontWeight: FONT_WEIGHT.NORMAL,
  fontSize: FONT_SIZE.PX_14,
  fontFamily: FONT_STACK.SANS,
  isEllipsis: false,
  isLoading: false,
}

const fontVariant = ({ variant }: { variant: HeadingVariants }) =>
  variant === HEADING_VARIANT.SERIF
    ? css`
        font-family: ${FONT_STACK.SERIF};
        font-weight: ${FONT_WEIGHT.NORMAL};
      `
    : css`
        font-family: ${FONT_STACK.SANS};
        font-weight: ${FONT_WEIGHT.MEDIUM};
      `

const fontSize =
  ({ mobile, desktop }: { mobile: string; desktop: string }) =>
  () => css`
    font-size: ${desktop};
    @media (max-width: ${DEVICE.TABLET}) {
      font-size: ${mobile};
    }
  `

const lineHeight = ({
  mobile,
  desktop,
}: {
  mobile: number
  desktop: number
}) => css`
  ${mq.to.DESKTOP`
    line-height: ${mobile};
  `}
  ${mq.from.DESKTOP`
    line-height: ${desktop};
  `}
`

export const H1 = styled('h1')<TypographyProps>`
  ${fontSize({
    mobile: FONT_SIZE.PX_36,
    desktop: FONT_SIZE.PX_48,
  })}
  ${lineHeight({
    mobile: LINE_HEIGHT.XXS,
    desktop: LINE_HEIGHT.XXS,
  })}
  ${fontVariant}
  ${textFunctions}
  ${ellipsisFunction}
  ${loadingEffectFunction}
`

export const H2 = styled('h2')<TypographyProps>`
  ${fontSize({
    mobile: FONT_SIZE.PX_32,
    desktop: FONT_SIZE.PX_36,
  })}
  ${lineHeight({
    mobile: LINE_HEIGHT.XXS,
    desktop: LINE_HEIGHT.XXS,
  })}
  ${fontVariant}
  ${textFunctions}
  ${ellipsisFunction}
  ${loadingEffectFunction}
`

export const H3 = styled('h3')<TypographyProps>`
  ${fontSize({
    mobile: FONT_SIZE.PX_24,
    desktop: FONT_SIZE.PX_32,
  })}
  ${lineHeight({
    mobile: LINE_HEIGHT.S,
    desktop: LINE_HEIGHT.XXS,
  })}
  ${fontVariant}
  ${textFunctions}
  ${ellipsisFunction}
  ${loadingEffectFunction}
`

export const H4 = styled('h4')<TypographyProps>`
  ${fontSize({
    mobile: FONT_SIZE.PX_20,
    desktop: FONT_SIZE.PX_24,
  })}
  ${lineHeight({
    mobile: LINE_HEIGHT.M,
    desktop: LINE_HEIGHT.S,
  })}
  ${fontVariant}
  ${textFunctions}
  ${ellipsisFunction}
  ${loadingEffectFunction}
`

export const H5 = styled('h5')<TypographyProps>`
  ${fontSize({
    mobile: FONT_SIZE.PX_20,
    desktop: FONT_SIZE.PX_20,
  })}
  ${lineHeight({
    mobile: LINE_HEIGHT.M,
    desktop: LINE_HEIGHT.M,
  })}
  ${fontVariant}
  ${textFunctions}
  ${ellipsisFunction}
  ${loadingEffectFunction}
`

export const H6 = styled('h6')<TypographyProps>`
  ${fontSize({
    mobile: FONT_SIZE.PX_16,
    desktop: FONT_SIZE.PX_16,
  })}
  ${lineHeight({
    mobile: LINE_HEIGHT.L,
    desktop: LINE_HEIGHT.L,
  })}
  ${fontVariant}
  ${textFunctions}
  ${ellipsisFunction}
  ${loadingEffectFunction}
`
