import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const ChevronFullRight: React.FC<IconProps> = ({
  color,
  size,
  fill,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0601 15.862C9.96617 15.9503 9.83837 16 9.70505 16C9.57173 16 9.44393 15.9503 9.35005 15.862L9.15005 15.6755C9.05239 15.5877 8.99809 15.4661 9.00005 15.3396V8.66037C8.99809 8.53389 9.05239 8.4123 9.15005 8.32454L9.35005 8.13797C9.44393 8.04967 9.57173 8 9.70505 8C9.83837 8 9.96617 8.04967 10.0601 8.13797L13.8501 11.6735C13.9467 11.7581 14.0016 11.8763 14.0016 12C14.0016 12.1237 13.9467 12.2419 13.8501 12.3265L10.0601 15.862Z"
      fill={color}
    />
  </svg>
)
