import PropTypes from 'prop-types'
import React from 'react'

export const FeedOutlined = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5 6H21.5C21.7761 6 22 6.22386 22 6.5V17C22 18.6569 20.6569 20 19 20H5C3.34315 20 2 18.6569 2 17V6C2 4.89543 2.89543 4 4 4H16C17.1046 4 18 4.89543 18 6V17C18 17.5523 18.4477 18 19 18C19.5523 18 20 17.5523 20 17V6.5C20 6.22386 20.2239 6 20.5 6ZM4 17C4 17.5523 4.44772 18 5 18H16.18C16.0636 17.6793 16.0027 17.3411 16 17V6H4V17ZM13.5 8H6.5C6.22386 8 6 8.22386 6 8.5V11.5C6 11.7761 6.22386 12 6.5 12H13.5C13.7761 12 14 11.7761 14 11.5V8.5C14 8.22386 13.7761 8 13.5 8ZM6.5 14H13.5C13.7761 14 14 14.2239 14 14.5V15.5C14 15.7761 13.7761 16 13.5 16H6.5C6.22386 16 6 15.7761 6 15.5V14.5C6 14.2239 6.22386 14 6.5 14Z"
      fill={color}
    />
  </svg>
)

FeedOutlined.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
