import PropTypes from 'prop-types'
import React from 'react'

export const Play = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0ZM12 2.4C17.3019 2.4 21.6 6.69807 21.6 12C21.6 17.3019 17.3019 21.6 12 21.6C6.69803 21.6 2.39996 17.3019 2.39996 12C2.39996 6.69807 6.69803 2.4 12 2.4ZM10.8 15.6001V8.40005C10.8034 8.16001 10.9495 7.94508 11.1715 7.85367C11.3935 7.76227 11.6486 7.81198 11.82 7.98005L15.42 11.5801C15.6502 11.8174 15.6502 12.1947 15.42 12.4321L11.82 16.0321C11.647 16.2016 11.3891 16.2505 11.166 16.1561C10.943 16.0616 10.7986 15.8423 10.8 15.6001Z"
      fill={color}
    />
  </svg>
)

Play.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
