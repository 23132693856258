import styled from 'styled-components'
import {
  type LayoutProps,
  type PositionProps,
  type SpaceProps,
  layout,
  position,
  space,
} from 'styled-system'
import { PAGE_MAX_WIDTH, SPACE } from 'Theme'
import { Flex } from './Flex'

export const CONTAINER_HORIZONTAL_PADDING = SPACE.PX_15

type ContainerProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  LayoutProps &
  PositionProps &
  SpaceProps

export const Small = styled(Flex)`
  max-width: ${PAGE_MAX_WIDTH.SMALL};
  margin: 0 auto;
  width: 100%;
  height: 100%;
`

export const Container: React.FC<ContainerProps> & { Small: typeof Small } =
  styled.div`
    max-width: ${PAGE_MAX_WIDTH.LAYOUT};
    margin: auto;
    width: 100%;
    height: 100%;
    padding: 0 ${CONTAINER_HORIZONTAL_PADDING};
    ${layout}
    ${space}
  ${position}
  `
Container.Small = Small
