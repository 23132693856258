import { useMutation, gql } from '@apollo/client'
import { CartFieldsFragmentDoc } from 'types/graphql-generated'

const SET_ITEM_TO_CART_MUTATION = gql`
  ${CartFieldsFragmentDoc}
  mutation SetItemToCart($input: SetItemToCartInput!) {
    setItemToCart(input: $input) {
      ...CartFields
      items {
        ... on CartItemProductVariant {
          metadata {
            ga4 {
              listName
              listIndex
            }
          }
        }
      }
    }
  }
`

export const useSetItemToCartMutation = ({
  productVariantId,
  quantity,
  expertSlug,
  type,
  metadata,
} = {}) =>
  useMutation(SET_ITEM_TO_CART_MUTATION, {
    skip: !productVariantId || !quantity,
    variables: {
      input: {
        productVariantId,
        quantity,
        expertSlug,
        type,
        requestedAtUtc: new Date().toISOString(),
        metadata,
      },
    },
  })
