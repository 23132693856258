import React, { useState } from 'react'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import styled, { css } from 'styled-components'
import { space } from 'styled-system'
import { ICON_SIZE, type IconProps } from 'components/Icon'
import { Box, Flex } from 'components/Layout'
import { LinkWithText } from 'components/Link'
import { disabledHover } from 'components/mixins'
import { useValidationErrorTrackingForGA4Provider } from 'providers/googleAnalytics/utils/ErrorTracking/useValidationErrorTrackingForGA4'
import {
  COLOR,
  FONT_SIZE,
  getBorderColor,
  HINT_HEIGHT,
  INPUT_SIZE,
  SPACE,
} from 'Theme'
import { CardInput } from './CardInput'
import { Hint } from './Hint'
import { InputArea } from './InputArea'
import { InputWrapper } from './InputWrapper'
import { LabelText } from './LabelText'

const INPUT_ICON_SIZE = {
  [INPUT_SIZE.S]: ICON_SIZE.PX_18,
  [INPUT_SIZE.M]: ICON_SIZE.PX_20,
  [INPUT_SIZE.L]: ICON_SIZE.PX_20,
} as const

export const Label = styled('label')`
  display: inline-flex;
  ${space};
  width: 100%;
  cursor: pointer;
  font-size: ${FONT_SIZE.PX_14};
  flex-direction: column;
  ${({ isInline }) =>
    isInline &&
    css`
      flex-direction: row;
    `}
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      ${disabledHover}
    `}
`
export interface InputProps
  extends Omit<
      Omit<React.HTMLProps<HTMLLabelElement>, 'children'>,
      'size' | 'onFocus' | 'onBlur' | 'onChange'
    >,
    Pick<
      Omit<React.HTMLProps<HTMLInputElement>, 'children'>,
      'value' | 'onChange' | 'onFocus' | 'onBlur' | 'placeholder'
    > {
  children?: (props: InputChildrenProps) => React.ReactNode
  label?: string
  hint?: string
  size?: (typeof INPUT_SIZE)[keyof typeof INPUT_SIZE]
  isInline?: boolean
  icon?: typeof React.Component | React.FC<React.PWC<IconProps>>
  iconSize?: (typeof ICON_SIZE)[keyof typeof ICON_SIZE]
  isDisabled?: boolean
  isError?: boolean
  isWarning?: boolean
  hintMessageContrast?: 'light' | 'dark'
  iconColor?: string
  hasHint?: boolean
  isCardInput?: boolean
  cardInputName?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  allowedBorderColor?: boolean
  postalCode?: string
  labelActionLink?: {
    label: string
    href: string
  }
}

interface InputChildrenProps
  extends Pick<InputProps, 'isError' | 'isWarning' | 'size' | 'placeholder'> {
  borderColor: string
  isFocused: boolean
  setIsFocused: React.Dispatch<React.SetStateAction<boolean>>
  onBlurHandler?: (event: React.FocusEvent<HTMLInputElement>) => void
}

export const Input = React.forwardRef<typeof Label, InputProps>(
  (props, ref) => {
    const {
      label,
      hint,
      isWarning = false,
      isError = false,
      hintMessageContrast = 'dark',
      isInline = false,
      size = INPUT_SIZE.M,
      value,
      icon: Icon,
      iconSize,
      isDisabled = false,
      iconColor = COLOR.GRAYSCALE_2,
      onChange,
      onBlur: onBlurHandler = null,
      placeholder,
      hasHint,
      isCardInput = false,
      cardInputName = 'card',
      children,
      onFocus: onFocusHandler = null,
      allowedBorderColor,
      postalCode,
      id,
      labelActionLink,
      ...restProps
    } = props
    const [isFocused, setIsFocused] = useState(false)
    const borderColor = getBorderColor({
      isFocusedOrChecked: isFocused,
      isError,
      isWarning,
      allowedBorderColor,
    })
    useValidationErrorTrackingForGA4Provider({
      hint,
      isError,
    })

    return (
      <Label
        {...restProps}
        ref={ref}
        isDisabled={isDisabled}
        isInline={isInline}
        {...(id && { for: id })}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <LabelText size={size} isInline={isInline}>
            {label}
          </LabelText>
          {labelActionLink && (
            <Box mb={SPACE.PX_5}>
              <LinkWithText
                href={labelActionLink.href}
                fontSize={FONT_SIZE.PX_12}
              >
                {labelActionLink.label}
              </LinkWithText>
            </Box>
          )}
        </Flex>
        <Flex
          display="inline-flex"
          flexDirection="column"
          height={
            hasHint
              ? `calc(${size} + ${SPACE.PX_5} + ${HINT_HEIGHT})`
              : 'initial'
          }
        >
          {children ? (
            children({
              isFocused,
              isWarning,
              isError,
              size,
              borderColor,
              setIsFocused,
              onBlurHandler,
              placeholder,
            })
          ) : (
            <InputWrapper
              isFocused={isFocused}
              isWarning={isWarning}
              isError={isError}
              size={size}
              isDisabled={isDisabled}
              hasIcon={!!Icon}
              borderColor={borderColor}
            >
              {Icon && (
                <Icon
                  color={iconColor}
                  size={iconSize ?? INPUT_ICON_SIZE[size]}
                />
              )}
              {isCardInput ? (
                <CardInput
                  cardInputName={cardInputName}
                  onChange={onChange}
                  onBlur={onBlurHandler}
                  onFocus={onFocusHandler}
                  setIsFocused={setIsFocused}
                  postalCode={postalCode}
                  {...(id && { id })}
                  {...restProps}
                />
              ) : (
                <InputArea
                  onFocus={(
                    event: React.FocusEvent<HTMLInputElement, Element>
                  ) => {
                    setIsFocused(true)
                    onFocusHandler?.(event)
                  }}
                  onBlur={(
                    event: React.FocusEvent<HTMLInputElement, Element>
                  ) => {
                    setIsFocused(false)
                    onBlurHandler?.(event)
                  }}
                  isWarning={isWarning}
                  isError={isError}
                  size={size}
                  disabled={isDisabled}
                  placeholder={placeholder}
                  hasIcon={!!Icon}
                  onChange={onChange}
                  value={value}
                  {...(id && { id })}
                  {...restProps}
                />
              )}
            </InputWrapper>
          )}
          <Hint
            isWarning={isWarning}
            isError={isError}
            messageContrast={hintMessageContrast}
            data-test-class={DATA_TEST_CLASS.INPUT_HINT}
            data-test-id={DATA_TEST_CLASS.INPUT_HINT}
          >
            {hint}
          </Hint>
        </Flex>
      </Label>
    )
  }
)
