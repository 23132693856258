import { CardElement } from '@stripe/react-stripe-js'
import PropTypes from 'prop-types'
import React from 'react'
import isRequiredIf from 'react-proptype-conditional-require'
import { Box } from 'components/Layout'

export const CardInput = ({
  postalCode,
  setIsFocused,
  onChange,
  cardInputName,
  onBlur: onBlurHandler,
  ...props
}) => (
  <Box width="100%">
    <CardElement
      onFocus={() => {
        setIsFocused(true)
      }}
      onBlur={() => {
        setIsFocused(false)
        if (onBlurHandler) {
          onBlurHandler({
            target: {
              name: cardInputName,
            },
          })
        }
      }}
      onChange={onChange}
      options={{
        value: { postalCode },
        hidePostalCode: Boolean(postalCode),
      }}
      {...props}
    />
  </Box>
)

CardInput.defaultProps = {
  postalCode: '',
  onBlur: null,
  isCardInput: false,
  cardInputName: 'card',
}

CardInput.propTypes = {
  postalCode: PropTypes.string,
  onBlur: PropTypes.func,
  setIsFocused: PropTypes.func,
  onChange: PropTypes.func,
  isCardInput: PropTypes.bool,
  cardInputName: isRequiredIf(
    PropTypes.string,
    ({ isCardInput }) => isCardInput
  ),
}
