import React from 'react'
import styled, { css } from 'styled-components'
import { type IconProps } from '../IconPropsInterface'

const StyledSVG = styled.svg`
  ${({ hoveringColor }) =>
    hoveringColor &&
    css`
      &:hover {
        path {
          fill: ${hoveringColor};
        }
      }
    `}
`

export const AccountOut: React.FC<React.PWC<IconProps>> = ({
  size,
  color,
  hoveringColor,
}) => (
  <StyledSVG
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    hoveringColor={hoveringColor}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 7C15.5 8.933 13.933 10.5 12 10.5C10.067 10.5 8.5 8.933 8.5 7C8.5 5.067 10.067 3.5 12 3.5C13.933 3.5 15.5 5.067 15.5 7ZM17 7C17 9.76142 14.7614 12 12 12C9.23858 12 7 9.76142 7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7ZM3.75 22C3.75 19.9142 5.91392 15.75 11.9998 15.75C18.0858 15.75 20.25 19.9142 20.25 22C20.25 22.4142 20.5858 22.75 21 22.75C21.4142 22.75 21.75 22.4142 21.75 22C21.75 19.0858 18.9142 14.25 11.9998 14.25C5.08544 14.25 2.25 19.0858 2.25 22C2.25 22.4142 2.58579 22.75 3 22.75C3.41421 22.75 3.75 22.4142 3.75 22Z"
      fill={color}
    />
  </StyledSVG>
)
