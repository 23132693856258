import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const WarningProposition65: React.FC<React.PWC<IconProps>> = ({
  size,
}) => (
  <svg
    version="1.1"
    id="svg2"
    x="0px"
    y="0px"
    width={size}
    height={size}
    viewBox="0 0 576.539 507.943"
    enableBackground="new 0 0 576.539 507.943"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs id="defs1312" />
    <path
      id="path8"
      d="M31.39,494.183h512.191c6.617,0,12.734-3.531,16.043-9.265  c3.311-5.73,3.311-12.794,0-18.524L303.529,22.822c-3.31-5.732-9.425-9.263-16.043-9.263c-6.619,0-12.734,3.531-16.043,9.263  L15.346,466.394c-3.31,5.73-3.31,12.794,0,18.524C18.655,490.65,24.771,494.183,31.39,494.183z"
    />
    <polygon
      id="polygon10"
      fill="#FFFF00"
      points="287.485,69.346 511.312,457.025 63.658,457.025 "
    />
    <circle id="circle12" cx="287.485" cy="399.078" r="25.644" />
    <path
      id="path14"
      d="M303.068,344.582l18.396-167.807c1.5-13.68-5.305-26.936-17.297-33.69  c-11.99-6.754-26.853-5.705-37.775,2.668c-10.922,8.373-15.797,22.454-12.389,35.787l19.122,166.535  c0.615,5.355,4.044,9.977,8.993,12.116c4.949,2.14,10.665,1.473,14.989-1.749C301.429,355.222,303.703,349.935,303.068,344.582"
    />
  </svg>
)
