import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const PlayVideo: React.FC<React.PWC<IconProps>> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 84 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="84" height="84" rx="42" fill="white" />
    <path d="M38 33L50 42L38 51L38 33Z" fill={color} />
  </svg>
)
