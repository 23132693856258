import { subtract, find } from 'lodash'
import { CURRENCY_ISO_CODE } from 'constants/common'
import {
  getTotal,
  mapProductVariantToItem,
  slugifyString,
  validateCheckoutResponse,
} from 'providers/googleAnalytics/utils'
import { prefixShowroom } from 'providers/googleAnalytics/utils/prefixes'
import {
  type CheckoutItemEdge,
  type OrderItem,
  type OrderShipment,
} from 'types/graphql-generated'
import { add, divide, multiply } from 'utils/money'
import {
  GA4_AREAS,
  GA4_SHIPPING_TYPES,
  type PurchaseTransformationProps,
} from '../../types'

type ExtractEntityAndDiscountFromNodeReturnType = {
  orderShipments: NodeWithDiscount<OrderShipment>[]
  orderItems: NodeWithDiscount<OrderItem>[]
}

type NodeWithDiscount<T> = T & {
  discountBase: number
  discountTotal: number
  discountPrice: DiscountPrice
}

type DiscountPrice = {
  base: number
  vat: number
  total: number
}

const extractEntityAndDiscountFromNode = (
  acc: ExtractEntityAndDiscountFromNodeReturnType,
  { node }: CheckoutItemEdge
) => {
  const entity = node.orderItem || node.orderShipment
  const { discountedPrice } = node

  if (!entity) {
    return acc
  }

  const totalDiscount = subtract(entity.price.base, discountedPrice.base)
  const quantity = node.orderItem ? node.orderItem.quantity : 1
  const values = {
    ...entity,
    discountPrice: discountedPrice,
    discountBase: divide(totalDiscount, quantity),
    discountTotal: totalDiscount,
  }

  if (node.orderItem) {
    acc.orderItems.push(values as NodeWithDiscount<OrderItem>)
  } else if (node.orderShipment) {
    acc.orderShipments.push(values as NodeWithDiscount<OrderShipment>)
  }
  return acc
}

export const formatCoupon = ({
  coupon,
  promoCode,
}: {
  coupon?: string
  promoCode?: string
}) => {
  if (coupon && promoCode) {
    return `coupon:${coupon} | promoCode:${promoCode}`
  }
  if (coupon) {
    return coupon
  }
  if (promoCode) {
    return promoCode
  }
  return ''
}

export const ShowroomTransformation = ({
  contextData,
}: PurchaseTransformationProps) => {
  const { checkoutResponse, paymentMethod } = contextData
  validateCheckoutResponse(checkoutResponse, 'showroom purchase')

  const MAPPED_SHIPMENT_TYPENAME_TO_GA4_TYPE = {
    OrderShipmentArtaQuote: GA4_SHIPPING_TYPES.ARTA,
    OrderShipmentFreightClubQuote: GA4_SHIPPING_TYPES.FREIGHT_CLUB,
    OrderShipmentVendorShipping: GA4_SHIPPING_TYPES.VENDOR,
  }

  const {
    id: checkoutId,
    checkoutItems,
    totalPrice,
    promoCode,
    coupon,
  } = checkoutResponse.data
  const currencyIsoCode = totalPrice?.currencyIsoCode || CURRENCY_ISO_CODE.USD

  const edges = checkoutItems?.edges
  if (!edges?.length) {
    throw new Error('Checkout items are not provided in showroom purchase')
  }

  const { orderItems, orderShipments } = edges.reduce(
    extractEntityAndDiscountFromNode,
    {
      orderItems: [],
      orderShipments: [],
    }
  )

  const orderShipmentsData = orderShipments.map(
    ({ shipmentData }) => shipmentData
  )
  const orderShipmentTypes = Array.from(
    new Set(
      orderShipmentsData.map(
        ({ __typename: typename }) =>
          MAPPED_SHIPMENT_TYPENAME_TO_GA4_TYPE[typename]
      )
    )
  )
  const orderShipmentTiers = orderShipmentsData.map((item) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { name, __typename } = item

    if (__typename === 'OrderShipmentArtaQuote') {
      const { services, type } = item
      const tier = find(services, { subtype: type })?.name ?? name

      return slugifyString(tier)
    }

    return slugifyString(name)
  })
  const basePriceTotal = getTotal(orderItems, 'price.base')
  const shippingPriceTotal = getTotal(orderShipments, 'discountPrice.base')
  const discountedBasePriceTotal = getTotal(orderItems, 'discountPrice.base')
  const taxPriceTotal = getTotal(orderItems, 'discountPrice.vat')
  const totalPriceTotal = getTotal(orderItems, 'discountPrice.total')
  const totalDiscount = getTotal(orderItems, 'discountTotal')
  const discountRate =
    totalDiscount > 0 ? multiply(divide(totalDiscount, basePriceTotal), 100) : 0

  return {
    ecommerce: {
      area: GA4_AREAS.SHOWROOM,
      transaction_id: prefixShowroom(checkoutId),
      affiliation: null,
      value: discountedBasePriceTotal,
      tax: taxPriceTotal,
      shipping: shippingPriceTotal,
      currency: currencyIsoCode,
      payment: add(totalPriceTotal, shippingPriceTotal),
      payment_type: paymentMethod,
      discount_all: totalDiscount,
      discount_rate: discountRate,
      coupon: formatCoupon({
        coupon: coupon?.code,
        promoCode: promoCode?.code,
      }),

      shipping_type: orderShipmentTypes.join(' / '),
      shipping_tier: orderShipmentTiers.join(' / '),

      items: orderItems.map(
        ({
          productVariant,
          price,
          discountPrice,
          quantity,
          metadata,
          discountBase,
        }) => {
          // Object destructuring needed for computing unit price
          const { base } = discountPrice

          return {
            ...mapProductVariantToItem({
              ...productVariant,
              discount: discountBase,
              price: { ...price, base: divide(base, quantity) },
            }),
            ...(metadata?.ga4 && {
              index: metadata.ga4.listIndex,
              item_list_name: metadata.ga4.listName,
            }),
            item_list_id: null,
            quantity,
          }
        }
      ),
    },
  }
}
