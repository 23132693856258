import React, { type ReactElement, type ReactNode } from 'react'
import styled from 'styled-components'
import { Box, Flex } from 'components/Layout'
import { SPACE } from 'Theme'
import { BUTTON_ICON_POSITION } from './constants'

const IconWrapper = styled(Box)`
  display: inline-flex;
  ${({ hasChildren }) =>
    hasChildren ? `padding: 0 ${SPACE.PX_5};` : 'width: 100%;'}
  ${({ isHidden }) => isHidden && `visibility: hidden`};
`

const ChildrenWrapper = styled(Box)`
  ${({ isHidden }) => isHidden && `visibility: hidden`};
`

export interface ButtonContentProps {
  icon?: ReactElement
  loader: ReactNode
  isLoading?: boolean
  iconPosition?: (typeof BUTTON_ICON_POSITION)[keyof typeof BUTTON_ICON_POSITION]
}

export const ButtonContent: React.FC<React.PWC<ButtonContentProps>> = ({
  loader,
  icon,
  iconPosition,
  children,
  isLoading = false,
}) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    width="100%"
    position="relative"
  >
    {loader && isLoading && (
      <Flex
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        justifyContent="center"
        alignItems="center"
      >
        {loader}
      </Flex>
    )}
    {icon && iconPosition === BUTTON_ICON_POSITION.LEFT && (
      <IconWrapper isHidden={isLoading} hasChildren={Boolean(children)}>
        {icon}
      </IconWrapper>
    )}
    {children && (
      <ChildrenWrapper isHidden={isLoading}>{children}</ChildrenWrapper>
    )}
    {icon && iconPosition === BUTTON_ICON_POSITION.RIGHT && (
      <IconWrapper isHidden={isLoading} hasChildren={Boolean(children)}>
        {icon}
      </IconWrapper>
    )}
  </Flex>
)
