import PropTypes from 'prop-types'
import React from 'react'

export const FilledBookmark = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 21C20.0007 21.3639 19.8037 21.6994 19.4856 21.8762C19.1676 22.0529 18.7786 22.0429 18.47 21.85L12.53 18.13C12.2057 17.9273 11.7943 17.9273 11.47 18.13L5.53 21.85C5.22143 22.0429 4.83245 22.0529 4.51436 21.8762C4.19626 21.6994 3.99927 21.3639 4 21V4C4 2.89543 4.89543 2 6 2H18C19.1046 2 20 2.89543 20 4V21Z"
      fill={color}
    />
  </svg>
)

FilledBookmark.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
