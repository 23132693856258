import PropTypes from 'prop-types'
import React from 'react'

export const Upload = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20ZM12.19 6C12.3884 6.0023 12.5783 6.08112 12.72 6.22L15.85 9.35C15.9447 9.44388 15.9979 9.57168 15.9979 9.705C15.9979 9.83832 15.9447 9.96612 15.85 10.06L15.14 10.77C15.0478 10.8641 14.9217 10.9171 14.79 10.9171C14.6583 10.9171 14.5322 10.8641 14.44 10.77L13 9.33V17.5C13 17.7761 12.7761 18 12.5 18H11.5C11.2239 18 11 17.7761 11 17.5V9.33L9.56 10.78C9.46612 10.8747 9.33832 10.9279 9.205 10.9279C9.07168 10.9279 8.94388 10.8747 8.85 10.78L8.15 10.07C8.05534 9.97612 8.0021 9.84832 8.0021 9.715C8.0021 9.58168 8.05534 9.45388 8.15 9.36L11.28 6.22C11.4205 6.07931 11.6112 6.00017 11.81 6H12.19Z"
      fill={color}
    />
  </svg>
)

Upload.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
