import PropTypes from 'prop-types'
import React from 'react'

export const CommentPlay = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 3H20C21.1046 3 22 3.89543 22 5V17C22 18.1046 21.1046 19 20 19H14.91C14.6477 19.0011 14.3963 19.1053 14.21 19.29L12.72 20.78C12.5795 20.9207 12.3888 20.9998 12.19 21H11.81C11.6112 20.9998 11.4205 20.9207 11.28 20.78L9.79 19.29C9.60374 19.1053 9.35234 19.0011 9.09 19H4C2.89543 19 2 18.1046 2 17V5C2 3.89543 2.89543 3 4 3ZM11.17 14.22L15.06 11.62C15.2696 11.4834 15.396 11.2502 15.396 11C15.396 10.7498 15.2696 10.5166 15.06 10.38L11.17 7.78C10.9403 7.62476 10.6438 7.60887 10.3988 7.73867C10.1539 7.86846 10.0005 8.12279 10 8.4V13.6C10.0005 13.8772 10.1539 14.1315 10.3988 14.2613C10.6438 14.3911 10.9403 14.3752 11.17 14.22Z"
      fill={color}
    />
  </svg>
)

CommentPlay.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
