import styled, { css } from 'styled-components'

export const AnimatedPathWrapper = styled.svg`
  ${({ hoveringColor }) =>
    hoveringColor &&
    css`
      &:hover {
        path {
          stroke: ${hoveringColor};
        }
      }
    `}
`
