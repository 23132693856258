import { transparentize } from 'polished'
import { css } from 'styled-components'
import { space, typography, color, border } from 'styled-system'
import { Text } from 'components/Typography'
import {
  BORDER_WIDTH,
  COLOR,
  COLOR_INTENT,
  FONT_SIZE,
  FONT_WEIGHT,
  GRADIENT,
  ICON_SPACE,
  RADIUS,
  SPACE,
} from 'Theme'
import {
  BUTTON_SIZE,
  BUTTON_INTENT,
  BUTTON_ICON_OPPOSITE_POSITION,
  BUTTON_VARIANT,
  BUTTON_GRADIENT,
  SHARP_BUTTON_INTENT,
} from './constants'

export const baseButtonStyles = css`
  ${({ hasChildren, width }) =>
    hasChildren &&
    css`
      width: ${width};
    `};
  display: inline-flex;
  position: relative;
  align-items: center;
  white-space: nowrap;
  border-radius: ${RADIUS.PX_6};
  padding: 0 ${SPACE.PX_15};
  font-size: ${FONT_SIZE.PX_14};
  height: ${BUTTON_SIZE.M};
  font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  color: ${COLOR_INTENT.BUTTON.TEXT_LIGHT};
  background-color: ${COLOR_INTENT.BUTTON.BACKGROUND_PRIMARY};
  ${({ intent }) => {
    switch (intent) {
      case BUTTON_INTENT.SUCCESS:
      case BUTTON_INTENT.WARNING:
      case BUTTON_INTENT.DANGER:
      case BUTTON_INTENT.BLACK:
        return css`
          background-color: ${intent};
        `
      case BUTTON_INTENT.NONE:
        return css`
          background: linear-gradient(${GRADIENT.GRADIENT_1}),
            ${COLOR_INTENT.BUTTON.BACKGROUND_LIGHT};
          border: ${BORDER_WIDTH.PX_1} solid ${COLOR_INTENT.BUTTON.BORDER};
          color: ${COLOR_INTENT.BUTTON.TEXT_DARK};
        `
      default:
        return ''
    }
  }}
  ${({ hasIcon, size, hasChildren, iconPosition }) => {
    if (hasIcon && hasChildren) {
      const composedStyle = (style) => css`
        padding-${BUTTON_ICON_OPPOSITE_POSITION[iconPosition].toLowerCase()}: ${
          SPACE.PX_15
        };
        ${style}
      `
      const padding = css`padding-${iconPosition.toLowerCase()}`
      if (size === BUTTON_SIZE.S) {
        return composedStyle(css`
          ${padding}: ${ICON_SPACE.PX_6};
        `)
      }
      if (size === BUTTON_SIZE.L) {
        return composedStyle(css`
          ${padding}: ${SPACE.PX_10};
        `)
      }
      return composedStyle(css`
        ${padding}: ${ICON_SPACE.PX_8};
      `)
    }
    if (hasIcon) {
      if (size === BUTTON_SIZE.S) {
        return css`
          padding: ${ICON_SPACE.PX_6};
        `
      }
      if (size === BUTTON_SIZE.L) {
        return css`
          padding: ${SPACE.PX_10};
        `
      }
      return css`
        padding: ${ICON_SPACE.PX_8};
      `
    }
    return ''
  }}
  ${({ size }) =>
    size &&
    css`
      height: ${size};
    `}
  ${({ variant, intent, isDisabled, isLoading }) => {
    if (variant === BUTTON_VARIANT.DEFAULT) {
      if (isDisabled || isLoading) {
        return css`
          opacity: 0.5;
          pointer-events: none;
        `
      }
      return css`
        &:hover {
          ${intent === BUTTON_INTENT.NONE
            ? `background: linear-gradient(${GRADIENT.GRADIENT_1_HOVER}), ${COLOR_INTENT.BUTTON.BACKGROUND_LIGHT};`
            : `background: linear-gradient(${BUTTON_GRADIENT.HOVER}), ${intent};`}
        }
        &:active {
          ${intent === BUTTON_INTENT.NONE
            ? `background: linear-gradient(${BUTTON_GRADIENT.ACTIVE_NONE}), ${COLOR_INTENT.BUTTON.BACKGROUND_LIGHT};`
            : `background: linear-gradient(${BUTTON_GRADIENT.ACTIVE}), ${intent};`}
        }
      `
    }
    if (variant === BUTTON_VARIANT.OUTLINE) {
      const style = css`
        border: ${BORDER_WIDTH.PX_2} solid ${transparentize(0.9, intent)};
        background: ${COLOR_INTENT.BUTTON.BACKGROUND_LIGHT};
        color: ${intent};
      `
      if (isDisabled || isLoading) {
        return css`
          ${style}
          pointer-events: none;
          opacity: 0.5;
        `
      }
      return css`
        ${style}
        &:hover {
          border-color: ${transparentize(0.8, intent)};
        }
        &:active {
          border-color: ${transparentize(0.7, intent)};
        }
      `
    }
    if (variant === BUTTON_VARIANT.FILLED) {
      const style = css`
        border: ${BORDER_WIDTH.PX_2} solid ${COLOR_INTENT.TEXT_ON_LIGHT};
        background: ${COLOR_INTENT.BUTTON.BACKGROUND_SECONDARY};
        color: ${intent};
      `
      if (isDisabled || isLoading) {
        return css`
          ${style}
          pointer-events: none;
          opacity: 0.75;
        `
      }
      return css`
        ${style}
        &:hover {
          background: ${COLOR_INTENT.BUTTON.BACKGROUND_DARK};
        }
        &:active {
          background: ${COLOR_INTENT.BUTTON.BACKGROUND_DARK};
        }
      `
    }
    if (variant === BUTTON_VARIANT.MINIMAL) {
      const style = css`
        background: none;
        border: none;
        color: ${intent};
      `
      if (isDisabled || isLoading) {
        return css`
          ${style}
          pointer-events: none;
          ${Text} {
            opacity: 0.5;
          }
        `
      }
      return css`
        ${style}
        &:hover {
          background: ${transparentize(0.9, intent)};
        }
        &:active {
          background: ${transparentize(0.8, intent)};
        }
      `
    }
    return ''
  }}
  ${space};
  ${typography};
  ${color};
  ${border};
`

export const baseSharpButtonStyles = css`
  border-radius: 0;
  text-transform: uppercase;
  font-weight: ${FONT_WEIGHT.MEDIUM};
  height: 100%;
  line-height: 1em;
  ${({ intent }) => {
    switch (intent) {
      case SHARP_BUTTON_INTENT.PRIMARY:
        return css`
          background-color: ${SHARP_BUTTON_INTENT.PRIMARY};
        `
      case SHARP_BUTTON_INTENT.SECONDARY:
        return css`
          background-color: ${SHARP_BUTTON_INTENT.SECONDARY};
          color: ${COLOR.PRIMARY_1};
        `
      default:
        return ''
    }
  }}
`
