import ct from 'countries-and-timezones'
import {
  DEPRECATED_TIMEZONES,
  additionalTimezones,
  mapCustomizedTimezoneToTimezone,
  mapTimezoneToCustomizedTimezone,
  formatTimezoneToString,
} from 'shared-constants'

const allTimezones = Object.values(ct.getAllTimezones())

export const timezones = [
  ...allTimezones.filter(({ name }) => !DEPRECATED_TIMEZONES.includes(name)),
  ...additionalTimezones,
]

export {
  mapCustomizedTimezoneToTimezone,
  mapTimezoneToCustomizedTimezone,
  additionalTimezones,
  formatTimezoneToString,
}
