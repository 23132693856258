import dynamic from 'next/dynamic'
import React from 'react'
import styled from 'styled-components'
import { Flex } from 'components/Layout'
import { useValidationErrorTrackingForGA4Provider } from 'providers/googleAnalytics/utils/ErrorTracking/useValidationErrorTrackingForGA4'
import { RADIUS } from 'Theme'
import { useMedia } from 'useMedia'
import 'react-datepicker/dist/react-datepicker.css'
import { DatePickerInput, type DatePickerInputProps } from './DatePickerInput'
import { MonthHeader } from './Headers'

const DayWrapper = styled(Flex)`
  border-radius: ${RADIUS.CIRCLE};
`

export interface ReactDatePickerCustomHeaderProps {
  monthDate: Date
  date: Date
  changeYear(year: number): void
  changeMonth(month: number): void
  customHeaderCount: number
  decreaseMonth(): void
  increaseMonth(): void
  prevMonthButtonDisabled: boolean
  nextMonthButtonDisabled: boolean
  decreaseYear(): void
  increaseYear(): void
  prevYearButtonDisabled: boolean
  nextYearButtonDisabled: boolean
}

type ReactDatePickerProps = {
  disabledKeyboardNavigation?: boolean
  selected?: Date | null
  disabled?: boolean
  onChange(
    date: Date | null | [Date | null, Date | null],
    event: React.SyntheticEvent<any> | undefined
  ): void
  inline?: boolean
  selectsRange?: boolean
  startDate?: Date | null
  openToDate?: Date
  endDate?: Date | null
  formatWeekDay?(day: string): React.ReactNode
  maximumDate?: Date | null
  minimumDate?: Date | null
  placeholder?: string
  customHeader?(params: ReactDatePickerCustomHeaderProps): React.ReactNode
  minDate?: Date | null
  maxDate?: Date | null
  withPortal?: boolean
  portalId?: string
  closeOnScroll?: boolean | ((e: Event) => boolean)
  placeholderText?: string
  renderCustomHeader?(params: ReactDatePickerCustomHeaderProps): React.ReactNode
  showPopperArrow?: boolean
  showTimeInput?: boolean
  customInput?: React.ReactNode
  renderDayContents?(dayOfMonth: number, date?: Date): React.ReactNode
  dateFormat?: string | string[]
  fixedHeight?: boolean
}

const ReactDatePicker = dynamic<ReactDatePickerProps>(
  () => import('react-datepicker'),
  {
    ssr: false,
  }
)

interface DatePickerProps
  extends Pick<
      ReactDatePickerProps,
      | 'selected'
      | 'onChange'
      | 'inline'
      | 'selectsRange'
      | 'startDate'
      | 'openToDate'
      | 'endDate'
      | 'formatWeekDay'
      | 'dateFormat'
      | 'fixedHeight'
    >,
    Pick<
      DatePickerInputProps,
      | 'label'
      | 'isError'
      | 'isWarning'
      | 'isDisabled'
      | 'hint'
      | 'hasHint'
      | 'isWithClearButton'
      | 'onClearButtonClick'
    > {
  maximumDate?: ReactDatePickerProps['maxDate']
  minimumDate?: ReactDatePickerProps['minDate']
  placeholder?: ReactDatePickerProps['placeholderText']
  customHeader?: ReactDatePickerProps['renderCustomHeader']

  isWithPortal?: boolean
  isWithTimeInput?: boolean
}

export const DatePicker: React.FC<DatePickerProps> = ({
  selected,
  onChange,
  label,
  maximumDate,
  minimumDate,
  placeholder = 'Select a date',
  isError,
  isWarning,
  isDisabled,
  hint,
  hasHint,
  customHeader = MonthHeader,
  isWithPortal = true,
  isWithClearButton,
  onClearButtonClick,
  isWithTimeInput,
  ...props
}) => {
  useValidationErrorTrackingForGA4Provider({
    hint,
    isError,
  })

  const media = useMedia()
  const isMobile = media.MOBILE
  return (
    <ReactDatePicker
      {...props}
      disabledKeyboardNavigation
      selected={selected}
      onChange={onChange}
      disabled={isDisabled}
      minDate={minimumDate}
      maxDate={maximumDate}
      withPortal={isWithPortal && isMobile}
      portalId="datepicker-portal"
      closeOnScroll
      placeholderText={placeholder}
      showPopperArrow={false}
      showTimeInput={isWithTimeInput}
      renderCustomHeader={customHeader}
      customInput={
        <DatePickerInput
          isWithClearButton={isWithClearButton}
          label={label}
          hint={hint}
          hasHint={hasHint}
          isError={isError}
          isWarning={isWarning}
          onClearButtonClick={onClearButtonClick}
          isDisabled={isDisabled}
        />
      }
      renderDayContents={(value) => (
        <DayWrapper justifyContent="center">{value}</DayWrapper>
      )}
    />
  )
}
