import { truncate } from 'lodash'
import type { TransformationProps } from '../types'
import {
  type PromotionItem,
  formatPromotionItem,
} from '../utils/Promotion/utils'

interface SelectPromotionTransformationContextData {
  item: PromotionItem & {
    selectedElement: string
  }
}

type SelectPromotionTransformationProps = TransformationProps<
  SelectPromotionTransformationContextData,
  'contextData'
>

export const SelectPromotionTransformation = ({
  contextData,
}: SelectPromotionTransformationProps) => {
  const { item } = contextData ?? {}

  if (!item) {
    throw new Error('Item not provided in select promotion transformation')
  }

  const { selectedElement } = item

  return {
    ecommerce: {
      items: [
        {
          ...formatPromotionItem(item),
          select: truncate(selectedElement, { length: 90 }),
        },
      ],
    },
  }
}
