const prefixer = (prefix: string) => (value: string | number) =>
  `${prefix}-${value}`

export const prefixConsultation = prefixer('C')
export const prefixGiftCards = prefixer('G')
export const prefixShowroom = prefixer('S')
export const prefixProcurement = prefixer('P')

/**
 * This prefix is used for coupon property
 */
export const prefixCoupon = prefixer('Coupon')
