import { isEmpty } from 'lodash'
import { compose, tap, pick } from 'lodash/fp'
import { useRouter } from 'next/router'
import { setCookie, parseCookies } from 'nookies'
import React, { useCallback, useEffect, useRef } from 'react'
import { useRouteChange } from 'hooks/useRouteChange'
import { useGoogleAnalytics } from './googleAnalyticsProvider'
import { GA4_EVENTS } from './types'

const COOKIEYES_EVENT_NAME = 'cookieyes_consent_update' as const

const DEFAULT_COOKIEYES_SETTINGS_COOKIE_NAME =
  'default-cookieyes-consent-settings' as const

const LEGACY_COOKIEYES_ACTION_COOKIE_NAME = 'cky-action' as const

const LEGACY_COOKIEYES_COOKIE_NAMES = [
  'cookieyes-advertisement',
  'cookieyes-analytics',
  'cookieyes-functional',
  'cookieyes-performance',
  'cookieyes-necessary',
] as const

const COOKIEYES_COOKIE_SETTINGS_COOKIE = 'cookieyes-consent' as const

const NON_LEGACY_COOKIEYES_COOKIE_VALUES = {
  consentid: 'consentid',
  consent: 'consent',
  action: 'action',
  other: 'other',
  advertisement: 'advertisement',
  analytics: 'analytics',
  functional: 'functional',
  performance: 'performance',
  necessary: 'necessary',
} as const

const NON_LEGACY_COOKIEYES_COOKIE_NAMES = Object.values(
  NON_LEGACY_COOKIEYES_COOKIE_VALUES
)

type DefaultCookieSettings = {
  [DEFAULT_COOKIEYES_SETTINGS_COOKIE_NAME]?: string
}

type LegacyCookieYes = {
  [K in (typeof LEGACY_COOKIEYES_COOKIE_NAMES)[number]]?: 'yes' | 'no'
} & {
  [LEGACY_COOKIEYES_ACTION_COOKIE_NAME]?: string
} & DefaultCookieSettings

type NonLegacyCookieYes = {
  [COOKIEYES_COOKIE_SETTINGS_COOKIE]?: string
} & DefaultCookieSettings

type NonLegacyCookieYesCookies = {
  [K in (typeof NON_LEGACY_COOKIEYES_COOKIE_NAMES)[number]]?: 'yes' | 'no'
}

type CookieYesEvent = { detail: { accepted: string[] } } & Event

const setCookieConsentCookie = (acceptedCookies: string[]) => {
  if (acceptedCookies.length) {
    setCookie(
      null,
      DEFAULT_COOKIEYES_SETTINGS_COOKIE_NAME,
      acceptedCookies.join(',')
    )
  }
  return acceptedCookies
}

const formatCookiesInput = (event: CookieYesEvent) =>
  event.detail?.accepted ?? []

/**
 * Production still uses legacy version which sets each cookie separately in sessionStorage
 */
const isLegacyVersion = (cookies: { [key: string]: string }) =>
  !Object.keys(cookies).includes(COOKIEYES_COOKIE_SETTINGS_COOKIE)

const getCookieYesCookies = () => {
  const cookies = parseCookies()

  if (isLegacyVersion(cookies)) {
    return pick<LegacyCookieYes>(
      [
        ...LEGACY_COOKIEYES_COOKIE_NAMES,
        DEFAULT_COOKIEYES_SETTINGS_COOKIE_NAME,
        LEGACY_COOKIEYES_ACTION_COOKIE_NAME,
      ],
      cookies
    )
  }

  return pick<NonLegacyCookieYes>(
    [COOKIEYES_COOKIE_SETTINGS_COOKIE, DEFAULT_COOKIEYES_SETTINGS_COOKIE_NAME],
    cookies
  )
}

const parseNonLegacyCookieYesCookieSettings = (
  cookies: NonLegacyCookieYes
): NonLegacyCookieYesCookies => {
  const cookieSettings = cookies[COOKIEYES_COOKIE_SETTINGS_COOKIE].split(',')

  return cookieSettings.reduce((acc, value) => {
    const [cookieName, cookieValue] = value.split(':')
    return { ...acc, [cookieName]: cookieValue || 'no' }
  }, {})
}

const getNonLegacyCookieYesCookies = (cookies: NonLegacyCookieYes) => {
  const cookieSettings = parseNonLegacyCookieYesCookieSettings(cookies)

  return pick<NonLegacyCookieYesCookies>(
    [
      NON_LEGACY_COOKIEYES_COOKIE_VALUES.advertisement,
      NON_LEGACY_COOKIEYES_COOKIE_VALUES.analytics,
      NON_LEGACY_COOKIEYES_COOKIE_VALUES.functional,
      NON_LEGACY_COOKIEYES_COOKIE_VALUES.performance,
      NON_LEGACY_COOKIEYES_COOKIE_VALUES.necessary,
    ],
    cookieSettings
  )
}

const getDefaultCookies = (
  cookies: LegacyCookieYes | NonLegacyCookieYes = {}
) => cookies[DEFAULT_COOKIEYES_SETTINGS_COOKIE_NAME]?.split(',') ?? []

const getAcceptedCookies = (
  cookies: LegacyCookieYes | NonLegacyCookieYes = {}
) => {
  if (isLegacyVersion(cookies)) {
    return Object.entries(cookies).reduce((acc, [cookieName, value]) => {
      if (value === 'yes') {
        const [, cookie] = cookieName.split('cookieyes-')

        if (!cookie) {
          return acc
        }

        return [...acc, cookie]
      }

      return acc
    }, [])
  }

  const cookieSettings = getNonLegacyCookieYesCookies(cookies)

  return Object.entries(cookieSettings).reduce(
    (acc, [cookieName, cookieValue]) =>
      cookieValue === 'yes' ? [...acc, cookieName] : acc,
    []
  )
}

export const CookieConsentTracker: React.FC<React.PWC<unknown>> = ({
  children,
}) => {
  const { executeDataToDataLayer, asPathWithoutQueryParams } =
    useGoogleAnalytics()
  const { hasChangedRoute } = useRouteChange()
  const router = useRouter()

  const initialConsentLoaded = useRef(false)
  const initialDefaultConsentLoaded = useRef(false)

  const pushGA4CookieConstentEvent = useCallback(
    (
      event:
        | typeof GA4_EVENTS.COOKIES_DEFAULT
        | typeof GA4_EVENTS.COOKIES_UPDATE
    ) =>
      (data: string[]) => {
        executeDataToDataLayer({
          event,
          overrideData: { consent: { detail: { accepted: data } } },
        })
      },
    [executeDataToDataLayer]
  )

  const handleCookieYesEvent = (event: CookieYesEvent) => {
    if (!initialConsentLoaded.current) {
      compose(
        tap(pushGA4CookieConstentEvent(GA4_EVENTS.COOKIES_UPDATE)),
        tap(pushGA4CookieConstentEvent(GA4_EVENTS.COOKIES_DEFAULT)),
        setCookieConsentCookie,
        formatCookiesInput
      )(event)
    } else {
      compose(
        tap(pushGA4CookieConstentEvent(GA4_EVENTS.COOKIES_UPDATE)),
        formatCookiesInput
      )(event)
    }

    initialConsentLoaded.current = true
  }

  useEffect(() => {
    document.addEventListener(COOKIEYES_EVENT_NAME, handleCookieYesEvent)

    const executeCookieConsentEvents = (
      _?: string,
      { shallow }: { shallow: boolean } = { shallow: false }
    ) => {
      // Handle filters change
      if (shallow || !hasChangedRoute) {
        return
      }

      const cookies = getCookieYesCookies()

      if (isEmpty(cookies)) {
        return
      }

      const defaultCookies = getDefaultCookies(cookies)
      const acceptedCookies = getAcceptedCookies(cookies)

      if (
        initialConsentLoaded.current ||
        cookies[LEGACY_COOKIEYES_ACTION_COOKIE_NAME] === 'yes' ||
        cookies[COOKIEYES_COOKIE_SETTINGS_COOKIE]?.includes('action:yes,')
      ) {
        pushGA4CookieConstentEvent(GA4_EVENTS.COOKIES_DEFAULT)(defaultCookies)
        pushGA4CookieConstentEvent(GA4_EVENTS.COOKIES_UPDATE)(acceptedCookies)
      }
    }

    // Workaround: this should be handled by using useCallback on dependencies
    if (!initialDefaultConsentLoaded.current) {
      executeCookieConsentEvents()
      initialDefaultConsentLoaded.current = true
    }

    router.events?.on('routeChangeComplete', executeCookieConsentEvents)

    return () => {
      document.removeEventListener(COOKIEYES_EVENT_NAME, handleCookieYesEvent)
      router.events?.off('routeChangeComplete', executeCookieConsentEvents)
    }
    // TODO: Add pushGA4CookieConstentEvent to dependency array when executeDataToDataLayer will be in useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPathWithoutQueryParams, hasChangedRoute])

  return <>{children}</>
}
