import React from 'react'
import { ToastContainer, Slide } from 'react-toastify'
import styled from 'styled-components'
import { FONT_STACK, BOX_SHADOW, COLOR, SPACE, RADIUS } from 'Theme'
import 'react-toastify/dist/ReactToastify.min.css'

const StyledContainer = styled(ToastContainer)`
  .Toastify__toast-container {
    margin-bottom: ${SPACE.PX_5};
  }
  .Toastify__toast {
    min-height: initial;
    box-shadow: ${BOX_SHADOW.DARK};
    border-radius: ${RADIUS.PX_6};
    font-family: ${FONT_STACK.SANS};
    opacity: 0.9;
    padding: ${SPACE.PX_5} ${SPACE.PX_10};
  }
  .Toastify__toast--default {
    color: ${COLOR.BLACK};
    background: ${COLOR.WHITE};
  }
  .Toastify__toast--error {
    color: ${COLOR.WHITE};
    background: ${COLOR.SECONDARY_1};
  }
  .Toastify__toast--info {
    color: ${COLOR.WHITE};
    background: ${COLOR.SECONDARY_6};
  }
  .Toastify__toast--success {
    color: ${COLOR.WHITE};
    background: ${COLOR.SECONDARY_8};
  }
`

const Container = () => (
  <StyledContainer
    position="top-center"
    autoClose={3000}
    hideProgressBar
    newestOnTop
    closeOnClick
    rtl={false}
    pauseOnVisibilityChange
    draggable={false}
    pauseOnHover
    transition={Slide}
  />
)

export default Container
