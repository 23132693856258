import PropTypes from 'prop-types'
import React from 'react'

export const Download = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8 17C11.6 17 11.4 16.9 11.3 16.8L6.2 11.6C6 11.4 6 11.1 6.2 10.9L6.9 10.2C7 10 7.4 10 7.6 10.3L11 13.7V3.5C11 3.2 11.2 3 11.5 3H12.5C12.8 3 13 3.2 13 3.5V13.7L16.4 10.3C16.6 10.1 16.9 10.1 17.1 10.3L17.8 11C18 11.2 18 11.5 17.8 11.7L12.7 16.8C12.6 16.9 12.4 17 12.2 17H11.8ZM21 15.5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V15.5C3 15.2 3.2 15 3.5 15H4.5C4.8 15 5 15.2 5 15.5V19H19V15.5C19 15.2 19.2 15 19.5 15H20.5C20.8 15 21 15.2 21 15.5Z"
      fill={color}
    />
  </svg>
)
Download.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
