import React from 'react'
import styled from 'styled-components'
import { type SpaceProps } from 'styled-system'
import {
  type SHARP_BUTTON_INTENT,
  baseSharpButtonStyles,
} from 'components/Button'
import { SPACE } from 'Theme'
import { StyledLink, type StyledLinkProps } from './StyledLink'

export interface SharpStyledLinkProps
  extends Pick<SpaceProps, 'py' | 'px'>,
    Omit<StyledLinkProps, 'intent'> {
  intent?: (typeof SHARP_BUTTON_INTENT)[keyof typeof SHARP_BUTTON_INTENT]
}

const LinkWithStyles = styled(StyledLink)`
  ${baseSharpButtonStyles}
`

export const SharpStyledLink: React.FC<React.PWC<SharpStyledLinkProps>> = ({
  children,
  px = SPACE.PX_30,
  py = SPACE.PX_15,
  ...rest
}) => (
  <LinkWithStyles px={px} py={py} {...rest}>
    {children}
  </LinkWithStyles>
)
