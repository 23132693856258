import React from 'react'
import { type FlexboxProps } from 'styled-system'
import { type IconProps, ICON_SIZE } from 'components/Icon'
import { Box, Flex } from 'components/Layout'
import { ROUTE } from 'constants/routes'
import { COLOR_INTENT, SPACE } from 'Theme'
import Link from './Link'

interface LinkWithIconProps extends FlexboxProps {
  href: string
  isExternal?: boolean
  target?: string
  icon: typeof React.Component | React.FC<React.PWC<IconProps>>
  'data-test-id'?: string
  'aria-label'?: string | null
}

export const LinkWithIcon: React.FC<React.PWC<LinkWithIconProps>> = ({
  children,
  href = ROUTE.FRONT_PAGE(),
  isExternal,
  target,
  icon: Icon,
  'aria-label': ariaLabel = null,
  ...props
}) => (
  <Link
    href={href}
    isExternal={isExternal}
    target={target}
    aria-label={
      ariaLabel ?? (typeof children === 'string' ? children : undefined)
    }
  >
    <Flex {...props}>
      <Box mr={SPACE.PX_4}>{children}</Box>
      <Icon color={COLOR_INTENT.LINK.PRIMARY} size={ICON_SIZE.PX_16} />
    </Flex>
  </Link>
)
