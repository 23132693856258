import { SCHEMA_PROPS, SCHEMA_TYPES } from 'constants/common'
import {
  type PromotionItem,
  getElementItemScopeFlatMap,
  getSchemaPropValue,
  getSelectedElement,
} from './utils'

type GetParsedPromotionDataReturnType = Omit<PromotionItem, 'locationId'> & {
  selectedElement?: string
}

export const getParsedPromotionData = (
  targetElement: HTMLElement,
  withSelectedElementType: boolean = false
): GetParsedPromotionDataReturnType => {
  const targetScopeElement = targetElement.closest('[itemscope]')
  const thingElement = targetScopeElement.closest(
    `[itemtype="${SCHEMA_TYPES.THING}"]`
  )

  const isCreativeWorkTargetScopeElement =
    targetScopeElement.getAttribute('itemtype') === SCHEMA_TYPES.CREATIVE_WORK
  const isListItemTargetScopeElement =
    targetScopeElement.getAttribute('itemtype') === SCHEMA_TYPES.LIST_ITEM

  const targetElementScopeNodes = getElementItemScopeFlatMap(targetScopeElement)
  const thingElementScopeNodes = getElementItemScopeFlatMap(thingElement)

  return {
    widgetId: '',
    widgetTitle: getSchemaPropValue(
      isListItemTargetScopeElement
        ? thingElementScopeNodes
        : targetElementScopeNodes,
      SCHEMA_PROPS.NAME
    ),
    widgetName: getSchemaPropValue(
      isCreativeWorkTargetScopeElement
        ? targetElementScopeNodes
        : thingElementScopeNodes,
      SCHEMA_PROPS.ALTERNATE_NAME
    ),
    selectedItemName: getSchemaPropValue(
      targetElementScopeNodes,
      SCHEMA_PROPS.NAME
    ),
    selectedItemOrder:
      parseInt(
        getSchemaPropValue(
          isListItemTargetScopeElement || isCreativeWorkTargetScopeElement
            ? targetElementScopeNodes
            : thingElementScopeNodes,
          SCHEMA_PROPS.POSITION
        )
      ) || -1,
    ...(withSelectedElementType
      ? { selectedElement: getSelectedElement(targetElement) }
      : {}),
  }
}
