export const TIME_FORMAT_WITH_PERIOD = 'hh:mm a'
export const WEEK_NUMBER = 'w'
export const DAY_OF_MONTH = 'd'
export const DAY_OF_WEEK = 'EEEE'
export const DAY_OF_WEEK_FIRST_LETTER = 'EEEEE'
export const DAY_OF_WEEK_SHORTCUT = 'eee'
export const TIMEZONE = 'zzzz'
export const MONTH = 'MMMM'
export const MONTH_WITH_YEAR = 'MMMM yyyy'
export const MONTH_WITH_YEAR_SHORT = 'MMM yyyy'
export const FULL_DATETIME = 'eeee, MMMM d, hh:mm a'
export const DATETIME = 'yyyy-MM-dd HH:mm'
export const DATE_DEFAULT = 'yyyy-MM-dd'
export const MONTH_DAY_YEAR = 'MMM d, yyyy'
export const MONTH_DAY_SHORT = 'MMM d'
export const FULL_MONTH_DAY_YEAR = 'MMMM d, yyyy'
export const DAY_OF_YEAR_WITH_MONTH = 'do MMMM'
export const DAY_OF_WEEK_WITH_MONTH_DAY = 'EEEE, MMMM d'
export const LOCAL_DAY_OF_WEEK_WITH_MONTH_DAY = 'eeee, MMMM d'
export const DAY_OF_YEAR_WITH_MONTH_YEAR = 'do MMMM yyyy'
export const LOCAL_DAY_OF_WEEK_WITH_MONTH_DAY_YEAR = 'eeee, MMMM dd, yyyy'
export const LOCAL_MONTH_DAY_YEAR = 'MMMM/dd/yyyy'
