import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const NewPerson: React.FC<IconProps> = ({ color, size, fill }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 21C5 17.134 8.13401 14 12 14M8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7ZM13 18C13 19.0609 13.4214 20.0783 14.1716 20.8284C14.9217 21.5786 15.9391 22 17 22C18.0609 22 19.0783 21.5786 19.8284 20.8284C20.5786 20.0783 21 19.0609 21 18C21 16.9391 20.5786 15.9217 19.8284 15.1716C19.0783 14.4214 18.0609 14 17 14C15.9391 14 14.9217 14.4214 14.1716 15.1716C13.4214 15.9217 13 16.9391 13 18Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5 18H18.5M17 16.5V19.5"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
