import { BORDER_WIDTH, COLOR } from 'Theme'

export const disabledHover = `
  *, &, *:hover, &:hover {
    pointer-events: none !important;
  }
`

export const bordered = `
  border: ${BORDER_WIDTH.PX_1} solid ${COLOR.GRAYSCALE_5};
`
