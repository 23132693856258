import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const MenuSearchMobile: React.FC<React.PWC<IconProps>> = ({
  color,
  size,
}) => (
  <svg
    width={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 16.5L22 22"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <circle cx="10.5" cy="10.5" r="7.75" stroke={color} strokeWidth="1.5" />
  </svg>
)
