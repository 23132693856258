const BING_EVENT = {
  SIGN_UP: 'User creates an account on The Expert',
  BOOK_SESSION: 'Client completes checkout to book a consultation',
  ORDER_CHECKOUT: 'Client completes checkout to order',
  ABANDONED_CHECKOUT: 'Abandoned cart',
}

declare global {
  interface Window {
    uetq: any
  }
}

export const trackBingEvent = (actionName, { category, label, ...rest }) => {
  const uetq = window.uetq || []
  uetq.push('event', actionName, {
    event_category: category,
    event_label: label,
    ...rest,
  })
}

export const trackBingSignUp = () =>
  trackBingEvent(BING_EVENT.SIGN_UP, {
    category: 'Sign Up',
    label: 'Sign Up',
    event_value: 0,
  })

export const trackBingCheckoutBooking = (price) =>
  trackBingEvent(BING_EVENT.BOOK_SESSION, {
    category: 'Purchase',
    label: 'Purchase',
    revenue_value: price,
    currency: 'USD',
  })

export const trackBingOrderCheckout = () => {
  // todo: sync about bing
  // trackBingEvent(BING_EVENT.ORDER_CHECKOUT, {
  //   category: 'Order',
  //   label: 'Order',
  //   revenue_value: price,
  //   currency: 'USD',
  // })
}

export const trackAbandonedBookingCheckout = () =>
  trackBingEvent(BING_EVENT.ABANDONED_CHECKOUT, {
    label: 'Abandoned Cart',
    category: 'Initiated Checkout',
    event_value: 0,
  })

export const trackAbandonedOrderCheckout = () => {
  // todo: sync about bing
  // trackBingEvent(BING_EVENT.ABANDONED_CHECKOUT, {
  //   label: 'Abandoned Order Checkout',
  //   category: 'Initiated Checkout',
  //   event_value: 0,
  // })
}
