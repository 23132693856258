import { type AreaTypes, type TransformationProps } from '../types'
import { slugifyString } from '../utils/helpers'

export interface SortingContextData {
  label: string
  area: AreaTypes // Sorting is a part of itemList which contains area
}

type SortingTransformationProps = TransformationProps<
  SortingContextData,
  'contextData'
>

export const SortingTransformation = ({
  contextData,
}: SortingTransformationProps) => {
  const { label, area } = contextData
  return {
    area,
    action: slugifyString(label),
  }
}
