import PropTypes from 'prop-types'
import React from 'react'

export const Quote = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.71118 0.547974L9.11801 2.33369C8.54865 3.05833 7.92754 4.09352 7.25466 5.43928C6.63354 6.78504 6.09006 8.28607 5.62422 9.94238C5.21014 11.5469 5.08075 13.1256 5.23602 14.6784C5.85714 14.4196 6.47826 14.2902 7.09938 14.2902C8.23809 14.2902 9.19565 14.6784 9.97205 15.4548C10.8002 16.1794 11.2143 17.1888 11.2143 18.4828C11.2143 19.9838 10.7743 21.2001 9.89441 22.1318C9.01449 23.0117 7.90166 23.4517 6.5559 23.4517C4.53727 23.4517 3.01035 22.7529 1.97516 21.3554C0.991718 19.9062 0.5 18.1204 0.5 15.9983C0.5 14.6008 0.732919 12.9703 1.19876 11.107C1.71636 9.24363 2.44099 7.38027 3.37267 5.51692C4.30435 3.65356 5.41718 1.99725 6.71118 0.547974ZM20.9969 0.547974L23.4037 2.33369C22.8344 3.05833 22.2132 4.09352 21.5404 5.43928C20.9193 6.78504 20.3758 8.28607 19.9099 9.94238C19.4959 11.5469 19.3665 13.1256 19.5217 14.6784C20.1429 14.4196 20.764 14.2902 21.3851 14.2902C22.5238 14.2902 23.4814 14.6784 24.2578 15.4548C25.0859 16.1794 25.5 17.1888 25.5 18.4828C25.5 19.9838 25.06 21.2001 24.1801 22.1318C23.3002 23.0117 22.1874 23.4517 20.8416 23.4517C18.823 23.4517 17.2961 22.7529 16.2609 21.3554C15.2774 19.9062 14.7857 18.1204 14.7857 15.9983C14.7857 14.6008 15.0186 12.9703 15.4845 11.107C16.0021 9.24363 16.7267 7.38027 17.6584 5.51692C18.5901 3.65356 19.7029 1.99725 20.9969 0.547974Z"
      fill={color}
    />
  </svg>
)

Quote.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
