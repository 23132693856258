import PropTypes from 'prop-types'
import React from 'react'

export const CaretRight = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.06 16.1398C10.9661 16.2345 10.8383 16.2877 10.705 16.2877C10.5717 16.2877 10.4439 16.2345 10.35 16.1398L10.15 15.9398C10.0523 15.8457 9.99802 15.7154 9.99999 15.5798V8.41981C9.99802 8.28423 10.0523 8.15389 10.15 8.05981L10.35 7.85981C10.4439 7.76516 10.5717 7.71191 10.705 7.71191C10.8383 7.71191 10.9661 7.76516 11.06 7.85981L14.85 11.6498C14.9467 11.7405 15.0015 11.8672 15.0015 11.9998C15.0015 12.1324 14.9467 12.2591 14.85 12.3498L11.06 16.1398Z"
      fill={color}
    />
  </svg>
)

CaretRight.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
