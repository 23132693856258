import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const MapMarker: React.FC<IconProps> = ({ color, size, fill }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 10C9.5 10.663 9.76339 11.2989 10.2322 11.7678C10.7011 12.2366 11.337 12.5 12 12.5C12.663 12.5 13.2989 12.2366 13.7678 11.7678C14.2366 11.2989 14.5 10.663 14.5 10C14.5 9.33696 14.2366 8.70107 13.7678 8.23223C13.2989 7.76339 12.663 7.5 12 7.5C11.337 7.5 10.7011 7.76339 10.2322 8.23223C9.76339 8.70107 9.5 9.33696 9.5 10Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.1837 14.9649L12.5724 20.3336C12.2713 20.6842 11.7287 20.6842 11.4276 20.3336L6.81632 14.9649C4.62027 12.4081 4.61641 8.63203 6.80723 6.07078C9.53478 2.88204 14.4652 2.88204 17.1928 6.07078C19.3836 8.63203 19.3797 12.4081 17.1837 14.9649Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
)
