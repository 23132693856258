import PropTypes from 'prop-types'
import React from 'react'

export const Google = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 11.7902C21 15.9402 18.79 21.0002 12.13 21.0002C7.12461 21.0335 3.03852 17.0056 3 12.0002C3.03852 6.99485 7.12461 2.96694 12.13 3.0002C14.2007 3.00788 16.2085 3.71238 17.83 5.0002C17.942 5.09174 18.0109 5.22581 18.02 5.3702C18.0206 5.51629 17.963 5.65661 17.86 5.7602C17.209 6.3554 16.5882 6.98286 16 7.6402C15.8289 7.8285 15.5422 7.85457 15.34 7.7002C14.4161 7.01649 13.2888 6.66418 12.14 6.7002C9.18528 6.7002 6.79 9.09548 6.79 12.0502C6.79 15.0049 9.18528 17.4002 12.14 17.4002C15.14 17.4002 16.41 16.1202 17.07 13.8502H12.5C12.2239 13.8502 12 13.6263 12 13.3502V10.7002C12 10.4241 12.2239 10.2002 12.5 10.2002H20.5C20.7302 10.1988 20.9244 10.3714 20.95 10.6002C20.9871 10.9957 21.0038 11.3929 21 11.7902Z"
      fill={color}
    />
  </svg>
)

Google.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
