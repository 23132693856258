import { initializeApp, getApps, getApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { environment } from 'constants/environment'

let firebaseApp

if (!getApps().length) {
  const appUrl = new URL(environment.APP_URL)

  firebaseApp = initializeApp({
    apiKey: environment.FIREBASE_API_KEY,
    authDomain: appUrl.hostname,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  })

  const firebaseAuth = getAuth(firebaseApp)
  if (process.env.NEXT_PUBLIC_NODE_ENV === 'test') {
    if (typeof window === 'undefined') {
      connectAuthEmulator(
        firebaseAuth,
        process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_HOST
      )
    } else {
      const host = window.location.hostname
      connectAuthEmulator(firebaseAuth, `http://${host}:9099`, {
        disableWarnings: true,
      })
    }

    firebaseAuth.settings.appVerificationDisabledForTesting = true
  }
}

const auth = firebaseApp ? getAuth(firebaseApp) : getAuth(getApp())

export default auth
