import PropTypes from 'prop-types'
import React from 'react'

export const Money = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 4H15.89C14.4976 4.02219 13.129 4.36435 11.89 5L11.27 5.31C10.2858 5.78603 9.20301 6.02247 8.11 6H3C2.44772 6 2 6.44772 2 7V19C2 19.5523 2.44772 20 3 20H8.11C9.49843 19.9933 10.8668 19.6683 12.11 19.05L12.73 18.74C13.7099 18.2467 14.7929 17.9931 15.89 18H21C21.5523 18 22 17.5523 22 17V5C22 4.44772 21.5523 4 21 4ZM20 13C18.3431 13 17 14.3431 17 16H15.89C14.5017 16.0077 13.1335 16.3327 11.89 16.95L11.27 17.26C10.2884 17.7487 9.20651 18.0021 8.11 18H7C7 16.3431 5.65685 15 4 15V11C5.65685 11 7 9.65685 7 8H8.11C9.50223 7.97674 10.8706 7.63465 12.11 7L12.73 6.69C13.7157 6.21856 14.7975 5.98236 15.89 6H17C17 7.65685 18.3431 9 20 9V13ZM12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
      fill={color}
    />
  </svg>
)

Money.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
