import PropTypes from 'prop-types'
import React from 'react'

export const Messages = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 3H17C18.1046 3 19 3.89543 19 5V15C19 16.1046 18.1046 17 17 17H3C1.89543 17 1 16.1046 1 15V5C1 3.89543 1.89543 3 3 3ZM3 15H17V8.73L11.65 12.48C10.659 13.1727 9.34095 13.1727 8.35 12.48L3 8.73V15ZM17 6.6L10.65 11.05C10.2591 11.3213 9.74087 11.3213 9.35 11.05L3 6.6V5H17V6.6ZM21 17V7C22.1046 7 23 7.89543 23 9V17C23 19.2091 21.2091 21 19 21H7C5.89543 21 5 20.1046 5 19H19C20.1046 19 21 18.1046 21 17Z"
      fill={color}
    />
  </svg>
)

Messages.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
