import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const MenuArrowLeft: React.FC<React.PWC<IconProps>> = ({
  color,
  size,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5303 5.03033C17.8232 4.73744 17.8232 4.26256 17.5303 3.96967C17.2374 3.67678 16.7626 3.67678 16.4697 3.96967L17.5303 5.03033ZM9.5 12L8.96967 11.4697C8.67678 11.7626 8.67678 12.2374 8.96967 12.5303L9.5 12ZM16.4697 20.0303C16.7626 20.3232 17.2374 20.3232 17.5303 20.0303C17.8232 19.7374 17.8232 19.2626 17.5303 18.9697L16.4697 20.0303ZM16.4697 3.96967L8.96967 11.4697L10.0303 12.5303L17.5303 5.03033L16.4697 3.96967ZM8.96967 12.5303L16.4697 20.0303L17.5303 18.9697L10.0303 11.4697L8.96967 12.5303Z"
      fill={color}
    />
  </svg>
)
