import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const Link: React.FC<React.PWC<IconProps>> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 3.6C6.33137 3.6 6.6 3.33137 6.6 3C6.6 2.66863 6.33137 2.4 6 2.4V3.6ZM13.6 10C13.6 9.66863 13.3314 9.4 13 9.4C12.6686 9.4 12.4 9.66863 12.4 10H13.6ZM9 2.4C8.66863 2.4 8.4 2.66863 8.4 3C8.4 3.33137 8.66863 3.6 9 3.6V2.4ZM13 3H13.6C13.6 2.66863 13.3314 2.4 13 2.4V3ZM12.4 7C12.4 7.33137 12.6686 7.6 13 7.6C13.3314 7.6 13.6 7.33137 13.6 7H12.4ZM6.57574 8.57574C6.34142 8.81005 6.34142 9.18995 6.57574 9.42426C6.81005 9.65858 7.18995 9.65858 7.42426 9.42426L6.57574 8.57574ZM11 12.4H5V13.6H11V12.4ZM3.6 11V5H2.4V11H3.6ZM5 3.6H6V2.4H5V3.6ZM12.4 10V11H13.6V10H12.4ZM5 12.4C4.2268 12.4 3.6 11.7732 3.6 11H2.4C2.4 12.4359 3.56406 13.6 5 13.6V12.4ZM11 13.6C12.4359 13.6 13.6 12.4359 13.6 11H12.4C12.4 11.7732 11.7732 12.4 11 12.4V13.6ZM3.6 5C3.6 4.2268 4.2268 3.6 5 3.6V2.4C3.56406 2.4 2.4 3.56406 2.4 5H3.6ZM9 3.6H13V2.4H9V3.6ZM12.4 3V7H13.6V3H12.4ZM12.5757 2.57574L6.57574 8.57574L7.42426 9.42426L13.4243 3.42426L12.5757 2.57574Z"
      fill={color}
    />
  </svg>
)
