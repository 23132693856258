import {
  ROUTE,
  PUBLIC_ROUTE,
  DYNAMIC_ROUTE,
  RECAPTCHA_ROUTES,
  REVALIDATE_ROUTE,
  EXPERT_DETAIL_TAB_KEYS,
} from 'shared-constants'

const LIST_OF_PAGES_WITH_FULL_VIEWPORT = [ROUTE.EXPERT_CALENDAR_AVAILABILITY()]

const EXPERT_DETAIL_TAB_KEY_ROUTES = {
  [EXPERT_DETAIL_TAB_KEYS.SHOWROOM]: ROUTE.FIND_AN_EXPERT_SHOWROOM_TAB_DYNAMIC,
  [EXPERT_DETAIL_TAB_KEYS.CONSULTATION]:
    ROUTE.FIND_AN_EXPERT_CONSULTATION_TAB_DYNAMIC,
}

export {
  EXPERT_DETAIL_TAB_KEY_ROUTES,
  ROUTE,
  PUBLIC_ROUTE,
  DYNAMIC_ROUTE,
  LIST_OF_PAGES_WITH_FULL_VIEWPORT,
  RECAPTCHA_ROUTES,
  REVALIDATE_ROUTE,
}
