import PropTypes from 'prop-types'
import React from 'react'

export const DownloadRounded = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6667 20C16.269 20 20 16.269 20 11.6667C20 7.06429 16.269 3.33333 11.6667 3.33333C7.0643 3.33333 3.33333 7.06429 3.33333 11.6667C3.33333 13.8768 4.21131 15.9964 5.77411 17.5592C7.33691 19.122 9.45653 20 11.6667 20ZM11.6667 5C15.3486 5 18.3333 7.98477 18.3333 11.6667C18.3333 15.3486 15.3486 18.3333 11.6667 18.3333C7.98477 18.3333 5 15.3486 5 11.6667C5 9.89856 5.70238 8.20286 6.95262 6.95262C8.20287 5.70238 9.89856 5 11.6667 5ZM11.5083 16.6667C11.343 16.6647 11.1848 16.5991 11.0667 16.4833L8.45834 13.875C8.37946 13.7968 8.33509 13.6903 8.33509 13.5792C8.33509 13.4681 8.37946 13.3616 8.45834 13.2833L9.05 12.6917C9.12681 12.6133 9.23193 12.5691 9.34167 12.5691C9.45141 12.5691 9.55653 12.6133 9.63334 12.6917L10.8333 13.8917L10.8333 7.08333C10.8333 6.85322 11.0199 6.66667 11.25 6.66667L12.0833 6.66667C12.3135 6.66667 12.5 6.85322 12.5 7.08333L12.5 13.8917L13.7 12.6833C13.7782 12.6045 13.8847 12.5601 13.9958 12.5601C14.1069 12.5601 14.2134 12.6045 14.2917 12.6833L14.875 13.275C14.9539 13.3532 14.9983 13.4597 14.9983 13.5708C14.9983 13.6819 14.9539 13.7884 14.875 13.8667L12.2667 16.4833C12.1496 16.6006 11.9907 16.6665 11.825 16.6667L11.5083 16.6667Z"
      fill={color}
    />
  </svg>
)

DownloadRounded.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
