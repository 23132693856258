import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const Video: React.FC<IconProps> = ({ size, color, fill }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 10.0006L19.553 7.72461C19.7054 7.64844 19.8748 7.61249 20.045 7.62015C20.2152 7.62782 20.3806 7.67885 20.5256 7.76841C20.6706 7.85797 20.7902 7.98308 20.8733 8.13188C20.9563 8.28067 20.9999 8.44822 21 8.61861V15.3826C20.9999 15.553 20.9563 15.7205 20.8733 15.8693C20.7902 16.0181 20.6706 16.1432 20.5256 16.2328C20.3806 16.3224 20.2152 16.3734 20.045 16.3811C19.8748 16.3887 19.7054 16.3528 19.553 16.2766L15 14.0006V10.0006Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 8C3 7.46957 3.21071 6.96086 3.58579 6.58579C3.96086 6.21071 4.46957 6 5 6H13C13.5304 6 14.0391 6.21071 14.4142 6.58579C14.7893 6.96086 15 7.46957 15 8V16C15 16.5304 14.7893 17.0391 14.4142 17.4142C14.0391 17.7893 13.5304 18 13 18H5C4.46957 18 3.96086 17.7893 3.58579 17.4142C3.21071 17.0391 3 16.5304 3 16V8Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
