import React from 'react'
import styled, { css } from 'styled-components'
import { type IconProps } from '../IconPropsInterface'

const StyledSVG = styled.svg`
  ${({ hoveringColor }) =>
    hoveringColor &&
    css`
      &:hover {
        rect {
          fill: ${hoveringColor};
        }
      }
    `}
`

export const Hamburger: React.FC<React.PWC<IconProps>> = ({
  color,
  size,
  hoveringColor,
}) => (
  <StyledSVG
    hoveringColor={hoveringColor}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2" y="5" width="20" height="1.5" rx="0.75" fill={color} />
    <rect x="2" y="11" width="20" height="1.5" rx="0.75" fill={color} />
    <rect x="2" y="17" width="20" height="1.5" rx="0.75" fill={color} />
  </StyledSVG>
)
