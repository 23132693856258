import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const Disable: React.FC<IconProps> = ({ color, size, fill }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0416 16.0456C20.8936 14.3545 21.1905 12.4377 20.89 10.568C20.5896 8.69838 19.707 6.97114 18.368 5.63214C17.029 4.29314 15.3018 3.4106 13.4321 3.11013C11.5625 2.80965 9.64571 3.10656 7.95459 3.95858M5.63659 5.63559C4.78952 6.46864 4.11578 7.46116 3.65422 8.5559C3.19266 9.65064 2.95242 10.8259 2.94735 12.014C2.94228 13.202 3.17248 14.3793 3.62468 15.478C4.07689 16.5766 4.74213 17.5748 5.58205 18.4151C6.42198 19.2553 7.41995 19.921 8.51841 20.3736C9.61687 20.8263 10.7941 21.0569 11.9821 21.0523C13.1702 21.0477 14.3456 20.8079 15.4405 20.3468C16.5354 19.8857 17.5282 19.2123 18.3616 18.3656M3 3L21 21"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
