export const environment = {
  API_URL: process.env.NEXT_PUBLIC_IS_PREVIEW
    ? process.env.NEXT_PUBLIC_API_URL_PREVIEW
    : process.env.NEXT_PUBLIC_API_URL,
  APP_URL: process.env.NEXT_PUBLIC_IS_PREVIEW
    ? process.env.NEXT_PUBLIC_APP_URL_PREVIEW
    : process.env.NEXT_PUBLIC_APP_URL,
  SSR_API_URL: process.env.NEXT_PUBLIC_IS_PREVIEW
    ? process.env.NEXT_PUBLIC_SSR_API_URL_PREVIEW
    : process.env.NEXT_PUBLIC_SSR_API_URL,
  STRIPE_API_KEY: process.env.NEXT_PUBLIC_IS_PREVIEW
    ? process.env.NEXT_PUBLIC_STRIPE_API_KEY_PREVIEW
    : process.env.NEXT_PUBLIC_STRIPE_API_KEY,
  STRIPE_CLIENT_ID: process.env.NEXT_PUBLIC_IS_PREVIEW
    ? process.env.NEXT_PUBLIC_STRIPE_CLIENT_ID_PREVIEW
    : process.env.NEXT_PUBLIC_STRIPE_CLIENT_ID,
  ZOOM_CLIENT_ID: process.env.NEXT_PUBLIC_IS_PREVIEW
    ? process.env.NEXT_PUBLIC_ZOOM_CLIENT_ID_PREVIEW
    : process.env.NEXT_PUBLIC_ZOOM_CLIENT_ID,
  G_RECAPTCHA_API_KEY: process.env.NEXT_PUBLIC_IS_PREVIEW
    ? process.env.NEXT_PUBLIC_G_RECAPTCHA_API_KEY_PREVIEW
    : process.env.NEXT_PUBLIC_G_RECAPTCHA_API_KEY,
  G_RECAPTCHA_V2_API_KEY: process.env.NEXT_PUBLIC_IS_PREVIEW
    ? process.env.NEXT_PUBLIC_G_RECAPTCHA_V2_API_KEY_PREVIEW
    : process.env.NEXT_PUBLIC_G_RECAPTCHA_V2_API_KEY,
  FIREBASE_API_KEY: process.env.NEXT_PUBLIC_IS_PREVIEW
    ? process.env.NEXT_PUBLIC_FIREBASE_API_KEY_PREVIEW
    : process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  IS_PREVIEW: process.env.NEXT_PUBLIC_IS_PREVIEW === 'true',
  NEXT_PUBLIC_NODE_ENV: process.env.NEXT_PUBLIC_NODE_ENV,
  NEXT_PUBLIC_GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
  SENTRY_BASE_URL: process.env.NEXT_PUBLIC_SENTRY_BASE_URL,
  REVALIDATION_TOKEN: process.env.REVALIDATION_TOKEN,
  ALGOLIA_APPLICATION_ID: process.env.NEXT_PUBLIC_IS_PREVIEW
    ? process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID_PREVIEW
    : process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
  ALGOLIA_READ_ONLY_API_KEY: process.env.NEXT_PUBLIC_IS_PREVIEW
    ? process.env.NEXT_PUBLIC_ALGOLIA_READ_ONLY_API_KEY_PREVIEW
    : process.env.NEXT_PUBLIC_ALGOLIA_READ_ONLY_API_KEY,
  CUSTOMER_SERVICE_FRAMEWORK:
    process.env.NEXT_PUBLIC_CUSTOMER_SERVICE_FRAMEWORK ?? 'intercom',
  SHAREASALE_MERCHANT_ID: process.env.NEXT_PUBLIC_SHAREASALE_MERCHANT_ID ?? '',
  FRONT_CHAT_ID: process.env.NEXT_PUBLIC_FRONT_CHAT_ID,
}
