import styled, { css } from 'styled-components'
import { COLOR_INTENT, LINE_HEIGHT, SPACE } from 'Theme'

interface InputAreaProps extends HTMLInputElement {
  hasIcon?: boolean
}

export const InputArea = styled('input')<InputAreaProps>`
  display: inline-flex;
  line-height: ${LINE_HEIGHT.XXS};
  width: 100%;
  border: none;
  background: transparent;
  padding: 0;
  &::placeholder {
    color: ${COLOR_INTENT.INPUT.PLACEHOLDER};
    opacity: 1;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${COLOR_INTENT.INPUT.DISABLED_TEXT};
      -webkit-text-fill-color: ${COLOR_INTENT.INPUT.DISABLED_TEXT};
      opacity: 1;
    `}
  ${({ hasIcon }) =>
    hasIcon &&
    css`
      padding-left: ${SPACE.PX_10};
    `}
`
