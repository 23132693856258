import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const BankAccount: React.FC<IconProps> = ({ color, size, fill }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 11V11.01M5.17283 8.37863C4.73423 8.04153 4.39737 7.58973 4.19949 7.07316C4.00161 6.55658 3.95041 5.99536 4.05154 5.45151C4.15268 4.90765 4.40222 4.40235 4.77258 3.99146C5.14294 3.58056 5.6197 3.28006 6.15016 3.12318C6.68063 2.96629 7.24414 2.95913 7.77842 3.10249C8.3127 3.24584 8.79694 3.53413 9.17762 3.93548C9.5583 4.33684 9.8206 4.83563 9.93552 5.37674C10.0504 5.91785 10.0135 6.48019 9.82883 7.00163M16 4V7.803C17.2376 8.51912 18.1799 9.65245 18.658 11H19.999C20.2642 11 20.5186 11.1054 20.7061 11.2929C20.8937 11.4804 20.999 11.7348 20.999 12V14C20.999 14.2652 20.8937 14.5196 20.7061 14.7071C20.5186 14.8946 20.2642 15 19.999 15H18.657C18.321 15.95 17.75 16.8 16.999 17.473V19.5C16.999 19.8978 16.841 20.2794 16.5597 20.5607C16.2784 20.842 15.8968 21 15.499 21C15.1012 21 14.7197 20.842 14.4384 20.5607C14.157 20.2794 13.999 19.8978 13.999 19.5V18.917C13.6686 18.9724 13.3341 19.0001 12.999 19H8.99901C8.66396 19.0001 8.32947 18.9724 7.99901 18.917V19.5C7.99901 19.8978 7.84098 20.2794 7.55967 20.5607C7.27837 20.842 6.89684 21 6.49901 21C6.10119 21 5.71966 20.842 5.43835 20.5607C5.15705 20.2794 4.99901 19.8978 4.99901 19.5V17.473C4.09298 16.663 3.45429 15.5969 3.1675 14.4159C2.8807 13.2348 2.95933 11.9946 3.39296 10.8592C3.82659 9.72387 4.59478 8.74697 5.59585 8.05783C6.59692 7.36869 7.78367 6.9998 8.99901 7H11.499L15.999 4H16Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
