import PropTypes from 'prop-types'
import React from 'react'

export const Warning = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9052 19.63L12.8128 2.37C12.676 2.14121 12.4293 2.00081 12.1626 2H11.8626C11.5959 2.00081 11.3492 2.14121 11.2124 2.37L1.10001 19.63C0.970121 19.8667 0.970121 20.1533 1.10001 20.39L1.24005 20.62C1.36967 20.8573 1.61982 21.0035 1.8902 21H22.115C22.3854 21.0035 22.6355 20.8573 22.7651 20.62L22.9052 20.39C23.0351 20.1533 23.0351 19.8667 22.9052 19.63ZM13.0028 16.5C13.0028 16.7761 12.7789 17 12.5027 17H11.5025C11.2263 17 11.0024 16.7761 11.0024 16.5V15.5C11.0024 15.2239 11.2263 15 11.5025 15H12.5027C12.7789 15 13.0028 15.2239 13.0028 15.5V16.5ZM12.5327 13C12.6599 13.0009 12.7675 12.9062 12.7828 12.78L13.1829 9.56C13.2001 9.41735 13.1552 9.27417 13.0594 9.16698C12.9637 9.05979 12.8265 8.99896 12.6828 9H11.3224C11.1787 8.99896 11.0415 9.05979 10.9458 9.16698C10.85 9.27417 10.8051 9.41735 10.8223 9.56L11.2224 12.78C11.2377 12.9062 11.3453 13.0009 11.4725 13H12.5327Z"
      fill={color}
    />
  </svg>
)

Warning.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
