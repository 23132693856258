import { format } from 'date-fns'
import React from 'react'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import { Button, BUTTON_VARIANT, BUTTON_SIZE } from 'components/Button'
import { Delimiter } from 'components/Delimiter'
import Icon from 'components/Icon'
import { Box, Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import {
  MONTH_WITH_YEAR,
  LOCAL_DAY_OF_WEEK_WITH_MONTH_DAY,
} from 'constants/datetime'
import { FONT_WEIGHT, SPACE } from 'Theme'

interface RangeHeaderProps {
  date?: Date
  decreaseMonth: () => void
  increaseMonth: () => void
  startDate?: Date
  endDate?: Date
}

export const RangeHeader: React.FC<RangeHeaderProps> = ({
  date,
  decreaseMonth,
  increaseMonth,
  startDate,
  endDate,
}) => (
  <>
    <Flex
      px={{ MOBILE: 0, TABLET: SPACE.PX_20 }}
      py={SPACE.PX_5}
      mb={SPACE.PX_10}
      justifyContent="space-between"
      alignItems="flex-end"
    >
      <Flex flexDirection="column">
        <Flex mb={SPACE.PX_10}>
          <Text fontWeight={FONT_WEIGHT.SEMI_BOLD} mr={SPACE.PX_5}>
            From:
          </Text>
          {startDate && (
            <Text>{format(startDate, LOCAL_DAY_OF_WEEK_WITH_MONTH_DAY)}</Text>
          )}
        </Flex>
        <Flex>
          <Text fontWeight={FONT_WEIGHT.SEMI_BOLD} mr={SPACE.PX_5}>
            To:
          </Text>
          {endDate && (
            <Text>{format(endDate, LOCAL_DAY_OF_WEEK_WITH_MONTH_DAY)}</Text>
          )}
        </Flex>
      </Flex>
      <Flex flexDirection="column" alignItems="flex-end">
        <Flex mb={SPACE.PX_5}>
          <Box>
            <Button
              icon={Icon.ArrowLeft}
              variant={BUTTON_VARIANT.MINIMAL}
              size={BUTTON_SIZE.S}
              onClick={decreaseMonth}
            />
          </Box>
          <Box>
            <Button
              icon={Icon.ArrowRight}
              variant={BUTTON_VARIANT.MINIMAL}
              size={BUTTON_SIZE.S}
              onClick={increaseMonth}
              data-test-id={DATA_TEST_ID.DATEPICKER_NEXT_MONTH_BTN}
            />
          </Box>
        </Flex>
        <Text>{format(date, MONTH_WITH_YEAR)}</Text>
      </Flex>
    </Flex>
    <Delimiter />
  </>
)
