import ct from 'countries-and-timezones'
import { getParamByISO } from 'iso-country-currency'
import { parseCookies, setCookie } from 'nookies'
import PropTypes from 'prop-types'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { CURRENCY_ISO_CODE } from 'constants/common'
import { useUserAuth } from 'providers/userAuth'

const CURRENCY_COOKIE_NAME = 'currencyIsoCode'

const CURRENCY_COOKIE_DURATION_IN_SECONDS = 60 * 60 * 24 * 365

export const setCurrencyCookie = (value, ctx) =>
  setCookie(ctx, CURRENCY_COOKIE_NAME, value, {
    maxAge: CURRENCY_COOKIE_DURATION_IN_SECONDS,
  })

export const getCurrency = (ctx, user) => {
  const cookieCurrency = parseCookies(ctx)[CURRENCY_COOKIE_NAME]
  if (cookieCurrency) {
    return cookieCurrency
  }
  if (typeof window === 'undefined') {
    return CURRENCY_ISO_CODE.USD
  }
  const userCurrency = user?.expert?.currencyIsoCode
  if (userCurrency) {
    return userCurrency
  }
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const country = ct.getCountryForTimezone(browserTimezone)
  if (!country) {
    return CURRENCY_ISO_CODE.USD
  }
  const currency = getParamByISO(country.id, 'currency')
  if (currency && CURRENCY_ISO_CODE[currency]) {
    return currency
  }
  if (browserTimezone.includes('Europe/')) {
    return CURRENCY_ISO_CODE.EUR
  }
  return CURRENCY_ISO_CODE.USD
}

const CurrencyContext = createContext()

export const useCurrency = () => useContext(CurrencyContext)

const CurrencyProvider = ({ children }) => {
  const [currencyIsoCode, setCurrencyIsoCode] = useState((ctx) =>
    getCurrency(ctx)
  )
  const { user } = useUserAuth()

  const setCurrency = useCallback((currency) => {
    setCurrencyIsoCode(currency)
    setCurrencyCookie(currency)
  }, [])

  useEffect(() => {
    setCurrencyIsoCode(getCurrency(undefined, user))
  }, [setCurrencyIsoCode, user])

  const contextValues = useMemo(
    () => ({
      currencyIsoCode,
      setCurrencyIsoCode: setCurrency,
    }),
    [currencyIsoCode, setCurrency]
  )

  return (
    <CurrencyContext.Provider value={contextValues}>
      {children}
    </CurrencyContext.Provider>
  )
}

CurrencyProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CurrencyProvider
