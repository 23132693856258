import { math, rem } from 'polished'
import { createGlobalStyle, css } from 'styled-components'
import 'sanitize.css'

export const FONT_STACK = {
  SANS: `'Graphik', sans-serif`,
  SERIF: `'Lora', serif`,
} as const

export const HEADING_VARIANT = {
  SANS: 'sans',
  SERIF: 'serif',
} as const

export const HINT_HEIGHT = rem(16)
export const MODAL_SELECT_DROPDOWN_HEIGHT = rem(200)

export const BORDER_WIDTH = {
  PX_1: '1px',
  PX_2: '2px',
  PX_3: '3px',
  PX_4: '4px',
} as const

export const Z_INDEX = {
  NOT_SHOWN: -1,
  BACKGROUND_IMAGE: -1,
  SOLD_OUT_BADGE: 1,
  PHONE_INPUT_INFO_ICON: 1,
  CATEGORY_HEADER_EXPERT_IMAGE: 2,
  MOBILE_EXPERT_PROFILE_IMAGE: 2,
  GALLERY_FADE_BOX: 5,
  CAROUSEL: 10,
  SELECT: 20,
  CONTEXT_MENU: 20,
  SIDENAV_HEADER: 25,
  NAVBAR: 30,
  SIDENAV: 30,
  DASHBOARD_MENU: 50,
  LOGOUT_POPUP: 50,
  LOADING_OVERLAY: 90,
  OVERLAY: 99,
  MODAL_HEADER: 100,
  MENU: 100,
  SEARCH_DROPDOWN_OPTION_TITLE: 100,
  DATEPICKER: 110,
  SEARCH: 150,
  TOOLTIP: 200,
  TRADE_PROGRAM_RIBBON: 200,
  SLIDER_THUMB: 0,
  CAROUSEL_NAVIGATION_WRAPPER: 1,
  DRAGGED: 50,
} as const

/*
 * Colors should be added and used according to our design system
 * @link https://www.figma.com/file/gTeQqSXb0M3CSjM9glXeEI/The-Expert---Design-system?node-id=7%3A2830
 */
export const COLOR_NAMES = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  PINEY_LAKE: '#126554', // PRIMARY
  WATER_BAPTISM: '#CEE0DD', // PRIMARY_20
  TETON_BREEZE: '#DDEAE7', // PRIMARY_10
  WARP_DRIVE: '#EBF2F1', // PRIMARY_5
  BLEACHED_SILK: '#F2F3F2',

  MATT_BLUE: '#1670BE', // INFO
  MOON_WHITE: '#E9F3FC', // INFO_5
  AZURE_X11: '#D0E0DD',
  CRAYOLA: '#12B886',

  PUMPKIN_ORANGE: '#FD7E14',
  CINNAMON_BUN: '#B05000', // PENDING
  RICE_PUDDING: '#FEEFE2', // PENDING_5
  FLUORESCENT_RED: '#FA5252',
  WHITE_GLOSS: '#FFEEEE', // WARNING_5
  KACEYS_PINK: '#E64980',
  CLEAR_POND: '#B4CDCA',
  EMERALD_SPRINGS: '#08805C', // SUCCESS
  SOFT_MINT: '#E7FAF1', // SUCCESS_5
  CLUB_SODA: '#E2EDEB',
  COOL_MINT: '#C8D8D5',
  AMARANTH_RED: '#D12B2B',
  ATHENS_GRAY: '#E4E7EB',
  CLOUD_DANCER: '#F0EEE9',
  LIGHT_YEAR: '#C1BFB4',

  // Grayscale colors
  EEIRE_BLACK: '#181B19',
  CARBON_COPY: '#545554',
  SONIC_SILVER: '#757575',
  GRAY_SHINGLE: '#949494',
  AMERICAN_SILVER: '#CECECE',
  JUPITER: '#E1E1E1',
  SNOWFLAKE: '#F0F0F0',
  DOCTOR: '#F8F8F8',
  WINDSOR_TAN: '#B25100',
} as const

/**
 * Colors should not be used directly but they should be assigned colors to the intention
 */
export const COLOR_INTENT = {
  TEXT_ON_LIGHT: COLOR_NAMES.PINEY_LAKE,
  TEXT_ON_DARK: COLOR_NAMES.WHITE,
  TEXT_ON_DISABLED: COLOR_NAMES.EEIRE_BLACK,
  BRAND: COLOR_NAMES.PINEY_LAKE,
  INFO: COLOR_NAMES.MATT_BLUE,
  GRAY_90: COLOR_NAMES.EEIRE_BLACK,
  GRAY_70: COLOR_NAMES.CARBON_COPY,
  GRAY_60: COLOR_NAMES.SONIC_SILVER,
  GRAY_50: COLOR_NAMES.GRAY_SHINGLE,
  GRAY_20: COLOR_NAMES.AMERICAN_SILVER,
  GRAY_10: COLOR_NAMES.JUPITER,
  GRAY_5: COLOR_NAMES.SNOWFLAKE,
  GRAY_3: COLOR_NAMES.DOCTOR,
  WARNING: COLOR_NAMES.PUMPKIN_ORANGE,
  ERROR_DARK: COLOR_NAMES.FLUORESCENT_RED,
  ERROR_LIGHT: COLOR_NAMES.WHITE_GLOSS,
  /* --- Components --- */
  SORT_FILTER: {
    VALUE: COLOR_NAMES.PINEY_LAKE,
    CARET: COLOR_NAMES.PINEY_LAKE,
  },
  LIGHTBOX_BUTTON: COLOR_NAMES.WHITE,
  BUTTON: {
    TEXT_LIGHT: COLOR_NAMES.WHITE,
    TEXT_DARK: COLOR_NAMES.EEIRE_BLACK,
    TEXT_PRIMARY: COLOR_NAMES.PINEY_LAKE,
    BACKGROUND_PRIMARY: COLOR_NAMES.PINEY_LAKE,
    BACKGROUND_SECONDARY: COLOR_NAMES.WARP_DRIVE,
    BACKGROUND_LIGHT: COLOR_NAMES.WHITE,
    BACKGROUND_DARK: COLOR_NAMES.TETON_BREEZE,
    BORDER: COLOR_NAMES.JUPITER,
    INTENT: {
      PRIMARY: COLOR_NAMES.PINEY_LAKE,
      SECONDARY: COLOR_NAMES.WARP_DRIVE,
      SUCCESS: COLOR_NAMES.CRAYOLA,
      SUCCESS_2: COLOR_NAMES.TETON_BREEZE,
      WARNING: COLOR_NAMES.PUMPKIN_ORANGE,
      DANGER: COLOR_NAMES.FLUORESCENT_RED,
      NONE: COLOR_NAMES.EEIRE_BLACK,
      BLACK: COLOR_NAMES.BLACK,
    },
  },
  SELECT: {
    CARET: COLOR_NAMES.PINEY_LAKE,
    PRIMARY_INTENT: COLOR_NAMES.PINEY_LAKE,
    BACKGROUND: COLOR_NAMES.WHITE,
    MENU_BACKGROUND: COLOR_NAMES.WHITE,
    DISABLED_OPTION_LABEL: COLOR_NAMES.KACEYS_PINK,
    OPTION_BACKGROUND_HOVER: COLOR_NAMES.PINEY_LAKE,
    OPTION_LABEL_HOVER: COLOR_NAMES.WHITE,
    OPTION_BACKGROUND_ACTIVE: COLOR_NAMES.WHITE,
    MULTI_VALUE_OPTION: COLOR_NAMES.WARP_DRIVE,
    MULTI_VALUE_LABEL: COLOR_NAMES.PINEY_LAKE,
    HINT_WARNING: COLOR_NAMES.PUMPKIN_ORANGE,
    HINT_ERROR: COLOR_NAMES.FLUORESCENT_RED,
    SEARCHABLE_BACKGROUND: COLOR_NAMES.WHITE,
  },
  TAG_FILTER: {
    BUTTON_BORDER_WITH_VALUE: COLOR_NAMES.CLEAR_POND,
  },
  FILTER_BUTTON: {
    OPEN_BORDER: COLOR_NAMES.PINEY_LAKE,
    ACTIVE_BORDER: COLOR_NAMES.CLEAR_POND,
  },
  APPLIED_FILTER_TAG: {
    BORDER: COLOR_NAMES.WARP_DRIVE,
    BACKGROUND: COLOR_NAMES.WARP_DRIVE,
    BORDER_HOVER: COLOR_NAMES.CLUB_SODA,
    BACKGROUND_HOVER: COLOR_NAMES.CLUB_SODA,
    TEXT: COLOR_NAMES.PINEY_LAKE,
    ICON_CROSS: COLOR_NAMES.PINEY_LAKE,
  },
  INPUT: {
    BACKGROUND: COLOR_NAMES.WHITE,
    DISABLED_BACKGROUND: COLOR_NAMES.DOCTOR,
    DISABLED_TEXT: COLOR_NAMES.CARBON_COPY,
    BORDER_HOVER: COLOR_NAMES.AMERICAN_SILVER,
    PLACEHOLDER: COLOR_NAMES.SONIC_SILVER,
    TEXT: COLOR_NAMES.EEIRE_BLACK,
  },
  CHECKBOX: {
    HINT: COLOR_NAMES.CARBON_COPY,
    TEXT: COLOR_NAMES.EEIRE_BLACK,
  },
  TEXTAREA: {
    MAX_LENGTH_TEXT: COLOR_NAMES.GRAY_SHINGLE,
    PLACEHOLDER: COLOR_NAMES.SONIC_SILVER,
    BACKGROUND: COLOR_NAMES.WHITE,
    BORDER: COLOR_NAMES.AMERICAN_SILVER,
    DISABLED_TEXT: COLOR_NAMES.GRAY_SHINGLE,
    DISABLED_BACKGROUND: COLOR_NAMES.DOCTOR,
  },
  SKELETON: {
    BACKGROUND: COLOR_NAMES.WHITE,
    FOREGROUND: COLOR_NAMES.JUPITER,
  },
  CAROUSEL: {
    ARROW: COLOR_NAMES.EEIRE_BLACK,
    BACKGROUND: COLOR_NAMES.WHITE,
    FOLD: COLOR_NAMES.WARP_DRIVE,
  },
  COLLECTION_WIDGET_CARD: {
    BORDER: COLOR_NAMES.WHITE,
    BACKGROUND: COLOR_NAMES.WHITE,
    TITLE: COLOR_NAMES.EEIRE_BLACK,
    PRICE: COLOR_NAMES.CARBON_COPY,
    MORE_OPTIONS: COLOR_NAMES.CARBON_COPY,
    TRADE_PRICE: COLOR_NAMES.EMERALD_SPRINGS,
    SOLD_OUT_BADGE: {
      BACKGROUND: COLOR_NAMES.WHITE,
      TEXT: COLOR_NAMES.CARBON_COPY,
    },
    BLACK_FRIDAY_BADGE: {
      BACKGROUND: COLOR_NAMES.WHITE,
      TEXT: COLOR_NAMES.PINEY_LAKE,
    },
    ARCHIVED_BADGE: {
      BACKGROUND: COLOR_NAMES.RICE_PUDDING,
      TEXT: COLOR_NAMES.CINNAMON_BUN,
    },
    ACTION: {
      DANGER: {
        ICON: COLOR_NAMES.FLUORESCENT_RED,
        BACKGROUND: COLOR_NAMES.RICE_PUDDING,
      },
      REARRANGE: {
        ICON: COLOR_NAMES.PINEY_LAKE,
        BACKGROUND: COLOR_NAMES.TETON_BREEZE,
      },
      DEFAULT: {
        ICON: COLOR_NAMES.CARBON_COPY,
        BACKGROUND: COLOR_NAMES.DOCTOR,
      },
      DISABLED: {
        ICON: COLOR_NAMES.GRAY_SHINGLE,
        BACKGROUND: COLOR_NAMES.DOCTOR,
      },
    },
  },
  PRODUCT_HEADER: {
    PRICE: COLOR_NAMES.CARBON_COPY,
    TRADE_PRICE: COLOR_NAMES.EMERALD_SPRINGS,
    DISCOUNT: COLOR_NAMES.PINEY_LAKE,
  },
  PRODUCT_CUSTOM_OFFER: {
    BACKGROUND: COLOR_NAMES.WARP_DRIVE,
  },
  PRODUCT_GALLERY_FADE_BOX: {
    GRADIENT: COLOR_NAMES.WHITE,
  },
  DELIMITER: COLOR_NAMES.JUPITER,
  BADGE: {
    GREEN: {
      BACKGROUND: COLOR_NAMES.SOFT_MINT,
      TEXT: COLOR_NAMES.EMERALD_SPRINGS,
    },
    ORANGE: {
      BACKGROUND: COLOR_NAMES.RICE_PUDDING,
      TEXT: COLOR_NAMES.PUMPKIN_ORANGE,
    },
    BROWN: {
      BACKGROUND: COLOR_NAMES.RICE_PUDDING,
      TEXT: COLOR_NAMES.CINNAMON_BUN,
    },
    BLUE: {
      BACKGROUND: COLOR_NAMES.MOON_WHITE,
      TEXT: COLOR_NAMES.MATT_BLUE,
    },
    RED: {
      BACKGROUND: COLOR_NAMES.WHITE_GLOSS,
      TEXT: COLOR_NAMES.FLUORESCENT_RED,
    },
    GRAY: {
      BACKGROUND: COLOR_NAMES.SNOWFLAKE,
      TEXT: COLOR_NAMES.CARBON_COPY,
    },
    WHITE: {
      BACKGROUND: COLOR_NAMES.WHITE,
      TEXT: COLOR_NAMES.EEIRE_BLACK,
      BORDER: COLOR_NAMES.JUPITER,
    },
  },
  PURCHASE_LIST: {
    BACKGROUND: COLOR_NAMES.DOCTOR,
    BORDER: COLOR_NAMES.JUPITER,
    SELECTED: COLOR_NAMES.WHITE,
    SELECTED_BORDER: COLOR_NAMES.PINEY_LAKE,
  },
  PURCHASE_DETAIL_TOGGLE: {
    TEXT: COLOR_NAMES.PINEY_LAKE,
  },
  PURCHASE_ITEM: {
    WRAPPER_BOTTOM: COLOR_NAMES.JUPITER,
  },
  PURCHASE_STATUS_WRAPPER: {
    BACKGROUND: COLOR_NAMES.DOCTOR,
    TEXT: COLOR_NAMES.DOCTOR,
  },
  DISCOUNT: {
    DISCOUNT_TEXT: COLOR_NAMES.EMERALD_SPRINGS,
    CODE_APPLIED: COLOR_NAMES.EMERALD_SPRINGS,
  },
  TOOLTIP: {
    BACKGROUND: COLOR_NAMES.EEIRE_BLACK,
    TEXT: COLOR_NAMES.WHITE,
  },
  BREADCRUMBS: {
    PRIMARY: {
      VISITED: COLOR_NAMES.EEIRE_BLACK,
      ACTIVE: COLOR_NAMES.EEIRE_BLACK,
      DISABLED: COLOR_NAMES.CARBON_COPY,
      ICON: COLOR_NAMES.CARBON_COPY,
    },
    CHECKOUT: {
      VISITED: COLOR_NAMES.PINEY_LAKE,
      ACTIVE: COLOR_NAMES.EEIRE_BLACK,
      DISABLED: COLOR_NAMES.SONIC_SILVER,
      ICON: COLOR_NAMES.SONIC_SILVER,
    },
  },
  SESSION_REPORT: {
    BACKGROUND: COLOR_NAMES.WARP_DRIVE,
    DELIMITER: COLOR_NAMES.GRAY_SHINGLE,
    ITEM_TITLE: COLOR_NAMES.BLACK,
  },
  FILES_AND_NOTES_ITEM: {
    WARNING_ICON: COLOR_NAMES.FLUORESCENT_RED,
    LOADING_BACKGROUND: COLOR_NAMES.DOCTOR,
    CANCEL_BUTTON_COLOR: COLOR_NAMES.CARBON_COPY,
    BACKGROUND: COLOR_NAMES.DOCTOR,
    DELETE_ICON: COLOR_NAMES.CARBON_COPY,
  },
  ATTACHMENT_ICON: {
    PRIMARY: COLOR_NAMES.PINEY_LAKE,
    DISABLED: COLOR_NAMES.CARBON_COPY,
  },
  SESSION_CLIENT_ATTACHMENTS: {
    ATTACHMENT_NAME: {
      PRIMARY: COLOR_NAMES.PINEY_LAKE,
      DISABLED: COLOR_NAMES.CARBON_COPY,
    },
    ATTACHMENT_FAILED_ICON: COLOR_NAMES.AMARANTH_RED,
    ATTACHMENT_DELETE_ICON: COLOR_NAMES.CARBON_COPY,
  },
  ICON: {
    DEFAULT_COLOR: COLOR_NAMES.PINEY_LAKE,
  },
  FOOTER: {
    WRAPPER: {
      TEXT: COLOR_NAMES.WHITE,
      BACKGROUND: COLOR_NAMES.PINEY_LAKE,
    },
    NAVIGATION_LINKS: {
      HEADING: COLOR_NAMES.WHITE,
      TEXT: COLOR_NAMES.WHITE,
    },
    NEWSLETTER_SUBSCRIBE: {
      HEADING: COLOR_NAMES.WHITE,
      TEXT: COLOR_NAMES.WARP_DRIVE,
    },
    AGREEMENT_LINKS: {
      TEXT: COLOR_NAMES.WARP_DRIVE,
    },
    COPYRIGHT: {
      TEXT: COLOR_NAMES.WHITE,
    },
    SOCIAL_LINKS: COLOR_NAMES.WARP_DRIVE,
  },
  ICON_BADGE: {
    DEFAULT: {
      BACKGROUND: COLOR_NAMES.PINEY_LAKE,
      TEXT: COLOR_NAMES.WHITE,
    },
    DANGER: {
      BACKGROUND: COLOR_NAMES.AMARANTH_RED,
      TEXT: COLOR_NAMES.WHITE,
    },
  },
  ORDERS_PAGE: {
    LIST_TEXT_COLOR: COLOR_NAMES.BLACK,
  },
  EXPERT_TESTIMONIAL: {
    PAGINATION_BULLET: {
      BACKGROUND: COLOR_NAMES.AMERICAN_SILVER,
      ACTIVE_BACKGROUND: COLOR_NAMES.PINEY_LAKE,
    },
    ARROW_ICON_COLOR: COLOR_NAMES.GRAY_SHINGLE,
  },
  LINK: {
    PRIMARY: COLOR_NAMES.PINEY_LAKE,
    DISABLED: COLOR_NAMES.CARBON_COPY,
  },
  COLLAPSIBLE_WRAPPER: {
    BORDER_COLOR: COLOR_NAMES.JUPITER,
  },
  DOT_SEPARATED_TEXT: {
    TEXT: COLOR_NAMES.CARBON_COPY,
    CIRCLE: COLOR_NAMES.AMERICAN_SILVER,
  },
  WIDGETS: {
    CARD_NAVIGATION: {
      MOBILE_ITEM_TITLE: COLOR_NAMES.BLACK,
    },
    HERO_MAIN: {
      BACKGROUND: COLOR_NAMES.WARP_DRIVE,
    },
    SPOTLIGHT: {
      TITLE_COLOR: COLOR_NAMES.BLACK,
      SUBTITLE_COLOR: COLOR_NAMES.PINEY_LAKE,
      BACKGROUD: COLOR_NAMES.WARP_DRIVE,
    },
    CATEGORY_HEADER: {
      EXPERT_BORDER: COLOR_NAMES.WHITE,
    },
    FEATURED_IN: {
      BACKGROUND_COLOR: COLOR_NAMES.WARP_DRIVE,
    },
    INTRODUCTION: {
      BACKGROUND: COLOR_NAMES.WARP_DRIVE,
    },
    LISTING_WIDGET: {
      CONSULTATION: {
        DIVIDER: COLOR_NAMES.AMERICAN_SILVER,
      },
    },
    VALUE_PROPOSITION: {
      BORDER: COLOR_NAMES.WATER_BAPTISM,
      BORDER_HOVER: COLOR_NAMES.PINEY_LAKE,
      ICON: COLOR_NAMES.EEIRE_BLACK,
    },
    TESTIMONIAL: {
      ODD: COLOR_NAMES.WARP_DRIVE,
    },
    ARTICLE_HEADING: COLOR_NAMES.EEIRE_BLACK,
    CONCIERGE: {
      BACKGROUND: COLOR_NAMES.BLEACHED_SILK,
      DIVIDER: COLOR_NAMES.LIGHT_YEAR,
    },
    VIDEO_SECTION: {
      BACKGROUND_COLOR: COLOR_NAMES.CLOUD_DANCER,
      PLAYER_BUTTON_BACKGROUND: COLOR_NAMES.WHITE,
      HINT_ERROR_TEXT: COLOR_NAMES.FLUORESCENT_RED,
      OVERLAY: COLOR_NAMES.EEIRE_BLACK,
    },
    ALPHABETICAL_INDEX: {
      TITLE: COLOR_NAMES.EEIRE_BLACK,
      LINK: COLOR_NAMES.PINEY_LAKE,
    },
    HERO_CAROUSEL: {
      TEXT: COLOR_NAMES.WHITE,
    },
    VALUE_PROPOSITION_ITEM: {
      HEADING: COLOR_NAMES.WHITE,
      TEXT: COLOR_NAMES.WHITE,
    },
    ALT_EXPERT_LISTING: {
      EXPERT_NAME: COLOR_NAMES.EEIRE_BLACK,
      BG_COLOR: COLOR_NAMES.DOCTOR,
    },
    SECTION_WIDGET: {
      TEXT: COLOR_NAMES.EEIRE_BLACK,
    },
  },
  TABLE: {
    CELL: {
      BORDER_COLOR: COLOR_NAMES.ATHENS_GRAY,
    },
  },
  HEADER: {
    BORDER: COLOR_NAMES.COOL_MINT,
    OVERLAY: COLOR_NAMES.EEIRE_BLACK,
    ICONS: {
      DEFAULT: COLOR_NAMES.EEIRE_BLACK,
      HOVER: COLOR_NAMES.PINEY_LAKE,
    },
    NEED_HELP_TEXT: COLOR_NAMES.PINEY_LAKE,
    ITEM_WITH_IMAGE_TEXT: {
      DEFAULT: COLOR_NAMES.EEIRE_BLACK,
      HOVER: COLOR_NAMES.PINEY_LAKE,
    },
    VIEW_ALL: {
      DEFAULT: COLOR_NAMES.EEIRE_BLACK,
      HOVER: COLOR_NAMES.PINEY_LAKE,
    },
    WRAPPER: COLOR_NAMES.WARP_DRIVE,
  },
  WRAPPED_LOADER: {
    BACKGOUND: COLOR_NAMES.WARP_DRIVE,
  },
  MENU: {
    BACKGROUND: COLOR_NAMES.WARP_DRIVE,
    HEADING: {
      DEFAULT: COLOR_NAMES.EEIRE_BLACK,
      HOVER: COLOR_NAMES.PINEY_LAKE,
    },
    TEXT_LINK: {
      HOVER: COLOR_NAMES.PINEY_LAKE,
      DEFAULT: COLOR_NAMES.EEIRE_BLACK,
    },
  },
  VINTAGE_CARD: {
    BORDER: COLOR_NAMES.COOL_MINT,
    TEXT: COLOR_NAMES.BLACK,
  },
  SHOWROOM_CARD: {
    BRAND: COLOR_NAMES.CARBON_COPY,
    BACKGROUND: COLOR_NAMES.WHITE,
    LINK: COLOR_NAMES.BLACK,
    SUBMITTED_BUTTON_BACKGROUND: COLOR_NAMES.TETON_BREEZE,
    SUBMITTED_BUTTON_TEXT: COLOR_NAMES.PINEY_LAKE,
  },
  LOGO_DEFAULT: COLOR_NAMES.BLACK,
  MODAL: {
    TITLE: {
      DANGER: COLOR_NAMES.FLUORESCENT_RED,
    },
    BACKGROUND: COLOR_NAMES.WHITE,
  },
  ORDER_NOTE: {
    BACKGROUND: COLOR_NAMES.DOCTOR,
    DATE: COLOR_NAMES.CARBON_COPY,
  },
  TOGGLE_BUTTON: {
    BORDER_COLOR: COLOR_NAMES.WARP_DRIVE,
    BORDER_COLOR_HOVER: COLOR_NAMES.AZURE_X11,
    BORDER_COLOR_CHECKED: COLOR_NAMES.PINEY_LAKE,
    BORDER_COLOR_DISABLED: COLOR_NAMES.DOCTOR,
    BACKGROUND: COLOR_NAMES.WHITE,
    BACKGROUND_CHECKED: COLOR_NAMES.WARP_DRIVE,
    BACKGROUND_DISABLED: COLOR_NAMES.DOCTOR,
    TEXT: COLOR_NAMES.PINEY_LAKE,
    TEXT_DISABLED: COLOR_NAMES.SONIC_SILVER,
  },
  ORDERABLE_SELECT: {
    BORDER_COLOR: COLOR_NAMES.JUPITER,
  },
  SIDENAV: {
    HEADER: {
      BACKGROUND: COLOR_NAMES.WHITE,
    },
  },
  CART_MODAL: {
    TITLE: COLOR_NAMES.PINEY_LAKE,
  },
  SEARCH: {
    RESULT_WRAPPER_BACKGROUND: COLOR_NAMES.WHITE,
    INPUT_ICON: COLOR_NAMES.GRAY_SHINGLE,
    NO_RESULT_TEXT: COLOR_NAMES.EEIRE_BLACK,
    NO_RESULT_SUB_TEXT: COLOR_NAMES.CARBON_COPY,
    PROFILE_NAME: COLOR_NAMES.EEIRE_BLACK,
    JOB_TITLE: COLOR_NAMES.CARBON_COPY,
  },
  CALLOUT: {
    DEFAULT: {
      BACKGROUND: COLOR_NAMES.JUPITER,
      COLOR: COLOR_NAMES.EEIRE_BLACK,
    },
    DANGER: {
      BACKGROUND: COLOR_NAMES.WHITE_GLOSS,
      COLOR: COLOR_NAMES.FLUORESCENT_RED,
    },
    WARNING: {
      BACKGROUND: COLOR_NAMES.RICE_PUDDING,
      COLOR: COLOR_NAMES.CINNAMON_BUN,
    },
    INFO: {
      BACKGROUND: COLOR_NAMES.RICE_PUDDING,
      COLOR: COLOR_NAMES.CARBON_COPY,
    },
    PRIMARY: {
      BACKGROUND: COLOR_NAMES.TETON_BREEZE,
      COLOR: COLOR_NAMES.PINEY_LAKE,
    },
  },
  ADMIN_PRODUCT_DETAIL_LIST: {
    BORDER: COLOR_NAMES.JUPITER,
    TITLE: COLOR_NAMES.EEIRE_BLACK,
    BACKGROUND: COLOR_NAMES.DOCTOR,
    ITEM: {
      TITLE: COLOR_NAMES.EEIRE_BLACK,
      TEXT: COLOR_NAMES.CARBON_COPY,
      BACKGROUND: COLOR_NAMES.WHITE,
    },
  },
  ADMIN_PRODUCT_DETAIL: {
    TITLE_BACKGROUND: COLOR_NAMES.WHITE,
  },
  ADMIN_WIDGET_WRAPPER: {
    BORDER: COLOR_NAMES.JUPITER,
  },
  COLLECTIONS_SHOWROOM_PAGE_CARD: {
    PROFILE_IMAGE_BORDER: COLOR_NAMES.WHITE,
  },
  PRODUCT_ACCORDION: {
    PROPOSITION_65_BUTTON: COLOR_NAMES.PINEY_LAKE,
  },
  PROPOSITION_65_MODAL: {
    ICON: COLOR_NAMES.EEIRE_BLACK,
  },
  DIVIDER: {
    DEFAULT_BACKGROUD: COLOR_NAMES.WARP_DRIVE,
  },
  FORM_STATE_INDICATOR: {
    UNSAVED_CHANGES: COLOR_NAMES.PUMPKIN_ORANGE,
    INVALID_INPUT: COLOR_NAMES.FLUORESCENT_RED,
  },
  PROMO_CODE_MODAL: {
    REMOVE_BUTTON: {
      TEXT: COLOR_NAMES.CARBON_COPY,
    },
  },
  TRADE_PROGRAM_RIBBON: {
    BACKGROUND: COLOR_NAMES.EEIRE_BLACK,
    TEXT: COLOR_NAMES.WHITE,
  },
  CAROUSEL_NAVIGATION: {
    LIGHT: {
      BULLET_COLOR: COLOR_NAMES.WHITE,
    },
    DARK: {
      BULLET_COLOR: {
        ACTIVE: COLOR_NAMES.PINEY_LAKE,
        INACTIVE: COLOR_NAMES.EEIRE_BLACK,
      },
    },
    ARROWS: {
      ARROW_COLOR: COLOR_NAMES.PINEY_LAKE,
      BG_COLOR: COLOR_NAMES.WHITE,
      ARROW_COLOR_HOVER: COLOR_NAMES.EEIRE_BLACK,
    },
  },
  PRODUCT_UNAVAILABLE_TEXT: COLOR_NAMES.WINDSOR_TAN,
  SUMMARY: {
    COLLAPSIBLE_SUMMARY: {
      BACKGROUND: COLOR_NAMES.DOCTOR,
      BUTTON_COLOR: COLOR_NAMES.CARBON_COPY,
    },
    TRADE_DISCOUNT_TEXT: COLOR_NAMES.PINEY_LAKE,
  },
  CARDS_MANAGEMENT: {
    BACKGROUND: COLOR_NAMES.WHITE,
    BORDER: COLOR_NAMES.JUPITER,
  },
  TABS: {
    BORDER: {
      BASE: COLOR_NAMES.JUPITER,
      ACTIVE: COLOR_NAMES.PINEY_LAKE,
    },
    TAB: {
      TEXT: {
        BASE: COLOR_NAMES.EEIRE_BLACK,
        GREY: COLOR_NAMES.CARBON_COPY,
        ACTIVE: COLOR_NAMES.PINEY_LAKE,
      },
    },
  },

  SIGN_IN: {
    BACKGROUND: COLOR_NAMES.DOCTOR,
    TEXT: COLOR_NAMES.CARBON_COPY,
  },
  MULTIFILE_UPLOAD: {
    UPLOADED_ITEMS: {
      BACKGROUND: COLOR_NAMES.DOCTOR,
      REMOVE_BUTTON: COLOR_NAMES.CARBON_COPY,
    },
  },
  DROPZONE: {
    TEXT: COLOR_NAMES.CARBON_COPY,
    BACKGROUND: COLOR_NAMES.DOCTOR,
    BORDER_ACTIVE: COLOR_NAMES.PINEY_LAKE,
    BORDER_INACTIVE: COLOR_NAMES.JUPITER,
  },
  DASHBOARD: {
    WRAPPER: {
      BACKGROUND: COLOR_NAMES.WHITE,
    },
  },
  CHECKOUT: {
    TAX_EXEMPTION_TOOLTIP_ICON: COLOR_NAMES.CARBON_COPY,
  },
  EXPERT_INFO: {
    PROFILE_IMAGE_BORDER: COLOR_NAMES.WHITE,
    PIN_ICON: COLOR_NAMES.SONIC_SILVER,
    FAVORITE_MARKER: COLOR_NAMES.PINEY_LAKE,
  },
  FAVORITE_MARKER_DEFAULT_COLOR: COLOR_NAMES.BLACK,
  SESSION_BOOKING_CARD: {
    BACKGROUND: COLOR_NAMES.WHITE,
    BORDER: COLOR_NAMES.JUPITER,
    SUBTITLE: COLOR_NAMES.SONIC_SILVER,
    DIVIDER: COLOR_NAMES.JUPITER,
    DESCRIPTION: COLOR_NAMES.SONIC_SILVER,
    ICON: {
      INFO: COLOR_NAMES.GRAY_SHINGLE,
    },
    SELECTED_BORDER: COLOR_NAMES.PINEY_LAKE,
  },
  INFO_CARD: {
    BACKGROUND: COLOR_NAMES.WARP_DRIVE,
  },
  PRIVATE_FORM: {
    REMOVE_CARD: {
      BACKGROUND: COLOR_NAMES.DOCTOR,
    },
    SETTINGS_ITEMS: {
      BORDER: COLOR_NAMES.DOCTOR,
    },
  },
  EXPERT_DETAIL: {
    PARTICIPATE_SECTION: {
      TEXT: COLOR_NAMES.CARBON_COPY,
    },
  },
  EXPERT_SHOWROOM: {
    DIVIDER: COLOR_NAMES.AMERICAN_SILVER,
    GUIDING_PRINCIPLES: {
      BORDER: COLOR_NAMES.WATER_BAPTISM,
      HIGHLIGHTED_TEXT: COLOR_NAMES.PINEY_LAKE,
      BACKGROUND: COLOR_NAMES.WARP_DRIVE,
    },
    SEND_REVIEW_BUTTON: {
      BACKGROUND: COLOR_NAMES.BLACK,
      COLOR: COLOR_NAMES.WHITE,
      DISABLED: {
        BACKGROUND: COLOR_NAMES.AMERICAN_SILVER,
        COLOR: COLOR_NAMES.CARBON_COPY,
      },
    },
    IN_REVIEW_SELECT: {
      BACKGROUND: COLOR_NAMES.WHITE,
      COLOR: COLOR_NAMES.AMARANTH_RED,
      CANCEL_REVIEW_BUTTON: {
        BACKGROUND: COLOR_NAMES.WHITE,
        COLOR: COLOR_NAMES.AMARANTH_RED,
      },
      LOADER_COLOR: COLOR_NAMES.BLACK,
    },
  },
  ERROR: {
    ICON: COLOR_NAMES.GRAY_SHINGLE,
  },
  SESSION_INFO: {
    LENGTH: COLOR_NAMES.GRAY_SHINGLE,
  },
  SESSION_WRAPPER: {
    BORDER: COLOR_NAMES.JUPITER,
  },
  SESSION_DATE: {
    TEXT: COLOR_NAMES.GRAY_SHINGLE,
  },
  SESSION_PREVIEW_WRAPPER: {
    TEXT: COLOR_NAMES.PINEY_LAKE,
  },
  UPCOMING_SESSION_WIDGET: {
    HEADER: COLOR_NAMES.PINEY_LAKE,
    VALUE: COLOR_NAMES.CARBON_COPY,
    PRICE: COLOR_NAMES.BLACK,
    DETAILS: COLOR_NAMES.PINEY_LAKE,
  },
  EXPERT_APPLICATION: {
    BACKGOUND: COLOR_NAMES.WARP_DRIVE,
  },
  EXPERT_APPLICATION_CATEGORIES: {
    HEADER: COLOR_NAMES.EEIRE_BLACK,
  },
  EXPERT_APPLICATION_DESCRIPTION: {
    HEADER: COLOR_NAMES.EEIRE_BLACK,
    NOTE: COLOR_NAMES.CARBON_COPY,
    DESCRIPTION: COLOR_NAMES.GRAY_SHINGLE,
  },
  EXPERT_APPLICATION_GENERAL: {
    HEADER: COLOR_NAMES.EEIRE_BLACK,
    NOTE: COLOR_NAMES.CARBON_COPY,
    DESCRIPTION: COLOR_NAMES.CARBON_COPY,
  },
  EXPERT_APPLICATION_SOCIAL: {
    HEADER: COLOR_NAMES.EEIRE_BLACK,
    NOTE: COLOR_NAMES.CARBON_COPY,
  },
  EXPERT_APPLICATION_THANK_YOU: {
    HEADER: COLOR_NAMES.EEIRE_BLACK,
    NOTE: COLOR_NAMES.CARBON_COPY,
    ICON: '#FAB005',
  },
  GROUP_SESSION_TIME_PICKER_MODAL: {
    CHECKBOX_WRAPPER_BORDER: COLOR_NAMES.JUPITER,
  },
  COUNTER: {
    ICON_DISABLED: COLOR_NAMES.AMERICAN_SILVER,
    ICON_ENABLED: COLOR_NAMES.CARBON_COPY,
  },
  DELETE_GROUP_SESSION_MODAL: {
    NO_REFUND: COLOR_NAMES.FLUORESCENT_RED,
    WILL_BE_REFUNDED: COLOR_NAMES.PINEY_LAKE,
  },
  EXPERT_SIDE_NAV_PAGE: {
    COMPLETE_STRIPE_ONBOARDING: COLOR_NAMES.FLUORESCENT_RED,
    HERE_LINK: COLOR_NAMES.FLUORESCENT_RED,
    PAYMENT_LINK: COLOR_NAMES.FLUORESCENT_RED,
    COMPLETE_ZOOM_ONBOARDING: COLOR_NAMES.FLUORESCENT_RED,
  },
  COLLECTION_GALLERY_VIEW: {
    BANNER_BACKGROUND: COLOR_NAMES.RICE_PUDDING,
    BANNER_COLOR: COLOR_NAMES.CINNAMON_BUN,
  },
} as const

/** @deprecated */
export const COLOR = {
  WHITE: '#FFFFFF',
  BLACK: '#000',
  PRIMARY_1: '#126554',
  PRIMARY_2: '#EBF2F1',
  SECONDARY_1: '#FA5252',
  SECONDARY_2: '#E64980',
  SECONDARY_3: '#BE4BDB',
  SECONDARY_4: '#7950F2',
  SECONDARY_5: '#4C6EF5',
  SECONDARY_6: '#228BE6',
  SECONDARY_7: '#15AABF',
  SECONDARY_8: '#12B886',
  SECONDARY_9: '#40C057',
  SECONDARY_10: '#82C91E',
  SECONDARY_11: '#FAB005',
  SECONDARY_12: '#FD7E14',
  SECONDARY_13: '#EB0E0E',
  GRAYSCALE_1: '#181B19',
  GRAYSCALE_2: '#545554',
  GRAYSCALE_3: '#949494',
  GRAYSCALE_4: '#CECECE',
  GRAYSCALE_5: '#E1E1E1',
  GRAYSCALE_6: '#F8F8F8',
  GRAYSCALE_7: '#333333',
  GRAYSCALE_8: '#C4C4C4',
} as const

export const GRADIENT = {
  GRADIENT_1: '180deg, rgba(24, 27, 25, 0) 0%, rgba(24, 27, 25, 0.03) 100%',
  GRADIENT_1_HOVER:
    '180deg, rgba(24, 27, 25, 0) 0%, rgba(24, 27, 25, 0.05) 100%',
  GRADIENT_2: '180deg, rgba(24, 27, 25, 0) 0%, rgba(24, 27, 25, 0.03) 100%',
  GRADIENT_2_HOVER:
    '180deg, rgba(24, 27, 25, 0) 0%, rgba(24, 27, 25, 0.05) 100%',
  GRADIENT_3: `180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 90%`,
  GRADIENT_4: `0deg, rgba(24,27,25,0.1), rgba(24,27,25,0.1)`,
  SWIPER_GRADIENT_TOP: `0deg, rgba(255, 255, 255, 0.00) 0%, #FFFFFF 100%`,
  SWIPER_GRADIENT_BOTTOM: `180deg, rgba(255, 255, 255, 0.00) 0%, #FFFFFF 100%`,
} as const

export const BOX_SHADOW = {
  DARK: '0px 1px 2px rgba(13, 64, 128, 0.19)',
  INNER: 'inset 1px 1px 4px rgba(0, 0, 0, 0.08)',
  ELEVATION_1: '0px 5px 10px rgba(24, 27, 25, 0.07)',
  ELEVATION_2: '0px 5px 20px rgba(24, 27, 25, 0.08)',
  ELEVATION_3: '0px 5px 30px rgba(24, 27, 25, 0.1)',
  ELEVATION_4: '0px 5px 40px rgba(24, 27, 25, 0.1)',
  ELEVATION_5: '0px 5px 20px rgba(24, 27, 25, 0.1)',
  ELEVATION_6: '0px 5px 20px rgba(0, 0, 0, 0.1)',
  ELEVATION_COLLECTION_CARD_1: '0px 2px 10px -3px rgba(0, 0, 0, 0.1)',
} as const

export const SPACE = {
  PX_1: rem(1),
  PX_2: rem(2),
  PX_4: rem(4),
  PX_5: rem(5),
  PX_8: rem(8),
  PX_6: rem(6),
  PX_9: rem(9),
  PX_10: rem(10),
  PX_12: rem(12),
  PX_14: rem(14),
  PX_15: rem(15),
  PX_16: rem(16),
  PX_18: rem(18),
  PX_20: rem(20),
  PX_21: rem(21),
  PX_22: rem(22),
  PX_24: rem(24),
  PX_26: rem(26),
  PX_28: rem(28),
  PX_30: rem(30),
  PX_32: rem(32),
  PX_36: rem(36),
  PX_38: rem(38),
  PX_40: rem(40),
  PX_48: rem(48),
  PX_50: rem(50),
  PX_56: rem(56),
  PX_60: rem(60),
  PX_64: rem(64),
  PX_80: rem(80),
  PX_100: rem(100),
  PX_120: rem(120),
  PX_150: rem(150),
  PX_160: rem(160),
  PX_200: rem(200),
  PX_210: rem(210),
  PX_222: rem(222),
} as const

// https://www.figma.com/file/xNeeXkQzLybZzYduA149HZ?node-id=484:881#35510912
export const ICON_SPACE = {
  PX_2: rem(2),
  PX_3: rem(3),
  PX_6: rem(6),
  PX_8: rem(8),
} as const

export const FONT_SIZE = {
  PX_9: rem(9),
  PX_10: rem(10),
  PX_11: rem(11),
  PX_12: rem(12),
  PX_13: rem(13),
  PX_14: rem(14),
  PX_15: rem(15),
  PX_16: rem(16),
  PX_18: rem(18),
  PX_20: rem(20),
  PX_24: rem(24),
  PX_32: rem(32),
  PX_36: rem(36),
  PX_48: rem(48),
} as const

export const LINE_HEIGHT = {
  XXXS: 1.06,
  XXS: 1.1,
  XS: 1.15,
  S: 1.2,
  M: 1.3,
  L: 1.4,
  XL: 1.75,
} as const

export const FONT_WEIGHT = {
  THIN: 100,
  EXTRA_LIGHT: 200,
  LIGHT: 300,
  NORMAL: 400,
  MEDIUM: 500,
  SEMI_BOLD: 600,
  BOLD: 700,
  BLACK: 800,
  SUPER: 900,
} as const

export const RADIUS = {
  PX_1: rem(1),
  PX_2: rem(2),
  PX_3: rem(3),
  PX_4: rem(4),
  PX_6: rem(6),
  PX_8: rem(8),
  PX_10: rem(10),
  PX_12: rem(12),
  PX_16: rem(12),
  PX_48: rem(48),
  PX_80: rem(80),
  CIRCLE: '50%',
} as const

export const MODAL_MAX_WIDTH = {
  PX_580: rem(580),
  PX_600: rem(600),
  PX_400: rem(400),
  PX_350: rem(350),
  PX_720: rem(720),
} as const

export const PAGE_MAX_WIDTH = {
  LAYOUT: rem(1180),
  SESSION_REPORT: rem(800),
  PUBLIC_LAYOUT_MOBILE: rem(375),
  SMALL: rem(375),
} as const

export const ARTICLE_MAX_WIDTH = {
  IMAGE: rem(1100),
  TEXT: rem(784),
  FAQ: rem(784),
} as const

export const IMAGE_BASE_WIDTH = {
  PX_1200: 1200,
  PX_800: 800,
  PX_500: 500,
  PX_400: 400,
  PX_320: 320,
  PX_260: 260,
  PX_160: 160,
  PX_110: 110,
  PX_80: 80,
  PX_40: 40,
  PX_30: 30,
} as const

export const WIDGET_MAX_WIDTH = {
  ARTICLE: 700,
} as const

export type InputSize = (typeof INPUT_SIZE)[keyof typeof INPUT_SIZE]
export const INPUT_SIZE = {
  S: rem(30),
  M: rem(36),
  L: rem(40),
} as const

export const LAYOUT_WRAPPER_MAX_WIDTH = rem(580)

export const SLIDE_OUT_CUBIC_BEZIER_EASE = { ease: [0.56, 0.8, 0.58, 0.97] }
export const MENU_CUBIC_BEZIER_EASE = { ease: [0.18, 0.49, 0.54, 0.91] }
export const SUBMENU_CUBIC_BEZIER_EASE = { ease: [0.16, 1, 0.3, 1] }

export const DEVICE = {
  MOBILE: '0',
  TABLET: '768px',
  DESKTOP: '1024px',
  TV: '1440px',
} as const

type MediaQueryReturnType = {
  [key in keyof typeof DEVICE]: any
}

const mediaQuery = ({ mobileFirst = true }) =>
  Object.entries(DEVICE).reduce<MediaQueryReturnType>(
    (deviceMediaQueries, [label, breakpoint]) => ({
      ...deviceMediaQueries,
      [label]: (...args) => css`
        @media screen and (${mobileFirst ? 'min-width' : 'max-width'}: ${math(
            `${breakpoint} - 0.1px`
          )}) {
          ${css(...args)}
        }
      `,
    }),
    {} as MediaQueryReturnType
  )

// mq is for usage within `styled` function
export const mq = {
  to: mediaQuery({ mobileFirst: false }),
  from: mediaQuery({ mobileFirst: true }),
}

export const getBorderColor = ({
  isFocusedOrChecked,
  isError,
  isWarning,
  allowedBorderColor = true,
}: {
  isFocusedOrChecked?: boolean
  isError?: boolean
  isWarning?: boolean
  allowedBorderColor?: boolean
}) => {
  if (isError) {
    return COLOR.SECONDARY_1
  }
  if (isWarning) {
    return COLOR.SECONDARY_12
  }
  if (isFocusedOrChecked) {
    return COLOR.PRIMARY_1
  }
  if (!allowedBorderColor) {
    return 'transparent'
  }
  return COLOR.GRAYSCALE_5
}

export const getTextColor = ({
  isWarning,
  isError,
  messageContrast,
}: {
  isWarning?: boolean
  isError?: boolean
  messageContrast?: 'dark' | 'light'
}) => {
  if (isWarning) {
    return COLOR_INTENT.WARNING
  }
  if (isError && messageContrast === 'light') {
    return COLOR_INTENT.ERROR_LIGHT
  }
  if (isError) {
    return COLOR_INTENT.ERROR_DARK
  }
  return COLOR_INTENT.GRAY_70
}

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html,
  body {
    min-width: 320px;
    margin: 0;
    height: 100%;
  }
  #__next {
    min-height: 100vh;
    min-height: -moz-available;          /* WebKit-based browsers will ignore this. */
    min-height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  }
  html {
    cursor: initial;
    scroll-behavior: smooth; /* Adds smooth scroll to all anchor links */
  }
  body {
    font-family: ${FONT_STACK.SANS};
    color: ${COLOR.GRAYSCALE_1};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: ${LINE_HEIGHT.L};
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 0.5em 0;
    font-weight: ${FONT_WEIGHT.MEDIUM};
    line-height: ${LINE_HEIGHT.M};
    letter-spacing: 0;
  }

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: ${COLOR.PRIMARY_1}
  }

  ul, ol {
    margin-top: 20px;
    padding-left: 40px;
  }

  ol ul {
    list-style-type: disc;
  }

  ol ::marker {
    font-weight: ${FONT_WEIGHT.MEDIUM};
  }

  input,
  textarea,
  button {
    font-family: inherit;
    font-size: ${FONT_SIZE.PX_14};
    letter-spacing: inherit;
    box-sizing: border-box;
  }

  input::placeholder {
    font-size: inherit;
  }

  button {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  input[type="number"] {
    appearance: textfield;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  code,
  kbd,
  samp,
  pre {
    /* TODO: change fonts */
    font-family: SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace;
    font-size: 1em;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  :focus {
    outline: none;
  }

  :root {
    --reach-dialog: 1; /* https://reacttraining.com/reach-ui/styling/#skip-including-styles */
  }

  .grecaptcha-badge {
    bottom: 8rem !important;
    z-index: 100;
    right: 0;
    visibility: ${({ showRecaptchaBadge }) =>
      showRecaptchaBadge ? 'visible' : 'hidden'};
  }

  /* Solves swiper flickering problem on Safari. https://github.com/nolimits4web/swiper/issues/3641 */
  .swiper-slide {
    -webkit-transform: translateZ(0);
  }
`
