import PropTypes from 'prop-types'
import React from 'react'

export const Settings = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.2 14.0741L21 14.7354C21.3796 15.0439 21.4811 15.582 21.24 16.008L20.08 18.012C19.8401 18.4022 19.3666 18.5797 18.93 18.4428L17.93 18.0821C17.3399 17.8611 16.6803 17.9317 16.15 18.2725C15.9149 18.4352 15.6675 18.5793 15.41 18.7034C14.8473 18.9925 14.4542 19.5313 14.35 20.1562L14.18 21.1582C14.1008 21.6483 13.6754 22.0063 13.18 21.9999H10.88C10.3846 22.0063 9.95925 21.6483 9.88002 21.1582L9.71002 20.1562C9.60579 19.5313 9.21272 18.9925 8.65002 18.7034C8.39252 18.5793 8.1451 18.4352 7.91002 18.2725C7.37973 17.9317 6.72016 17.8611 6.13002 18.0821L5.13002 18.4428C4.67577 18.6119 4.16589 18.4304 3.92002 18.012L2.76002 16.008C2.51894 15.582 2.62044 15.0439 3.00002 14.7354L3.80002 14.0741C4.28507 13.6707 4.55189 13.0617 4.52002 12.4309V12V11.5691C4.55189 10.9383 4.28507 10.3293 3.80002 9.92587L3.00002 9.26455C2.62044 8.95612 2.51894 8.41797 2.76002 7.99202L3.92002 5.98803C4.16405 5.57925 4.66157 5.39911 5.11002 5.55717L6.11002 5.91789C6.70016 6.13888 7.35973 6.06834 7.89002 5.72751C8.1251 5.56478 8.37252 5.42072 8.63002 5.29665C9.17736 4.99804 9.5545 4.46084 9.65002 3.84376L9.82002 2.84176C9.89925 2.35173 10.3246 1.9937 10.82 2.00008H13.12C13.6154 1.9937 14.0408 2.35173 14.12 2.84176L14.29 3.84376C14.3942 4.46873 14.7873 5.0075 15.35 5.29665C15.6075 5.42072 15.8549 5.56478 16.09 5.72751C16.6203 6.06834 17.2799 6.13888 17.87 5.91789L18.87 5.55717C19.3243 5.38806 19.8341 5.56962 20.08 5.98803L21.24 7.99202C21.4811 8.41797 21.3796 8.95612 21 9.26455L20.2 9.92587C19.715 10.3293 19.4481 10.9383 19.48 11.5691V12V12.4309C19.4481 13.0617 19.715 13.6707 20.2 14.0741ZM9.00002 12C9.00002 13.6602 10.3432 15.006 12 15.006C12.7957 15.006 13.5587 14.6893 14.1213 14.1256C14.6839 13.5618 15 12.7972 15 12C15 10.3398 13.6569 8.99401 12 8.99401C10.3432 8.99401 9.00002 10.3398 9.00002 12Z"
      fill={color}
    />
  </svg>
)

Settings.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
