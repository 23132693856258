import PropTypes from 'prop-types'
import React from 'react'

export const Image = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2 3H2.8C1.80589 3 1 3.80589 1 4.8V15.6C1 16.5941 1.80589 17.4 2.8 17.4H17.2C18.1941 17.4 19 16.5941 19 15.6V4.8C19 3.80589 18.1941 3 17.2 3ZM17.2004 14.5474C17.2029 14.6674 17.1563 14.7833 17.0714 14.8683C16.9864 14.9532 16.8705 14.9998 16.7504 14.9974C16.6081 15.0003 16.4735 14.933 16.3904 14.8174L12.3854 9.48035C12.2984 9.37029 12.1658 9.3061 12.0254 9.3061C11.8851 9.3061 11.7525 9.37029 11.6654 9.48035L9.10045 12.8194C9.01455 12.9316 8.88176 12.998 8.74045 12.9994C8.60789 12.9995 8.48231 12.94 8.39845 12.8374L7.06645 11.2624C6.97978 11.1583 6.85086 11.0988 6.71545 11.1004C6.57912 11.1035 6.45095 11.1659 6.36445 11.2714L3.60145 14.8174C3.51835 14.927 3.388 14.9905 3.25045 14.9884C3.13036 14.9908 3.01445 14.9442 2.92952 14.8593C2.84458 14.7743 2.79797 14.6584 2.80045 14.5384V4.80035H17.2004V14.5474ZM7.75 6.59965C7.00442 6.59965 6.4 7.20406 6.4 7.94965C6.4 8.69523 7.00442 9.29965 7.75 9.29965C8.49558 9.29965 9.1 8.69523 9.1 7.94965C9.1 7.20406 8.49558 6.59965 7.75 6.59965Z"
      fill={color}
    />
  </svg>
)

Image.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
