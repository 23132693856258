import NextLink from 'next/link'
import React from 'react'
import styled from 'styled-components'
import {
  space,
  layout,
  flexbox,
  typography,
  color,
  type LayoutProps,
  type SpaceProps,
  type FlexboxProps,
  type TypographyProps,
  type ColorProps,
  system,
} from 'styled-system'
import { COLOR_INTENT, FONT_SIZE } from 'Theme'

export interface LinkProps
  extends Omit<React.ComponentPropsWithoutRef<typeof NextLink>, 'color'>,
    LayoutProps,
    SpaceProps,
    FlexboxProps,
    TypographyProps,
    ColorProps {
  disabled?: boolean
  isExternal?: boolean
  textDecoration?: string
}

const StyledNextLink = styled(NextLink).withConfig({
  shouldForwardProp: (prop) =>
    ![
      'fontSize',
      'mt',
      'color',
      'textDecoration',
      'intent',
      'variant',
    ].includes(prop),
})`
  cursor: pointer;
  text-decoration: none;
  font-size: ${FONT_SIZE.PX_14};
  color: ${COLOR_INTENT.LINK.PRIMARY};
  line-height: 1.2;
  ${space};
  ${flexbox};
  ${layout};
  ${typography};
  ${color};
  ${system({ textDecoration: true })};
  ${({ disabled }) =>
    disabled && `pointer-events: none; color: ${COLOR_INTENT.LINK.DISABLED};`}
`

const Link: React.FC<React.PWC<LinkProps>> = ({
  href,
  children,
  disabled = false,
  isExternal = false,
  target,
  ...rest
}) => (
  <StyledNextLink
    href={href}
    disabled={disabled}
    target={target}
    aria-label={typeof children === 'string' ? children : undefined}
    {...(isExternal ? { target: '_blank', rel: 'noreferrer noopener' } : {})}
    {...rest}
  >
    {children}
  </StyledNextLink>
)

export default Link
