import { relayStylePagination } from '@apollo/client/utilities'
import { uniqBy } from 'lodash'

export const cursorPagination = (keyArgs, { formatFunction } = {}) => ({
  ...relayStylePagination(keyArgs),
  merge: (existing, incoming) => {
    const uniqData = uniqBy(
      [
        ...(existing ? existing.edges : []),
        ...(incoming ? incoming.edges : []),
      ],
      'node.__ref'
    )

    return {
      ...incoming,
      edges: formatFunction ? formatFunction(uniqData) : uniqData,
    }
  },
})
