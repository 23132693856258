import PropTypes from 'prop-types'
import React from 'react'

export const Attachment = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 6.38333V6.66666C17.5007 7.55507 17.1467 8.407 16.5167 9.03333L14.1667 11.3917C13.7282 11.8259 13.0218 11.8259 12.5833 11.3917L12.5 11.3C12.3412 11.138 12.3412 10.8787 12.5 10.7167L15.3667 7.84999C15.6726 7.53276 15.8403 7.10729 15.8333 6.66666V6.38333C15.8337 5.93912 15.6567 5.51316 15.3417 5.19999L14.8 4.65833C14.4868 4.34329 14.0609 4.16631 13.6167 4.16666H13.3333C12.8891 4.16631 12.4632 4.34329 12.15 4.65833L9.28333 7.49999C9.12132 7.6588 8.86202 7.6588 8.7 7.49999L8.60833 7.40833C8.17407 6.96988 8.17407 6.26344 8.60833 5.82499L10.9667 3.45833C11.5971 2.83742 12.4485 2.49265 13.3333 2.49999H13.6167C14.501 2.4993 15.3493 2.85003 15.975 3.47499L16.525 4.02499C17.15 4.65065 17.5007 5.49901 17.5 6.38333ZM7.20834 11.6167L11.6167 7.20833C11.6949 7.12945 11.8014 7.08508 11.9125 7.08508C12.0236 7.08508 12.1301 7.12945 12.2083 7.20833L12.7917 7.79166C12.8705 7.8699 12.9149 7.97639 12.9149 8.08749C12.9149 8.19859 12.8705 8.30509 12.7917 8.38333L8.38334 12.7917C8.3051 12.8705 8.1986 12.9149 8.0875 12.9149C7.9764 12.9149 7.86991 12.8705 7.79167 12.7917L7.20834 12.2083C7.12946 12.1301 7.08509 12.0236 7.08509 11.9125C7.08509 11.8014 7.12946 11.6949 7.20834 11.6167ZM11.3 12.5C11.138 12.3412 10.8787 12.3412 10.7167 12.5L7.85834 15.3417C7.54314 15.6588 7.11376 15.8359 6.66667 15.8333H6.38334C5.93913 15.8337 5.51317 15.6567 5.2 15.3417L4.65834 14.8C4.3433 14.4868 4.16632 14.0609 4.16667 13.6167V13.3333C4.16632 12.8891 4.3433 12.4632 4.65834 12.15L7.50834 9.28333C7.66715 9.12131 7.66715 8.86201 7.50834 8.69999L7.41667 8.60833C6.97822 8.17407 6.27179 8.17407 5.83334 8.60833L3.48334 10.9667C2.85327 11.593 2.4993 12.4449 2.5 13.3333V13.625C2.50151 14.5064 2.85208 15.3514 3.475 15.975L4.025 16.525C4.65066 17.15 5.49901 17.5007 6.38334 17.5H6.66667C7.54454 17.5051 8.389 17.1638 9.01667 16.55L11.3917 14.175C11.8259 13.7365 11.8259 13.0301 11.3917 12.5917L11.3 12.5Z"
      fill={color}
    />
  </svg>
)

Attachment.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
