import PropTypes from 'prop-types'
import React from 'react'

export const Hidden = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.37856 3.15005C4.28788 3.05338 4.16128 2.99854 4.02877 2.99854C3.89627 2.99854 3.76967 3.05338 3.67899 3.15005L3.14931 3.68005C3.05269 3.77079 2.99788 3.89747 2.99788 4.03005C2.99788 4.16264 3.05269 4.28932 3.14931 4.38005L5.74773 7.00005C4.05625 8.13216 2.76555 9.77014 2.05997 11.6801C2.02265 11.7827 2.00239 11.8908 2.00001 12.0001C1.99948 12.1063 2.01986 12.2117 2.05997 12.3101C3.68479 16.3691 7.62406 19.022 11.9939 19.0001C13.6115 18.9906 15.2064 18.6181 16.6611 17.9101L19.6093 20.8501C19.7 20.9467 19.8266 21.0016 19.9591 21.0016C20.0916 21.0016 20.2182 20.9467 20.3089 20.8501L20.8385 20.3201C20.9352 20.2293 20.99 20.1026 20.99 19.9701C20.99 19.8375 20.9352 19.7108 20.8385 19.6201L4.37856 3.15005ZM11.9939 17.0001C8.6124 16.9924 5.5333 15.0498 4.06875 12.0001C4.73385 10.5281 5.82309 9.28852 7.19684 8.44005L9.35553 10.6001C8.80796 11.7473 9.04253 13.1153 9.94096 14.0143C10.8394 14.9132 12.2066 15.148 13.3531 14.6001L15.112 16.3601C14.1189 16.7572 13.063 16.9739 11.9939 17.0001ZM9.11567 5.40005C14.3856 3.8925 19.9421 6.61201 21.9878 11.7001C22.0041 11.7994 22.0041 11.9007 21.9878 12.0001C21.9911 12.113 21.9706 12.2254 21.9279 12.3301C21.4434 13.684 20.6454 14.9039 19.5993 15.8901L18.1802 14.4701C18.9265 13.7779 19.519 12.9363 19.9191 12.0001C18.4545 8.95028 15.3754 7.00767 11.9939 7.00005C11.5958 7.00038 11.1983 7.03047 10.8046 7.09005L9.11567 5.40005Z"
      fill={color}
    />
  </svg>
)

Hidden.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
