import { rem } from 'polished'
import React from 'react'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import Icon from 'components/Icon'
import { Box, Flex } from 'components/Layout'
import { StyledLink } from 'components/Link'
import { H3, Text } from 'components/Typography'
import { ROUTE } from 'constants/routes'
import { COLOR_INTENT, FONT_SIZE, FONT_WEIGHT, SPACE } from 'Theme'

interface ErrorProps {
  icon?: React.ReactNode
  description?: React.ReactNode
}

export const Error: React.FC<React.PWC<ErrorProps>> = ({
  icon = <Icon.FeedOutlined color={COLOR_INTENT.ERROR.ICON} />,
  description = (
    <Flex gap={SPACE.PX_10}>
      <StyledLink href={ROUTE.SHOWROOM_PRODUCTS()}>
        Shop all products
      </StyledLink>
      <StyledLink href={ROUTE.EXPERTS()}>Browse all Experts</StyledLink>
    </Flex>
  ),
  children = (
    <Box>
      <Text as="p">Uh-oh, we can&apos;t find this page.</Text>
      <Text
        as="p"
        fontSize={FONT_SIZE.PX_24}
        mt={SPACE.PX_8}
        fontWeight={FONT_WEIGHT.MEDIUM}
      >
        Let&apos;s get you back on track:
      </Text>
    </Box>
  ),
}) => (
  <Flex
    data-test-id={DATA_TEST_ID.ERROR}
    mb={{ MOBILE: rem(150), TABLET: rem(270) }}
    mt={{ MOBILE: rem(80), TABLET: rem(150) }}
    flexDirection="column"
    alignItems="center"
  >
    {icon}
    <H3 textAlign="center" mb={SPACE.PX_20} mt={SPACE.PX_40}>
      {children}
    </H3>
    {description}
  </Flex>
)
