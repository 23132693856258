import React from 'react'
import styled, { css } from 'styled-components'
import { type IconProps } from '../IconPropsInterface'

const StyledSVG = styled.svg`
  ${({ hoveringColor }) =>
    hoveringColor &&
    css`
      &:hover {
        path {
          stroke: ${hoveringColor};
        }
        circle {
          stroke: ${hoveringColor};
        }
      }
    `}
`

export const MenuSearch: React.FC<React.PWC<IconProps>> = ({
  color,
  size,
  hoveringColor,
}) => (
  <StyledSVG
    hoveringColor={hoveringColor}
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 12.5L18 18"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <circle cx="8" cy="8" r="6.25" stroke={color} strokeWidth="1.5" />
  </StyledSVG>
)
