import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const MenuArrowRight: React.FC<React.PWC<IconProps>> = ({
  color,
  size,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.96967 7.53033C9.67678 7.23744 9.67678 6.76256 9.96967 6.46967C10.2626 6.17678 10.7374 6.17678 11.0303 6.46967L9.96967 7.53033ZM15.5 12L16.0303 11.4697C16.3232 11.7626 16.3232 12.2374 16.0303 12.5303L15.5 12ZM11.0303 17.5303C10.7374 17.8232 10.2626 17.8232 9.96967 17.5303C9.67678 17.2374 9.67678 16.7626 9.96967 16.4697L11.0303 17.5303ZM11.0303 6.46967L16.0303 11.4697L14.9697 12.5303L9.96967 7.53033L11.0303 6.46967ZM16.0303 12.5303L11.0303 17.5303L9.96967 16.4697L14.9697 11.4697L16.0303 12.5303Z"
      fill={color}
    />
  </svg>
)
