import { rem } from 'polished'
import React from 'react'
import { COLOR_INTENT } from 'Theme'
import { type IconProps } from './IconPropsInterface'
import { AccountIn } from './icons/AccountIn'
import { AccountOut } from './icons/AccountOut'
import { Add } from './icons/Add'
import { Applications } from './icons/Applications'
import { ArrowDown } from './icons/ArrowDown'
import { ArrowLeft } from './icons/ArrowLeft'
import { ArrowRight } from './icons/ArrowRight'
import { ArrowUp } from './icons/ArrowUp'
import { Attachment } from './icons/Attachment'
import { BanCircle } from './icons/BanCircle'
import { BankAccount } from './icons/BankAccount'
import { Basket } from './icons/Basket'
import { Bin } from './icons/Bin'
import { BinOutlined } from './icons/BinOutlined'
import { Bookmark } from './icons/Bookmark'
import { Calendar } from './icons/Calendar'
import { CalendarPlus } from './icons/CalendarPlus'
import { CardOutlined } from './icons/CardOutlined'
import { CaretDown } from './icons/CaretDown'
import { CaretRight } from './icons/CaretRight'
import { CarouselArrowLeft } from './icons/CarouselArrowLeft'
import { CarouselArrowRight } from './icons/CarouselArrowRight'
import { Cart } from './icons/Cart'
import { Chair } from './icons/Chair'
import { Checkmark } from './icons/Checkmark'
import { ChevronDownLarge } from './icons/ChevronDownLarge'
import { ChevronDownSmall } from './icons/ChevronDownSmall'
import { ChevronFullDown } from './icons/ChevronFullDown'
import { ChevronFullLeft } from './icons/ChevronFullLeft'
import { ChevronFullRight } from './icons/ChevronFullRight'
import { ChevronFullUp } from './icons/ChevronFullUp'
import { ChevronLeftLarge } from './icons/ChevronLeftLarge'
import { ChevronLeftSmall } from './icons/ChevronLeftSmall'
import { ChevronRight } from './icons/ChevronRight'
import { ChevronRightLarge } from './icons/ChevronRightLarge'
import { ChevronRightSmall } from './icons/ChevronRightSmall'
import { ChevronUpLarge } from './icons/ChevronUpLarge'
import { ChevronUpSmall } from './icons/ChevronUpSmall'
import { CircleCheckmark } from './icons/CircleCheckmark'
import { CircleQuestionMark } from './icons/CircleQuestionMark'
import { Cog } from './icons/Cog'
import { CommentPlay } from './icons/CommentPlay'
import { Communication } from './icons/Communication'
import { Confirm } from './icons/Confirm'
import { Control } from './icons/Control'
import { Conversation } from './icons/Conversation'
import { Copy } from './icons/Copy'
import { Cross } from './icons/Cross'
import { Delivery } from './icons/Delivery'
import { Disable } from './icons/Disable'
import { Dollar } from './icons/Dollar'
import { DollarOutlined } from './icons/DollarOutlined'
import { Dot } from './icons/Dot'
import { Download } from './icons/Download'
import { DownloadRounded } from './icons/DownloadRounded'
import { Drag } from './icons/Drag'
import { Edit } from './icons/Edit'
import { Export } from './icons/Export'
import { ExternalLink } from './icons/ExternalLink'
import { EyeOff } from './icons/EyeOff'
import { EyeOpen } from './icons/EyeOpen'
import { Facebook } from './icons/Facebook'
import { Fail } from './icons/Fail'
import { FeedOutlined } from './icons/FeedOutlined'
import { FilledBookmark } from './icons/FilledBookmark'
import { FilledCheckmark } from './icons/FilledCheckmark'
import { Filter } from './icons/Filter'
import { Flare } from './icons/Flare'
import { FolderClose } from './icons/FolderClose'
import { Globe } from './icons/Globe'
import { Google } from './icons/Google'
import { Group } from './icons/Group'
import { Hamburger } from './icons/Hamburger'
import { Hidden } from './icons/Hidden'
import { History } from './icons/History'
import { Home } from './icons/Home'
import { Image } from './icons/Image'
import { Info } from './icons/Info'
import { InfoSign } from './icons/InfoSign'
import { Instagram } from './icons/Instagram'
import { LightBulb } from './icons/Lightbulb'
import { Link } from './icons/Link'
import { LinkedIn } from './icons/LinkedIn'
import { Lock } from './icons/Lock'
import { Logout } from './icons/Logout'
import { MapMarker } from './icons/MapMarker'
import { MenuArrowLeft } from './icons/MenuArrowLeft'
import { MenuArrowRight } from './icons/MenuArrowRight'
import { MenuCross } from './icons/MenuCross'
import { MenuSearch } from './icons/MenuSearch'
import { MenuSearchMobile } from './icons/MenuSearchMobile'
import { Message } from './icons/Message'
import { Messages } from './icons/Messages'
import { Minus } from './icons/Minus'
import { Money } from './icons/Money'
import { More } from './icons/More'
import { Move } from './icons/Move'
import { NewLayers } from './icons/NewLayers'
import { NewPerson } from './icons/NewPerson'
import { OpenInNew } from './icons/OpenInNew'
import { Pdf } from './icons/Pdf'
import { People } from './icons/People'
import { Person } from './icons/Person'
import { Pin } from './icons/Pin'
import { PinOutlined } from './icons/PinOutlined'
import { Pinterest } from './icons/Pinterest'
import { Play } from './icons/Play'
import { PlayVideo } from './icons/PlayVideo'
import { Plus } from './icons/Plus'
import { Projects } from './icons/Projects'
import { Quote } from './icons/Quote'
import { Repeat } from './icons/Repeat'
import { Restore } from './icons/Restore'
import { Search } from './icons/Search'
import { Send } from './icons/Send'
import { SendOutlined } from './icons/SendOutlined'
import { Settings } from './icons/Settings'
import { Share } from './icons/Share'
import { Shop } from './icons/Shop'
import { Smile } from './icons/Smile'
import { Sort } from './icons/Sort'
import { Star } from './icons/Star'
import { StarEmpty } from './icons/StarEmpty'
import { Tick } from './icons/Tick'
import { TickCircle } from './icons/TickCircle'
import { Time } from './icons/Time'
import { Trash } from './icons/Trash'
import { Truck } from './icons/Truck'
import { Twitter } from './icons/Twitter'
import { Upload } from './icons/Upload'
import { User } from './icons/User'
import { UserAdd } from './icons/UserAdd'
import { Vendor } from './icons/Vendor'
import { Video } from './icons/Video'
import { Visible } from './icons/Visible'
import { Warning } from './icons/Warning'
import { WarningProposition65 } from './icons/WarningProposition65'
import { WarningSign } from './icons/WarningSign'
import { Website } from './icons/Website'
import { Youtube } from './icons/Youtube'

export const ICON_SIZE = {
  PX_12: rem(12),
  PX_14: rem(14),
  PX_16: rem(16),
  PX_18: rem(18),
  PX_20: rem(20),
  PX_22: rem(22),
  PX_24: rem(24),
  PX_30: rem(30),
  PX_32: rem(32),
  PX_36: rem(36),
  PX_44: rem(44),
  PX_48: rem(48),
}

const createIcon =
  (
    Svg: React.FC<React.PWC<IconProps>>
  ): React.FC<React.PWC<Partial<IconProps>>> =>
  ({
    size = ICON_SIZE.PX_24,
    color = COLOR_INTENT.ICON.DEFAULT_COLOR,
    fill = 'none',
    hoveringColor = null,
  }) => (
    <Svg color={color} hoveringColor={hoveringColor} size={size} fill={fill} />
  )

export default {
  AccountIn: createIcon(AccountIn),
  AccountOut: createIcon(AccountOut),
  Add: createIcon(Add),
  ArrowDown: createIcon(ArrowDown),
  ArrowLeft: createIcon(ArrowLeft),
  ArrowRight: createIcon(ArrowRight),
  ArrowUp: createIcon(ArrowUp),
  Attachment: createIcon(Attachment),
  Applications: createIcon(Applications),
  BankAccount: createIcon(BankAccount),
  Basket: createIcon(Basket),
  BanCircle: createIcon(BanCircle),
  Bin: createIcon(Bin),
  BinOutlined: createIcon(BinOutlined),
  Bookmark: createIcon(Bookmark),
  Calendar: createIcon(Calendar),
  CalendarPlus: createIcon(CalendarPlus),
  CardOutlined: createIcon(CardOutlined),
  CaretDown: createIcon(CaretDown),
  CaretRight: createIcon(CaretRight),
  CarouselArrowLeft: createIcon(CarouselArrowLeft),
  CarouselArrowRight: createIcon(CarouselArrowRight),
  Cart: createIcon(Cart),
  Chair: createIcon(Chair),
  Checkmark: createIcon(Checkmark),
  ChevronRight: createIcon(ChevronRight),
  ChevronDownLarge: createIcon(ChevronDownLarge),
  ChevronDownSmall: createIcon(ChevronDownSmall),
  ChevronUpLarge: createIcon(ChevronUpLarge),
  ChevronUpSmall: createIcon(ChevronUpSmall),
  ChevronLeftLarge: createIcon(ChevronLeftLarge),
  ChevronLeftSmall: createIcon(ChevronLeftSmall),
  ChevronRightLarge: createIcon(ChevronRightLarge),
  ChevronRightSmall: createIcon(ChevronRightSmall),
  ChevronFullDown: createIcon(ChevronFullDown),
  ChevronFullLeft: createIcon(ChevronFullLeft),
  ChevronFullUp: createIcon(ChevronFullUp),
  ChevronFullRight: createIcon(ChevronFullRight),
  CircleCheckmark: createIcon(CircleCheckmark),
  CircleQuestionMark: createIcon(CircleQuestionMark),
  Cog: createIcon(Cog),
  Control: createIcon(Control),
  CommentPlay: createIcon(CommentPlay),
  Communication: createIcon(Communication),
  Confirm: createIcon(Confirm),
  Conversation: createIcon(Conversation),
  Copy: createIcon(Copy),
  Cross: createIcon(Cross),
  Delivery: createIcon(Delivery),
  Disable: createIcon(Disable),
  Dollar: createIcon(Dollar),
  DollarOutlined: createIcon(DollarOutlined),
  Dot: createIcon(Dot),
  DownloadRounded: createIcon(DownloadRounded),
  Download: createIcon(Download),
  Drag: createIcon(Drag),
  Edit: createIcon(Edit),
  Export: createIcon(Export),
  EyeOff: createIcon(EyeOff),
  EyeOpen: createIcon(EyeOpen),
  ExternalLink: createIcon(ExternalLink),
  Facebook: createIcon(Facebook),
  Fail: createIcon(Fail),
  FeedOutlined: createIcon(FeedOutlined),
  FilledBookmark: createIcon(FilledBookmark),
  FilledCheckmark: createIcon(FilledCheckmark),
  Filter: createIcon(Filter),
  Flare: createIcon(Flare),
  FolderClose: createIcon(FolderClose),
  Google: createIcon(Google),
  Globe: createIcon(Globe),
  Group: createIcon(Group),
  Hamburger: createIcon(Hamburger),
  Hidden: createIcon(Hidden),
  History: createIcon(History),
  Home: createIcon(Home),
  Image: createIcon(Image),
  Info: createIcon(Info),
  InfoSign: createIcon(InfoSign),
  Instagram: createIcon(Instagram),
  Lightbulb: createIcon(LightBulb),
  Link: createIcon(Link),
  LinkedIn: createIcon(LinkedIn),
  Lock: createIcon(Lock),
  Logout: createIcon(Logout),
  MapMarker: createIcon(MapMarker),
  MenuArrowRight: createIcon(MenuArrowRight),
  MenuArrowLeft: createIcon(MenuArrowLeft),
  MenuCross: createIcon(MenuCross),
  MenuSearch: createIcon(MenuSearch),
  MenuSearchMobile: createIcon(MenuSearchMobile),
  Message: createIcon(Message),
  Messages: createIcon(Messages),
  Money: createIcon(Money),
  More: createIcon(More),
  Move: createIcon(Move),
  Minus: createIcon(Minus),
  NewLayers: createIcon(NewLayers),
  NewPerson: createIcon(NewPerson),
  OpenInNew: createIcon(OpenInNew),
  People: createIcon(People),
  Person: createIcon(Person),
  Pdf: createIcon(Pdf),
  Pin: createIcon(Pin),
  PinOutlined: createIcon(PinOutlined),
  Pinterest: createIcon(Pinterest),
  Play: createIcon(Play),
  Plus: createIcon(Plus),
  Projects: createIcon(Projects),
  Quote: createIcon(Quote),
  Repeat: createIcon(Repeat),
  Restore: createIcon(Restore),
  Search: createIcon(Search),
  Send: createIcon(Send),
  SendOutlined: createIcon(SendOutlined),
  Settings: createIcon(Settings),
  Share: createIcon(Share),
  Shop: createIcon(Shop),
  Smile: createIcon(Smile),
  Sort: createIcon(Sort),
  Star: createIcon(Star),
  StarEmpty: createIcon(StarEmpty),
  Tick: createIcon(Tick),
  TickCircle: createIcon(TickCircle),
  Time: createIcon(Time),
  Trash: createIcon(Trash),
  Truck: createIcon(Truck),
  Twitter: createIcon(Twitter),
  Upload: createIcon(Upload),
  User: createIcon(User),
  UserAdd: createIcon(UserAdd),
  Visible: createIcon(Visible),
  Video: createIcon(Video),
  Vendor: createIcon(Vendor),
  Warning: createIcon(Warning),
  WarningProposition65: createIcon(WarningProposition65),
  WarningSign: createIcon(WarningSign),
  Website: createIcon(Website),
  Youtube: createIcon(Youtube),
  PlayVideo: createIcon(PlayVideo),
}
