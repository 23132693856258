import { type CheckoutResponseType } from '../types'

export const validateCheckoutResponse = (
  checkoutResponse: CheckoutResponseType,
  purchaseEventName: string
) => {
  if (checkoutResponse?.error) {
    throw new Error(checkoutResponse.error.message)
  }

  if (!checkoutResponse?.data) {
    throw new Error(`Checkout response has no data for ${purchaseEventName}`)
  }
}
