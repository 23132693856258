import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const CircleQuestionMark: React.FC<React.PWC<IconProps>> = ({
  size,
  color,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.6665 17.9998C9.6665 13.3975 13.3975 9.6665 17.9998 9.6665C20.21 9.6665 22.3296 10.5445 23.8924 12.1073C25.4552 13.6701 26.3332 15.7897 26.3332 17.9998C26.3332 22.6022 22.6022 26.3332 17.9998 26.3332C13.3975 26.3332 9.6665 22.6022 9.6665 17.9998ZM11.3332 17.9998C11.3332 21.6817 14.3179 24.6665 17.9998 24.6665C19.7679 24.6665 21.4636 23.9641 22.7139 22.7139C23.9641 21.4636 24.6665 19.7679 24.6665 17.9998C24.6665 14.3179 21.6817 11.3332 17.9998 11.3332C14.3179 11.3332 11.3332 14.3179 11.3332 17.9998ZM17.5832 21.3332C17.3531 21.3332 17.1665 21.5197 17.1665 21.7498V22.5832C17.1665 22.8133 17.3531 22.9998 17.5832 22.9998H18.4165C18.6466 22.9998 18.8332 22.8133 18.8332 22.5832V21.7498C18.8332 21.5197 18.6466 21.3332 18.4165 21.3332H17.5832ZM18.8332 13.0998C20.1587 13.0998 21.2332 14.1744 21.2332 15.4998L21.2915 15.9665C21.2845 17.0048 20.6127 17.9218 19.6248 18.2415L18.8332 18.4998C18.7797 18.5038 18.7371 18.5463 18.7332 18.5998V19.2498C18.7332 19.48 18.5466 19.6665 18.3165 19.6665H17.6832C17.4531 19.6665 17.2665 19.48 17.2665 19.2498V18.5998C17.2683 17.9256 17.7013 17.3281 18.3415 17.1165L19.1332 16.8582C19.5129 16.7266 19.7673 16.3684 19.7665 15.9665V15.4998C19.7665 14.9844 19.3486 14.5665 18.8332 14.5665H17.1665C16.919 14.5665 16.6816 14.6648 16.5065 14.8399C16.3315 15.0149 16.2332 15.2523 16.2332 15.4998V15.9165C16.2332 16.1466 16.0466 16.3332 15.8165 16.3332H15.1832C14.9531 16.3332 14.7665 16.1466 14.7665 15.9165V15.4998C14.7665 14.1744 15.841 13.0998 17.1665 13.0998H18.8332Z"
      fill={color}
    />
  </svg>
)
