import { GA4_EVENTS, type TransformationProps } from '../types'

export const COOKIES_YES_OPTIONS = {
  advertisement: ['ad_storage', 'ad_user_data', 'ad_personalization'],
  analytics: 'analytics_storage',
  functional: 'functionality_storage',
  performance: 'personalization_storage',
  necessary: 'security_storage',
} as const

const COOKIES_YES_PERMISSION_TYPE = {
  DENIED: 'denied',
  GRANTED: 'granted',
} as const

const getCookieConsentValues = (
  consent: string[],
  cookieConsentOptions: string[]
) =>
  cookieConsentOptions.reduce((acc, key) => {
    const cookieYesOption = COOKIES_YES_OPTIONS[key]
    const value = consent.includes(key)
      ? COOKIES_YES_PERMISSION_TYPE.GRANTED
      : COOKIES_YES_PERMISSION_TYPE.DENIED

    if (Array.isArray(cookieYesOption)) {
      const optionValues = cookieYesOption.reduce(
        (optionAcc, option) => ({
          ...optionAcc,
          [option]: value,
        }),
        {}
      )
      return { ...acc, ...optionValues }
    }

    return { ...acc, [cookieYesOption]: value }
  }, {})

interface CookiesYesTransformationContextData {
  consent: { detail: { accepted: string[] } }
}

type CookiesYesTransformationProps = TransformationProps<
  CookiesYesTransformationContextData,
  'event' | 'contextData'
>

export const CookiesYesTransformation = ({
  event,
  contextData,
}: CookiesYesTransformationProps) => {
  const consent = contextData.consent?.detail?.accepted ?? []

  if (!consent.length) {
    return null
  }

  const cookieConsentOptions = Object.keys(COOKIES_YES_OPTIONS)

  if (event === GA4_EVENTS.COOKIES_DEFAULT) {
    return getCookieConsentValues(consent, cookieConsentOptions)
  }

  return getCookieConsentValues(
    consent,
    cookieConsentOptions.filter((option) => consent.includes(option))
  )
}
