import {
  GA4_PARAMS,
  type MapItemToInput,
  type AreaTypes,
  type TransformationProps,
  type ItemListNameTypes,
} from '../types'
import { findPropertyInTree, getItemListName, mapItemToInput } from '../utils'

interface SelectOrViewItemsContextData {
  area?: AreaTypes
  items: (MapItemToInput & { index: number })[]
  item_list_name: ItemListNameTypes
}

type SelectOrViewItemsTransformationProps = TransformationProps<
  SelectOrViewItemsContextData,
  'contextData' | 'parent'
>

export const SelectOrViewItemsTransformation = ({
  contextData,
  parent,
}: SelectOrViewItemsTransformationProps) => {
  const { items } = contextData

  if (!items?.length) {
    throw new Error(
      'Items are not provided in select or view items transformation'
    )
  }

  return {
    ecommerce: {
      area: findPropertyInTree({
        keyName: GA4_PARAMS.AREA,
        contextData,
        parent,
      }),
      items: items.map((item) => ({
        ...mapItemToInput()(item),
        index: item.index,
        item_list_id: null,
        item_list_name: getItemListName({
          contextData,
          parent,
        }),
        quantity: 1,
      })),
    },
  }
}
