import { useRouter } from 'next/router'
import React, { useRef, useEffect } from 'react'
import { HEADER_HEIGHT_VALUES, SCHEMA_TYPES } from 'constants/common'
import { useMedia } from 'useMedia'
import { useGoogleAnalytics } from './googleAnalyticsProvider'
import { GA4_EVENTS } from './types'
import { getPageType } from './utils'
import { getParsedPromotionData } from './utils/Promotion/getParsedPromotionData'
import { getThingElementsFlatArray } from './utils/Promotion/utils'

const VIEW_PROMOTION_THRESHOLD = 0.5 // 50% of element must be visible

if (typeof window !== 'undefined' && !('requestIdleCallback' in window)) {
  // @ts-ignore - requestIdleCallback is not defined in window on safari
  window.requestIdleCallback = (callback) => {
    const start = Date.now()
    return setTimeout(() => {
      callback({
        didTimeout: false,
        timeRemaining: () => Math.max(0, 50 - (Date.now() - start)),
      })
    }, 1)
  }
  // @ts-ignore - requestIdleCallback is not defined in window on safari
  window.cancelIdleCallback = (id) => clearTimeout(id)
}

export const ViewPromotionIntersectionObserver: React.FC<React.PWC> = ({
  children,
}) => {
  const { pathname } = useRouter()
  const { MOBILE: isMobile } = useMedia()
  const currentObservedElements = useRef<Element[]>([])
  const viewPromotionObservedElements = useRef<Element[]>([])
  const { executeDataToDataLayer } = useGoogleAnalytics()
  const intersectionObserver = useRef<IntersectionObserver | null>(null)
  const mutationObserver = useRef<MutationObserver | null>(null)

  useEffect(() => {
    intersectionObserver.current = new IntersectionObserver(
      (entries) => {
        window.requestIdleCallback(() => {
          const intersectingElements = entries.filter(
            (entry) => entry.isIntersecting
          )

          // If element is already observed, we will skip it
          const nonObservedIntersectingElements = intersectingElements.filter(
            (entry) =>
              !viewPromotionObservedElements.current.some((element) =>
                element.isEqualNode(entry.target)
              )
          )

          if (nonObservedIntersectingElements.length) {
            const items = nonObservedIntersectingElements.map(({ target }) => {
              viewPromotionObservedElements.current.push(target)

              return {
                // Parse data
                ...getParsedPromotionData(target as HTMLElement),
                locationId: getPageType(pathname),
              }
            })

            executeDataToDataLayer({
              event: GA4_EVENTS.VIEW_PROMOTION,
              overrideData: {
                items,
              },
            })
          }
        })
      },
      {
        threshold: VIEW_PROMOTION_THRESHOLD,
        rootMargin: `${
          isMobile
            ? HEADER_HEIGHT_VALUES.MOBILE_FULL
            : HEADER_HEIGHT_VALUES.DESKTOP
        }px 0px 0px 0px`,
      }
    )

    mutationObserver.current = new MutationObserver(() => {
      const thingElements = document.querySelectorAll(
        `[itemtype="${SCHEMA_TYPES.THING}"]`
      )

      const elementsForObservation = getThingElementsFlatArray(
        Array.from(thingElements)
      )

      if (elementsForObservation) {
        currentObservedElements.current =
          currentObservedElements.current.filter((element) => {
            if (!elementsForObservation.includes(element)) {
              intersectionObserver.current.unobserve(element)
              return false
            }
            return true
          })
        elementsForObservation.forEach((element) => {
          if (!currentObservedElements.current.includes(element)) {
            intersectionObserver.current.observe(element)
            currentObservedElements.current.push(element)
          }
        })
      }

      return () => {
        intersectionObserver.current.disconnect()
        mutationObserver.current.disconnect()
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executeDataToDataLayer])

  useEffect(() => {
    if (mutationObserver.current) {
      mutationObserver.current.observe(document.body, {
        childList: true,
        subtree: true,
      })
    }
  }, [mutationObserver])

  return <>{children}</>
}
