import {
  GA4_PARAMS,
  type TransformationProps,
  type MapItemToInput,
} from '../types'
import { findPropertyInTree, mapItemToInput } from '../utils'

interface ViewItemTransformationContextData {
  items: MapItemToInput[]
}

type ViewItemTransformationProps = TransformationProps<
  ViewItemTransformationContextData,
  'contextData' | 'parent'
>

export const ViewItemTransformation = ({
  contextData,
  parent,
}: ViewItemTransformationProps) => {
  const { items } = contextData

  if (!items?.length) {
    throw new Error('Items are not provided in view item transformation')
  }

  return {
    ecommerce: {
      area: findPropertyInTree({
        keyName: GA4_PARAMS.AREA,
        contextData,
        parent,
      }),
      items: items.map((item) => ({
        quantity: 1,
        ...mapItemToInput()(item),
      })),
    },
  }
}
