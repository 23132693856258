import styled from 'styled-components'
import {
  type FlexboxProps,
  grid,
  gridArea,
  type GridProps,
  type LayoutProps,
  position,
  type PositionProps,
  type SpaceProps,
  type ColorProps,
  type BorderProps,
} from 'styled-system'
import { type SCHEMA_TYPES } from 'constants/common'
import { Flex } from './Flex'

export interface CustomGridProps
  extends GridProps,
    SpaceProps,
    FlexboxProps,
    PositionProps,
    ColorProps,
    BorderProps,
    LayoutProps {
  itemScope?: boolean
  itemType?: (typeof SCHEMA_TYPES)[keyof typeof SCHEMA_TYPES]
  gap?: any // TODO: Fix type when styled-components package will be updated
}

export const Grid: React.FC<React.PWC<CustomGridProps>> = styled(Flex)`
  display: ${(props) => props.display ?? 'grid'};
  ${grid}
  ${gridArea}
  ${position}
`
