import {
  CartItemOperationTransformation,
  CheckoutTransformation,
  ConsultationTransformation,
  SelectOrViewItemsTransformation,
  CookiesYesTransformation,
  CouponTransformation,
  PageViewTransformation,
  ViewCartTransformation,
  SignInUpTransformation,
  ViewItemTransformation,
  ClickTransformation,
  RequestAvailabilityTransformation,
  ErrorMessageTransformation,
  ViewCalendarOrSelectDateConsultations,
  SortingTransformation,
  UserDataTransformation,
  SelectPromotionTransformation,
  ViewPromotionTransformation,
} from './transformations'
import { FilterTransformation } from './transformations/filterTransformation'
import { ProcurementTransformation } from './transformations/PurchaseTransformations/procurementTransformation'
import { ShowroomTransformation } from './transformations/PurchaseTransformations/showroomTransformation'
import { SearchTransformation } from './transformations/searchTransformation'
import {
  GA4_ECOM_CUSTOM_EVENTS,
  GA4_EVENTS,
  GA4_PURCHASE_TYPES,
  type PurchaseTransformationProps,
  type PurchaseTypes,
  type TransformationInputDataToGA4DataLayerInput,
} from './types'

const GA4_TRANSFORMATION_MAPPER = {
  [GA4_EVENTS.PAGE_VIEW]: PageViewTransformation,
  [GA4_EVENTS.VIEW_CART]: ViewCartTransformation,
  [GA4_EVENTS.VIEW_ITEM]: ViewItemTransformation,
  [GA4_EVENTS.FILTER]: FilterTransformation,
  [GA4_EVENTS.CLICK]: ClickTransformation,
  [GA4_EVENTS.NOTIFY_ME]: RequestAvailabilityTransformation,
  [GA4_EVENTS.SEARCH]: SearchTransformation,
  [GA4_EVENTS.ERROR_MESSAGE]: ErrorMessageTransformation,
  [GA4_EVENTS.SORTING]: SortingTransformation,
  [GA4_ECOM_CUSTOM_EVENTS.VIEW_CALENDAR_CONSULTATIONS]:
    ViewCalendarOrSelectDateConsultations,
  [GA4_ECOM_CUSTOM_EVENTS.SELECT_DATE_CONSULTATIONS]:
    ViewCalendarOrSelectDateConsultations,
  [GA4_EVENTS.COOKIES_DEFAULT]: CookiesYesTransformation,
  [GA4_EVENTS.COOKIES_UPDATE]: CookiesYesTransformation,
  [GA4_EVENTS.REMOVE_FROM_CART]: CartItemOperationTransformation,
  [GA4_EVENTS.ADD_TO_CART]: CartItemOperationTransformation,
  [GA4_EVENTS.SIGN_UP]: SignInUpTransformation,
  [GA4_EVENTS.LOGIN]: SignInUpTransformation,
  [GA4_EVENTS.VIEW_ITEM_LIST]: SelectOrViewItemsTransformation,
  [GA4_EVENTS.SELECT_ITEM]: SelectOrViewItemsTransformation,
  [GA4_EVENTS.BEGIN_CHECKOUT]: CheckoutTransformation,
  [GA4_EVENTS.ADD_SHIPPING_INFO]: CheckoutTransformation,
  [GA4_EVENTS.ADD_PAYMENT_INFO]: CheckoutTransformation,
  [GA4_EVENTS.USER_DATA]: UserDataTransformation,
  [GA4_EVENTS.SELECT_PROMOTION]: SelectPromotionTransformation,
  [GA4_EVENTS.VIEW_PROMOTION]: ViewPromotionTransformation,
} as const

const mapPurchaseEvent = (
  props: PurchaseTransformationProps,
  purchaseType: PurchaseTypes
  // eslint-disable-next-line consistent-return
) => {
  if (purchaseType === GA4_PURCHASE_TYPES.GIFT_CARD) {
    return CouponTransformation(props)
  }
  if (purchaseType === GA4_PURCHASE_TYPES.PROCUREMENT) {
    return ProcurementTransformation(props)
  }
  if (purchaseType === GA4_PURCHASE_TYPES.SHOWROOM) {
    return ShowroomTransformation(props)
  }
  if (purchaseType === GA4_PURCHASE_TYPES.CONSULTATIONS) {
    return ConsultationTransformation(props)
  }
}

export const transformationInputDataToGA4DataLayer = (
  props: TransformationInputDataToGA4DataLayerInput
) => {
  const { event, contextData } = props
  const mappedEventMethod = GA4_TRANSFORMATION_MAPPER[event]
  if (mappedEventMethod) {
    return mappedEventMethod(props)
  }

  if (event === GA4_EVENTS.PURCHASE) {
    return mapPurchaseEvent(props, contextData.purchaseType)
  }

  // For trivial events, we will just push data to contextData that will be pushed to dataLayer.
  // No need transformation for very simple events that does not need
  return contextData
}
