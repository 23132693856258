import PropTypes from 'prop-types'
import React from 'react'

export const LinkedIn = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3ZM8 18C8.27614 18 8.5 17.7761 8.5 17.5V10.5C8.5 10.2238 8.27614 9.99998 8 9.99998H6.5C6.22386 9.99998 6 10.2238 6 10.5V17.5C6 17.7761 6.22386 18 6.5 18H8ZM7.25002 9C6.42159 9 5.75002 8.32843 5.75002 7.5C5.75002 6.67157 6.42159 6 7.25002 6C8.07844 6 8.75002 6.67157 8.75002 7.5C8.75002 8.32843 8.07844 9 7.25002 9ZM17.5 18C17.7761 18 18 17.7762 18 17.5V12.9C18.0324 11.3108 16.8576 9.95453 15.28 9.76002C14.177 9.65926 13.1082 10.1744 12.5 11.1V10.5C12.5 10.2239 12.2761 10 12 10H10.5C10.2238 10 9.99998 10.2239 9.99998 10.5V17.5C9.99998 17.7762 10.2238 18 10.5 18H12C12.2761 18 12.5 17.7762 12.5 17.5V13.75C12.5 12.9216 13.1716 12.25 14 12.25C14.8284 12.25 15.5 12.9216 15.5 13.75V17.5C15.5 17.7762 15.7238 18 16 18H17.5Z"
      fill={color}
    />
  </svg>
)

LinkedIn.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
