import PropTypes from 'prop-types'
import React from 'react'

export const Star = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.20143 9.85425C2.03248 9.73123 1.96025 9.51452 2.02161 9.31473L2.10153 9.06496C2.15996 8.86756 2.33563 8.72781 2.5411 8.71527L8.92493 8.20573L11.3726 2.32101C11.4547 2.11485 11.6607 1.98554 11.8821 2.00129H12.1418C12.3505 1.99687 12.539 2.12523 12.6114 2.32101L15.069 8.20573L21.4528 8.71527C21.6583 8.72781 21.834 8.86756 21.8924 9.06496L21.9723 9.31473C22.0403 9.50821 21.9805 9.72355 21.8225 9.85425L17.0071 13.9905L18.4857 20.195C18.5344 20.3936 18.4597 20.6021 18.2959 20.7245L18.0061 20.8844C17.8371 20.9986 17.6157 20.9986 17.4467 20.8844L12.0119 17.5873L6.54723 20.9143C6.37823 21.0286 6.15676 21.0286 5.98777 20.9143L5.76798 20.7645C5.60419 20.642 5.52945 20.4335 5.57817 20.2349L7.01677 13.9905L2.20143 9.85425Z"
      fill={color}
    />
  </svg>
)

Star.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
