import React from 'react'
import styled, { css } from 'styled-components'
import { type IconProps } from '../IconPropsInterface'

const StyledSVG = styled.svg`
  ${({ hoveringColor }) =>
    hoveringColor &&
    css`
      &:hover {
        path {
          stroke: ${hoveringColor};
        }
        circle {
          stroke: ${hoveringColor};
        }
      }
    `}
`

export const Cart: React.FC<React.PWC<IconProps>> = ({
  color,
  size,
  hoveringColor,
}) => (
  <StyledSVG
    hoveringColor={hoveringColor}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 6H20.7534C21.3931 6 21.8684 6.59242 21.7296 7.21693L20.522 12.6508C20.217 14.0234 18.9996 15 17.5935 15H9.54138C8.07486 15 6.82329 13.9398 6.5822 12.4932L5.4178 5.5068C5.17671 4.06024 3.92514 3 2.45862 3H2"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <circle cx="8" cy="20" r="2.25" stroke={color} strokeWidth="1.5" />
    <circle cx="18" cy="20" r="2.25" stroke={color} strokeWidth="1.5" />
  </StyledSVG>
)
