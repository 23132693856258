import PropTypes from 'prop-types'
import React from 'react'

export const DollarOutlined = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20ZM13 6.5V7C14.1046 7 15 7.89543 15 9V9.75C15 10.0261 14.7761 10.25 14.5 10.25H13.75C13.4739 10.25 13.25 10.0261 13.25 9.75V8.75H10.75V9.63C10.7458 9.7045 10.7797 9.77603 10.84 9.82L14.27 12.65C14.7308 13.0288 14.9985 13.5935 15 14.19V15C15 16.1046 14.1046 17 13 17V17.5C13 17.7761 12.7761 18 12.5 18H11.5C11.2239 18 11 17.7761 11 17.5V17C9.89543 17 9 16.1046 9 15V14.25C9 13.9739 9.22386 13.75 9.5 13.75H10.25C10.5261 13.75 10.75 13.9739 10.75 14.25V15.25H13.25V14.37C13.2542 14.2955 13.2203 14.224 13.16 14.18L9.73 11.35C9.26919 10.9712 9.0015 10.4065 9 9.81V9C9 7.89543 9.89543 7 11 7V6.5C11 6.22386 11.2239 6 11.5 6H12.5C12.7761 6 13 6.22386 13 6.5Z"
      fill={color}
    />
  </svg>
)

DollarOutlined.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
