import PropTypes from 'prop-types'
import React from 'react'

export const CircleCheckmark = ({ color, size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 8C2 4.68629 4.68629 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8ZM7.23791 10.0104L10.6099 6.63836C10.7204 6.52247 10.7204 6.34025 10.6099 6.22436L10.2919 5.90636C10.1753 5.79202 9.98856 5.79202 9.87191 5.90636L7.02791 8.75036L6.12791 7.85636C6.07347 7.79836 5.99746 7.76545 5.91791 7.76545C5.83836 7.76545 5.76235 7.79836 5.70791 7.85636L5.38991 8.17436C5.33312 8.23069 5.30117 8.30737 5.30117 8.38736C5.30117 8.46735 5.33312 8.54403 5.38991 8.60036L6.81791 10.0104C6.87235 10.0684 6.94836 10.1013 7.02791 10.1013C7.10746 10.1013 7.18347 10.0684 7.23791 10.0104Z"
      fill={color}
    />
  </svg>
)

CircleCheckmark.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
