import { CURRENCY_ISO_CODE } from 'constants/common'
import { validateCheckoutResponse } from 'providers/googleAnalytics/utils'
import {
  prefixConsultation,
  prefixCoupon,
} from 'providers/googleAnalytics/utils/prefixes'
import {
  GA4_AREAS,
  GA4_ITEM_CATEGORY,
  type PurchaseTransformationProps,
} from '../../types'

export const ConsultationTransformation = ({
  contextData,
}: PurchaseTransformationProps) => {
  const { checkoutResponse, paymentMethod, extra } = contextData

  validateCheckoutResponse(checkoutResponse, 'consultation purchase')

  const { expertSession, itemListData } = extra
  const { id: checkoutId, totalPrice, coupon } = checkoutResponse.data
  const { expert, price, payPrice, lengthInMinutes } = expertSession
  const currencyIsoCode = totalPrice?.currencyIsoCode || CURRENCY_ISO_CODE.USD
  const couponId = coupon?.id

  return {
    ecommerce: {
      area: GA4_AREAS.CONSULTATIONS,
      transaction_id: prefixConsultation(checkoutId),
      affiliation: null,
      value: price.total,
      tax: 0,
      shipping: 0,
      currency: currencyIsoCode,
      payment: payPrice.total,
      payment_type: paymentMethod,
      coupon: couponId ? prefixCoupon(couponId) : '',
      // TODO: After implementation of Promo codes for Consultation, add discount
      // EPIC: https://github.com/sudolabs-io/the-expert/issues/6466
      discount_all: 0,
      discount_rate: 0,

      items: [
        {
          ...(itemListData &&
            itemListData.itemListName && {
              index: itemListData.index,
              item_list_name: itemListData.itemListName,
            }),
          item_id: prefixConsultation(expert.id),
          item_name: expert.profileName,
          item_variant: lengthInMinutes,
          item_tax: 0,
          item_list_id: null,
          item_category: GA4_ITEM_CATEGORY.CONSULTATIONS,
          price: price.total,
          quantity: 1,
          currency: currencyIsoCode,
        },
      ],
    },
  }
}
