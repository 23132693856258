import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const WarningSign: React.FC<IconProps> = ({ color, size, fill }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 9V13M12 16H12.01M10.3635 3.59119L2.25747 17.1252C2.09037 17.4146 2.00194 17.7427 2.00098 18.0768C2.00003 18.411 2.08658 18.7396 2.25202 19.0299C2.41746 19.3203 2.65604 19.5622 2.94401 19.7317C3.23199 19.9013 3.55933 19.9924 3.89347 19.9962H20.1075C20.4415 19.9923 20.7686 19.9011 21.0565 19.7317C21.3443 19.5622 21.5828 19.3204 21.7482 19.0302C21.9136 18.74 22.0002 18.4116 21.9994 18.0776C21.9986 17.7435 21.9103 17.4155 21.7435 17.1262L13.6375 3.59019C13.4669 3.30869 13.2267 3.07593 12.9399 2.91439C12.6532 2.75284 12.3296 2.66797 12.0005 2.66797C11.6713 2.66797 11.3478 2.75284 11.061 2.91439C10.7743 3.07593 10.534 3.30869 10.3635 3.59019V3.59119Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
