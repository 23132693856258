import { datadogRum } from '@datadog/browser-rum'
import { useRouter } from 'next/router'
import queryString from 'query-string'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useLocation } from 'react-use'
import { SCHEMA_TYPES } from 'constants/common'
import { useUserAuth } from '../userAuth'
import { transformationInputDataToGA4DataLayer } from './transformation'
import {
  type ExecuteDataToDataLayerProps,
  GA4_ECOMMERCE_EVENTS,
  GA4_EVENTS,
  type GoogleAnalyticsContextInterface,
} from './types'
import {
  findPropertyInTree,
  getPageType,
  getRoot,
  getUserType,
  prioritizedAnalyticsEvents,
} from './utils'
import { getParsedPromotionData } from './utils/Promotion/getParsedPromotionData'
import { validateIsElementWithinValidActionElement } from './utils/Promotion/utils'

interface GoogleAnalyticsProviderProps {
  parent?: GoogleAnalyticsContextInterface
  data?: Record<string, any>
}

export const GoogleAnalyticsContext =
  createContext<GoogleAnalyticsContextInterface | null>(null)
export const useGoogleAnalytics = () => useContext(GoogleAnalyticsContext)

interface HandleDataLayerPushMethodProps {
  event: string
  isClearEvent: boolean
  transformedInputData: any
}

/**
 * Google analytics provider is forkable and at the lower level and holds reference to parent so we can access it's parent states
 * This is useful because we can have GAProvider at widget level and execute it at certain events and they will always be able to access pageId, title and section defined by parent
 */
const GoogleAnalyticsProvider: React.FC<
  React.PWC<GoogleAnalyticsProviderProps>
> = ({ children, parent, data = {} }) => {
  const { pathname, asPath, isReady } = useRouter()
  const [asPathWithoutQueryParams] = asPath.split('?')
  const {
    user,
    isAdminLoggedInAsExpert,
    isAdminLoggedInAsClient,
    signedInUserType,
  } = useUserAuth()
  const storedData = useRef(data)
  const location = useLocation()
  const query = queryString.parse(location.search)
  // Using internal states and refs are forbidden in component. Use non internal references
  // Imagine these internal states as private abstract property of root.
  const [isPageViewLoadingInternal, setPageViewLoadingInternal] = useState(true)
  const dataLayerBufferInternal = useRef([])
  const { isPageViewLoading, setPageViewLoading, dataLayerBuffer } = parent
    ? getRoot(parent)
    : {
        isPageViewLoading: isPageViewLoadingInternal,
        setPageViewLoading: setPageViewLoadingInternal,
        dataLayerBuffer: dataLayerBufferInternal,
      }

  const userType = getUserType({
    isAdminLoggedInAsExpert,
    isAdminLoggedInAsClient,
    signedInUserType,
  })

  const pushBufferToDataLayer = useCallback(() => {
    if (dataLayerBuffer.current.length > 0) {
      window.dataLayer.push(...dataLayerBuffer.current)
      dataLayerBuffer.current = []
    }
  }, [dataLayerBuffer])

  const aggregateData = (
    callback: (
      data: typeof storedData.current
    ) => typeof storedData.current & any
  ) => {
    storedData.current = callback(storedData.current)
  }

  const getPropertyInTree = useCallback(
    (keyName: string) =>
      findPropertyInTree({ keyName, contextData: storedData.current, parent }),
    [parent]
  )

  useEffect(() => {
    // If page view is loaded, we will push data from buffer to data layer automatically
    if (isPageViewLoading || parent) {
      return
    }
    pushBufferToDataLayer()
  }, [isPageViewLoading, parent, pushBufferToDataLayer])

  const handleDataLayerPushMethod = useCallback(
    ({
      event,
      isClearEvent,
      transformedInputData,
    }: HandleDataLayerPushMethodProps) => {
      if (
        event === GA4_EVENTS.COOKIES_DEFAULT ||
        event === GA4_EVENTS.COOKIES_UPDATE
      ) {
        const [eventName, eventType] = event.split('_')

        // Call function from GA script
        // @ts-ignore - Type is not correct because of transformedInputData which has any type
        window.dataLayer.push([eventName, eventType, transformedInputData])

        if (event === GA4_EVENTS.COOKIES_UPDATE) {
          pushBufferToDataLayer()
        }
        return
      }

      const resultObject = {
        event,
        ...transformedInputData,
        ...(isClearEvent && { _clear: true }),
      }

      if (!isPageViewLoading) {
        pushBufferToDataLayer()
        window.dataLayer.push(resultObject)
        return
      }

      if (event === GA4_EVENTS.PAGE_VIEW) {
        window.dataLayer.push(resultObject)
        setPageViewLoading(false)
        return
      }

      // Prioritized event must not wait for page view and cookies
      if (prioritizedAnalyticsEvents.includes(event)) {
        window.dataLayer.push(resultObject)
      } else {
        dataLayerBuffer.current.push(resultObject)
      }
    },
    [
      dataLayerBuffer,
      isPageViewLoading,
      pushBufferToDataLayer,
      setPageViewLoading,
    ]
  )

  const executeDataToDataLayer = useCallback(
    ({
      event,
      overrideData = {},
      includeStoredData = true,
    }: ExecuteDataToDataLayerProps<typeof overrideData>) => {
      // If google analytics are not loaded yet, it should not process this method.
      if (
        !window?.dataLayer ||
        process.env.NEXT_PUBLIC_NODE_ENV !== 'production'
      ) {
        // Debug loggin in development
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.debug(
            event,
            transformationInputDataToGA4DataLayer({
              event,
              contextData: {
                ...(includeStoredData && storedData.current),
                ...overrideData,
              },
              asPath,
              pathname,
              user,
              userType,
              parent,
              query,
            })
          )
        }
        return
      }
      // Context data represent data of current provider overrided by overrideData
      const contextData = {
        ...(includeStoredData && storedData.current),
        ...overrideData,
      }

      if (GA4_ECOMMERCE_EVENTS[event]) {
        if (isPageViewLoading || event === GA4_EVENTS.SELECT_PROMOTION) {
          dataLayerBuffer.current.push({ ecommerce: null })
        } else {
          window.dataLayer.push({ ecommerce: null })
        }
      }

      const isClearEvent = GA4_ECOMMERCE_EVENTS[event]

      try {
        const transformedInputData = transformationInputDataToGA4DataLayer({
          event,
          contextData,
          asPath,
          pathname,
          user,
          userType,
          parent,
          query,
        })

        if (!transformedInputData) {
          return
        }

        handleDataLayerPushMethod({ event, isClearEvent, transformedInputData })
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : err
        const fullError = new Error(
          JSON.stringify({
            error: errorMessage,
            event,
            contextData,
          })
        )
        datadogRum.addError(fullError)
      }
    },
    [
      asPath,
      dataLayerBuffer,
      handleDataLayerPushMethod,
      isPageViewLoading,
      parent,
      pathname,
      query,
      user,
      userType,
    ]
  )

  // Select promotion event handler
  useEffect(() => {
    const onClickListener = (event: PointerEvent) => {
      // Run only in the top most provider
      if (parent) {
        return
      }

      const targetElement = event.target as HTMLElement

      const thingScopeElement = targetElement.closest(
        `[itemtype="${SCHEMA_TYPES.THING}"]`
      )

      if (
        !thingScopeElement ||
        // We want track select promotion events only when the user clicks on a button/button like element {link styled as button} or any other link
        !validateIsElementWithinValidActionElement(targetElement)
      ) {
        return
      }

      // Parse data
      const parsedData = getParsedPromotionData(targetElement, true)

      // Push data to dataLayer
      executeDataToDataLayer({
        event: GA4_EVENTS.SELECT_PROMOTION,
        overrideData: {
          item: {
            ...parsedData,
            locationId: getPageType(pathname),
          },
        },
      })
    }

    // ! Use capture needs to be set to true to make sure it will be triggered on the page where the event happened
    document.addEventListener('click', onClickListener, true)

    return () => {
      document.removeEventListener('click', onClickListener, true)
    }
  }, [executeDataToDataLayer, getPropertyInTree, parent, pathname])

  const contextValues = useMemo(
    () => ({
      userType,
      user,
      asPath,
      asPathWithoutQueryParams,
      routerIsReady: isReady,
      pathname,
      parent,
      query,
      data: storedData,
      executeDataToDataLayer,
      aggregateData,
      getPropertyInTree,
      setPageViewLoading,
      isPageViewLoading,
      dataLayerBuffer,
    }),
    [
      asPath,
      asPathWithoutQueryParams,
      dataLayerBuffer,
      executeDataToDataLayer,
      getPropertyInTree,
      isPageViewLoading,
      isReady,
      parent,
      pathname,
      query,
      setPageViewLoading,
      user,
      userType,
    ]
  )

  return (
    <GoogleAnalyticsContext.Provider value={contextValues}>
      {children}
    </GoogleAnalyticsContext.Provider>
  )
}

export default GoogleAnalyticsProvider
