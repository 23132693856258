import currency from 'currency.js'

export const add = (...args: currency.Any[]): number =>
  args.reduce<number>((acc, next) => currency(acc).add(next).value, 0)

export const subtract = (value1: currency.Any, value2: currency.Any): number =>
  currency(value1).subtract(value2).value

export const divide = (value1: currency.Any, value2: currency.Any): number =>
  currency(value1).divide(value2).value

export const multiply = (value1: currency.Any, value2: currency.Any): number =>
  currency(value1).multiply(value2).value

export const subtractPercentage = (
  value: currency.Any,
  percentage: number
): number => {
  const price = currency(value)
  return price.subtract(price.divide(100).multiply(percentage)).value
}
