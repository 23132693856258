import currency from 'currency.js'
import {
  addDays,
  addWeeks,
  endOfMonth,
  format,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns'
import { getParamByISO } from 'iso-country-currency'
import {
  reduce,
  find,
  isPlainObject,
  capitalize,
  upperFirst,
  isString,
  isObject,
} from 'lodash'
import mapValues from 'lodash/mapValues'
import sanitizeHtml from 'sanitize-html'
import {
  ARTA_METHOD_PARCEL_TRANSPORT_ESTIMATES,
  ARTA_METHOD_DESCRIPTION,
  FLX_UNIT_TO_ARTA_UNITS,
  MANAGED_FLX_CUSTOM_FIELDS_VALUES,
  AVAILABILITY_LABEL,
  ORDER_ID_PREFIX,
  FREIGHT_CLUB_METHOD_DESCRIPTION,
  formatPrice,
  STATES,
  EXPERT_USER_CAPABILITIES,
} from 'shared-constants'
import {
  ARTA_SERVICE_TYPE,
  PRODUCT_STATUSES,
  PRODUCT_VARIANT_STATUSES,
  ORDER_SHIPMENT_TYPES,
  WHITESPACE_REGEX_PATTERN,
  CART_SHIPMENT_TYPES,
} from 'constants/common'
import { FULL_MONTH_DAY_YEAR } from 'constants/datetime'
import { type UserTypes, USER_TYPE } from 'providers/userAuthUtil'
import {
  type Price,
  type ClientMeOrdersQuery,
  type ProductOptionValue,
  type ProductOption,
  type ProductVariant,
  type Product,
  type ProductCategory,
  type Brand,
} from 'types/graphql-generated'

const PDF_EXTENSION = '.pdf'
const STANDARD_DELIVERY_SUB_SUBTYPE_POSTFIX = '_without_additional_services'

type FormatDateInput = number | Date

export const formatDimensions = ({
  width,
  height,
  length,
  depth,
  unit,
  precision = 2,
}: {
  width?: number
  height?: number
  length?: number
  depth?: number
  unit: (typeof FLX_UNIT_TO_ARTA_UNITS)[keyof typeof FLX_UNIT_TO_ARTA_UNITS]
  precision?: number
}) =>
  reduce(
    {
      W: width,
      L: length,
      D: depth,
      H: height,
    },
    (acc, dimension, label) => {
      if (!dimension) {
        return acc
      }
      return [
        ...acc,
        `${currency(dimension, {
          precision,
        }).toString()}${
          FLX_UNIT_TO_ARTA_UNITS[unit] === FLX_UNIT_TO_ARTA_UNITS.inch
            ? '″'
            : ` ${FLX_UNIT_TO_ARTA_UNITS[unit]}`
        } ${label}`,
      ]
    },
    []
  ).join(' x ')

export const formatArtaShipmentName = ({
  services,
  type,
  name,
  additionalServices,
}) => {
  if (
    type === MANAGED_FLX_CUSTOM_FIELDS_VALUES.ARTA_METHOD.SELECT &&
    additionalServices?.length === 0
  ) {
    return {
      shipmentMethodTitle: name,
      shipmentMethodDescription:
        ARTA_METHOD_DESCRIPTION.SELECT.WITHOUT_ADDITIONAL_SERVICES,
    }
  }
  if (
    [
      MANAGED_FLX_CUSTOM_FIELDS_VALUES.ARTA_METHOD.SELECT,
      MANAGED_FLX_CUSTOM_FIELDS_VALUES.ARTA_METHOD.PREMIUM,
    ].includes(type)
  ) {
    return {
      shipmentMethodTitle: name,
      shipmentMethodDescription:
        ARTA_METHOD_DESCRIPTION.SELECT.WITH_ADDITIONAL_SERVICES,
    }
  }
  const { name: serviceName, subSubtype } =
    find(services, { type: ARTA_SERVICE_TYPE.TRANSPORT }) ?? {}

  return {
    shipmentMethodTitle: serviceName ?? name,
    shipmentMethodDescription:
      ARTA_METHOD_PARCEL_TRANSPORT_ESTIMATES[subSubtype],
  }
}
export const formatFreightClubShipmentName = ({ name, requestedType }) => ({
  shipmentMethodTitle: name,
  shipmentMethodDescription: FREIGHT_CLUB_METHOD_DESCRIPTION[requestedType],
})
export const formatVendorShippingShipmentName = ({ name }) => ({
  shipmentMethodTitle: name,
  shipmentMethodDescription: '',
})

type FormatShipmentNameInput =
  ClientMeOrdersQuery['clientMe']['orders']['edges'][0]['node']['orderItems'][0]['orderShipment']

type FormatShipmentNameOutput = {
  shipmentMethodTitle: string
  shipmentMethodDescription?: string
}

const formatShipmentName =
  ({ VendorShipping, FreightClubQuote, ArtaQuote }) =>
  ({ shipmentData }: FormatShipmentNameInput): FormatShipmentNameOutput => {
    const { __typename: typename } = shipmentData
    if (typename === VendorShipping) {
      return formatVendorShippingShipmentName(
        shipmentData as Extract<
          FormatShipmentNameInput['shipmentData'],
          { __typename: typeof VendorShipping }
        >
      )
    }
    if (typename === ArtaQuote) {
      return formatArtaShipmentName(
        shipmentData as Extract<
          FormatShipmentNameInput['shipmentData'],
          { __typename: typeof ArtaQuote }
        >
      )
    }
    if (typename === FreightClubQuote) {
      return formatFreightClubShipmentName(
        shipmentData as Extract<
          FormatShipmentNameInput['shipmentData'],
          { __typename: typeof FreightClubQuote }
        >
      )
    }
    return {
      shipmentMethodTitle: shipmentData.name,
      shipmentMethodDescription: null,
    }
  }

export const formatShipmentNameCart = formatShipmentName(CART_SHIPMENT_TYPES)
export const formatShipmentNameOrder = formatShipmentName(ORDER_SHIPMENT_TYPES)

export const findArtaShipmentServiceSubSubtypeByType = ({
  services,
  additionalServices,
  serviceType,
}: {
  services: {
    subSubtype: string
    type: string
  }[]
  additionalServices: string[]
  serviceType: (typeof ARTA_SERVICE_TYPE)[keyof typeof ARTA_SERVICE_TYPE]
}) => {
  const subSubtype = find(
    services,
    ({ type }) => type === serviceType
  )?.subSubtype

  return `${subSubtype}${
    additionalServices?.length === 0 && STANDARD_DELIVERY_SUB_SUBTYPE_POSTFIX
  }`
}

interface FormatSavedCreditCardTextInput {
  last4: string
  expiryMonth: string
  expiryYear: string
  isMobile: boolean
}

export const SHOWROOM_COLLECTION_SLUG_REGEX = /shop\/(.+?)[/|$](.*)/
export const PRODUCT_SLUG_REGEX = /\/([^/]+)/

export const formatSavedCreditCardText = ({
  last4,
  expiryMonth,
  expiryYear,
  isMobile = false,
}: FormatSavedCreditCardTextInput) =>
  `${isMobile ? 'Ending' : 'Credit card ending'} ${last4} ${
    isMobile ? '' : '- '
  }Expiring ${expiryMonth.padStart(2, '0')}/${expiryYear}`

export const removeWhiteSpaces = (string: string) =>
  string?.replace(WHITESPACE_REGEX_PATTERN, '')

interface FormatWithExpertUserInput {
  title: string
  firstName: string
  lastName: string
  isWithPrefix?: boolean
}

export const formatWithExpertUser = ({
  title,
  firstName,
  lastName,
  isWithPrefix = true,
}: FormatWithExpertUserInput) => {
  if (!title && !firstName && !lastName) {
    return null
  }
  const formattedOutput = `${[
    ...(isWithPrefix ? ['- with'] : []),
    ...(firstName ? [firstName] : []),
    ...(lastName ? [lastName] : []),
  ].join(' ')}`
  if (title) {
    if (firstName || lastName) {
      return `${formattedOutput}, ${title}`
    }
    if (formattedOutput) {
      return `${formattedOutput} ${title}`
    }
    return title
  }
  return formattedOutput
}

interface FormatUserInput {
  user?: any
  signedInUserType?: UserTypes
}

export const formatUserNameOrEmail = ({
  user,
  signedInUserType,
}: FormatUserInput = {}) => {
  if (!user) {
    return ''
  }
  if (signedInUserType === USER_TYPE.CLIENT) {
    return `${user.firstName} ${user.lastName}`
  }
  if (signedInUserType === USER_TYPE.EXPERT) {
    if (user.expertFirstName || user.expertLastName) {
      return [
        ...(user.expertFirstName ? [user.expertFirstName] : []),
        ...(user.expertLastName ? [user.expertLastName] : []),
      ].join(' ')
    }
    return user.email
  }
  return ''
}

// TODO: Missing user type
export const formatUserName = ({
  user,
  signedInUserType,
}: FormatUserInput = {}) => {
  if (!user) {
    return ''
  }
  if (signedInUserType === USER_TYPE.CLIENT) {
    return `${user.firstName} ${user.lastName}`
  }
  if (
    signedInUserType === USER_TYPE.EXPERT &&
    (user.expertFirstName || user.expertLastName)
  ) {
    return [
      ...(user.expertFirstName ? [user.expertFirstName] : []),
      ...(user.expertLastName ? [user.expertLastName] : []),
    ].join(' ')
  }
  return ''
}

export const formatDateInput = (
  date: FormatDateInput,
  isMobileView: boolean
) => ({
  // We need to +1 day or -1 because of different timezones.
  // Stored date is in UTC so we want to get all data
  from: (isMobileView
    ? addDays(startOfDay(date), -1)
    : addDays(startOfWeek(date), -1)
  ).toISOString(),
  to: (isMobileView
    ? addDays(startOfDay(addDays(date, 1)), 1)
    : addDays(startOfWeek(addWeeks(date, 1)), 1)
  ).toISOString(),
})

export const formatMonthDateInput = (date: FormatDateInput) => ({
  from: addDays(startOfMonth(date), -1).toISOString(),
  to: addDays(endOfMonth(date), 1).toISOString(),
})

interface FormatAdressInput {
  addressLine1: string
  addressLine2: string
  countryIsoCode: string
  countryState: string
  city: string
  zipCode: string
  phone: string
  company: string
}

interface FormatLocationInput
  extends Pick<FormatAdressInput, 'city' | 'countryIsoCode'> {
  primaryLocation: string
}

export const formatLocation = ({
  primaryLocation,
  city,
  countryIsoCode,
}: FormatLocationInput) =>
  `${primaryLocation || city}, ${countryIsoCode.toUpperCase()}`

export const formatAddress = ({
  addressLine1,
  addressLine2,
  countryIsoCode,
  countryState,
  city,
  zipCode,
  phone,
  company,
}: FormatAdressInput) => {
  const country = getParamByISO(countryIsoCode, 'countryName')
  return [
    ...(company ? [company] : []),
    addressLine1,
    ...(addressLine2 ? [addressLine2] : []),
    city,
    `${countryState ? `${countryState} ` : ''}${zipCode}, ${country}`,
    ...(phone ? [phone] : []),
  ]
}

export const DOTS_CHARACTER = '\u2026'
const DEFAULT_MULTILINE_ELLIPSIS_TEXT_LENGTH = 70

export const multilineEllipsis = (
  text = '',
  { maxLength = DEFAULT_MULTILINE_ELLIPSIS_TEXT_LENGTH } = {}
) => {
  if (typeof text !== 'string') {
    return ''
  }
  if (text.length > maxLength) {
    return `${text.substr(0, maxLength)}${DOTS_CHARACTER}`
  }
  return text
}

export const trimAllStrings = (variable: any) => {
  if (Array.isArray(variable)) {
    return variable.map(trimAllStrings)
  }
  if (variable && typeof variable === 'object') {
    return mapValues(variable, trimAllStrings)
  }
  if (typeof variable === 'string') {
    return variable.trim()
  }

  return variable
}

export const PRICE_UPON_REQUEST = 'Price upon request'

interface FormatPriceRangeInput {
  priceMin: number
  priceMax: number
  currency: string
}

export const formatPriceRange = ({
  priceMin,
  priceMax,
  currency: currencyIsoCode,
}: FormatPriceRangeInput) => {
  if (priceMin === priceMax || priceMin > priceMax) {
    return priceMax > 0
      ? formatPrice({ price: priceMax, currency: currencyIsoCode })
      : PRICE_UPON_REQUEST
  }
  return `${formatPrice({
    price: priceMin,
    currency: currencyIsoCode,
  })} - ${formatPrice({
    price: priceMax,
    currency: currencyIsoCode,
  })}`
}

interface FormatPriceFilterLabelInput {
  value: {
    priceFrom: number
    priceTo: number
  }
  currencyIsoCode: string
  maxPrice: number
}

export const formatPriceFilterLabel = ({
  value: { priceFrom: from, priceTo: to },
  currencyIsoCode,
  maxPrice,
}: FormatPriceFilterLabelInput) => {
  if (from === to) {
    return `Only ${formatPrice({
      price: from,
      currency: currencyIsoCode,
    })}`
  }
  if ((from && (!to || to === maxPrice)) || (from === to && to === maxPrice)) {
    return `From ${formatPrice({
      price: from,
      currency: currencyIsoCode,
    })}`
  }
  if (!from && to) {
    return `Up to ${formatPrice({
      price: to,
      currency: currencyIsoCode,
    })}`
  }
  return `${formatPrice({
    price: from,
    currency: currencyIsoCode,
  })} - ${formatPrice({
    price: to,
    currency: currencyIsoCode,
  })}`
}

export const formatAvailabilityFilterLabel = (
  from: FormatDateInput,
  to: FormatDateInput
) => {
  const fromDate = new Date(from)
  const toDate = new Date(to)

  if (isSameDay(fromDate, toDate)) {
    return `${format(fromDate, FULL_MONTH_DAY_YEAR)}`
  }
  return `${format(fromDate, FULL_MONTH_DAY_YEAR)} - ${format(
    toDate,
    FULL_MONTH_DAY_YEAR
  )}`
}

export const deepFlattenObject = <
  T extends Record<string | number | symbol, any>,
>(
  values: T
) =>
  reduce(
    values,
    (acc, value, key) => ({
      ...acc,
      ...(isPlainObject(value) ? deepFlattenObject(value) : { [key]: value }),
    }),
    {}
  )

export const deepClearObjectValues = <
  T extends Record<string | number | symbol, any>,
>(
  values: T
) =>
  mapValues(values, (value) =>
    isPlainObject(value) ? deepClearObjectValues(value) : undefined
  )

export const isFilePdf = (name: string) =>
  typeof name === 'string' && name.endsWith(PDF_EXTENSION)

export const formatQuantityWithUnit = ({
  quantity,
  unit,
  isUnitInParentheses = false,
}: {
  quantity: number
  unit?: {
    singular: string
    plural: string
  } | null
  isUnitInParentheses?: boolean
}) => {
  const { singular, plural } = unit || {}
  const unitToDisplay = quantity === 1 ? singular : plural
  if (!unitToDisplay) {
    return `${quantity}`
  }
  return `${quantity}${
    isUnitInParentheses ? ` (${unitToDisplay})` : ` ${unitToDisplay}`
  }`
}

interface FormatPriceWithQuantityUnitInput {
  price: Pick<Price, 'total' | 'currencyIsoCode'>
  quantityUnit?: { singular: string }
}

export const formatPriceWithQuantityUnit = ({
  price,
  quantityUnit,
}: FormatPriceWithQuantityUnitInput) => {
  const formattedPrice = formatPrice({
    price: price.total,
    currency: price.currencyIsoCode,
  })

  return quantityUnit
    ? `${formattedPrice} / ${quantityUnit.singular}`
    : formattedPrice
}

export const formatLeadTimeString = (leadTime: number) =>
  `${AVAILABILITY_LABEL.MADE_TO_ORDER}: ${leadTime} week${
    leadTime > 1 ? 's' : ''
  }`

export const formatShowroomOrderIdWithPrefix = (orderId: string | number) => {
  const orderIdString = orderId.toString()
  const formattedOrderId = `000000${orderIdString}`.slice(
    orderIdString.length < 7 ? -7 : -orderIdString.length
  )
  return `${ORDER_ID_PREFIX}${formattedOrderId}`
}

export type SnakeCaseToCamelCase<Key extends string> =
  Key extends `${infer FirstPart}_${infer FirstLetter}${infer LastPart}`
    ? `${FirstPart}${Uppercase<FirstLetter>}${SnakeCaseToCamelCase<LastPart>}`
    : Key

export const formatProductVariantOptionVales = (
  optionValues: {
    productOption: {
      name: ProductOption['name']
    }
    value: ProductOptionValue['value']
  }[]
) =>
  optionValues?.length
    ? optionValues
        .map(
          ({ productOption, value }) =>
            `${capitalize(productOption.name)}: ${value}`
        )
        .join(', ')
    : ''

type ProductVariantStatusLabelInput = {
  status: ProductVariant['status']
  isPublishedFirstTime?: boolean
}

export const shouldShowPendingApprovalStatus = ({
  isPublishedFirstTime = true,
  status,
}: ProductVariantStatusLabelInput) =>
  !isPublishedFirstTime && status !== PRODUCT_VARIANT_STATUSES.ARCHIVED

export const formatProductVariantStatusLabel = ({
  status,
  isPublishedFirstTime = true,
}: ProductVariantStatusLabelInput) =>
  shouldShowPendingApprovalStatus({ isPublishedFirstTime, status })
    ? PRODUCT_STATUSES.PENDING_APPROVAL
    : status

export const formatProductStatusLabelBasedOnProductVariantStatuses = (
  variants: ProductVariantStatusLabelInput[],
  productStatus: Product['status']
) =>
  variants.some(shouldShowPendingApprovalStatus)
    ? PRODUCT_STATUSES.PENDING_APPROVAL
    : productStatus

export const slugToTitle = (slug) =>
  slug.replace(/-/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase())

export const prefixObjectParams = (object: object, prefix: string) =>
  reduce(
    object,
    (acc, value, key) => {
      acc[`${prefix}${upperFirst(key)}`] = value

      return acc
    },
    {}
  )

type OptionValue = {
  value: ProductOptionValue['value']
  productOption: Pick<ProductOption, 'name'>
}

export const formatVariantOptionsString = ({
  optionValues,
  isOptionNameIncluded = false,
}: {
  optionValues: OptionValue[]
  isOptionNameIncluded?: boolean
}) => {
  const options = reduce(
    optionValues,
    (acc, { value, productOption: { name } }) => {
      acc[name] = `${
        isOptionNameIncluded ? `${capitalize(name)}: ` : ''
      }${value}`
      return acc
    },
    {
      material: null,
      color: null,
      finish: null,
    }
  )
  const { material, color, finish, ...restOptions } = options
  return [material, color, finish, ...Object.values(restOptions)]
    .filter(isString)
    .join(', ')
}

type ProductCategoryWithNameAndParent = Pick<ProductCategory, 'name'> & {
  parentCategory?: ProductCategoryWithNameAndParent
}

export const formatProductCategoryNameChain = (
  productCategory: ProductCategoryWithNameAndParent
) => {
  const { name, parentCategory } = productCategory
  return `${name}${
    parentCategory?.name
      ? ` - ${formatProductCategoryNameChain(parentCategory)}`
      : ''
  }`
}

type ProductVariantTitleWithOptionValuesAndBrands = Pick<
  ProductVariant,
  'title'
> & {
  optionValues: OptionValue[]
  brand: Brand['id']
}

export const formatProductDetailPageMetaTitle = ({
  title,
  brand,
  optionValues,
}: ProductVariantTitleWithOptionValuesAndBrands) => {
  const prefix = `${title} by ${brand}`
  const postfix = optionValues.length
    ? `, ${formatVariantOptionsString({
        optionValues,
      })}`
    : ''
  return `${prefix}${postfix}`
}

export const formatProductDetailPageDescription = ({
  title,
  description,
  brand,
  optionValues,
}: ProductVariantTitleWithOptionValuesAndBrands &
  Pick<Product, 'description'>) => {
  const prefix = `Shop ${title} by ${brand}`
  const postfix = `${
    optionValues.length
      ? ` ${formatVariantOptionsString({
          optionValues,
          isOptionNameIncluded: true,
        })}.`
      : ''
  } ${sanitizeHtml(description, { allowedTags: [] })}`

  return `${prefix}.${postfix}`
}

export const formatStateCodesToTitles = (stateCodes: string[]) => {
  const stateCodesSet = new Set(stateCodes)
  const filteredStates = STATES.filter(({ shortCode }) =>
    stateCodesSet.has(shortCode)
  )
  return filteredStates.map(({ title }) => title)
}

export const formatExpertUserCapabilities = (capabilities: string[]) => {
  if (capabilities.includes(EXPERT_USER_CAPABILITIES.CONSULTATION)) {
    return 'Expert'
  }
  if (capabilities.includes(EXPERT_USER_CAPABILITIES.TRADE)) {
    return 'Trade'
  }
  return ''
}

/**
 * Returns the first string found in object or its properties. Uses depth-first search to traverse the object and its properties.
 * Useful for getting the error message from nested Formik error object.
 * @param stringOrObject any object or string (meta.error object from Formik)
 * @returns string | null - first string from provided object
 */
export const getNestedStringProperty = (
  stringOrObject: Record<string, any> | string = {}
) => {
  if (isString(stringOrObject)) {
    return stringOrObject
  }
  const stack = [stringOrObject]

  while (stack.length) {
    const obj = stack.pop()

    if (isString(obj)) {
      return obj
    }

    if (isObject(obj)) {
      Object.values(obj)
        .reverse()
        .forEach((value) => {
          stack.push(value)
        })
    }
  }

  return null
}
