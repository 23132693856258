import PropTypes from 'prop-types'
import React from 'react'

export const Restore = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 12C22 16.9706 17.9706 21 13 21C11.1524 21.0061 9.34883 20.4363 7.84 19.37C7.71944 19.2831 7.64276 19.148 7.63 19C7.61807 18.8559 7.66913 18.7136 7.77 18.61L8.49 17.88C8.65902 17.7127 8.92245 17.6876 9.12 17.82C10.2662 18.5925 11.6178 19.0036 13 19C16.866 19 20 15.866 20 12C20 8.13401 16.866 5 13 5C9.13401 5 6 8.13401 6 12H8.36C8.49559 11.998 8.62593 12.0523 8.72 12.15L8.92 12.35C9.01466 12.4439 9.0679 12.5717 9.0679 12.705C9.0679 12.8383 9.01466 12.9661 8.92 13.06L5.39 16.6C5.19219 16.7918 4.87781 16.7918 4.68 16.6L1.15 13.06C1.05534 12.9661 1.0021 12.8383 1.0021 12.705C1.0021 12.5717 1.05534 12.4439 1.15 12.35L1.35 12.15C1.44408 12.0523 1.57442 11.998 1.71 12H4C4 7.02944 8.02944 3 13 3C17.9706 3 22 7.02944 22 12Z"
      fill={color}
    />
  </svg>
)

Restore.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
