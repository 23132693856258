import React from 'react'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import {
  type BorderProps,
  type ColorProps,
  type SpaceProps,
  type TypographyProps,
} from 'styled-system'
import { type IconProps } from 'components/Icon'
import { Loader } from 'components/Loader'
import { COLOR_INTENT } from 'Theme'
import { ButtonContent } from './ButtonContent'
import {
  BUTTON_ICON_POSITION,
  BUTTON_INTENT,
  BUTTON_SIZE,
  BUTTON_VARIANT,
  BUTTON_ICON_SIZE,
} from './constants'
import { baseButtonStyles } from './styles'

const ButtonBase = styled('button').attrs((props) => ({
  type: props.type || 'button',
}))`
  ${baseButtonStyles}
`
export interface ButtonProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, 'size' | 'color'>,
    SpaceProps,
    TypographyProps,
    ColorProps,
    BorderProps {
  width?: string
  isLoading?: boolean
  isDisabled?: boolean
  icon?: typeof React.Component | React.FC<React.PWC<IconProps>>
  size?: (typeof BUTTON_SIZE)[keyof typeof BUTTON_SIZE]
  intent?: (typeof BUTTON_INTENT)[keyof typeof BUTTON_INTENT]
  variant?: (typeof BUTTON_VARIANT)[keyof typeof BUTTON_VARIANT]
  iconPosition?: (typeof BUTTON_ICON_POSITION)[keyof typeof BUTTON_ICON_POSITION]
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size = BUTTON_SIZE.M,
      intent = BUTTON_INTENT.PRIMARY,
      variant = BUTTON_VARIANT.DEFAULT,
      iconPosition = BUTTON_ICON_POSITION.LEFT,
      isLoading = false,
      isDisabled = false,
      width = '100%',
      icon: Icon,
      onClick,
      children,
      ...props
    },
    ref
  ) => {
    const defaultContentColor =
      variant === BUTTON_VARIANT.DEFAULT
        ? COLOR_INTENT.BUTTON.BACKGROUND_LIGHT
        : intent
    return (
      <ButtonBase
        ref={ref}
        size={size}
        intent={intent}
        variant={variant}
        iconPosition={iconPosition}
        isLoading={isLoading}
        isDisabled={isDisabled}
        disabled={isDisabled}
        onClick={onClick}
        width={width}
        hasIcon={Icon !== undefined}
        hasChildren={children !== undefined}
        {...props}
      >
        <ButtonContent
          iconPosition={iconPosition}
          loader={
            <Loader
              data-test-id={DATA_TEST_ID.BUTTON_LOADER}
              color={props?.color?.toString() ?? defaultContentColor}
            />
          }
          icon={
            Icon && (
              <Icon
                size={BUTTON_ICON_SIZE[size]}
                color={props?.color?.toString() ?? defaultContentColor}
              />
            )
          }
          isLoading={isLoading}
        >
          {children}
        </ButtonContent>
      </ButtonBase>
    )
  }
)
