'use client'

import Script from 'next/script'
import React from 'react'
import { environment } from 'constants/environment'

function initFrontChat() {
  window.FrontChat('init', {
    chatId: environment.FRONT_CHAT_ID,
    useDefaultLauncher: true,
  })
}

export const FrontChat = () => (
  <Script
    id="front-chat-script"
    src="https://chat-assets.frontapp.com/v1/chat.bundle.js"
    onLoad={initFrontChat}
  />
)
