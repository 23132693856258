// firebase error proxy based on https://github.com/firebase/firebase-js-sdk/blob/e04b7452bae10e6525cfb9c551f76a1aa98f9078/packages/auth/src/core/errors.ts
const ALLOWED_CODES_TO_PROPAGATE = {
  CAPTCHA_CHECK_FAILED: 'auth/captcha-check-failed',
  CODE_EXPIRED: 'auth/code-expired',
  CREDENTIAL_ALREADY_IN_USE: 'auth/credential-already-in-use',
  CREDENTIAL_MISMATCH: 'auth/custom-token-mismatch',
  CREDENTIAL_TOO_OLD_LOGIN_AGAIN: 'auth/requires-recent-login',
  EMAIL_EXISTS: 'auth/email-already-in-use',
  INTERNAL_ERROR: 'auth/internal-error',
  INVALID_CODE: 'auth/invalid-verification-code',
  INVALID_EMAIL: 'auth/invalid-email',
  INVALID_IDP_RESPONSE: 'auth/invalid-credential',
  INVALID_MFA_SESSION: 'auth/invalid-multi-factor-session',
  INVALID_PASSWORD: 'auth/wrong-password',
  INVALID_PHONE_NUMBER: 'auth/invalid-phone-number',
  MFA_INFO_NOT_FOUND: 'auth/multi-factor-info-not-found',
  MFA_REQUIRED: 'auth/multi-factor-auth-required',
  MISSING_CODE: 'auth/missing-verification-code',
  MISSING_MFA_INFO: 'auth/missing-multi-factor-info',
  MISSING_MFA_SESSION: 'auth/missing-multi-factor-session',
  MISSING_PHONE_NUMBER: 'auth/missing-phone-number',
  MISSING_SESSION_INFO: 'auth/missing-verification-id',
  NETWORK_REQUEST_FAILED: 'auth/network-request-failed',
  OPERATION_NOT_ALLOWED: 'auth/operation-not-allowed',
  PROVIDER_ALREADY_LINKED: 'auth/provider-already-linked',
  QUOTA_EXCEEDED: 'auth/quota-exceeded',
  REJECTED_CREDENTIAL: 'auth/rejected-credential',
  SECOND_FACTOR_ALREADY_ENROLLED: 'auth/second-factor-already-in-use',
  SECOND_FACTOR_LIMIT_EXCEEDED: 'auth/maximum-second-factor-count-exceeded',
  TIMEOUT: 'auth/timeout',
  TOKEN_EXPIRED: 'auth/user-token-expired',
  TOO_MANY_ATTEMPTS_TRY_LATER: 'auth/too-many-requests',
  UNVERIFIED_EMAIL: 'auth/unverified-email',
  USER_CANCELLED: 'auth/user-cancelled',
  USER_DELETED: 'auth/user-not-found',
  USER_DISABLED: 'auth/user-disabled',
  USER_SIGNED_OUT: 'auth/user-signed-out',
}

const allowedCodes = Object.values(ALLOWED_CODES_TO_PROPAGATE)

const MAP_CODE_TO_ERROR = {
  [ALLOWED_CODES_TO_PROPAGATE.INVALID_PASSWORD]:
    'The password is invalid or the user does not have a password.',
  [ALLOWED_CODES_TO_PROPAGATE.INVALID_CODE]: 'Invalid verification code.',
}

export const getFirebaseAuthErrorMessage = (
  firebaseError,
  defaultMessage = 'Authentication error. Please try again.'
) => {
  const errorCodeToCustomError = MAP_CODE_TO_ERROR[firebaseError?.code]
  if (errorCodeToCustomError) {
    return errorCodeToCustomError
  }
  return firebaseError?.code && allowedCodes.includes(firebaseError.code)
    ? firebaseError.message
        .replace('Firebase: ', '')
        .replace(/\(auth.*\)\.?/, '')
    : defaultMessage
}
