import React from 'react'
import styled, { css } from 'styled-components'
import { type IconProps } from '../IconPropsInterface'

const StyledSVG = styled.svg`
  ${({ hoveringColor }) =>
    hoveringColor &&
    css`
      &:hover {
        path {
          fill: ${hoveringColor};
        }
      }
    `}
`

export const AccountIn: React.FC<React.PWC<IconProps>> = ({
  size,
  color,
  hoveringColor,
}) => (
  <StyledSVG
    hoveringColor={hoveringColor}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 7C15.5 8.933 13.933 10.5 12 10.5C10.067 10.5 8.5 8.933 8.5 7C8.5 5.067 10.067 3.5 12 3.5C13.933 3.5 15.5 5.067 15.5 7ZM17 7C17 9.76142 14.7614 12 12 12C9.23858 12 7 9.76142 7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7ZM3.75 22.0002C3.75 20.02 5.69435 16.1895 11.0564 15.785L10.9436 14.2892C4.78922 14.7535 2.25 19.2453 2.25 22.0002C2.25 22.4144 2.58579 22.7502 3 22.7502C3.41421 22.7502 3.75 22.4144 3.75 22.0002Z"
      fill={color}
    />
    <path
      d="M15.3047 18.2538L17.0411 19.9902L20.6953 16.3965"
      stroke="#126554"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <circle cx="18" cy="18" r="5.25" stroke="#126554" strokeWidth="1.5" />
  </StyledSVG>
)
