import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const More: React.FC<IconProps> = ({ color, size, fill }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.82322 12.1768C4.77634 12.1299 4.75 12.0663 4.75 12C4.75 11.9337 4.77634 11.8701 4.82322 11.8232C4.87011 11.7763 4.9337 11.75 5 11.75C5.0663 11.75 5.12989 11.7763 5.17678 11.8232C5.22366 11.8701 5.25 11.9337 5.25 12C5.25 12.0663 5.22366 12.1299 5.17678 12.1768C5.12989 12.2237 5.0663 12.25 5 12.25C4.9337 12.25 4.87011 12.2237 4.82322 12.1768ZM11.8232 12.1768C11.7763 12.1299 11.75 12.0663 11.75 12C11.75 11.9337 11.7763 11.8701 11.8232 11.8232C11.8701 11.7763 11.9337 11.75 12 11.75C12.0663 11.75 12.1299 11.7763 12.1768 11.8232C12.2237 11.8701 12.25 11.9337 12.25 12C12.25 12.0663 12.2237 12.1299 12.1768 12.1768C12.1299 12.2237 12.0663 12.25 12 12.25C11.9337 12.25 11.8701 12.2237 11.8232 12.1768ZM18.8232 12.1768C18.7763 12.1299 18.75 12.0663 18.75 12C18.75 11.9337 18.7763 11.8701 18.8232 11.8232C18.8701 11.7763 18.9337 11.75 19 11.75C19.0663 11.75 19.1299 11.7763 19.1768 11.8232C19.2237 11.8701 19.25 11.9337 19.25 12C19.25 12.0663 19.2237 12.1299 19.1768 12.1768C19.1299 12.2237 19.0663 12.25 19 12.25C18.9337 12.25 18.8701 12.2237 18.8232 12.1768Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
