import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const ChevronFullUp: React.FC<IconProps> = ({ color, size, fill }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.862 12.9415C15.9503 13.0354 16 13.1632 16 13.2965C16 13.4298 15.9503 13.5576 15.862 13.6515L15.6755 13.8515C15.5877 13.9492 15.4661 14.0035 15.3396 14.0015H8.66037C8.53389 14.0035 8.4123 13.9492 8.32454 13.8515L8.13797 13.6515C8.04967 13.5576 8 13.4298 8 13.2965C8 13.1632 8.04967 13.0354 8.13797 12.9415L11.6735 9.15152C11.7581 9.05484 11.8763 9 12 9C12.1237 9 12.2419 9.05484 12.3265 9.15152L15.862 12.9415Z"
      fill={color}
    />
  </svg>
)
