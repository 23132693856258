import { useRouter } from 'next/router'
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react'
import { useRouteChange } from 'hooks/useRouteChange'

interface IGoogleOptimizeContext {
  variant: string
}

const OPTIMIZE = {
  CALLBACK: 'optimize.callback',
  ACTIVATE: 'optimize.activate',
}

export const GoogleOptimizeContext =
  createContext<IGoogleOptimizeContext | null>(null)
export const useExperiment = () => useContext(GoogleOptimizeContext)

const GoogleOptimizeProvider: React.FC<React.PWC> = ({ children }) => {
  const [variant, setVariant] = useState('0')
  const { hasChangedRoute } = useRouteChange()
  const { events } = useRouter()

  useEffect(() => {
    // there is also available parameter - name - for experiment name
    const implementManyExperiments = (value: string) => {
      setVariant(value)
    }

    window?.gtag?.('event', OPTIMIZE.CALLBACK, {
      callback: implementManyExperiments,
    })
    window?.dataLayer?.push({ event: OPTIMIZE.ACTIVATE })
    return () => {
      window?.gtag?.('event', OPTIMIZE.CALLBACK, {
        callback: implementManyExperiments,
        remove: true,
      })
    }
  }, [setVariant])

  useEffect(() => {
    const activateOptimize = (_: string, { shallow }: { shallow: boolean }) => {
      if (shallow || !hasChangedRoute) {
        return
      }

      window?.dataLayer?.push({ event: OPTIMIZE.ACTIVATE })
    }

    // Register event only once
    events?.on('routeChangeComplete', activateOptimize)

    return () => {
      events?.off('routeChangeComplete', activateOptimize)
    }
  }, [events, hasChangedRoute])

  const contextValues = useMemo(
    () => ({
      variant,
    }),
    [variant]
  )

  return (
    <GoogleOptimizeContext.Provider value={contextValues}>
      {children}
    </GoogleOptimizeContext.Provider>
  )
}

export default GoogleOptimizeProvider
