import get from 'lodash/get'
import { SIGN_UP_METHOD } from 'providers/userAuthUtil'
import { GA4_PARAMS, type TransformationProps } from '../types'
import { findPropertyInTree } from '../utils'

const providers = {
  [SIGN_UP_METHOD.GOOGLE]: 'Google',
  [SIGN_UP_METHOD.FACEBOOK]: 'Facebook',
}

interface SignUpTransformationContextData {
  providerId?: (typeof SIGN_UP_METHOD)[keyof typeof SIGN_UP_METHOD]
}

type SignUpTransformationProps = TransformationProps<
  SignUpTransformationContextData,
  'contextData' | 'query' | 'parent'
>

export const SignInUpTransformation = ({
  contextData,
  query,
  parent,
}: SignUpTransformationProps) => {
  const { providerId } = contextData
  const method = get(providers, providerId, 'Email')
  const { fromUrl } = query

  return {
    area:
      fromUrl ||
      findPropertyInTree({
        keyName: GA4_PARAMS.PAGE_AREA,
        contextData,
        parent,
      }) ||
      'Menu',
    method,
  }
}
