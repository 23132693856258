import { ApolloProvider } from '@apollo/client'
import { datadogRum } from '@datadog/browser-rum'
import { createTheme, MantineProvider } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import { Notifications } from '@mantine/notifications'
import { type AppProps } from 'next/app'
import { useRouter } from 'next/router'
import Script from 'next/script'
import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useCookie } from 'react-use'
import { ThemeProvider } from 'styled-components'
import { DatePickerGlobalStyles } from 'components/DatePicker'
import { FrontChat } from 'components/FrontChat'
import { Seo } from 'components/Seo'
import { Toast } from 'components/Toast'
import { mapPathnameToTitle, RECAPTCHA_CONTAINER_ID } from 'constants/common'
import { environment } from 'constants/environment'
import {
  UTM_SCRIPT,
  GTM_SCRIPT,
  INTERCOM_SCRIPT,
  GA_TRACKING_SCRIPT,
  SURVICATE_SCRIPT,
} from 'constants/inlineScripts'
import { RECAPTCHA_ROUTES } from 'constants/routes'
import { CartProvider } from 'providers/cart'
import CurrencyProvider from 'providers/currency'
import GoogleAnalyticsProvider, {
  CookieConsentTracker,
  KlaviyoFormEventInterceptor,
  ViewPromotionIntersectionObserver,
  PageViewTracker,
} from 'providers/googleAnalytics'
import GoogleOptimizeProvider from 'providers/googleOptimize'
import { SharedOptionsProvider } from 'providers/sharedOptions'
import ItemListStoreProvider from 'providers/useItemListStore'
import UserAuthProvider from 'providers/userAuth'
import { GlobalStyles } from 'Theme'
import { BREAKPOINTS } from 'useMedia'

import { useApollo } from 'utils/apolloClient'
import { handleKlaviyoAriaLabels } from 'utils/klaviyo'
import { pageErrorHandler } from 'utils/pageErrorHandler'
import { DatadogInit } from '../src/datadog'
import { RenderError } from '../src/routes/RenderError'
import 'sanitize.css'
import 'react-image-lightbox/style.css'
import 'fonts.css'
import 'style.css'
import '@mantine/core/styles.css'
import 'mantine-react-table/styles.css'
import '@mantine/notifications/styles.css'

const theme = createTheme({
  fontFamily: 'SF Ui Text, sans-serif',
  fontFamilyMonospace: 'Monaco, Courier, monospace',
  headings: { fontFamily: 'Graphik, sans-serif' },
})

const CustomApp = ({ Component, pageProps, err, token }: AppProps) => {
  const [urlHistory, setUrlHistory] = useState([])
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // With SSR, we usually want to set some default staleTime
            // above 0 to avoid refetching immediately on the client
            staleTime: 60 * 1000,
          },
        },
      })
  )
  const router = useRouter()
  const [nonceCookie] = useCookie('nonce')
  const setupHistory = (url) =>
    setUrlHistory((oldHistory) => [...oldHistory, url])

  useEffect(() => {
    if (router.isReady && window?.dataLayer) {
      // Custom event we will trigger instead of old gtag event.
      // This event will be deleted later, we need it to compare it with virtualPageView and trigger google ads
      window.dataLayer.push({ event: 'virpagview' })
    }

    router.events.on('beforeHistoryChange', setupHistory)
    return () => {
      router.events.off('beforeHistoryChange', setupHistory)
    }
  }, [router.events, router.isReady, router.asPath])

  useEffect(() => {
    // Create a MutationObserver to watch for changes in the DOM as we are loading klavyio banner with delay
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'childList') {
          const dialogElement = document.querySelector(
            '[role="dialog"][class*="kl-private-reset-css"]'
          )
          if (dialogElement) {
            ;(dialogElement as HTMLElement).style.zIndex = '99999999'

            // Once the dialog is found, disconnect the observer to prevent further checks
            observer.disconnect()
          }
        }
      })
    })

    // Start observing the document body for changes (like the addition of the dialog)
    observer.observe(document.body, { childList: true, subtree: true })

    // Clean up the observer when the component is unmounted
    return () => {
      observer.disconnect()
    }
  }, [])

  const apolloClient = useApollo(pageProps.initialApolloState, token)
  const getLayout = Component.getLayout || ((page) => page)

  return (
    <QueryClientProvider client={queryClient} contextSharing>
      <DatadogInit />
      <ApolloProvider client={apolloClient}>
        <Seo
          title={mapPathnameToTitle(router.pathname)}
          urlPath={router.asPath}
          {...Component.seoProps}
        />

        <SharedOptionsProvider>
          <ThemeProvider
            theme={{
              breakpoints: BREAKPOINTS,
            }}
          >
            <GoogleReCaptchaProvider
              reCaptchaKey={environment.G_RECAPTCHA_API_KEY}
            >
              <UserAuthProvider>
                <GoogleAnalyticsProvider>
                  <KlaviyoFormEventInterceptor />
                  <GoogleOptimizeProvider>
                    <CookieConsentTracker>
                      <PageViewTracker>
                        <ViewPromotionIntersectionObserver>
                          <ItemListStoreProvider>
                            <CurrencyProvider>
                              <CartProvider>
                                <MantineProvider theme={theme}>
                                  <Notifications />
                                  <ModalsProvider />
                                  {getLayout(
                                    <ErrorBoundary
                                      FallbackComponent={RenderError}
                                      onError={pageErrorHandler}
                                    >
                                      <Component
                                        {...pageProps}
                                        err={err}
                                        urlHistory={urlHistory}
                                      />
                                    </ErrorBoundary>
                                  )}
                                </MantineProvider>
                              </CartProvider>
                            </CurrencyProvider>
                          </ItemListStoreProvider>
                        </ViewPromotionIntersectionObserver>
                      </PageViewTracker>
                    </CookieConsentTracker>
                  </GoogleOptimizeProvider>
                </GoogleAnalyticsProvider>
              </UserAuthProvider>
              <Toast.Container />
              <GlobalStyles
                showRecaptchaBadge={
                  RECAPTCHA_ROUTES.includes(router.pathname) &&
                  process.env.NEXT_PUBLIC_NODE_ENV !== 'test'
                }
              />
              <DatePickerGlobalStyles />
            </GoogleReCaptchaProvider>
          </ThemeProvider>
        </SharedOptionsProvider>
        <div id={RECAPTCHA_CONTAINER_ID} />
        {process.env.NEXT_PUBLIC_NODE_ENV === 'production' && (
          <>
            {/* Survicate */}
            <Script
              id="survicate-script"
              dangerouslySetInnerHTML={{
                __html: SURVICATE_SCRIPT,
              }}
            />
            <script
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: GA_TRACKING_SCRIPT,
              }}
            />
            {/* Global Site Tag (gtag.js) - Google Analytics */}
            <Script
              src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_TRACKING_ID}`}
            />
            {/* Intercom load */}
            {environment.CUSTOMER_SERVICE_FRAMEWORK === 'intercom' && (
              <script
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: INTERCOM_SCRIPT,
                }}
              />
            )}
            {environment.CUSTOMER_SERVICE_FRAMEWORK === 'front' && (
              <FrontChat />
            )}
            {environment.SHAREASALE_MERCHANT_ID && (
              <Script
                nonce={nonceCookie}
                defer
                src="https://www.dwin1.com/19038.js"
              />
            )}
            {/* Google Tag Manager */}
            <Script
              id="gtm-script"
              nonce={nonceCookie}
              dangerouslySetInnerHTML={{
                __html: GTM_SCRIPT,
              }}
            />
            {/* Google Optimize */}
            <Script
              src={`https://www.googleoptimize.com/optimize.js?id=${process.env.NEXT_PUBLIC_GOOGLE_OPTIMIZE_ID}`}
            />
            {/* Klaviyo */}
            <Script
              type="text/javascript"
              src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.NEXT_PUBLIC_KLAVIYO_API_KEY}`}
              onReady={handleKlaviyoAriaLabels}
            />
            {/* Microsoft UTM Tags */}
            <Script
              id="utm-tags-script"
              dangerouslySetInnerHTML={{ __html: UTM_SCRIPT }}
            />
            {/* CookieYes load */}
            {process.env.NEXT_PUBLIC_COOKIEYES_API_KEY && (
              <Script
                id="cookieyes"
                type="text/javascript"
                src={`https://cdn-cookieyes.com/client_data/${process.env.NEXT_PUBLIC_COOKIEYES_API_KEY}/script.js`}
                onError={(innerError) => {
                  if (process.env.NODE_ENV === 'production') {
                    // Don't log cookieYes load errors that are coming from bots
                    if (innerError?.isTrusted !== true) {
                      const error = new Error('Failed to load cookieYes script')

                      datadogRum.addError(error, {
                        innerError,
                      })
                    }
                  }
                }}
              />
            )}
          </>
        )}
      </ApolloProvider>
    </QueryClientProvider>
  )
}

export default CustomApp
