import Container from './Container'
import { success, error, info, basic, dark } from './Toast'

const Toast = {
  Container,
  basic,
  success,
  error,
  info,
  dark,
}

export { Toast }
