import PropTypes from 'prop-types'
import React from 'react'

export const Visible = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.9878 11.6996C20.3582 7.61605 16.3881 4.95443 11.9939 4.99959C7.62899 4.97434 3.69113 7.61836 2.05997 11.6696C2.02155 11.7754 2.00128 11.887 2 11.9996C1.99947 12.1059 2.01985 12.2112 2.05997 12.3096C3.68478 16.3687 7.62405 19.0216 11.9939 18.9996C16.3588 19.0248 20.2967 16.3808 21.9279 12.3296C21.9706 12.225 21.9911 12.1126 21.9878 11.9996C22.004 11.9002 22.004 11.7989 21.9878 11.6996ZM11.9939 16.9996C8.6124 16.992 5.53329 15.0494 4.06874 11.9996C5.52823 8.94557 8.61075 7.00158 11.9939 7.00158C15.3771 7.00158 18.4596 8.94557 19.9191 11.9996C18.4545 15.0494 15.3754 16.992 11.9939 16.9996ZM8.99574 11.9996C8.99574 10.3427 10.3381 8.99959 11.9939 8.99959C13.6498 8.99959 14.9921 10.3427 14.9921 11.9996C14.9921 13.6564 13.6498 14.9996 11.9939 14.9996C10.3381 14.9996 8.99574 13.6564 8.99574 11.9996Z"
      fill={color}
    />
  </svg>
)

Visible.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
