import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { disabledHover } from 'components/mixins'
import {
  BORDER_WIDTH,
  COLOR,
  COLOR_NAMES,
  COLOR_INTENT,
  INPUT_SIZE,
  LINE_HEIGHT,
  RADIUS,
  SPACE,
} from 'Theme'

export const InputWrapper = styled('div')`
  flex-shrink: 0;
  display: inline-flex;
  border: ${BORDER_WIDTH.PX_1} solid ${({ borderColor }) => borderColor};
  background-color: ${COLOR_INTENT.INPUT.BACKGROUND};
  width: 100%;
  border-radius: ${RADIUS.PX_6};
  padding: 0 ${SPACE.PX_10};
  line-height: ${LINE_HEIGHT.XXS};
  height: ${INPUT_SIZE.M};
  align-items: center;
  cursor: text;
  ${({ isFocused, isError, isWarning, isDisabled }) =>
    !isFocused &&
    !isError &&
    !isWarning &&
    !isDisabled &&
    css`
      &:hover {
        border-color: ${COLOR_INTENT.INPUT.BORDER_HOVER};
      }
    `}
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      background-color: ${COLOR_INTENT.INPUT.DISABLED_BACKGROUND};
      border: none;
      color: ${COLOR_INTENT.INPUT.DISABLED_TEXT};
      ${disabledHover}
    `}
  ${({ size }) => `height: ${size};`}
  ${({ hasIcon }) =>
    hasIcon &&
    `
    padding-left: ${SPACE.PX_10};
  `}
`

InputWrapper.defaultProps = {
  isFocused: false,
  isDisabled: false,
  isError: false,
  isWarning: false,
}

InputWrapper.propTypes = {
  isFocused: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isWarning: PropTypes.bool,
  isError: PropTypes.bool,
  hasIcon: PropTypes.bool,
  borderColor: PropTypes.oneOf(
    Object.values({ ...COLOR, COLOR_NAMES, transparent: 'transparent' })
  ).isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
