import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const Message: React.FC<React.PWC<IconProps>> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 7.71429C4 7.25963 4.1873 6.82359 4.5207 6.5021C4.8541 6.18061 5.30628 6 5.77778 6H18.2222C18.6937 6 19.1459 6.18061 19.4793 6.5021C19.8127 6.82359 20 7.25963 20 7.71429M4 7.71429V16.2857C4 16.7404 4.1873 17.1764 4.5207 17.4979C4.8541 17.8194 5.30628 18 5.77778 18H18.2222C18.6937 18 19.1459 17.8194 19.4793 17.4979C19.8127 17.1764 20 16.7404 20 16.2857V7.71429M4 7.71429L12 12.8571L20 7.71429"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
