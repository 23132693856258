import { GA4_PARAMS, type TransformationProps } from '../types'
import { findPropertyInTree } from '../utils'

interface ErrorMessageTransformationContextData {
  text: string
}

type ErrorMessageTransformationProps = TransformationProps<
  ErrorMessageTransformationContextData,
  'contextData' | 'parent'
>

export const ErrorMessageTransformation = ({
  contextData,
  parent,
}: ErrorMessageTransformationProps) => {
  const { text } = contextData
  if (!text) {
    throw new Error(
      'Error message is not provided in error message transformation'
    )
  }

  return {
    page_area:
      findPropertyInTree({
        keyName: GA4_PARAMS.PAGE_AREA,
        contextData,
        parent,
      }) ?? null,
    text: text.slice(0, 90),
  }
}
