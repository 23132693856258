import PropTypes from 'prop-types'
import React from 'react'

export const UserAdd = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 12C11.2091 12 13 10.2091 13 8C13 5.79086 11.2091 4 9 4C6.79086 4 5 5.79086 5 8C5 10.2091 6.79086 12 9 12ZM16.89 18.55C17.046 18.8595 17.0305 19.2277 16.8492 19.5231C16.6679 19.8185 16.3466 19.999 16 20H2C1.6534 19.999 1.33207 19.8185 1.15076 19.5231C0.969451 19.2277 0.954027 18.8595 1.11 18.55L2.55 15.66C3.05879 14.6408 4.10089 13.9978 5.24 14H12.76C13.8991 13.9978 14.9412 14.6408 15.45 15.66L16.89 18.55ZM20 10H22.5C22.7761 10 23 10.2239 23 10.5V11.5C23 11.7761 22.7761 12 22.5 12H20V14.5C20 14.7761 19.7761 15 19.5 15H18.5C18.2239 15 18 14.7761 18 14.5V12H15.5C15.2239 12 15 11.7761 15 11.5V10.5C15 10.2239 15.2239 10 15.5 10H18V7.5C18 7.22386 18.2239 7 18.5 7H19.5C19.7761 7 20 7.22386 20 7.5V10Z"
      fill={color}
    />
  </svg>
)

UserAdd.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
