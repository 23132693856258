import { useMutation, gql } from '@apollo/client'
import { CartFieldsFragmentDoc } from 'types/graphql-generated'

const REMOVE_ITEM_FROM_CART_MUTATION = gql`
  ${CartFieldsFragmentDoc}
  mutation RemoveItemFromCart($input: RemoveItemFromCartInput!) {
    removeItemFromCart(input: $input) {
      ...CartFields
    }
  }
`

export const useRemoveItemFromCartMutation = ({ cartItemId } = {}) =>
  useMutation(REMOVE_ITEM_FROM_CART_MUTATION, {
    variables: {
      input: {
        cartItemId,
      },
    },
  })
