import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { datadogRum } from '@datadog/browser-rum'
import { createUploadLink } from 'apollo-upload-client'
import { print } from 'graphql/language/printer'
import { merge, get, orderBy } from 'lodash'
import { useMemo } from 'react'
import { CREATE_FILES_AND_NOTES_ATTACHMENT_OPERATION_NAME } from '@expert/components/SessionRecapModal/hooks'
import { Toast } from 'components/Toast'
import { environment } from 'constants/environment'
import {
  ApplyCouponDocument,
  ApplyPromoCodeDocument,
} from 'types/graphql-generated'
import auth from 'utils/firebase'
import fragmentTypesJson from '../../fragmentTypes.json'
import { onGraphqlError } from './graphqlErrorHandler'
import { cursorPagination } from './pagination/cursorPagination'
import { pageLimitPagination } from './pagination/pageLimitPagination'

const EXCLUDED_NETWORK_ERROR_HANDLING = [
  CREATE_FILES_AND_NOTES_ATTACHMENT_OPERATION_NAME,
]

const EXCLUDED_GRAPHQL_ERROR_HANDLING = [
  ApplyPromoCodeDocument.definitions[0].name.value,
  ApplyCouponDocument.definitions[0].name.value,
]

let globalApolloClient

const authLink = setContext(async (_, ctx) => {
  const { headers } = ctx
  try {
    const token = await auth.currentUser?.getIdToken()
    const replayUrl = window?.auryc?.getSessionMetadata?.()?.replayUrl
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        replayUrl,
      },
    }
  } catch (error) {
    return {
      headers,
    }
  }
})

const errorLink = onError(
  ({
    graphQLErrors,
    networkError,
    operation: { operationName, query, variables },
  }) => {
    if (
      networkError &&
      !EXCLUDED_NETWORK_ERROR_HANDLING.includes(operationName)
    ) {
      Toast.error(networkError.message)
    }
    if (
      graphQLErrors &&
      !EXCLUDED_GRAPHQL_ERROR_HANDLING.includes(operationName)
    ) {
      graphQLErrors
        .filter(({ name }) => !name)
        .forEach(({ message }) => {
          const contextData = {
            contexts: {
              graphql: {
                query: print(query),
                variables: JSON.stringify(variables),
              },
            },
          }
          datadogRum.addError(new Error(message), contextData)
        })
      onGraphqlError({ graphQLErrors })
    }
  }
)

export const getApiUrl = () => {
  if (typeof window === 'undefined') {
    return environment.SSR_API_URL
  }
  if (environment.NEXT_PUBLIC_NODE_ENV === 'test') {
    const url = new URL(environment.API_URL)
    const host = window.location.hostname
    return `http://${host}:${url.port}`
  }
  return environment.API_URL
}

function createApolloClient(initialToken) {
  const link = ApolloLink.from([
    authLink,
    errorLink,
    createUploadLink({
      uri: getApiUrl(), // Server URL (must be absolute)
      credentials: 'include',
      headers: {
        authorization: initialToken ? `Bearer ${initialToken}` : '',
      },
    }),
  ])
  return new ApolloClient({
    connectToDevTools:
      environment.NEXT_PUBLIC_NODE_ENV !== 'production' ||
      environment.IS_PREVIEW,
    ssrMode: typeof window === 'undefined',
    link,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            coupons: cursorPagination(['input', ['search', 'filter']]),
            adminOrders: cursorPagination(['input', ['sorter', 'search']]),
            tradeLicenses: cursorPagination(['input', ['sorter', 'search']]),
            adminDashboardExpertUsers: cursorPagination([
              'input',
              ['sorter', 'search', 'filter'],
            ]),
            adminDashboardExperts: cursorPagination([
              'input',
              ['sorter', 'search', 'filter'],
            ]),
            adminDashboardCollections: cursorPagination([
              'input',
              ['sorter', 'search', 'filter'],
            ]),
            publicExperts: pageLimitPagination(),
            cmsWidgetById: {
              keyArgs: ['input', ['widgetId']],
            },
          },
        },
        Collection: {
          fields: {
            itemsCursorPagination: cursorPagination([
              'input',
              ['sorter', 'search', 'filter'],
            ]),
          },
        },
        Product: {
          keyFields: (product) => {
            if (Number.isInteger(product?.positionIndexWeight)) {
              return ['id', 'positionIndexWeight']
            }
            return product?.id ? ['id'] : false
          },
        },
        Client: {
          fields: {
            favoriteExperts: pageLimitPagination(),
            purchases: cursorPagination(),
          },
        },
        Expert: {
          fields: {
            reviews: pageLimitPagination(),
            purchases: cursorPagination(),
            purchaseCommissions: cursorPagination(),
            showroomCommissions: cursorPagination(),
          },
        },
        Order: {
          fields: {
            notes: cursorPagination('input', {
              formatFunction: (items) => orderBy(items, 'node.__ref', 'desc'),
            }),
          },
        },
        SessionReport: {
          fields: {
            filesAndNotes: cursorPagination([
              'input',
              ['filter', ['fileType']],
            ]),
          },
        },
        CMSExpertsWidget: {
          fields: {
            cmsExpertsWidgetItems: pageLimitPagination({
              parseInput: (input) => {
                const defaultExpertsInput = get(
                  input,
                  ['variables', 'widgetsDefaultInputs', 'cmsExpertsInput'],
                  {}
                )
                const specificInput = get(input, ['variables', 'input'])

                const page = get(
                  specificInput,
                  ['cmsExpertsInput', 'input', 'page'],
                  defaultExpertsInput.page
                )
                const limit = get(
                  specificInput,
                  ['cmsExpertsInput', 'input', 'limit'],
                  defaultExpertsInput.limit
                )
                return {
                  page,
                  limit,
                  inputRest: {},
                }
              },
            }),
          },
        },
        CMSArticlesWidget: {
          fields: {
            cmsArticlesWidgetItems: pageLimitPagination({
              parseInput: (input) => {
                const defaultArticlesInput = get(
                  input,
                  ['variables', 'widgetsDefaultInputs', 'cmsArticlesInput'],
                  {}
                )
                const specificInput = get(input, ['variables', 'input'])

                const page = get(
                  specificInput,
                  ['cmsArticlesInput', 'input', 'page'],
                  defaultArticlesInput.page
                )
                const limit = get(
                  specificInput,
                  ['cmsArticlesInput', 'input', 'limit'],
                  defaultArticlesInput.limit
                )
                return {
                  page,
                  limit,
                  inputRest: {},
                }
              },
            }),
          },
        },
        CMSMultiCollectionWidget: {
          fields: {
            items: pageLimitPagination({
              parseInput: (input) => {
                const defaultMultiCollectionProductsInput = get(
                  input,
                  [
                    'variables',
                    'widgetsDefaultInputs',
                    'cmsMultiCollectionProductsInput',
                  ],
                  {}
                )
                const specificInput = get(input, [
                  'variables',
                  'input',
                  'cmsMultiCollectionProductsInput',
                  'input',
                ])
                const { pagination, ...inputRest } = merge(
                  {},
                  specificInput,
                  defaultMultiCollectionProductsInput
                )
                return {
                  ...pagination,
                  inputRest,
                }
              },
            }),
          },
        },
        CMSExpertsListingWidget: {
          fields: {
            items: pageLimitPagination({
              parseInput: (input) => {
                const defaultExpertsListingWidgetInput = get(
                  input,
                  [
                    'variables',
                    'widgetsDefaultInputs',
                    'cmsExpertsListingWidgetInput',
                  ],
                  {}
                )

                const expertsListingWidgetInputSpecificInput = get(input, [
                  'variables',
                  'input',
                  'cmsExpertsListingWidgetInput',
                  'input',
                ])

                const { pagination, ...inputRest } = merge(
                  {},
                  expertsListingWidgetInputSpecificInput,
                  defaultExpertsListingWidgetInput
                )
                return {
                  ...pagination,
                  inputRest,
                }
              },
            }),
          },
        },
      },
      possibleTypes: fragmentTypesJson.possibleTypes,
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
      mutate: {
        errorPolicy: 'all',
      },
      query: {
        errorPolicy: 'all',
      },
    },
  })
}

export function initializeApollo(initialState = null, initialToken = null) {
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    const apolloClient = createApolloClient(initialToken)
    if (initialState) {
      apolloClient.cache.restore(initialState)
    }
    return apolloClient
  }

  if (!globalApolloClient) {
    globalApolloClient = createApolloClient(initialToken)
  }

  // gets hydrated here
  if (initialState) {
    globalApolloClient.cache.restore(initialState)
  }

  // Create the Apollo Client once in the client
  return globalApolloClient
}

export function useApollo(initialState, initialToken) {
  return useMemo(() => {
    if (globalApolloClient && initialState) {
      const before = globalApolloClient.cache.extract()
      const merged = merge({}, before, initialState)
      globalApolloClient.restore(merged)
    }
    return globalApolloClient ?? initializeApollo(initialState, initialToken)
  }, [initialState, initialToken])
}
