import PropTypes from 'prop-types'
import React from 'react'

export const Delivery = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 15h-.7a1.7 1.7 0 0 1-1.6-1.7V5.4a1.7 1.7 0 0 1 1.6-1.7H12a1.7 1.7 0 0 1 1.6 1.7v5.8"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6 6.8H16a1 1 0 0 1 .8.4l1.9 2.5.3.9v4.2a.2.2 0 0 1-.2.1h-2.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3 16.7a1.9 1.9 0 1 0 0-3.7 1.9 1.9 0 0 0 0 3.7Zm-7.9 0a1.9 1.9 0 1 0 0-3.7 1.9 1.9 0 0 0 0 3.7Zm6-1.7H8.1"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

Delivery.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
