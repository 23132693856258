import PropTypes from 'prop-types'
import React from 'react'

export const Filter = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 5H17"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 10H17"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 15H16"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="4.75"
      cy="4.75"
      r="2"
      fill="white"
      stroke={color}
      strokeWidth="1.5"
    />
    <circle
      cx="9.75"
      cy="9.75"
      r="2"
      fill="white"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M17.25 14.75C17.25 15.8546 16.3546 16.75 15.25 16.75C14.1454 16.75 13.25 15.8546 13.25 14.75C13.25 13.6454 14.1454 12.75 15.25 12.75C16.3546 12.75 17.25 13.6454 17.25 14.75Z"
      fill="white"
      stroke={color}
      strokeWidth="1.5"
    />
  </svg>
)

Filter.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
