import { useEffect } from 'react'
import { useGoogleAnalytics } from './googleAnalyticsProvider'
import { GA4_EVENTS, GA4_NEWSLETTER_AREAS } from './types'

const KLAVIYO_FORMS_LISTENER_EVENT_TYPE = 'klaviyoForms'

const KLAVIYO_EVENT_TYPE = {
  OPEN: 'open',
  EMBED_OPEN: 'embedOpen',
  CLOSE: 'close',
  REDIRECT_TO_URL: 'redirectToUrl',
  SUBMIT: 'submit',
  STEP_SUBMIT: 'stepSubmit',
} as const

interface KlaviyoEvent extends Event {
  detail: {
    type: (typeof KLAVIYO_EVENT_TYPE)[keyof typeof KLAVIYO_EVENT_TYPE]
    formId: string
    companyId: string
    metaData?: {
      $email?: string
      [key: string]: string
    }
  }
}

const isKlaviyoFormEventWithEmail = (event: KlaviyoEvent) =>
  Boolean(event.detail.metaData?.$email)

const executeDataToDataLayerOfKlaviyoForm =
  (executeDataToDataLayer) => (event: KlaviyoEvent) => {
    if (
      event.detail.type === KLAVIYO_EVENT_TYPE.SUBMIT &&
      isKlaviyoFormEventWithEmail(event)
    ) {
      executeDataToDataLayer({
        event: GA4_EVENTS.NEWSLETTER_SUBSCRIBE,
        overrideData: {
          page_area: GA4_NEWSLETTER_AREAS.POPUP,
        },
      })
    }
  }

export const KlaviyoFormEventInterceptor = () => {
  const { executeDataToDataLayer } = useGoogleAnalytics()

  const handleKlaviyoEvent = executeDataToDataLayerOfKlaviyoForm(
    executeDataToDataLayer
  )

  useEffect(() => {
    window?.addEventListener(
      KLAVIYO_FORMS_LISTENER_EVENT_TYPE,
      handleKlaviyoEvent
    )

    return () => {
      window?.removeEventListener(
        KLAVIYO_FORMS_LISTENER_EVENT_TYPE,
        handleKlaviyoEvent
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
