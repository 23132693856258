import React from 'react'
import styled from 'styled-components'
import Icon from 'components/Icon'
import { Input } from 'components/Input'
import { Box } from 'components/Layout'

const IconWrapper = styled(Box)`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 30px;
`

export interface DatePickerInputProps
  extends Pick<
    React.ComponentProps<typeof Input>,
    | 'value'
    | 'label'
    | 'placeholder'
    | 'onClick'
    | 'hint'
    | 'hasHint'
    | 'isError'
    | 'isWarning'
    | 'isDisabled'
  > {
  isWithClearButton?: boolean
  onClearButtonClick?: () => void
}

export const DatePickerInput: React.FC<DatePickerInputProps> = React.forwardRef(
  (
    {
      value,
      onClick,
      label,
      hasHint,
      placeholder,
      isError,
      isWarning,
      isDisabled,
      hint,
      isWithClearButton,
      onClearButtonClick,
      ...props
    },
    ref
  ) => (
    <Box>
      <Input
        {...props}
        ref={ref}
        label={label}
        placeholder={placeholder}
        value={value}
        onClick={onClick}
        hint={hint}
        hasHint={hasHint}
        isError={isError}
        isWarning={isWarning}
        isDisabled={isDisabled}
        readOnly
        {...(!label ? { 'aria-label': 'Date picker input' } : {})}
      />
      {isWithClearButton && onClearButtonClick && value && !isDisabled && (
        <IconWrapper onClick={onClearButtonClick}>
          <Icon.Cross />
        </IconWrapper>
      )}
    </Box>
  )
)
