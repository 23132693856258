import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { useUserAuth } from 'providers/userAuth'
import { useGoogleAnalytics } from './googleAnalyticsProvider'
import { GA4_EVENTS } from './types'
import { PATHNAMES } from './utils'
import { isPathnameRestricted } from './utils/pageViewRouteRestrictions'

export const PageViewTracker: React.FC<React.PWC<unknown>> = ({ children }) => {
  const {
    executeDataToDataLayer,
    userType,
    setPageViewLoading,
    routerIsReady,
    asPathWithoutQueryParams,
  } = useGoogleAnalytics()
  const { pathname, events, isReady, asPath } = useRouter()
  const { isLoading, isPageLoading, temporaryUserData } = useUserAuth()

  // This event is just for testing purpose to compare it with virpagview event.
  // Both events should be triggered at same time and has same trigger count, but perhaps if it goes through our events it can be broken.
  // Following event will use same policy as other events, i.e ordering and waiting for virtualPageView and cookie consent.
  // Comparing virpagview and virpagview2 we will understand the erroring caused by cookieyes
  useEffect(() => {
    if (isReady) {
      executeDataToDataLayer({
        event: GA4_EVENTS.PAGE_VIEW_TEST2,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, asPath])

  useEffect(() => {
    const handleRouterChange = (
      _: string, // URL param which we don't need. In case we will need it, rename it.
      { shallow }: { shallow: boolean }
    ) => {
      if (!shallow) {
        setPageViewLoading(true)
      }
    }

    // Register event only once
    events?.on('routeChangeStart', handleRouterChange)
    return () => {
      events?.off('routeChangeStart', handleRouterChange)
    }
  }, [events, setPageViewLoading])

  useEffect(() => {
    if (
      routerIsReady &&
      !pathname.startsWith(PATHNAMES.ADMIN) &&
      !isLoading &&
      !isPageLoading &&
      !isPathnameRestricted({
        userType,
        pathname,
      })
    ) {
      executeDataToDataLayer({
        event: GA4_EVENTS.PAGE_VIEW,
        overrideData: {
          id: null,
          title: window.document.title,
        },
      })
      executeDataToDataLayer({
        event: GA4_EVENTS.USER_DATA,
        overrideData: {
          userData: temporaryUserData.current,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPathWithoutQueryParams, routerIsReady, isLoading, isPageLoading])

  return <>{children}</>
}
