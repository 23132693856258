import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const Repeat: React.FC<IconProps> = ({ color, size, fill }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9331 13.0417C19.7442 14.4816 19.167 15.843 18.2632 16.9798C17.3595 18.1166 16.1633 18.986 14.803 19.4947C13.4427 20.0034 11.9696 20.1321 10.5417 19.8672C9.1138 19.6023 7.78492 18.9537 6.69761 17.991C5.6103 17.0282 4.80557 15.7876 4.36973 14.4023C3.93389 13.017 3.88338 11.5391 4.22362 10.1272C4.56385 8.71537 5.282 7.42275 6.30104 6.38801C7.32007 5.35327 8.60156 4.61544 10.0081 4.25366C13.9071 3.25366 17.9431 5.26066 19.4331 9.00066M20 4V9H15"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
