import React from 'react'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import {
  type BorderProps,
  type ColorProps,
  type SpaceProps,
  type TypographyProps,
} from 'styled-system'
import {
  BUTTON_ICON_POSITION,
  BUTTON_ICON_SIZE,
  BUTTON_INTENT,
  BUTTON_SIZE,
  BUTTON_VARIANT,
  baseButtonStyles,
} from 'components/Button'
import { type IconProps } from 'components/Icon'
import { Loader } from 'components/Loader'
import { COLOR_INTENT, SPACE } from 'Theme'
import Link, { type LinkProps } from './Link'

export interface StyledLinkProps
  extends Omit<LinkProps, 'children'>,
    SpaceProps,
    TypographyProps,
    ColorProps,
    BorderProps {
  href: string
  width?: string
  isLoading?: boolean
  isDisabled?: boolean
  icon?: typeof React.Component | React.FC<React.PWC<IconProps>>
  size?: (typeof BUTTON_SIZE)[keyof typeof BUTTON_SIZE]
  intent?: (typeof BUTTON_INTENT)[keyof typeof BUTTON_INTENT]
  variant?: (typeof BUTTON_VARIANT)[keyof typeof BUTTON_VARIANT]
  iconPosition?: (typeof BUTTON_ICON_POSITION)[keyof typeof BUTTON_ICON_POSITION]
}

const LinkWithStyles = styled(Link).withConfig({
  shouldForwardProp: (prop) =>
    ![
      'color',
      'fontWeight',
      'fontFamily',
      'width',
      'iconPosition',
      'isLoading',
      'isDisabled',
      'hasIcon',
      'hasChildren',
    ].includes(prop),
})`
  ${baseButtonStyles}
`

const LinkContent = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LoaderWrapper = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
const IconWrapper = styled.span`
  display: inline-flex;
  ${({ hasChildren }) =>
    hasChildren ? `padding: 0 ${SPACE.PX_5};` : 'width: 100%;'}
  ${({ isHidden }) => isHidden && `visibility: hidden`};
`

const ChildrenWrapper = styled.span`
  ${({ isHidden }) => isHidden && `visibility: hidden`};
`

export const StyledLink = React.forwardRef<
  HTMLAnchorElement,
  React.PWC<StyledLinkProps>
>(
  (
    {
      href,
      size = BUTTON_SIZE.M,
      intent = BUTTON_INTENT.PRIMARY,
      variant = BUTTON_VARIANT.DEFAULT,
      iconPosition = BUTTON_ICON_POSITION.LEFT,
      isLoading = false,
      isDisabled = false,
      width = 'max-content',
      icon: Icon,
      children,
      ...props
    },
    ref
  ) => (
    <LinkWithStyles
      ref={ref}
      href={href}
      size={size}
      intent={intent}
      variant={variant}
      iconPosition={iconPosition}
      isLoading={isLoading}
      isDisabled={isDisabled}
      width={width}
      hasIcon={Icon !== undefined}
      hasChildren={children !== undefined}
      {...props}
    >
      <LinkContent>
        {isLoading && (
          <LoaderWrapper>
            <Loader
              data-test-class={DATA_TEST_CLASS.BUTTON_LOADER}
              color={
                variant === BUTTON_VARIANT.DEFAULT
                  ? COLOR_INTENT.BUTTON.BACKGROUND_LIGHT
                  : intent
              }
            />
          </LoaderWrapper>
        )}
        {Icon && iconPosition === BUTTON_ICON_POSITION.LEFT && (
          <IconWrapper isHidden={isLoading} hasChildren={Boolean(children)}>
            <Icon
              size={BUTTON_ICON_SIZE[size]}
              color={
                variant === BUTTON_VARIANT.DEFAULT
                  ? COLOR_INTENT.BUTTON.BACKGROUND_LIGHT
                  : intent
              }
            />
          </IconWrapper>
        )}
        {children && (
          <ChildrenWrapper isHidden={isLoading}>{children}</ChildrenWrapper>
        )}
        {Icon && iconPosition === BUTTON_ICON_POSITION.RIGHT && (
          <IconWrapper isHidden={isLoading} hasChildren={Boolean(children)}>
            <Icon
              size={BUTTON_ICON_SIZE[size]}
              color={
                variant === BUTTON_VARIANT.DEFAULT
                  ? COLOR_INTENT.BUTTON.BACKGROUND_LIGHT
                  : intent
              }
            />
          </IconWrapper>
        )}
      </LinkContent>
    </LinkWithStyles>
  )
)
