import PropTypes from 'prop-types'
import React from 'react'

export const Youtube = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.54 4.33999L20 4.48999C21.7285 4.68535 23.0264 6.1607 23 7.89999V16.1C23.0264 17.8393 21.7285 19.3146 20 19.51L18.6 19.66C14.2316 20.11 9.82844 20.11 5.46 19.66L4 19.51C2.27152 19.3146 0.973555 17.8393 1 16.1V7.89999C0.973555 6.1607 2.27152 4.68535 4 4.48999L5.4 4.33999C9.76844 3.89001 14.1716 3.89001 18.54 4.33999ZM11.11 15.22L15 12.62H15.06C15.2696 12.4834 15.396 12.2502 15.396 12C15.396 11.7498 15.2696 11.5166 15.06 11.38L11.17 8.77999C10.9403 8.62475 10.6438 8.60885 10.3988 8.73865C10.1539 8.86845 10.0005 9.12278 10 9.39999V14.6C10.0129 14.8581 10.1577 15.0913 10.3833 15.2173C10.6089 15.3433 10.8835 15.3443 11.11 15.22Z"
      fill={color}
    />
  </svg>
)

Youtube.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
