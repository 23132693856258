import { rem, transparentize } from 'polished'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import { BORDER_WIDTH, COLOR } from 'Theme'

export const LOADER_SIZE = {
  XXXS: rem(16),
  XXS: rem(20),
  XS: rem(26),
  S: rem(30),
  M: rem(36),
  L: rem(40),
  XL: rem(46),
  XXL: rem(50),
  XXXL: rem(56),
}

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const Loader = styled('div')`
  border: ${BORDER_WIDTH.PX_3} solid
    ${({ color }) => transparentize(0.8, color)};
  border-top: ${BORDER_WIDTH.PX_3} solid ${({ color }) => color};
  border-radius: 50%;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  animation: ${spin} 0.6s linear infinite;
  flex-shrink: 0;
`

Loader.defaultProps = {
  size: LOADER_SIZE.XS,
  color: COLOR.PRIMARY_1,
}

Loader.propTypes = {
  size: PropTypes.oneOf(Object.values(LOADER_SIZE)),
  color: PropTypes.oneOf(Object.values(COLOR)),
}
