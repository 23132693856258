import PropTypes from 'prop-types'
import React from 'react'

export const Facebook = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.6467 12.0733C24.6467 5.40541 19.2413 0 12.5733 0C5.90541 0 0.5 5.40541 0.5 12.0733C0.5 18.0994 4.91502 23.0943 10.6869 24V15.5633H7.62138V12.0733H10.6869V9.41343C10.6869 6.38755 12.4894 4.71615 15.2472 4.71615C16.5677 4.71615 17.9497 4.95195 17.9497 4.95195V7.92313H16.4274C14.9276 7.92313 14.4598 8.85386 14.4598 9.80958V12.0733H17.8083L17.273 15.5633H14.4598V24C20.2316 23.0943 24.6467 18.0994 24.6467 12.0733Z"
      fill={color}
    />
  </svg>
)

Facebook.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
