import React, { type ReactNode } from 'react'
import { toast, type ToastOptions } from 'react-toastify'
import Icon, { ICON_SIZE } from 'components/Icon'
import { COLOR } from 'Theme'

const createCloseButton = (color) => (
  <Icon.Cross size={ICON_SIZE.PX_20} color={color} />
)

const defaultOptions: ToastOptions = {
  autoClose: process.env.NEXT_PUBLIC_NODE_ENV === 'test' ? false : 4000,
  className: 'general-toast',
}

type ToastFunction = (
  display: string | ReactNode,
  options?: ToastOptions | undefined
) => React.ReactText

export const info: ToastFunction = (display, options) =>
  toast(display, {
    type: toast.TYPE.INFO,
    closeButton: () => createCloseButton(COLOR.WHITE),
    ...defaultOptions,
    ...options,
  })

export const error: ToastFunction = (display, options) =>
  toast(display, {
    type: toast.TYPE.ERROR,
    closeButton: () => createCloseButton(COLOR.WHITE),
    ...defaultOptions,
    ...options,
  })

export const success: ToastFunction = (display, options) =>
  toast(display, {
    type: toast.TYPE.SUCCESS,
    closeButton: () => createCloseButton(COLOR.WHITE),
    ...defaultOptions,
    ...options,
  })

export const basic: ToastFunction = (display, options) =>
  toast(display, {
    type: toast.TYPE.DEFAULT,
    closeButton: () => createCloseButton(COLOR.BLACK),
    ...defaultOptions,
    ...options,
  })

export const dark: ToastFunction = (display, options) =>
  toast(display, {
    type: toast.TYPE.DARK,
    closeButton: () => createCloseButton(COLOR.WHITE),
    ...defaultOptions,
    ...options,
  })
