import { rem } from 'polished'
import { ICON_SIZE } from 'components/Icon'
import { COLOR_INTENT } from 'Theme'

export const SHARP_BUTTON_INTENT = {
  PRIMARY: COLOR_INTENT.BUTTON.INTENT.PRIMARY,
  SECONDARY: COLOR_INTENT.BUTTON.INTENT.SECONDARY,
} as const

export const BUTTON_INTENT = {
  PRIMARY: COLOR_INTENT.BUTTON.INTENT.PRIMARY,
  SUCCESS: COLOR_INTENT.BUTTON.INTENT.SUCCESS,
  WARNING: COLOR_INTENT.BUTTON.INTENT.WARNING,
  DANGER: COLOR_INTENT.BUTTON.INTENT.DANGER,
  NONE: COLOR_INTENT.BUTTON.INTENT.NONE,
  BLACK: COLOR_INTENT.BUTTON.INTENT.BLACK,
} as const

export const BUTTON_SIZE = {
  S: rem(30),
  M: rem(36),
  L: rem(40),
} as const

export const BUTTON_ICON_SIZE = {
  [BUTTON_SIZE.S]: ICON_SIZE.PX_18,
  [BUTTON_SIZE.M]: ICON_SIZE.PX_20,
  [BUTTON_SIZE.L]: ICON_SIZE.PX_22,
} as const

export const BUTTON_VARIANT = {
  FILLED: 'filled',
  OUTLINE: 'outline',
  MINIMAL: 'minimal',
  DEFAULT: 'default',
} as const

export const BUTTON_ICON_POSITION = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
} as const

export const BUTTON_ICON_OPPOSITE_POSITION = {
  [BUTTON_ICON_POSITION.LEFT]: BUTTON_ICON_POSITION.RIGHT,
  [BUTTON_ICON_POSITION.RIGHT]: BUTTON_ICON_POSITION.LEFT,
} as const

export const BUTTON_GRADIENT = {
  HOVER: '0deg, rgba(24, 27, 25, 0.1), rgba(24, 27, 25, 0.1)',
  ACTIVE: '0deg, rgba(24, 27, 25, 0.2), rgba(24, 27, 25, 0.2)',
  ACTIVE_NONE: '180deg, rgba(24, 27, 25, 0) 0%, rgba(24, 27, 25, 0.07) 100%',
} as const
