import React from 'react'
import { Text, type TypographyProps } from 'components/Typography'
import { FONT_SIZE, getTextColor, SPACE } from 'Theme'

interface HintProps extends TypographyProps {
  isWarning?: boolean
  isError?: boolean
  messageContrast?: 'dark' | 'light'
}

export const Hint: React.FC<React.PWC<HintProps>> = ({
  children,
  isWarning = false,
  isError = false,
  messageContrast = 'dark',
  ...props
}) => (
  <>
    {children && (
      <Text
        fontSize={FONT_SIZE.PX_12}
        mt={SPACE.PX_5}
        color={getTextColor({ isWarning, isError, messageContrast })}
        {...props}
      >
        {children}
      </Text>
    )}
  </>
)
