import PropTypes from 'prop-types'
import React from 'react'

export const BinOutlined = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_808_13911" fill="white">
      <path d="M5.87 20.14C5.94365 21.1896 6.81787 22.0026 7.87 22H16.15C17.2021 22.0026 18.0764 21.1896 18.15 20.14L19 8H5L5.87 20.14Z" />
    </mask>
    <path
      d="M5.87 20.14L7.8651 20L7.86488 19.997L5.87 20.14ZM7.87 22V20L7.86509 20L7.87 22ZM16.15 22L16.1549 20H16.15V22ZM18.15 20.14L20.1451 20.28L20.1451 20.2797L18.15 20.14ZM19 8L20.9951 8.13969L21.1449 6H19V8ZM5 8V6H2.85154L3.00512 8.14296L5 8ZM3.87491 20.28C4.0222 22.3791 5.77065 24.0052 7.87491 24L7.86509 20L7.86509 20L3.87491 20.28ZM7.87 24H16.15V20H7.87V24ZM16.1451 24C18.2494 24.0052 19.9978 22.3791 20.1451 20.28L16.1549 20L16.1549 20L16.1451 24ZM20.1451 20.2797L20.9951 8.13969L17.0049 7.86031L16.1549 20.0003L20.1451 20.2797ZM19 6H5V10H19V6ZM3.00512 8.14296L3.87512 20.283L7.86488 19.997L6.99488 7.85704L3.00512 8.14296Z"
      fill={color}
      mask="url(#path-1-inside-1_808_13911)"
    />
    <path
      d="M19.9995 4.50049V5.50049C19.9995 5.77663 19.7757 6.00049 19.4995 6.00049H4.49951C4.22337 6.00049 3.99951 5.77663 3.99951 5.50049V4.50049C3.99951 4.22435 4.22337 4.00049 4.49951 4.00049H8.99951V3.00049C8.99951 2.4482 9.44723 2.00049 9.99951 2.00049H13.9995C14.5518 2.00049 14.9995 2.4482 14.9995 3.00049V4.00049H19.4995C19.7757 4.00049 19.9995 4.22435 19.9995 4.50049Z"
      fill={color}
    />
  </svg>
)

BinOutlined.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
