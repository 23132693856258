import PropTypes from 'prop-types'
import React from 'react'

export const Home = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.717.468l5.566 5.564a.75.75 0 01.217.526v.817a.375.375 0 01-.375.375H14V13a.75.75 0 01-.75.75H9.5a.75.75 0 01-.75-.75V9.437a.188.188 0 00-.188-.187H7.438a.188.188 0 00-.188.188V13a.75.75 0 01-.75.75H2.75A.75.75 0 012 13V7.75H.875A.375.375 0 01.5 7.375v-.817a.75.75 0 01.218-.526L6.282.468A.75.75 0 016.808.25h2.385a.75.75 0 01.524.218zm.533 11.782h2.25V5.372L8.877 1.75H7.122L3.5 5.372v6.878h2.25V8.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75v3.75z"
      fill={color}
    />
  </svg>
)

Home.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
