import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const ChevronFullLeft: React.FC<IconProps> = ({ color, size, fill }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9415 8.13797C13.0354 8.04967 13.1632 8 13.2965 8C13.4298 8 13.5576 8.04967 13.6515 8.13797L13.8515 8.32454C13.9492 8.4123 14.0035 8.53389 14.0015 8.66037V15.3396C14.0035 15.4661 13.9492 15.5877 13.8515 15.6755L13.6515 15.862C13.5576 15.9503 13.4298 16 13.2965 16C13.1632 16 13.0354 15.9503 12.9415 15.862L9.15152 12.3265C9.05484 12.2419 9 12.1237 9 12C9 11.8763 9.05484 11.7581 9.15152 11.6735L12.9415 8.13797Z"
      fill={color}
    />
  </svg>
)
