import { useQuery, gql } from '@apollo/client'
import { get } from 'lodash'

const EXPERT_SESSION_CLIENT_QUERY = gql`
  query expertSessionClient($id: Int!) {
    expertSessionClient(id: $id) {
      id
      report {
        id
        note
        tradeOnlyItems {
          id
          url
          originUrl
          note
          title
          publisher
          attachment {
            id
            url
            name
            alt
          }
        }
        directToConsumerItems {
          id
          url
          originUrl
          title
          note
          publisher
          attachment {
            id
            url
            name
            alt
          }
        }
        filesAndNotes {
          edges {
            node {
              id
              note
              attachment {
                id
                url
                name
                alt
              }
            }
          }
        }
      }
    }
  }
`

export const useExpertSessionClient = (sessionClientId, options) => {
  const {
    data,
    loading: isLoading,
    refetch,
  } = useQuery(EXPERT_SESSION_CLIENT_QUERY, {
    variables: {
      id: sessionClientId,
    },
    ...options,
  })
  return {
    refetch,
    report: get(data, ['expertSessionClient', 'report']),
    isLoading,
  }
}
