import { type CartShipment } from '../../../types/graphql-generated'
import {
  GA4_PARAMS,
  type PaymentMethodTypes,
  type TransformationProps,
  type MapItemToInput,
} from '../types'
import { findPropertyInTree, mapItemToInput, slugifyString } from '../utils'

type SelectedShipmentMethods = CartShipment & {
  shipmentMethodTitle: string
  shipmentMethodDescription: string
  shipmentValue: string
}

interface CheckoutTransformationContextData {
  items: MapItemToInput[]
  paymentType?: PaymentMethodTypes
  selectedShipmentMethods?: SelectedShipmentMethods[]
}

type CheckoutTransformationProps = TransformationProps<
  CheckoutTransformationContextData,
  'contextData' | 'parent'
>

export const CheckoutTransformation = ({
  contextData,
  parent,
}: CheckoutTransformationProps) => {
  const { items, selectedShipmentMethods, paymentType } = contextData

  if (!items?.length) {
    throw new Error('Items are not provided in checkout transformation')
  }

  return {
    ecommerce: {
      area: findPropertyInTree({
        keyName: GA4_PARAMS.AREA,
        contextData,
        parent,
      }),
      items: items?.map(mapItemToInput(true)),
      ...(paymentType && { payment_type: paymentType }),
      ...(selectedShipmentMethods?.length && {
        shipping_tier: selectedShipmentMethods
          .map(({ shipmentMethodTitle }) => slugifyString(shipmentMethodTitle))
          .join(' / '),
      }),
    },
  }
}
