import PropTypes from 'prop-types'
import React from 'react'

export const Twitter = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.97 6.46378C21.4539 7.15213 20.8301 7.75258 20.1227 8.24195C20.1227 8.42177 20.1227 8.60158 20.1227 8.79139C20.1284 12.0568 18.8241 15.1879 16.5021 17.4828C14.1802 19.7777 11.0349 21.0443 7.77132 20.9988C5.88459 21.0051 4.02202 20.5743 2.32951 19.7401C2.23825 19.7003 2.17939 19.61 2.17973 19.5103V19.4005C2.17973 19.257 2.29596 19.1407 2.43934 19.1407C4.29396 19.0796 6.0824 18.4366 7.55165 17.3026C5.87297 17.2688 4.36258 16.2744 3.66749 14.7453C3.63239 14.6617 3.64332 14.5659 3.69634 14.4925C3.74937 14.419 3.83682 14.3785 3.9271 14.3856C4.43729 14.4369 4.95258 14.3894 5.44482 14.2458C3.5917 13.8611 2.19928 12.3227 2 10.4397C1.99292 10.3494 2.0334 10.2619 2.10682 10.2088C2.18024 10.1558 2.27598 10.1448 2.35946 10.18C2.85676 10.3994 3.39359 10.515 3.93709 10.5196C2.31331 9.45384 1.61195 7.42672 2.22966 5.58469C2.29342 5.4057 2.44661 5.27347 2.63293 5.2366C2.81925 5.19972 3.01122 5.26365 3.13829 5.40487C5.32948 7.73695 8.3402 9.12662 11.5357 9.28088C11.4538 8.95427 11.4135 8.61862 11.4158 8.28191C11.4457 6.51636 12.5381 4.94357 14.1817 4.29986C15.8252 3.65615 17.6945 4.06893 18.9146 5.34494C19.7462 5.18651 20.5502 4.90716 21.301 4.51579C21.356 4.48145 21.4257 4.48145 21.4807 4.51579C21.515 4.57081 21.515 4.64058 21.4807 4.6956C21.117 5.52835 20.5027 6.2268 19.7233 6.69355C20.4058 6.6144 21.0763 6.45341 21.7203 6.21404C21.7746 6.17713 21.8458 6.17713 21.9001 6.21404C21.9455 6.23482 21.9795 6.27457 21.9929 6.32269C22.0064 6.37081 21.998 6.42242 21.97 6.46378Z"
      fill={color}
    />
  </svg>
)

Twitter.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
