import { includes } from 'lodash'
import { GA4_USER_TYPES } from '../types'
import { PATHNAMES } from './pageTypes'

const COMMON_RESTRICTED_PATHNAMES = [
  PATHNAMES.SIGN_IN,
  PATHNAMES.EXPERT_SIGN_IN,
  PATHNAMES.ADMIN,
]

const pageViewRouteRestrictions = new Map([
  [GA4_USER_TYPES.GUEST, [PATHNAMES.EXPERT, PATHNAMES.CLIENT, PATHNAMES.ADMIN]],
  [
    GA4_USER_TYPES.INTERNAL,
    [PATHNAMES.CLIENT, PATHNAMES.EXPERT, ...COMMON_RESTRICTED_PATHNAMES],
  ],
  [
    GA4_USER_TYPES.INTERNAL_AS_CLIENT,
    [PATHNAMES.EXPERT, ...COMMON_RESTRICTED_PATHNAMES],
  ],
  [
    GA4_USER_TYPES.INTERNAL_AS_EXPERT,
    [PATHNAMES.CLIENT, ...COMMON_RESTRICTED_PATHNAMES],
  ],
  [GA4_USER_TYPES.CLIENT, [PATHNAMES.EXPERT, ...COMMON_RESTRICTED_PATHNAMES]],
  [GA4_USER_TYPES.EXPERT, [PATHNAMES.CLIENT, ...COMMON_RESTRICTED_PATHNAMES]],
  [GA4_USER_TYPES.UNKNOWN, []],
])

interface Props {
  userType: (typeof GA4_USER_TYPES)[keyof typeof GA4_USER_TYPES]
  pathname: string
}

export const isPathnameRestricted = ({ userType, pathname }: Props) =>
  includes(pageViewRouteRestrictions.get(userType), pathname)
