import React from 'react'
import styled from 'styled-components'
import { type SpaceProps } from 'styled-system'
import { SPACE } from 'Theme'
import { Button, type ButtonProps } from './Button'
import { type SHARP_BUTTON_INTENT } from './constants'
import { baseSharpButtonStyles } from './styles'

export interface SharpButtonProps
  extends Pick<SpaceProps, 'py' | 'px'>,
    Omit<ButtonProps, 'intent'> {
  intent?: (typeof SHARP_BUTTON_INTENT)[keyof typeof SHARP_BUTTON_INTENT]
}

const SharpButtonBase = styled(Button)`
  ${baseSharpButtonStyles}
`
export const SharpButton: React.FC<React.PWC<SharpButtonProps>> = ({
  px = SPACE.PX_30,
  py = SPACE.PX_15,
  children,
  ...rest
}) => (
  <SharpButtonBase px={px} py={py} {...rest}>
    {children}
  </SharpButtonBase>
)
