import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const ArrowLeft: React.FC<IconProps> = ({ color, size, fill }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 12H19M5 12L11 18M5 12L11 6"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
