import { useMutation, gql } from '@apollo/client'
import { get } from 'lodash'
import { useCallback } from 'react'
import { Toast } from 'components/Toast'

export const CREATE_FILES_AND_NOTES_ATTACHMENT_OPERATION_NAME =
  'createFilesAndNotesAttachmentMutation'

const CREATE_FILES_AND_NOTES_ATTACHMENT_MUTATION = gql`
  mutation ${CREATE_FILES_AND_NOTES_ATTACHMENT_OPERATION_NAME}(
    $input: CreateFilesAndNotesAttachmentInput!
  ) {
    createFilesAndNotesAttachment(input: $input) {
      id
      name
      url
    }
  }
`

const USER_ABORTED_REQUEST_ERROR = 'The user aborted a request.'

const containsAbortError = (errors) => {
  if (Array.isArray(errors)) {
    return errors.some((error) => error.message === USER_ABORTED_REQUEST_ERROR)
  }

  if (typeof errors === 'object' && errors !== null) {
    return errors.message === USER_ABORTED_REQUEST_ERROR
  }

  return false
}

export const useManageFilesAndNotesAttachments = ({
  updateFile,
  getFiles,
  removeFile,
  setNewFilesToUpload,
  maxFilesCount,
}) => {
  const [
    createFilesAndNotesAttachmentMutation,
    { loading: isCreateFilesAndNotesAttachmentMutationLoading },
  ] = useMutation(CREATE_FILES_AND_NOTES_ATTACHMENT_MUTATION, {
    onError: ({ networkError }) => {
      if (networkError && networkError.message !== USER_ABORTED_REQUEST_ERROR) {
        Toast.error(networkError.message)
      }
    },
  })

  const removeFilesAndNotesItem = useCallback(
    (clientId) => {
      removeFile(clientId)
    },
    [removeFile]
  )

  const addFilesAndNotesItems = useCallback(
    (uploadedFiles) => {
      const areFilesValid = setNewFilesToUpload(uploadedFiles)
      if (!areFilesValid) {
        Toast.error(`Maximum count of attachments is ${maxFilesCount}`)
        return
      }
      const files = getFiles()
      files
        .slice(files.length - uploadedFiles.length, files.length)
        .forEach(async ({ clientId, file, abortController }) => {
          const { data, errors } = await createFilesAndNotesAttachmentMutation({
            variables: {
              input: {
                file,
              },
            },
            context: {
              fetchOptions: { signal: abortController.signal },
            },
          })

          if (containsAbortError(errors)) {
            removeFilesAndNotesItem(clientId)
            return
          }

          const { id, name, url } = get(
            data,
            'createFilesAndNotesAttachment',
            {}
          )

          updateFile({
            clientId,
            itemToUpdate: {
              ...(errors
                ? {
                    isError: true,
                  }
                : {
                    attachmentId: id,
                    name,
                    url,
                  }),
              isLoading: false,
            },
          })
        })
    },
    [
      createFilesAndNotesAttachmentMutation,
      getFiles,
      maxFilesCount,
      removeFilesAndNotesItem,
      setNewFilesToUpload,
      updateFile,
    ]
  )

  return {
    addFilesAndNotesItems,
    removeFilesAndNotesItem,
    isLoading: isCreateFilesAndNotesAttachmentMutationLoading,
  }
}
