import { useRouter } from 'next/router'
import { useRef } from 'react'

export const useRouteChange = () => {
  const router = useRouter()
  const hasChangedRoute = useRef(true)

  const getRouteWithoutQueryParams = (url: string) => {
    const [routeWithoutQueryParams] = url.split('?')

    return routeWithoutQueryParams
  }

  router.events?.on('routeChangeStart', (url: string) => {
    hasChangedRoute.current =
      getRouteWithoutQueryParams(url) !==
      getRouteWithoutQueryParams(router.asPath)
  })

  return { hasChangedRoute: hasChangedRoute.current }
}
