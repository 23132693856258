export const TIME_FORMAT = {
  BACKEND_DATE: 'yyyy-MM-dd',
  LOCAL_DATE: 'MM/dd/yyyy',
  LOCAL_DATETIME: 'MM/dd/yyyy hh:mm:ss aa',
}

export const EXPERT_APPLICATION_STATUSES = {
  PENDING: 'PENDING',
  IN_WAITLIST: 'IN_WAITLIST',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
}

export const SORT_FIELDS = {
  FIRST_NAME: 'firstName',
  EMAIL: 'email',
  CREATED_AT_UTC: 'createdAtUtc',
  UPDATED_AT_UTC: 'updatedAtUtc',
  POSITION_INDEX_WEIGHT: 'positionIndexWeight',
  NON_BOOKED_AVAILABILITY_HOURS: 'nonBookedAvailabilityHours',
  START_AT_UTC: 'startAtUtc',
  OCCURRED_AT_UTC: 'occurredAtUtc',
  QUANTITY: 'quantity',
  MANUFACTURER: 'manufacturer',
  STATUS: 'status',
}

export const PRODUCT_SORT_FIELDS = {
  ID: 'id',
  CREATED_AT_UTC: 'createdAtUtc',
  UPDATED_AT_UTC: 'updatedAtUtc',
  MANUFACTURER: 'manufacturer',
  SKU: 'sku',
  QUANTITY: 'quantity',
  STATUS: 'status',
  TITLE: 'title',
}

export const COLLECTION_ITEM_SORT_FIELDS = {
  PRODUCT_ID: 'productId',
  POSITION_INDEX_WEIGHT: 'positionIndexWeight',
  UPDATED_AT_UTC: 'updatedAtUtc',
  CREATED_AT_UTC: 'createdAtUtc',
  MANUFACTURER: 'manufacturer',
  PRODUCT_SKU: 'productSku',
  MIN_PUBLISHED_PRICE: 'minPublishedPrice',
  PRODUCT_TITLE: 'productTitle',
  FIRST_PUBLISHED_AT_UTC: 'firstPublishedAtUtc',
  STATUS: 'status',
}

export const COLLECTION_SORT_FIELDS = {
  POSITION_INDEX_WEIGHT: 'positionIndexWeight',
  PRICE: 'price',
  FIRST_PUBLISHED_AT_UTC: 'firstPublishedAtUtc',
}

export const PURCHASES_SORT_FIELDS = {
  ID: 'id',
  TOTAL_PRICE: 'totalPrice',
  PLATFORM_COMMISSION: 'platformCommissionAmount',
  BUYER_EMAIL: 'buyerEmail',
  EXPERT_EMAIL: 'email',
}

export const ORDERS_SORT_FIELDS = {
  ID: 'id',
  FLX_ID: 'flxId',
  CREATED_AT_UTC: 'createdAtUtc',
  TOTAL_PRICE: 'totalPrice',
  BUYER_EMAIL: 'buyerEmail',
}

export const SORT_DIRECTION_ENUM = {
  ASC: 'ASC',
  DESC: 'DESC',
}

export const EXPERT_STATUSES = {
  ACTIVE: 'ACTIVE',
  SUSPENDED: 'SUSPENDED',
}

export const CLIENT_STATUSES = {
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE',
  SUSPENDED: 'SUSPENDED',
}

export const SESSION_STATUSES = {
  PENDING: 'PENDING',
  FINISHED: 'FINISHED',
  CANCELLED: 'CANCELLED',
  ON_HOLD: 'ON_HOLD',
}

export const SESSION_CLIENT_STATUSES = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  CANCELLED: 'CANCELLED',
}

export const SESSION_CLIENT_PAYMENT_STATUSES = {
  PENDING: 'PENDING',
  ON_HOLD: 'ON_HOLD',
  PAID: 'PAID',
  CANCELLED: 'CANCELLED',
}

export const SESSION_LOG_EVENT_TYPES = {
  JOINED: 'JOINED',
  LEFT: 'LEFT',
}

export const PURCHASE_STATUSES = {
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  PAID: 'PAID',
  PROCESSING: 'PROCESSING',
  IN_PRODUCTION: 'IN_PRODUCTION',
  SHIPPED: 'SHIPPED',
  DELIVERED: 'DELIVERED',
  REFUNDED: 'REFUNDED',
  VOID: 'VOID',
}

export const PAYMENT_STATUS = {
  PENDING: 'PENDING',
  ON_HOLD: 'ON_HOLD',
  PAID: 'PAID',
  CANCELLED: 'CANCELLED',
}
