import PropTypes from 'prop-types'
import React from 'react'

export const Info = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.666504 9.00008C0.666504 4.39771 4.39746 0.666748 8.99984 0.666748C11.21 0.666748 13.3296 1.54472 14.8924 3.10752C16.4552 4.67033 17.3332 6.78994 17.3332 9.00008C17.3332 13.6025 13.6022 17.3334 8.99984 17.3334C4.39746 17.3334 0.666504 13.6025 0.666504 9.00008ZM2.33317 9.00008C2.33317 12.682 5.31794 15.6667 8.99984 15.6667C10.7679 15.6667 12.4636 14.9644 13.7139 13.7141C14.9641 12.4639 15.6665 10.7682 15.6665 9.00008C15.6665 5.31818 12.6817 2.33342 8.99984 2.33342C5.31794 2.33342 2.33317 5.31818 2.33317 9.00008ZM9.83317 9.41675C9.83317 9.18663 9.64662 9.00008 9.4165 9.00008H8.58317C8.35305 9.00008 8.1665 9.18663 8.1665 9.41675V11.9167C8.1665 12.1469 8.35305 12.3334 8.58317 12.3334H9.4165C9.64662 12.3334 9.83317 12.1469 9.83317 11.9167V9.41675ZM9.4165 5.66675C9.64662 5.66675 9.83317 5.8533 9.83317 6.08341V6.91675C9.83317 7.14687 9.64662 7.33341 9.4165 7.33341H8.58317C8.35305 7.33341 8.1665 7.14687 8.1665 6.91675V6.08341C8.1665 5.8533 8.35305 5.66675 8.58317 5.66675H9.4165Z"
      fill={color}
    />
  </svg>
)

Info.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
