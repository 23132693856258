import { type SearchTypes, type TransformationProps } from '../types'

export interface SearchContextData {
  type: SearchTypes
  searchData: {
    searchValue: string
  }
}

type SearchTransformationProps = TransformationProps<
  SearchContextData,
  'contextData'
>

export const SearchTransformation = ({
  contextData,
}: SearchTransformationProps) => {
  const { type, searchData } = contextData

  if (!searchData) {
    throw new Error('Search data are not provided')
  }

  return {
    type,
    search_term: searchData.searchValue,
    results: null, // TODO: Add results when fulltext type will be implemented
  }
}
