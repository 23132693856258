import PropTypes from 'prop-types'
import React from 'react'

export const Bookmark = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2H18C19.1046 2 20 2.89543 20 4V21C20 21.5523 19.5523 22 19 22C18.8128 22.0006 18.6291 21.9486 18.47 21.85L12.53 18.13C12.2057 17.9273 11.7943 17.9273 11.47 18.13L5.53 21.85C5.37086 21.9486 5.18723 22.0006 5 22C4.44771 22 4 21.5523 4 21V4C4 2.89543 4.89543 2 6 2ZM18 19.19V4H6V19.19L10.41 16.44C11.3828 15.832 12.6172 15.832 13.59 16.44L18 19.19Z"
      fill={color}
    />
  </svg>
)

Bookmark.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
