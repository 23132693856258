import { type PublicExpertSession } from 'types/graphql-generated'
import { type TransformationProps } from '../types'
import { prefixConsultation } from '../utils'

interface ViewCalendarOrSelectDateConsultationsTransformationContextData {
  session: PublicExpertSession
}

type ViewCalendarOrSelectDateConsultationsTransformationProps =
  TransformationProps<
    ViewCalendarOrSelectDateConsultationsTransformationContextData,
    'contextData'
  >

export const ViewCalendarOrSelectDateConsultations = ({
  contextData: { session },
}: ViewCalendarOrSelectDateConsultationsTransformationProps) => {
  if (!session?.expertUser) {
    throw new Error('Session or expert user is not provided')
  }

  const { id, price, sessionType, lengthInMinutes, expertUser } = session
  return {
    currency: price.currencyIsoCode,
    consultation_id: prefixConsultation(id),
    consultation_name: `${expertUser.firstName} ${expertUser.lastName}`,
    consultation_variant: sessionType,
    consultation_lenght: lengthInMinutes,
    value: price.total,
  }
}
