import { GA4_PARAMS, type TransformationProps, type AreaTypes } from '../types'
import { findPropertyInTree } from '../utils'

interface RequestAvailabilityTransformationContextData {
  area: AreaTypes
}

type RequestAvailabilityTransformationProps = TransformationProps<
  RequestAvailabilityTransformationContextData,
  'contextData' | 'parent'
>

export const RequestAvailabilityTransformation = ({
  contextData,
  parent,
}: RequestAvailabilityTransformationProps) => ({
  area: findPropertyInTree({
    keyName: GA4_PARAMS.AREA,
    contextData,
    parent,
  }),
})
