import PropTypes from 'prop-types'
import React from 'react'

export const Website = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 4H4C2.89543 4 2 4.89543 2 6V18C2 19.1046 2.89543 20 4 20H20C21.1046 20 22 19.1046 22 18V6C22 4.89543 21.1046 4 20 4ZM20 18H4V8H20V18ZM10.5 16H6.5C6.22386 16 6 15.7761 6 15.5V10.5C6 10.2239 6.22386 10 6.5 10H10.5C10.7761 10 11 10.2239 11 10.5V15.5C11 15.7761 10.7761 16 10.5 16ZM13.5 12H17.5C17.7761 12 18 11.7761 18 11.5V10.5C18 10.2239 17.7761 10 17.5 10H13.5C13.2239 10 13 10.2239 13 10.5V11.5C13 11.7761 13.2239 12 13.5 12ZM15.5 16H13.5C13.2239 16 13 15.7761 13 15.5V14.5C13 14.2239 13.2239 14 13.5 14H15.5C15.7761 14 16 14.2239 16 14.5V15.5C16 15.7761 15.7761 16 15.5 16Z"
      fill={color}
    />
  </svg>
)

Website.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
