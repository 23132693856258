import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const Conversation: React.FC<React.PWC<IconProps>> = ({
  color,
  size,
}) => (
  <svg
    fill="none"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
  >
    <path
      d="M42 3.6H25c-1 0-1.8.4-2.4 1-.7.7-1 1.5-1 2.4l-.1 17 2.5-1.8a4 4 0 0 1 2.3-.7H42c1 0 1.9-.5 2.5-1.1.6-.7 1-1.5 1-2.4V7c0-.9-.4-1.7-1-2.4-.6-.6-1.5-1-2.4-1z"
      stroke={color}
      strokeLinecap="round"
    />
    <path
      d="M32 32.5V38l-4.9-4a4 4 0 0 0-2.5-1H7a4 4 0 0 1-4-4V17a4 4 0 0 1 4-4h21a4 4 0 0 1 4 4v12"
      stroke={color}
      strokeLinecap="round"
      strokeWidth="1.5"
    />
  </svg>
)
