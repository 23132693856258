import { setWith } from 'lodash/fp'
import { createStateContext } from 'react-use'

export const [useSharedOptions, SharedOptionsProvider] = createStateContext({})

export const setInputToCorrectPosition = ({
  cmsPageSlug,
  widgetId,
  input,
  oldWidgetsInput,
}) =>
  setWith(Object, ['cmsInputs', cmsPageSlug, widgetId], input, oldWidgetsInput)
