import styled, { css } from 'styled-components'
import { COLOR, SPACE } from 'Theme'
import { Input } from './Input'
import { InputWrapper } from './InputWrapper'

export const SharpInput = styled(Input)`
  ${InputWrapper} {
    height: auto;
    padding: ${SPACE.PX_15} ${SPACE.PX_20};
    border-radius: 0;

    ${({ isFocused, isError, isWarning, isDisabled }) =>
      !isFocused &&
      !isError &&
      !isWarning &&
      !isDisabled &&
      css`
        border-color: ${COLOR.WHITE};
        &:hover {
          border-color: ${COLOR.GRAYSCALE_5};
        }
      `}
  }
`
