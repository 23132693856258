import PropTypes from 'prop-types'
import React from 'react'

export const SendOutlined = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.9674 3.94904L14.9783 20.6706C14.9068 20.8682 14.7193 20.9999 14.5091 21H14.3494C14.1534 21 13.9748 20.8874 13.8903 20.7105L11.5345 15.719C11.1876 14.9831 11.3219 14.1103 11.8739 13.5128L14.8685 10.2383C15.0523 10.0455 15.0523 9.74232 14.8685 9.5495L14.4892 9.17015C14.2964 8.98633 13.9932 8.98633 13.8004 9.17015L10.5264 12.165C9.92884 12.7171 9.05615 12.8514 8.32035 12.5045L3.3294 10.1485C3.13693 10.076 3.00697 9.89483 2.99999 9.68926V9.52953C2.98232 9.30492 3.11729 9.09631 3.3294 9.0204L20.0491 3.0306C20.2288 2.96459 20.4305 3.00726 20.5682 3.14042L20.8277 3.39997C20.9837 3.53626 21.0393 3.75475 20.9674 3.94904Z"
      fill={color}
    />
  </svg>
)

SendOutlined.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
