import { useCallback, useEffect } from 'react'
import { GA4_EVENTS, useGoogleAnalytics } from 'providers/googleAnalytics'

interface UseValidationErrorTrackingForGA4Provider {
  hint: string
  isError: boolean
}

export const useValidationErrorTrackingForGA4Provider = ({
  hint,
  isError,
}: UseValidationErrorTrackingForGA4Provider) => {
  const { executeDataToDataLayer } = useGoogleAnalytics()

  const trackErrorMessage = useCallback(
    (error: string) => {
      executeDataToDataLayer({
        event: GA4_EVENTS.ERROR_MESSAGE,
        overrideData: {
          text: error,
        },
      })
    },
    [executeDataToDataLayer]
  )

  useEffect(() => {
    if (!hint?.length || !isError) {
      return
    }

    trackErrorMessage(hint)
  }, [hint, isError, trackErrorMessage])
}
