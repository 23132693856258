import { useMutation, gql } from '@apollo/client'
import { useCallback } from 'react'
import { Toast } from 'components/Toast'
import { formatValuesToInput } from '../utils'

const SESSION_REPORT_FIELDS = gql`
  fragment SessionReportFields on SessionReport {
    id
    note
    filesAndNotes {
      edges {
        node {
          id
          note
          attachment {
            id
            name
            url
            alt
          }
        }
      }
    }
    tradeOnlyItems {
      id
      attachment {
        id
        name
        url
        alt
      }
      publisher
      url
      title
    }
    directToConsumerItems {
      id
      attachment {
        id
        name
        url
        alt
      }
      publisher
      url
      title
    }
  }
`

const CREATE_SESSION_REPORT_MUTATION = gql`
  ${SESSION_REPORT_FIELDS}
  mutation createSessionReport($input: SessionReportInput!) {
    createSessionReport(input: $input) {
      ...SessionReportFields
    }
  }
`

const UPDATE_SESSION_REPORT_MUTATION = gql`
  ${SESSION_REPORT_FIELDS}
  mutation updateSessionReport($input: SessionReportInput!) {
    updateSessionReport(input: $input) {
      ...SessionReportFields
    }
  }
`

export const useSessionReports = ({
  getFiles,
  setFiles,
  report,
  selectedSessionClient,
  onModalClose,
}) => {
  const [
    createSessionReportMutation,
    { loading: isCreateSessionReportMutationLoading },
  ] = useMutation(CREATE_SESSION_REPORT_MUTATION)
  const [
    updateSessionReportMutation,
    { loading: isUpdateSessionReportMutationLoading },
  ] = useMutation(UPDATE_SESSION_REPORT_MUTATION)

  const submitSessionReport = useCallback(
    async ({
      note: noteObject,
      tradeOnlyItems,
      directToConsumerItems,
      isWithEmailNotification,
    }) => {
      let response
      const formattedInput = formatValuesToInput({
        filesAndNotes: getFiles(),
        noteObject,
        tradeOnlyItems,
        directToConsumerItems,
        selectedSessionClient,
        isWithEmailNotification,
      })
      if (report) {
        response = await updateSessionReportMutation({
          variables: {
            input: {
              id: report.id,
              ...formattedInput,
            },
          },
        })
      } else {
        response = await createSessionReportMutation({
          variables: {
            input: formattedInput,
          },
        })
      }
      if (!response.errors) {
        Toast.success(
          `Consultation recap has been successfully ${
            isWithEmailNotification ? 'sent' : 'saved'
          }`
        )
        onModalClose()
        setFiles([])
      }
    },
    [
      createSessionReportMutation,
      getFiles,
      onModalClose,
      report,
      selectedSessionClient,
      setFiles,
      updateSessionReportMutation,
    ]
  )

  return {
    submitSessionReport,
    isLoading:
      isCreateSessionReportMutationLoading ||
      isUpdateSessionReportMutationLoading,
  }
}
