import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const Move: React.FC<IconProps> = ({ color, size, fill }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 9L21 12M21 12L18 15M21 12H15M6 9L3 12M3 12L6 15M3 12H9M9 18L12 21M12 21L15 18M12 21V15M15 6L12 3M12 3L9 6M12 3V9"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
