import PropTypes from 'prop-types'
import React from 'react'

export const Smile = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20ZM15.88 13.17C15.9777 13.2788 16.0217 13.4254 16 13.57H15.96C15.6791 15.5412 13.9911 17.0057 12 17.0057C10.0089 17.0057 8.32089 15.5412 8.04 13.57C8.01834 13.4254 8.06234 13.2788 8.16 13.17C8.2551 13.0597 8.39436 12.9974 8.54 13H15.5C15.6456 12.9974 15.7849 13.0597 15.88 13.17ZM15 8C13.8954 8 13 8.89543 13 10V10.5C13 10.7761 13.2239 11 13.5 11H16.5C16.7761 11 17 10.7761 17 10.5V10C17 8.89543 16.1046 8 15 8ZM11 10V10.5C11 10.7761 10.7761 11 10.5 11H7.5C7.22386 11 7 10.7761 7 10.5V10C7 8.89543 7.89543 8 9 8C10.1046 8 11 8.89543 11 10Z"
      fill={color}
    />
  </svg>
)

Smile.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
