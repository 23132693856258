import { CURRENCY_ISO_CODE } from 'constants/common'
import { validateCheckoutResponse } from 'providers/googleAnalytics/utils'
import { prefixProcurement } from 'providers/googleAnalytics/utils/prefixes'
import {
  GA4_AREAS,
  GA4_ITEM_CATEGORY,
  GA4_ITEM_NAME,
  GA4_SHIPPING_TIERS,
  GA4_SHIPPING_TYPES,
  type PurchaseTransformationProps,
} from '../../types'

export const ProcurementTransformation = ({
  contextData,
}: PurchaseTransformationProps) => {
  const { checkoutResponse, paymentMethod } = contextData

  validateCheckoutResponse(checkoutResponse, 'procurement purchase')

  const { id: checkoutId, checkoutItems } = checkoutResponse.data
  const purchase = checkoutItems?.edges?.[0]?.node?.purchase
  if (!purchase) {
    throw new Error(
      'Can not resolve purchase from checkoutItems in procurement purchase'
    )
  }

  const { id, retailPrice, tax, shippingPrice, totalPrice } = purchase
  const currencyIsoCode = totalPrice?.currencyIsoCode || CURRENCY_ISO_CODE.USD
  return {
    ecommerce: {
      area: GA4_AREAS.PROCUREMENT,
      transaction_id: prefixProcurement(checkoutId),
      affiliation: null,
      value: retailPrice.base,
      tax: tax.base,
      shipping: shippingPrice.base,
      currency: currencyIsoCode,
      payment: totalPrice.base,
      payment_type: paymentMethod,

      // TODO: implement dynamic value for type && tier when custom purchase will be reworked
      shipping_type: GA4_SHIPPING_TYPES.VENDOR,
      shipping_tier: GA4_SHIPPING_TIERS.STANDARD_SHIPPING,

      // TODO: edit when custom purchase will be reworked
      items: [
        {
          index: 0,
          item_id: prefixProcurement(id),
          item_name: GA4_ITEM_NAME.PROCUREMENT,
          item_variant: null,
          item_tax: tax.base,
          item_brand: null,
          item_category: GA4_ITEM_CATEGORY.PROCUREMENT,
          item_list_id: null,
          item_list_name: null, // TODO: Resolve when procurements are done
          price: retailPrice.base,
          quantity: 1,
          currency: currencyIsoCode,
        },
      ],
    },
  }
}
