import PropTypes from 'prop-types'
import React from 'react'

export const Group = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00202 4.99997C6.34471 4.99997 5.00119 6.34312 5.00119 7.99997C5.00119 9.65683 6.34471 11 8.00202 11C9.65933 11 11.0028 9.65683 11.0028 7.99997C11.0028 6.34312 9.65933 4.99997 8.00202 4.99997ZM19.0049 7.99997C19.0049 9.65683 17.6614 11 16.0041 11C14.3468 11 13.0033 9.65683 13.0033 7.99997C13.0033 6.34312 14.3468 4.99997 16.0041 4.99997C17.6614 4.99997 19.0049 6.34312 19.0049 7.99997ZM22.8962 17.5499L21.4558 14.6599C20.9485 13.644 19.9109 13.0015 18.7751 12.9999H14.774C14.957 13.238 15.1178 13.4924 15.2541 13.7599L16.7045 16.6599C17.0634 17.39 17.1104 18.2346 16.8346 18.9999H22.006C22.3527 18.9989 22.6741 18.8184 22.8555 18.5231C23.0368 18.2277 23.0522 17.8595 22.8962 17.5499ZM10.7627 12.9999C11.9021 12.9977 12.9445 13.6408 13.4535 14.6599L14.8938 17.5499C15.0499 17.8595 15.0344 18.2277 14.8531 18.5231C14.6717 18.8184 14.3503 18.9989 14.0036 18.9999H2.0003C1.65361 18.9989 1.33219 18.8184 1.15083 18.5231C0.969472 18.2277 0.954044 17.8595 1.11006 17.5499L2.55045 14.6599C3.05939 13.6408 4.10178 12.9977 5.24119 12.9999H10.7627Z"
      fill={color}
    />
  </svg>
)

Group.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
