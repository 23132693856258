import { find, get } from 'lodash'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { useUserAuth } from 'providers/userAuth'
import {
  CartDocument,
  useCartQuery,
  useUpdateCartShipmentAddressMutation,
} from 'types/graphql-generated'
import {
  useSetItemToCartMutation,
  useRemoveItemFromCartMutation,
} from './hooks'
import { type CartContextInterface } from './types'

const CartContext = createContext<CartContextInterface | null>(null)

export const useCart = () => useContext(CartContext)
export const CartProvider = ({ children }) => {
  const { isPageLoading, user } = useUserAuth()
  const {
    data: { cart } = {},
    loading: isLoading,
    refetch: refetchCart,
    error: cartQueryError,
  } = useCartQuery({ skip: isPageLoading, fetchPolicy: 'cache-first' })
  const isCartLoading = isLoading || isPageLoading

  const [executeSetItemToCartMutation, { loading: isSetItemToCartLoading }] =
    useSetItemToCartMutation()
  const [executeRemoveItemFromCartMutation] = useRemoveItemFromCartMutation()
  const [executeUpdateCartShipmentAddressMutation] =
    useUpdateCartShipmentAddressMutation()

  // Refetch cart when user changes
  // Note: We cannot use firebase onAuthStateChanged because we are doing more checks in the userAuth provider
  useEffect(() => {
    refetchCart()
  }, [refetchCart, user])

  const setItem = useCallback(
    ({ productVariantId, quantity, expertSlug, type, itemListData }) =>
      executeSetItemToCartMutation({
        variables: {
          input: {
            productVariantId,
            quantity,
            expertSlug,
            type,
            requestedAtUtc: new Date().toISOString(),
            ...(itemListData && {
              metadata: {
                ga4: {
                  listIndex: itemListData.index || null,
                  listName: itemListData.itemListName,
                },
              },
            }),
          },
        },
        refetchQueries: [CartDocument],
      }),
    [executeSetItemToCartMutation]
  )

  const removeItem = useCallback(
    (cartItemId) =>
      executeRemoveItemFromCartMutation({
        variables: {
          input: {
            cartItemId,
          },
        },
        refetchQueries: [CartDocument],
      }),
    [executeRemoveItemFromCartMutation]
  )

  const updateCartShipmentAddress = useCallback(
    (input) =>
      executeUpdateCartShipmentAddressMutation({
        variables: {
          input,
        },
      }),
    [executeUpdateCartShipmentAddressMutation]
  )

  const getCartItemByProductVariantId = useCallback(
    (productVariantId) => {
      const items = get(cart, 'items', [])
      if (items.length) {
        const cartItem = find(
          items,
          (item) => item?.productVariant?.id === productVariantId
        )
        if (cartItem) {
          return cartItem
        }
      }
      return { cartQuantity: 0 }
    },
    [cart]
  )

  const contextValues = useMemo(
    () => ({
      cart,
      updateCartShipmentAddress,
      refetchCart,
      isCartLoading,
      cartQueryError,
      setItem,
      isSetItemToCartLoading,
      removeItem,
      getCartItemByProductVariantId,
    }),
    [
      cart,
      updateCartShipmentAddress,
      refetchCart,
      isCartLoading,
      cartQueryError,
      setItem,
      isSetItemToCartLoading,
      removeItem,
      getCartItemByProductVariantId,
    ]
  )

  return (
    <CartContext.Provider value={contextValues}>
      {children}
    </CartContext.Provider>
  )
}
