import PropTypes from 'prop-types'
import React from 'react'

export const CalendarPlus = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 6C21 4.89543 20.1046 4 19 4H18V2.5C18 2.22386 17.7761 2 17.5 2H16.5C16.2239 2 16 2.22386 16 2.5V4H8V2.5C8 2.22386 7.77614 2 7.5 2H6.5C6.22386 2 6 2.22386 6 2.5V4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V6ZM19 19H5V8H19V19ZM15 12.62V13.38C15 13.6561 14.7761 13.88 14.5 13.88H12.88V15.5C12.88 15.7761 12.6561 16 12.38 16H11.62C11.3439 16 11.12 15.7761 11.12 15.5V13.88H9.5C9.22386 13.88 9 13.6561 9 13.38V12.62C9 12.3439 9.22386 12.12 9.5 12.12H11.12V10.5C11.12 10.2239 11.3439 10 11.62 10H12.38C12.6561 10 12.88 10.2239 12.88 10.5V12.12H14.5C14.7761 12.12 15 12.3439 15 12.62Z"
      fill={color}
    />
  </svg>
)

CalendarPlus.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
