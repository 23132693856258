import {
  multiFactor,
  FacebookAuthProvider,
  GoogleAuthProvider,
  EmailAuthProvider,
} from 'firebase/auth'
import { EXPERT_USER_CAPABILITIES } from 'shared-constants'
import { type User } from 'types/graphql-generated'

export const USER_TYPE = {
  CLIENT: 'client',
  EXPERT: 'expert',
  ADMIN: 'admin',
  NONE: 'none',
} as const

export type UserTypes = (typeof USER_TYPE)[keyof typeof USER_TYPE]

export const AUTHENTICATION_SCREEN_TYPE = {
  SIGN_IN: 'sign-in',
  SIGN_UP: 'sign-up',
}

const getIsMFAEnrolled = (firebaseUser) =>
  multiFactor(firebaseUser)?.enrolledFactors?.length

export const getIsMFAEnrollRequired = (firebaseUser, userType) =>
  userType === USER_TYPE.ADMIN && !getIsMFAEnrolled(firebaseUser)

export const getSignedInUserType = ({ isClient, isExpertUser, isAdmin }) => {
  if (isClient) {
    return USER_TYPE.CLIENT
  }
  if (isExpertUser) {
    return USER_TYPE.EXPERT
  }
  if (isAdmin) {
    return USER_TYPE.ADMIN
  }
  return USER_TYPE.NONE
}

export const formatUserMe = (
  user: User
): User & { isTradeCapable: boolean; isConsultationsCapable: boolean } => {
  let formattedUser = {
    ...user,
    isTradeCapable: false,
    isConsultationsCapable: false,
  }
  if (user.__typename === 'ExpertUser') {
    if (user.capabilities) {
      formattedUser = {
        ...formattedUser,
        isTradeCapable: user.capabilities.includes(
          EXPERT_USER_CAPABILITIES.TRADE
        ),
        isConsultationsCapable: user.capabilities.includes(
          EXPERT_USER_CAPABILITIES.CONSULTATION
        ),
      }
    }
  }

  return formattedUser
}

export const PROVIDER_METHODS = {
  FACEBOOK: FacebookAuthProvider,
  GOOGLE: GoogleAuthProvider,
  EMAIL: EmailAuthProvider,
}

export const SIGN_UP_METHOD = {
  FACEBOOK: PROVIDER_METHODS.FACEBOOK.PROVIDER_ID,
  GOOGLE: PROVIDER_METHODS.GOOGLE.PROVIDER_ID,
  PASSWORD: PROVIDER_METHODS.EMAIL.PROVIDER_ID,
}
