import { type CartContextItem } from 'providers/cart/types'
import { type ProductVariant } from 'types/graphql-generated'
import { GA4_AREAS, type TransformationProps } from '../types'
import { mapProductVariantToItem } from '../utils'

export interface CartOperationTransformationContextData {
  cartItem: CartContextItem
  quantity: number
  itemListName?: string
}

type CartOperationTransformationProps = TransformationProps<
  CartOperationTransformationContextData,
  'contextData'
>

export const CartItemOperationTransformation = ({
  contextData,
}: CartOperationTransformationProps) => {
  const { cartItem, quantity, itemListName } = contextData

  if (!cartItem || !cartItem.productVariant) {
    throw new Error(
      'Cart item or product variant is not provided in cart item operation'
    )
  }

  const { cartId, productVariant } = cartItem
  return {
    ecommerce: {
      area: GA4_AREAS.SHOWROOM,
      cart_id: cartId,
      items: [
        {
          ...mapProductVariantToItem(productVariant as ProductVariant),
          ...(itemListName && {
            item_list_name: itemListName,
          }),
          quantity,
        },
      ],
    },
  }
}
