import styled from 'styled-components'
import { space, layout, type SpaceProps, type LayoutProps } from 'styled-system'
import { BORDER_WIDTH, COLOR_INTENT } from 'Theme'

interface DelimiterProps extends SpaceProps, LayoutProps {
  borderWidth?: (typeof BORDER_WIDTH)[keyof typeof BORDER_WIDTH]
  color?: (typeof COLOR_INTENT)[keyof typeof COLOR_INTENT]
}

export const Delimiter: React.FC<DelimiterProps> = styled('hr')`
  border: 0;
  width: '100%';
  border-bottom: ${({
    borderWidth = BORDER_WIDTH.PX_1,
    color = COLOR_INTENT.DELIMITER,
  }) => `${borderWidth} solid ${color}`};
  margin: 0;
  ${space};
  ${layout};
`
