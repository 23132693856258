import { format } from 'date-fns'
import React from 'react'
import { DATA_TEST_CLASS, DATA_TEST_ID } from 'shared-constants/build/testIds'
import { Button, BUTTON_VARIANT, BUTTON_SIZE } from 'components/Button'
import Icon from 'components/Icon'
import { Box, Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import { MONTH_WITH_YEAR } from 'constants/datetime'
import { FONT_WEIGHT, SPACE } from 'Theme'

interface MonthHeaderProps {
  date?: Date
  decreaseMonth: () => void
  increaseMonth: () => void
  prevMonthButtonDisabled?: boolean
  nextMonthButtonDisabled?: boolean
}

export const MonthHeader: React.FC<MonthHeaderProps> = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => (
  <Flex
    justifyContent="space-between"
    alignItems="center"
    margin={`${SPACE.PX_5} ${SPACE.PX_15}`}
  >
    <Box>
      <Button
        icon={Icon.ArrowLeft}
        variant={BUTTON_VARIANT.MINIMAL}
        size={BUTTON_SIZE.S}
        onClick={decreaseMonth}
        isDisabled={prevMonthButtonDisabled}
      />
    </Box>
    <Text
      data-test-id={DATA_TEST_ID.DATEPICKER_HEADER_MONTH}
      fontWeight={FONT_WEIGHT.SEMI_BOLD}
    >
      {format(date, MONTH_WITH_YEAR)}
    </Text>
    <Box>
      <Button
        icon={Icon.ArrowRight}
        variant={BUTTON_VARIANT.MINIMAL}
        size={BUTTON_SIZE.S}
        onClick={increaseMonth}
        isDisabled={nextMonthButtonDisabled}
        data-test-class={DATA_TEST_CLASS.DATE_PICKER_NEXT_MONTH_BTN}
      />
    </Box>
  </Flex>
)
