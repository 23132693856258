import { TEXT_EDITOR_FORMAT } from 'constants/common'

const EMPTY_ARRAY = []

const mapExternalProductPreviewsToSessionReportItems = (items) =>
  items.map(({ id, url, title, note, publisher, attachment }) => ({
    id,
    url,
    title,
    note,
    publisher,
    attachmentId: attachment ? attachment.id : null,
  }))

export const formatValuesToInput = ({
  filesAndNotes,
  noteObject,
  tradeOnlyItems,
  directToConsumerItems,
  selectedSessionClient,
  isWithEmailNotification,
}) => ({
  sessionClientId: selectedSessionClient.id,
  note:
    typeof noteObject === 'string'
      ? noteObject
      : noteObject.toString(TEXT_EDITOR_FORMAT.MARKDOWN),
  filesAndNotes: filesAndNotes
    .filter(({ isError }) => !isError)
    .map(({ id, attachmentId, note }) => ({
      id,
      note: note ?? '',
      attachmentId,
    })),
  tradeOnlyItems:
    mapExternalProductPreviewsToSessionReportItems(tradeOnlyItems),
  directToConsumerItems: mapExternalProductPreviewsToSessionReportItems(
    directToConsumerItems
  ),
  isWithEmailNotification,
})

// We need to disable this eslint rule for adding new blank lines, which isn't possible to do without whitespaces
/* eslint-disable no-irregular-whitespace */
const getRecapNote = ({
  clientFirstName,
  expertName,
}) => `Dear ${clientFirstName},


​

We hope you enjoyed your consultation! Please see below for a recap of our call, including shopping suggestions and relevant files.


​

Thank you,

${expertName}`
/* eslint-enable no-irregular-whitespace */

export const formatDbFilesToForm = (filesAndNotes) =>
  filesAndNotes?.map(
    ({ attachment: { id: attachmentId, name, url }, id, note }) => ({
      attachmentId,
      id,
      name,
      url,
      note,
    })
  ) ?? EMPTY_ARRAY

export const formatInitialValues = ({
  report,
  clientFirstName,
  expertName,
  ...rest
}) => ({
  note: report?.note ?? getRecapNote({ clientFirstName, expertName }),
  tradeOnlyItems: report?.tradeOnlyItems ?? EMPTY_ARRAY,
  directToConsumerItems: report?.directToConsumerItems ?? EMPTY_ARRAY,
  ...rest,
})

export const formatFilename = (
  filename,
  { maxLength = 15, splitAfter = 10, keepLast = 5 } = {}
) => {
  if (filename.length > maxLength) {
    return `${filename.substr(0, splitAfter)}...${filename.substr(
      keepLast * -1
    )}`
  }

  return filename
}
