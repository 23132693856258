import {
  type FilterEventProps,
  GA4_PARAMS,
  type TransformationProps,
} from '../types'
import { findPropertyInTree } from '../utils'

type FilterTransformationProps = TransformationProps<
  FilterEventProps,
  'contextData' | 'parent'
>

export const FilterTransformation = ({
  contextData,
  parent,
}: FilterTransformationProps) => {
  const { type, action } = contextData

  return {
    area: findPropertyInTree({
      keyName: GA4_PARAMS.AREA,
      contextData,
      parent,
    }),
    type,
    action,
  }
}
