import type { TransformationProps } from '../types'
import {
  type PromotionItem,
  formatPromotionItem,
} from '../utils/Promotion/utils'

interface ViewPromotionTransformationContextData {
  items: PromotionItem[]
}

type ViewPromotionTransformationProps = TransformationProps<
  ViewPromotionTransformationContextData,
  'contextData'
>

export const ViewPromotionTransformation = ({
  contextData,
}: ViewPromotionTransformationProps) => {
  const { items } = contextData ?? {}

  if (!items?.length) {
    throw new Error('Items not provided in view promotion transformation')
  }

  return {
    ecommerce: {
      items: items.map(formatPromotionItem),
    },
  }
}
