import { type CartFieldsFragment } from 'types/graphql-generated'
import { GA4_AREAS, type TransformationProps } from '../types'
import { mapItemToInput } from '../utils'

interface ViewCartTransformationContextData {
  cart?: CartFieldsFragment
}

type ViewCartTransformationProps = TransformationProps<
  ViewCartTransformationContextData,
  'contextData'
>

export const ViewCartTransformation = ({
  contextData,
}: ViewCartTransformationProps) => {
  const { cart } = contextData

  if (!cart?.items?.length) {
    return {
      ecommerce: {
        area: GA4_AREAS.SHOWROOM,
        items: [],
      },
    }
  }

  const { id, summary, items } = cart
  return {
    ecommerce: {
      area: GA4_AREAS.SHOWROOM,
      cart_id: id,
      currency: summary.totalPrice.currencyIsoCode,
      value: summary.totalPrice.base,
      items: items.map(mapItemToInput()),
    },
  }
}
