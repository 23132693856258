import PropTypes from 'prop-types'
import React from 'react'

export const Time = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9999 1.99997C6.47703 1.99997 1.99988 6.47712 1.99988 12C1.99988 17.5228 6.47703 22 11.9999 22C17.5227 22 21.9999 17.5228 21.9999 12C21.9999 9.3478 20.9463 6.80427 19.0709 4.9289C17.1956 3.05354 14.652 1.99997 11.9999 1.99997ZM11.9997 19.9999C7.58147 19.9999 3.99975 16.4182 3.99975 11.9999C3.99975 7.58166 7.58147 3.99994 11.9997 3.99994C16.418 3.99994 19.9997 7.58166 19.9997 11.9999C19.9997 14.1217 19.1569 16.1565 17.6566 17.6568C16.1563 19.1571 14.1215 19.9999 11.9997 19.9999ZM12.8798 6.5V11.64L16.5098 15.27C16.7016 15.4678 16.7016 15.7822 16.5098 15.98L15.9798 16.51C15.782 16.7018 15.4676 16.7018 15.2698 16.51L11.2698 12.51C11.1781 12.4159 11.1247 12.2912 11.1198 12.16V6.5C11.1198 6.22386 11.3436 6 11.6198 6H12.3798C12.6559 6 12.8798 6.22386 12.8798 6.5Z"
      fill={color}
    />
  </svg>
)

Time.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
