import React from 'react'

export const Copy = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2.75"
      y="2.75"
      width="12.5"
      height="12.5"
      rx="1.25"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M18 6V16C18 17.1046 17.1046 18 16 18H6"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
