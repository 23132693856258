import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const LightBulb: React.FC<React.PWC<IconProps>> = ({ color, size }) => (
  <svg
    fill="none"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
  >
    <path d="M19 35v1c0 2.7 2.5 5 5 5s5-2.3 5-5v-1H19z" stroke={color} />
    <path
      d="M29.4 32a8 8 0 0 1 1.8-2.6l1.3-1.3A11.7 11.7 0 0 0 35 15.3 11.8 11.8 0 0 0 24 8a12.2 12.2 0 0 0-11 7.3A11.6 11.6 0 0 0 15.4 28l1.3 1.3a8 8 0 0 1 1.6 2.3"
      stroke={color}
      strokeLinecap="round"
      strokeWidth="1.5"
    />
    <path
      d="M23.5.5v4m16 2-3 3m7 12h-3.6M8 21.5H4.5m7-12-3-3"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
