import { WEBSITE_TITLE } from 'constants/seo'

export const formatPageTitleWithDash = (title: string, subtitle?: string) =>
  `${WEBSITE_TITLE} - ${title}${subtitle ? ` - ${subtitle}` : ''}`
export const formatExpertDashboardTitle = (title: string) =>
  formatPageTitleWithDash('Expert dashboard', title)
export const formatClientDashboardTitle = (title: string) =>
  formatPageTitleWithDash('Client dashboard', title)
export const formatAdminDashboardTitle = (title: string, subtitle?: string) =>
  formatPageTitleWithDash(`Admin - ${title}`, subtitle)
export const formatProductDetailTitle = (
  title: string,
  optionValues: string[]
): string => formatPageTitleWithDash(title, optionValues.join(', '))
export const formatExpertDetailTitle = (title: string) =>
  `${title} - Book Online Consultation`
