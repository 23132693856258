import { useMutation, gql } from '@apollo/client'

const EXTERNAL_PRODUCT_PREVIEW_MUTATION = gql`
  mutation createExternalProductPreview($input: ExternalProductPreviewInput!) {
    createExternalProductPreview(input: $input) {
      url
      inputUrl
      originUrl
      title
      publisher
      imageUrl
      attachment {
        id
        url
        name
        alt
      }
    }
  }
`

export const useExternalProductPreview = (options) =>
  useMutation(EXTERNAL_PRODUCT_PREVIEW_MUTATION, options)
