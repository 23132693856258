import PropTypes from 'prop-types'
import React from 'react'

export const Pinterest = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 0C5.87188 0 0.5 5.37188 0.5 12C0.5 17.0859 3.66406 21.4266 8.12656 23.175C8.02344 22.2234 7.925 20.7703 8.16875 19.7344C8.38906 18.7969 9.575 13.7719 9.575 13.7719C9.575 13.7719 9.21406 13.0547 9.21406 11.9906C9.21406 10.3219 10.1797 9.075 11.3844 9.075C12.4062 9.075 12.9031 9.84375 12.9031 10.7672C12.9031 11.7984 12.2469 13.3359 11.9094 14.7609C11.6281 15.9562 12.5094 16.9313 13.6859 16.9313C15.8187 16.9313 17.4594 14.6812 17.4594 11.4375C17.4594 8.56406 15.3969 6.55313 12.4484 6.55313C9.03594 6.55313 7.02969 9.1125 7.02969 11.7609C7.02969 12.7922 7.42812 13.8984 7.925 14.4984C8.02344 14.6156 8.0375 14.7234 8.00937 14.8406C7.92031 15.2203 7.71406 16.0359 7.67656 16.2C7.625 16.4203 7.50313 16.4672 7.27344 16.3594C5.77344 15.6609 4.83594 13.4719 4.83594 11.7094C4.83594 7.92188 7.5875 4.44844 12.7625 4.44844C16.925 4.44844 20.1594 7.41563 20.1594 11.3813C20.1594 15.5156 17.5531 18.8438 13.9344 18.8438C12.7203 18.8438 11.5766 18.2109 11.1828 17.4656C11.1828 17.4656 10.5828 19.7578 10.4375 20.3203C10.1656 21.3609 9.43437 22.6688 8.94687 23.4656C10.0719 23.8125 11.2625 24 12.5 24C19.1281 24 24.5 18.6281 24.5 12C24.5 5.37188 19.1281 0 12.5 0Z"
      fill={color}
    />
  </svg>
)

Pinterest.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
