import { compact } from 'lodash'
import { useMedia as useMediaHook } from 'react-use'
import { DEVICE } from 'Theme'

export const BREAKPOINTS = {
  MOBILE: DEVICE.MOBILE,
  TABLET: DEVICE.TABLET,
  DESKTOP: DEVICE.DESKTOP,
  TV: DEVICE.TV,
} as const

type Entries<T> = {
  [K in keyof T]: [K, T[K]]
}[keyof T][]

const queries = (Object.entries(BREAKPOINTS) as Entries<typeof BREAKPOINTS>)
  .map<[keyof typeof BREAKPOINTS, string]>(
    ([breakpoint, width], index, array) => [
      breakpoint,
      compact([
        index !== 0 ? `(min-width: ${Number.parseInt(width)}px)` : null,
        index !== array.length - 1
          ? `(max-width: ${Number.parseInt(array[index + 1][1]) - 0.1}px)`
          : null,
      ]).join(' and '),
    ]
  )
  .reduce<Record<keyof typeof BREAKPOINTS, string>>(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value,
    }),
    {
      MOBILE: '',
      TABLET: '',
      DESKTOP: '',
      TV: '',
    }
  )

const { MOBILE, TABLET, DESKTOP, TV } = queries

export const useMedia = () => {
  const isMobile = useMediaHook(MOBILE, false)
  const isTablet = useMediaHook(TABLET, false)
  const isDesktop = useMediaHook(DESKTOP, false)
  const isTv = useMediaHook(TV, false)

  return {
    MOBILE: isMobile,
    TABLET: isTablet,
    DESKTOP: isDesktop,
    TV: isTv,
    isReady: isMobile || isTablet || isDesktop || isTv,
  }
}
