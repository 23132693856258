import PropTypes from 'prop-types'
import React from 'react'

export const Basket = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5 10H18.5L12.73 2.3C12.5864 2.11356 12.3653 2.00305 12.13 2H11.87C11.6347 2.00305 11.4136 2.11356 11.27 2.3L5.5 10H1.5C1.22386 10 1 10.2239 1 10.5V11.5C1 11.7761 1.22386 12 1.5 12H2L3.71 19.65C4.01148 21.0089 5.20813 21.982 6.6 22H17.4C18.8031 21.9959 20.016 21.0198 20.32 19.65L22 12H22.47C22.7461 12 22.97 11.7761 22.97 11.5V10.5C22.9705 10.2352 22.7644 10.0159 22.5 10ZM12 4.67L16 10H8L12 4.67ZM17.37 20C17.8467 20.0117 18.2652 19.6852 18.37 19.22L20 12H4L5.6 19.22C5.70475 19.6852 6.12334 20.0117 6.6 20H17.37Z"
      fill={color}
    />
  </svg>
)

Basket.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
