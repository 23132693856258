import PropTypes from 'prop-types'
import React from 'react'

export const Vendor = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 3.5V4.5C4 4.77614 4.22386 5 4.5 5H19.5C19.7761 5 20 4.77614 20 4.5V3.5C20 3.22386 19.7761 3 19.5 3H4.5C4.22386 3 4 3.22386 4 3.5ZM20.28 7.58L21.79 10.58C21.9278 10.8565 21.9997 11.1611 22 11.47V12C22 12.5523 21.5523 13 21 13H20V20C20 20.5523 19.5523 21 19 21H15C14.4477 21 14 20.5523 14 20V15.25C14 15.1119 13.8881 15 13.75 15H10.25C10.1119 15 10 15.1119 10 15.25V20C10 20.5523 9.55228 21 9 21H5C4.44772 21 4 20.5523 4 20V13H3C2.44771 13 2 12.5523 2 12V11.47C2.00033 11.1611 2.07221 10.8565 2.21 10.58L3.72 7.58C3.89107 7.2405 4.23984 7.02735 4.62 7.03H19.38C19.7602 7.02735 20.1089 7.2405 20.28 7.58Z"
      fill={color}
    />
  </svg>
)

Vendor.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
