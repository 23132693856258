import { truncate } from 'lodash'
import { type SCHEMA_PROPS, SCHEMA_TYPES } from 'constants/common'

/**
 * Removes all new line characters from a given string.
 * @param text - The string to remove new lines from.
 * @returns The input string without any new line characters.
 */
export const getStringWithoutNewLines = (text: string): string =>
  text.replace(/\n/g, ' ')

export type PromotionItem = {
  widgetId: string
  widgetTitle: string
  widgetName: string
  selectedItemName: string
  selectedItemOrder: number
  locationId: string
}

export const formatPromotionItem = ({
  widgetId,
  widgetTitle,
  widgetName,
  selectedItemName,
  selectedItemOrder,
  locationId,
}: PromotionItem) => ({
  promotion_id: widgetId,
  // Truncate promotion name to 90 characters
  promotion_name: `${truncate(getStringWithoutNewLines(widgetTitle), {
    length: 87 - widgetName.length, // 90 - 3 characters for the separator - widgetName length
  })} | ${getStringWithoutNewLines(widgetName)}`,
  creative_name: getStringWithoutNewLines(selectedItemName),
  creative_slot: selectedItemOrder === -1 ? '-' : selectedItemOrder.toString(),
  location_id: locationId,
})

/**
 * @description Function that returns the elements to be observed for the mutation observer
 * - If thing element contains list items:
 * > - If list item contains creative work element, return the creative work element
 * > - Otherwise, return the list item element
 * - If thing element contains creative work elements, return the creative work elements
 * - Otherwise, return the thing element
 * @returns `Element[]` - Flat array of elements to be observed
 */
export const getThingElementsFlatArray = (elements: Element[]): Element[] => {
  const elementsList = elements.map((element) => {
    const listItemElements = element.querySelectorAll(
      `[itemtype="${SCHEMA_TYPES.LIST_ITEM}"]`
    )

    if (listItemElements.length) {
      // List items can contain creative work elements
      return Array.from(listItemElements).map((listItemElement) => {
        const creativeWorkElements = listItemElement.querySelector(
          `[itemtype="${SCHEMA_TYPES.CREATIVE_WORK}"]`
        )

        if (creativeWorkElements) {
          return creativeWorkElements
        }

        return listItemElement
      })
    }

    const creativeWorkElements = element.querySelectorAll(
      `[itemtype="${SCHEMA_TYPES.CREATIVE_WORK}"]`
    )

    if (creativeWorkElements.length) {
      return Array.from(creativeWorkElements)
    }

    return element
  })

  return elementsList.flat()
}

/**
 * @description This function returns a flat map of all the elements that are within the initial passed element item scope and don't have their own item scope
 */
export const getElementItemScopeFlatMap = (
  element: Element,
  elementsSet: Set<Element> = new Set()
) => {
  const childNodes = element.childNodes as unknown as Element[]

  if (childNodes.length === 0) {
    elementsSet.add(element)
  }

  childNodes.forEach((childNode) => {
    if (childNode.nodeName === '#comment') {
      return
    }

    // In case of span, we need to push the parent element as it treats the text as a child node
    if (childNode.nodeName === '#text') {
      elementsSet.add(childNode.parentElement)
      return
    }

    // Check if the child node has its own item scope
    if (!childNode.hasAttribute('itemscope')) {
      getElementItemScopeFlatMap(childNode, elementsSet)
    }
  })

  return Array.from(elementsSet)
}

/**
 * @description Funcion that validates whether the element is an ancestor of button or link element
 */
export const validateIsElementWithinValidActionElement = (
  element: HTMLElement
): boolean => {
  if (!element || element.tagName === 'BODY') {
    return false
  }

  if (element.getAttribute('itemtype') === SCHEMA_TYPES.THING) {
    return false
  }

  if (
    (element.tagName === 'BUTTON' || element.tagName === 'A') &&
    !element.hasAttribute('data-parser-ignore')
  ) {
    return true
  }

  return validateIsElementWithinValidActionElement(element.parentElement)
}

/**
 * @description Function that returns the correct element to be used as the selected element
 * - Button/Button like {link styled as button} element can contain div elements, we need to find the correct element to be used as the one that was clicked
 */
export const getActionOrInitialElement = (
  initialElement: HTMLElement,
  iterationElement: HTMLElement = initialElement
): HTMLElement => {
  if (
    iterationElement.getAttribute('itemtype') === SCHEMA_TYPES.LIST_ITEM ||
    iterationElement.getAttribute('itemtype') === SCHEMA_TYPES.CREATIVE_WORK
  ) {
    return initialElement
  }

  if (
    iterationElement.tagName === 'BUTTON' ||
    iterationElement.tagName === 'A'
  ) {
    return iterationElement
  }

  return getActionOrInitialElement(
    initialElement,
    iterationElement.parentElement
  )
}

/**
 * @description Helper function that returns the selected element type or its inner text with selected element type prefix
 * - In case of image, it returns `Image`
 * - In case of text, it returns `Text - {textContent}`
 * - In case of link, it returns `Link - {textContent}`
 * - In case of button, it returns `Button - {textContent}`
 * - In case of other tags, it returns `Wrapper`
 */
export const getSelectedElement = (target: HTMLElement): string => {
  if (target.tagName === 'IMG') {
    return 'Image'
  }
  if (
    ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'SPAN', 'P'].includes(target.tagName)
  ) {
    return `Text - ${target.textContent}`
  }
  const element = getActionOrInitialElement(target)
  if (element.tagName === 'A') {
    return `Link - ${element.textContent}`
  }
  if (element.tagName === 'BUTTON') {
    return `Button - ${element.textContent}`
  }
  return 'Wrapper'
}

/**
 * @description Helper function that finds the element with the matching schema prop and returns its value
 * - In case of meta tag, it returns the content attribute value
 * - In case of other tags, it returns the inner text
 * - In case of no matching element, it returns '-'
 */
export const getSchemaPropValue = (
  elements: Element[],
  schemaProp: (typeof SCHEMA_PROPS.NAME)[keyof typeof SCHEMA_PROPS.NAME]
): string => {
  const matchingElement = elements.find(
    (element) => element.getAttribute('itemprop') === schemaProp
  ) as HTMLElement

  if (!matchingElement) {
    return '-'
  }

  if (matchingElement.tagName === 'META') {
    return matchingElement.getAttribute('content')
  }

  return matchingElement.innerText
}
