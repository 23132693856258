import PropTypes from 'prop-types'
import React from 'react'

export const FilledCheckmark = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.33325 7.99998C1.33325 4.31808 4.31802 1.33331 7.99992 1.33331C9.76803 1.33331 11.4637 2.03569 12.714 3.28594C13.9642 4.53618 14.6666 6.23187 14.6666 7.99998C14.6666 11.6819 11.6818 14.6667 7.99992 14.6667C4.31802 14.6667 1.33325 11.6819 1.33325 7.99998ZM7.15327 10.2333L10.8999 6.48663C11.0227 6.35786 11.0227 6.1554 10.8999 6.02663L10.5466 5.6733C10.417 5.54625 10.2096 5.54625 10.0799 5.6733L6.91994 8.8333L5.91994 7.83996C5.85945 7.77551 5.775 7.73895 5.6866 7.73895C5.59821 7.73895 5.51376 7.77551 5.45327 7.83996L5.09994 8.1933C5.03683 8.25589 5.00134 8.34108 5.00134 8.42996C5.00134 8.51884 5.03683 8.60404 5.09994 8.66663L6.6866 10.2333C6.74709 10.2977 6.83155 10.3343 6.91994 10.3343C7.00833 10.3343 7.09278 10.2977 7.15327 10.2333Z"
      fill={color}
    />
  </svg>
)

FilledCheckmark.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
