import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const PinOutlined: React.FC<React.PWC<IconProps>> = ({
  color,
  size,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 10C20 5.58172 16.4183 2 12 2C7.58172 2 4 5.58172 4 10C4 13.7448 5.83051 15.2169 7.52961 16.5833C8.65265 17.4865 9.71829 18.3434 10.16 19.78L10.67 21.32C10.813 21.7454 11.2218 22.0234 11.67 22H12.23C12.6782 22.0234 13.087 21.7454 13.23 21.32L13.74 19.78C14.2178 18.3544 15.2945 17.4996 16.4226 16.604C18.151 15.2317 20 13.7637 20 10ZM12 4C8.68629 4 6 6.68629 6 10C6 12.8 7.18 13.74 8.82 15C10.1988 15.9318 11.2981 17.2212 12 18.73C12.7053 17.2233 13.8039 15.9348 15.18 15C16.82 13.74 18 12.8 18 10C18 6.68629 15.3137 4 12 4ZM14 10C14 11.1046 13.1046 12 12 12C10.8954 12 10 11.1046 10 10C10 8.89543 10.8954 8 12 8C13.1046 8 14 8.89543 14 10Z"
      fill={color}
    />
  </svg>
)
