import Head from 'next/head'
import React from 'react'
import { environment } from 'constants/environment'
import { SEO_DESCRIPTION, WEBSITE_TITLE } from 'constants/seo'

export interface SeoProps {
  title?: string
  metaTitle?: string
  urlPath?: string
  description?: string
  publisher?: string
  author?: string
  keywords?: string[]
  image?: string
  canonicalUrl?: string
}

export const Seo = ({
  title = WEBSITE_TITLE,
  metaTitle,
  urlPath = '',
  description = SEO_DESCRIPTION,
  publisher,
  author,
  keywords,
  image = `${environment.APP_URL}/favicon.svg`,
  canonicalUrl,
}: SeoProps) => (
  <Head>
    {/* Schema.org markup for Google+ */}
    <meta itemProp="name" content={metaTitle ?? title} key="schema:title" />
    <meta
      itemProp="description"
      content={description}
      key="schema:description"
    />
    <meta itemProp="image" content={image} key="schema:image" />

    {/* Twitter Card data */}
    <meta
      name="twitter:card"
      content="summary_large_image"
      key="twitter:card"
    />
    <meta
      name="twitter:title"
      content={metaTitle ?? title}
      key="twitter:title"
    />
    <meta
      name="twitter:description"
      content={description}
      key="twitter:description"
    />
    <meta name="twitter:image:src" content={image} key="twitter:image:src" />

    {/* Open Graph data */}
    <meta property="og:title" content={metaTitle ?? title} key="og:title" />
    <meta property="og:type" content="website" key="og:type" />
    <meta
      property="og:url"
      content={`${environment.APP_URL}${urlPath}`}
      key="og:url"
    />
    <meta property="og:image" content={image} key="og:image" />
    <meta
      property="og:description"
      content={description}
      key="og:description"
    />

    <title key="title">{title}</title>
    <meta name="description" content={description} key="description" />
    {publisher && <meta name="publisher" content={publisher} key="publisher" />}
    {author && <meta name="author" content={author} key="author" />}
    {keywords && (
      <meta name="keywords" content={keywords.join(', ')} key="keywords" />
    )}

    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
      key="viewport"
    />

    {canonicalUrl && (
      <link rel="canonical" href={`${environment.APP_URL}${canonicalUrl}`} />
    )}
  </Head>
)
