import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'
import { Box } from 'components/Layout'
import { Text } from 'components/Typography'
import { COLOR_INTENT, FONT_SIZE, SPACE } from 'Theme'

const TRANSITION_DURATION = 0.2

const StyledText = styled(Text)`
  cursor: pointer;
`

const AnimationWrapper = styled(motion.div)`
  overflow: hidden;
`

interface AnimatedInputWrapperProps {
  label: string
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
}

export const AnimatedInputWrapper: React.FC<
  React.PWC<AnimatedInputWrapperProps>
> = ({ label, isVisible = false, setIsVisible, children }) => (
  <Box mb={isVisible ? 0 : SPACE.PX_15}>
    {!isVisible && (
      <StyledText
        onClick={() => {
          setIsVisible(true)
        }}
        fontSize={FONT_SIZE.PX_12}
        color={COLOR_INTENT.BRAND}
      >
        {label}
      </StyledText>
    )}
    <AnimatePresence initial={false}>
      {isVisible && (
        <AnimationWrapper
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: 'auto' },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{ duration: TRANSITION_DURATION }}
        >
          {children}
        </AnimationWrapper>
      )}
    </AnimatePresence>
  </Box>
)
