import { transparentize } from 'polished'
import { createGlobalStyle } from 'styled-components'
import {
  Z_INDEX,
  FONT_SIZE,
  BOX_SHADOW,
  COLOR,
  FONT_WEIGHT,
  SPACE,
  GRADIENT,
} from 'Theme'

export const DatePickerGlobalStyles = createGlobalStyle`
   .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: ${Z_INDEX.DATEPICKER};
  }

  .react-datepicker__header {
    background: none;
  }

  .react-datepicker__portal {
    .react-datepicker {
      .react-datepicker__header {
        margin-top: 1em;
      }

      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        margin: 0;
        width: 54px;
        line-height: 50px;
        padding: 0 2px;
      }
    }
  }

  .react-datepicker {
    font-family: inherit;
    font-size: ${FONT_SIZE.PX_12};
    border: none;
    box-shadow: ${BOX_SHADOW.ELEVATION_5};

    .react-datepicker__day {
      border-radius: initial;
      color: ${COLOR.GRAYSCALE_2};

      &:not(.react-datepicker__day--in-range):hover {
        background-color: ${COLOR.WHITE};
      }

      &:hover {
        div {
          background-color: ${COLOR.GRAYSCALE_5};
        }
      }
    }

    .react-datepicker__day--disabled,
    .react-datepicker__day--outside-month {
      color: ${COLOR.GRAYSCALE_4};

      &.react-datepicker__day--in-range,
      &.react-datepicker__day--in-selecting-range {
        background-color: ${COLOR.GRAYSCALE_6};
      }
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--today {
      font-weight: ${FONT_WEIGHT.NORMAL};
      background: none;
    }

    .react-datepicker__day--selected {
      background-color: ${COLOR.WHITE};
    }

    .react-datepicker__day--in-range {
      background-color: ${transparentize(0.8, COLOR.PRIMARY_1)};
      &:hover {
        div {
          background-color: ${COLOR.GRAYSCALE_4};
        }
      }
    }

    .react-datepicker__day--in-selecting-range {
      background-color: ${transparentize(0.8, COLOR.PRIMARY_1)};

      &:not(.react-datepicker__day--selecting-range-start):hover {
        color: ${COLOR.WHITE};
        background: linear-gradient(
          90deg,
          ${transparentize(0.8, COLOR.PRIMARY_1)} 50%,
          ${COLOR.WHITE} 50%
        );
        div {
          background-color: ${COLOR.PRIMARY_1};
        }
      }
    }

    .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--range-start {
      background: linear-gradient(
        90deg,
        ${COLOR.WHITE} 50%,
        ${transparentize(0.8, COLOR.PRIMARY_1)} 50%
      );

      &.react-datepicker__day--outside-month,
      &.react-datepicker__day--disabled {
        background: linear-gradient(
          90deg,
          ${COLOR.WHITE} 50%,
          ${COLOR.GRAYSCALE_6} 50%
        );

        div {
          background-color: ${COLOR.GRAYSCALE_5};
        }
      }
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--range-end {
      color: ${COLOR.WHITE};

      div {
        background-color: ${COLOR.PRIMARY_1};
      }

      &:hover {
        div {
          background: linear-gradient(${GRADIENT.GRADIENT_4}), ${
            COLOR.PRIMARY_1
          };
        }
      }
    }

    .react-datepicker__day--range-end {
      background: linear-gradient(
        90deg,
        ${transparentize(0.8, COLOR.PRIMARY_1)} 50%,
        ${COLOR.WHITE} 50%
      );

      &.react-datepicker__day--outside-month {
        background: linear-gradient(
          90deg,
          ${COLOR.GRAYSCALE_6} 50%,
          ${COLOR.WHITE} 50%
        );

        div {
          background-color: ${COLOR.GRAYSCALE_5};
        }
      }

      &.react-datepicker__day--range-start {
        background: none;
      }
    }

    .react-datepicker__day--outside-month,
    .react-datepicker__day--disabled {
      pointer-events: none;
    }

    .react-datepicker__header {
      border-bottom: none;
    }

    .react-datepicker__navigation,
    .react-datepicker__current-month,
    .react-datepicker__day-names {
      margin-top: ${SPACE.PX_10};
    }

    .react-datepicker__week {
      margin: 8px 0;
    }

    .react-datepicker__month {
      margin: ${SPACE.PX_15};
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      margin: 0;
      width: 38px;
      line-height: 34px;
      padding: 0 2px;
    }

    .react-datepicker-wrapper {
      width: 100%;
      display: flex;
    }

    .react-datepicker__portal {
      background-color: ${transparentize(0.7, COLOR.GRAYSCALE_1)};
    }
  }
`
