import PropTypes from 'prop-types'
import React from 'react'

export const OpenInNew = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 19V13.5C21 13.2239 20.7761 13 20.5 13H19.5C19.2239 13 19 13.2239 19 13.5V19H5V5H10.5C10.7761 5 11 4.77614 11 4.5V3.5C11 3.22386 10.7761 3 10.5 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19ZM20.51 3.23L20.78 3.5V3.49C20.9159 3.62558 20.9946 3.80811 21 4V10.5C21 10.7761 20.7761 11 20.5 11H19.5C19.2239 11 19 10.7761 19 10.5V6.42L10.56 14.85C10.4661 14.9447 10.3383 14.9979 10.205 14.9979C10.0717 14.9979 9.94388 14.9447 9.85 14.85L9.15 14.15C9.05534 14.0561 9.0021 13.9283 9.0021 13.795C9.0021 13.6617 9.05534 13.5339 9.15 13.44L17.59 5H13.5C13.2239 5 13 4.77614 13 4.5V3.5C13 3.22386 13.2239 3 13.5 3H20C20.1933 3.00802 20.376 3.09042 20.51 3.23Z"
      fill={color}
    />
  </svg>
)

OpenInNew.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
