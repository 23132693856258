import PropTypes from 'prop-types'
import React from 'react'

export const Search = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.5 12.5L18 18" stroke={color} />
    <circle cx="8" cy="8" r="6.25" stroke={color} />
  </svg>
)

Search.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
