import React from 'react'
import Link, { type LinkProps } from './Link'

export const OptionalLink: React.FC<React.PWC<LinkProps>> = ({
  children,
  href,
  ...rest
}) =>
  href ? (
    <Link href={href} {...rest}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  )
