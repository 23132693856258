import { getParamByISO } from 'iso-country-currency'
import {
  GA4_USER_TYPES,
  type Ga4UserTypes,
  type TransformationProps,
} from '../types'

type UserType = {
  userLoggedIn: boolean
  userId: number
  userType: Ga4UserTypes
  email: string
  firstName: string
  lastName: string
  userName: string
  phone: string
  streetAddress: string
  city: string
  state: string
  country: string
  zipCode: string
}

type UserDataTransformationProps = TransformationProps<
  { userData?: Partial<UserType> },
  'contextData' | 'user' | 'userType'
>

export const UserDataTransformation = ({
  contextData,
  user,
  userType,
}: UserDataTransformationProps): { user_data: Partial<UserType> } => {
  const userData = contextData?.userData
  if (!user) {
    // If we have contextData but no user, it means that user is a guest
    // And we have some data about him
    const {
      email,
      city,
      country,
      userName,
      state,
      phone,
      zipCode,
      streetAddress,
    } = userData ?? {}

    return {
      user_data: {
        userLoggedIn: false,
        userType: GA4_USER_TYPES.GUEST,
        ...(email && { email }),
        ...(userName && { userName }),
        ...(phone && { phone }),
        ...(streetAddress && { streetAddress }),
        ...(city && { city }),
        ...(state && { state }),
        ...(country && { country }),
        ...(zipCode && { zipCode }),
      },
    }
  }

  if (user.__typename === 'Client') {
    const {
      id,
      contactEmail,
      firstName,
      loginEmail,
      lastName,
      phoneNumber,
      addresses,
    } = user

    const {
      addressLine1,
      addressLine2,
      city,
      countryState,
      zipCode,
      countryIsoCode,
    } = addresses.find(({ isPrimary }) => Boolean(isPrimary)) ?? {}

    return {
      user_data: {
        userLoggedIn: true,
        userId: id,
        userType,
        email: contactEmail || loginEmail,
        firstName,
        lastName,
        phone: phoneNumber,
        ...(!userData &&
          addresses.length && {
            streetAddress: `${addressLine1}${
              addressLine2 ? `, ${addressLine2}` : ''
            }`,
            ...(countryState && { state: countryState }),
            city,
            country: getParamByISO(countryIsoCode, 'countryName'),
            zipCode,
          }),
        ...(userData && {
          phone: userData.phone,
          userName: userData.userName,
          city: userData.city,
          state: userData.state,
          zipCode: userData.zipCode,
          streetAddress: userData.streetAddress,
        }),
      },
    }
  }

  if (user.__typename === 'ExpertUser') {
    const { email, expert, id } = user
    return {
      user_data: {
        userLoggedIn: true,
        userId: id,
        userType,
        email,
        userName: expert.profileName,
        country:
          userData?.country ||
          (expert.countryIsoCode &&
            getParamByISO(expert.countryIsoCode, 'countryName')) ||
          '',
        ...(userData && {
          userName: userData.userName,
          city: userData.city,
          state: userData.state,
          zipCode: userData.zipCode,
          streetAddress: userData.streetAddress,
        }),
      },
    }
  }

  if (user.__typename === 'Admin') {
    const { email, id, name } = user
    return {
      user_data: {
        userLoggedIn: true,
        userId: id,
        userType,
        email,
        userName: name,
      },
    }
  }

  return null
}
