import { environment } from 'constants/environment'
import { type TransformationProps } from '../types'
import { getPageType, getPageSection, tranformPageTitle } from '../utils'

interface PageViewTransformationContextData {
  id?: string | number // TODO: Edit type when page_id param will be implemented
  title: string
}

type PageViewTransformationProps = TransformationProps<
  PageViewTransformationContextData,
  'contextData' | 'user' | 'userType' | 'pathname' | 'asPath'
>

export const PageViewTransformation = ({
  contextData,
  user,
  userType,
  pathname,
  asPath,
}: PageViewTransformationProps) => {
  const pageType = getPageType(pathname)
  const pageSection = getPageSection(pathname)

  const { id, title } = contextData

  return {
    page_section: pageSection,
    page_type: pageType,
    page_id: id,
    page_title: tranformPageTitle(title),
    page_url: `${environment.APP_URL}${asPath}`,
    environment: environment.NEXT_PUBLIC_NODE_ENV,
    user_id: user ? user.id : null,
    user_type: userType,
  }
}
