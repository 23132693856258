import { DYNAMIC_ROUTE, ROUTE } from 'constants/routes'

export const PATHNAMES = {
  SIGN_IN: '/sign-in',
  EXPERT_SIGN_IN: '/expert/sign-in',
  NOT_FOUND_ERROR: '/404',
  INTERNAL_SERVER_ERROR: '/500',
  CONSULTATIONS: '/experts',
  SHOWROOM: '/showroom',
  QUESTIONNAIRE: '/questionnaire',
  BLOG: '/blog',
  GIFT_CARD: '/coupon/checkout',
  PROCUREMENT: '/purchase/checkout',
  CLIENT: '/client',
  EXPERT: '/expert',
  ADMIN: '/admin',
  CLIENT_PATH: '/client/',
  EXPERT_PATH: '/expert/',
} as const

// Perhaps this might be initialized in a function if we will have an issue with importing component
const pageTypesMap = new Map([
  // General
  [ROUTE.FRONT_PAGE(), 'homepage'],
  [ROUTE.FRONT_PAGE_FEATURED(), 'homepage'],
  [PATHNAMES.NOT_FOUND_ERROR, 'error 404'],
  [PATHNAMES.INTERNAL_SERVER_ERROR, 'error 500'],
  [ROUTE.CAREERS(), 'career'],
  [ROUTE.FAQ(), 'faq'],
  [ROUTE.PRIVACY_POLICY(), 'privacy'],
  [ROUTE.TERMS_OF_USE(), 'terms'],
  [ROUTE.SUPPORT(), 'support'],
  [ROUTE.SIGN_UP(), 'sign'],
  [ROUTE.SIGN_IN(), 'sign'],
  [ROUTE.EXPERT_SIGN_IN(), 'sign'],
  [ROUTE.ADMIN_SIGN_IN(), 'sign'],

  // Consultations
  [ROUTE.EXPERTS(), 'consultation category'],
  [DYNAMIC_ROUTE.EXPERT_PROFILE_CONSULTATION_TAB(), 'consultation detail'],
  [DYNAMIC_ROUTE.SESSION_CHECKOUT(), 'consultation checkout'],
  [DYNAMIC_ROUTE.CLIENT_SESSION_QUESTIONNAIRE(), 'consultation purchase'],

  // Showroom
  [ROUTE.SHOWROOM(), 'showroom category'],
  [DYNAMIC_ROUTE.SHOWROOM_DETAIL(), 'showroom category'],
  [DYNAMIC_ROUTE.SHOWROOM_PRODUCT_DETAIL(), 'showroom detail'],
  [DYNAMIC_ROUTE.EXPERT_PROFILE_SHOWROOM_TAB(), 'showroom expert detail'],
  [ROUTE.SHOWROOM_CART(), 'showroom cart'],
  [ROUTE.SHOWROOM_CHECKOUT_CONTACT(), 'showroom checkout 1'],
  [ROUTE.SHOWROOM_CHECKOUT_SHIPPING(), 'showroom checkout 2'],
  [ROUTE.ORDER_CONFIRMATION(), 'showroom purchase'],

  // Gift Card
  [ROUTE.COUPON_CHECKOUT(), 'gift card checkout'],
  [ROUTE.COUPON_CHECKOUT_THANK_YOU_PAGE(), 'gift card purchase'],

  // Procurement
  [DYNAMIC_ROUTE.PURCHASE_CHECKOUT(), 'procurement checkout'],
  [ROUTE.PURCHASE_CHECKOUT_THANK_YOU_PAGE(), 'procurement purchase'],

  // Blog
  [ROUTE.BLOG_LIST(), 'blog articles'],
  [DYNAMIC_ROUTE.BLOG_DETAIL(), 'blog article'],
])

export const getPageType = (pathname: string) => {
  if (
    pathname.startsWith(PATHNAMES.CLIENT_PATH) ||
    (pathname.startsWith(PATHNAMES.EXPERT_PATH) &&
      pathname !== ROUTE.EXPERT_SIGN_IN() &&
      // Expert profile tabs
      pathname !== DYNAMIC_ROUTE.EXPERT_PROFILE_CONSULTATION_TAB() &&
      pathname !== DYNAMIC_ROUTE.EXPERT_PROFILE_SHOWROOM_TAB() &&
      pathname !== DYNAMIC_ROUTE.SESSION_CHECKOUT() &&
      pathname !== DYNAMIC_ROUTE.CLIENT_SESSION_QUESTIONNAIRE()) ||
    pathname === PATHNAMES.CLIENT ||
    pathname === PATHNAMES.EXPERT
  ) {
    return 'user account'
  }

  return pageTypesMap.get(pathname) || 'unclassified'
}

export const getPageSection = (pathname: string) => {
  if (
    pathname.startsWith(PATHNAMES.SHOWROOM) ||
    pathname.startsWith(PATHNAMES.CONSULTATIONS) ||
    pathname.startsWith(PATHNAMES.QUESTIONNAIRE) ||
    pathname.startsWith(PATHNAMES.PROCUREMENT) ||
    pathname.startsWith(PATHNAMES.GIFT_CARD) ||
    // Expert profile tabs
    pathname === DYNAMIC_ROUTE.EXPERT_PROFILE_CONSULTATION_TAB() ||
    pathname === DYNAMIC_ROUTE.EXPERT_PROFILE_SHOWROOM_TAB() ||
    pathname === DYNAMIC_ROUTE.SESSION_CHECKOUT()
  ) {
    return 'online store'
  }

  if (
    pathname.startsWith(PATHNAMES.CLIENT_PATH) ||
    pathname.startsWith(PATHNAMES.EXPERT_PATH) ||
    pathname === PATHNAMES.CLIENT ||
    pathname === PATHNAMES.EXPERT
  ) {
    return 'user account'
  }

  if (pathname.startsWith(PATHNAMES.BLOG)) {
    return 'blog'
  }

  return 'other'
}
