import React, { createContext, useCallback, useContext, useMemo } from 'react'
import { catchHandler } from 'utils/catchHandler'

export const LIST_ENTITY_TYPE = {
  EXPERTS: 'Experts',
  PRODUCT: 'Product',
} as const

type EntityType = (typeof LIST_ENTITY_TYPE)[keyof typeof LIST_ENTITY_TYPE]
type GetItemListNameInput = {
  entityId: number
  entityType: EntityType
}

export type ItemListStoreData = {
  index: number
  itemListName?: string
} | null

interface ItemListStoreContextInterface {
  getItemListData: (key: GetItemListNameInput) => ItemListStoreData
  setItemListData: (
    key: GetItemListNameInput,
    itemListData: ItemListStoreData
  ) => void
  removeItemListData: (key: GetItemListNameInput) => void
}

export const ItemListStoreContext =
  createContext<ItemListStoreContextInterface>(null)
export const useItemListStore = () => useContext(ItemListStoreContext)

const getKey = ({ entityId, entityType }: GetItemListNameInput) =>
  `${entityType}-${entityId}`

const ItemListStoreProvider: React.FC<React.PWC> = ({ children }) => {
  const getItemListData = useCallback(
    (key: GetItemListNameInput): ItemListStoreData =>
      catchHandler(() =>
        JSON.parse(window.localStorage.getItem(getKey(key)))
      )(),
    []
  )

  const setItemListData = useCallback(
    (key: GetItemListNameInput, itemListData: ItemListStoreData) => {
      if (typeof window === 'undefined') {
        return
      }

      catchHandler(() => {
        if (!itemListData?.itemListName) {
          // remove from localStorage if 2nd param is null
          window.localStorage.removeItem(getKey(key))
          return
        }
        window.localStorage.setItem(getKey(key), JSON.stringify(itemListData))
      })()
    },
    []
  )

  const removeItemListData = useCallback((key: GetItemListNameInput) => {
    if (typeof window === 'undefined') {
      return
    }

    catchHandler(() => {
      window.localStorage.removeItem(getKey(key))
    })()
  }, [])

  const contextValues = useMemo(
    () => ({
      getItemListData,
      setItemListData,
      removeItemListData,
    }),
    [getItemListData, setItemListData, removeItemListData]
  )

  return (
    <ItemListStoreContext.Provider value={contextValues}>
      {children}
    </ItemListStoreContext.Provider>
  )
}

export default ItemListStoreProvider
