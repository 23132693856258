import PropTypes from 'prop-types'
import React from 'react'

export const Minus = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 13a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-13z"
      fill={color}
    />
  </svg>
)

Minus.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
