import { flatten } from 'flat'

export const createCacheKey = (input = {}) => {
  const flattenInput = flatten(input)
  return JSON.stringify(
    Object.keys(flattenInput)
      .sort()
      .map((key) => `${key}:${flattenInput[key]}`)
  )
}
