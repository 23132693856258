const STYLED_SYSTEM_EXCLUDED_PROPS = [
  'color',
  'fontFamily',
  'fontSize',
  'fontWeight',
  'lineHeight',
  'letterSpacing',
  'textAlign',
  'fontStyle',
  'width',
  'height',
  'display',
  'size',
  'verticalAlign',
  'flex',
  'order',
  'position',
  'top',
  'right',
  'bottom',
  'left',
  'variant',
  'elevation',
  'border',
  'overflow',
  'isMobile',
]

export const EXCLUDE_STYLED_SYSTEM_PROPS = {
  shouldForwardProp: (prop, defaultValidator) =>
    !STYLED_SYSTEM_EXCLUDED_PROPS.includes(prop) && defaultValidator(prop),
}
