import React from 'react'
import styled from 'styled-components'
import { Input, type InputProps, type Label } from './Input'

export const StyledInput = styled(Input)`
  input {
    cursor: pointer;
  }
`

export const ClickableReadonlyInput = React.forwardRef<
  typeof Label,
  InputProps
>((props, ref) => <StyledInput readOnly ref={ref} {...props} />)
