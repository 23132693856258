import { datadogRum } from '@datadog/browser-rum'

export const catchHandler =
  (fn: (...args: unknown[]) => any) =>
  (...args: unknown[]) => {
    try {
      return fn(...args)
    } catch (error) {
      datadogRum.addError(error)
    }
    return undefined
  }
