import { uniqBy } from 'lodash'
import { createCacheKey } from './utils'

const parseInputDefault = (input) => {
  const {
    args: {
      input: { pagination: { limit, page } = {}, ...inputRest } = {},
    } = {},
  } = input
  return {
    inputRest,
    limit,
    page,
  }
}

/**
 * Type policy for page based pagination that allows us to **load more** items into the list.
 * It merges loaded items for a `key` which is generated from query input variables.
 * Works only if items are loaded from `page: 1`
 *
 * @see https://www.apollographql.com/docs/react/pagination/core-api/
 * @see https://github.com/apollographql/apollo-client/blob/main/src/utilities/policies/pagination.ts#L33-L49
 */
export const pageLimitPagination = (
  { parseInput } = {
    parseInput: parseInputDefault,
  }
) => ({
  keyArgs: false,
  read(existing, input) {
    const currentPagination = existing || {}

    const { page, limit, inputRest } = parseInput(input)
    const cacheKey = createCacheKey(inputRest)
    const itemFromCache = currentPagination[cacheKey]

    if (!itemFromCache) {
      return undefined
    }
    const { data = [] } = itemFromCache

    if (!page || !limit) {
      return itemFromCache
    }
    const offset = (page - 1) * limit
    return {
      ...itemFromCache,
      ...(page * limit < data.length
        ? {
            paginationInfo: {
              ...itemFromCache.paginationInfo,
              nextPage: page + 1,
            },
          }
        : {}),
      data: data.slice(offset, offset + limit),
    }
  },
  merge(existing, incoming, input) {
    const currentPagination = existing || {}

    const { inputRest } = parseInput(input)
    const cacheKey = createCacheKey(inputRest)
    const itemFromCache = currentPagination[cacheKey]
    if (!itemFromCache) {
      return {
        ...currentPagination,
        [cacheKey]: incoming,
      }
    }
    return {
      ...currentPagination,
      [cacheKey]: {
        ...itemFromCache,
        ...incoming,
        data: uniqBy([...itemFromCache.data, ...incoming.data], '__ref'),
      },
    }
  },
})
