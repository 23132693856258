import { type ApolloError } from '@apollo/client'
import { type ErrorResponse } from '@apollo/client/link/error'
import { Toast } from 'components/Toast'

const getErrorMessage = ({ name, message }) =>
  name ? message : 'Something went wrong'

export const onGraphqlError = ({
  graphQLErrors,
}: ApolloError | ErrorResponse) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      Toast.error(getErrorMessage(error))
    })
  }
}
