import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const ChevronRight: React.FC<React.PWC<IconProps>> = ({
  color,
  size,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.03033 3.96967C6.73744 3.67678 6.26256 3.67678 5.96967 3.96967C5.67678 4.26256 5.67678 4.73744 5.96967 5.03033L7.03033 3.96967ZM10 8L10.5303 8.53033C10.8232 8.23744 10.8232 7.76256 10.5303 7.46967L10 8ZM5.96967 10.9697C5.67678 11.2626 5.67678 11.7374 5.96967 12.0303C6.26256 12.3232 6.73744 12.3232 7.03033 12.0303L5.96967 10.9697ZM5.96967 5.03033L9.46967 8.53033L10.5303 7.46967L7.03033 3.96967L5.96967 5.03033ZM9.46967 7.46967L5.96967 10.9697L7.03033 12.0303L10.5303 8.53033L9.46967 7.46967Z"
      fill={color}
    />
  </svg>
)
