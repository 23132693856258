import PropTypes from 'prop-types'
import React from 'react'

export const Globe = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM18.92 11H16.35C16.2943 9.1973 15.8937 7.42197 15.17 5.77C17.1948 6.80134 18.593 8.75135 18.92 11ZM12 19H12.45C13.36 18.41 14.41 16.22 14.59 13H9.41C9.55 16.24 10.64 18.43 11.55 19H12ZM9.45 11C9.63 7.78 10.68 5.59 11.59 5H12H12.45C13.36 5.57 14.45 7.76 14.59 11H9.45ZM7.65 11C7.70571 9.1973 8.10626 7.42197 8.83 5.77C6.80522 6.80134 5.40704 8.75135 5.08 11H7.65ZM5.08 13H7.65C7.70571 14.8027 8.10626 16.578 8.83 18.23C6.80522 17.1987 5.40704 15.2487 5.08 13ZM16.35 13C16.2943 14.8027 15.8937 16.578 15.17 18.23C17.1948 17.1987 18.593 15.2487 18.92 13H16.35Z"
      fill={color}
    />
  </svg>
)

Globe.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
