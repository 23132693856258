import styled from 'styled-components'
import {
  border,
  layout,
  order,
  position,
  space,
  color,
  type BorderProps,
  type LayoutProps,
  type OrderProps,
  type PositionProps,
  type SpaceProps,
  type ColorProps,
  type FlexProps,
  flex,
} from 'styled-system'
import { EXCLUDE_STYLED_SYSTEM_PROPS } from 'utils/styledSystem'

type BoxProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  BorderProps &
  LayoutProps &
  OrderProps &
  PositionProps &
  SpaceProps &
  ColorProps &
  FlexProps

export const Box: React.FC<React.PWC<BoxProps>> = styled.div.withConfig(
  EXCLUDE_STYLED_SYSTEM_PROPS
)`
  ${border}
  ${layout}
  ${order}
  ${position}
  ${space}
  ${color}
  ${flex}
`
