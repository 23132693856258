import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const Chair: React.FC<React.PWC<IconProps>> = ({ color, size }) => (
  <svg
    fill="none"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
  >
    <path
      d="M22.5 24.5H39a2 2 0 0 1 2 2V30c0 1.1-.9 2.5-2 2.5H12L9.3 7.3a2 2 0 0 1 2-2.3h2.3a2 2 0 0 1 2 1.5l3.4 12"
      stroke={color}
      strokeLinecap="round"
    />
    <path
      d="M37 40V24.8c0-3-3-5.2-6-4.8l-9 1.1a6 6 0 0 0-4.1 2.5C16.2 26 9.4 36.4 7.5 39"
      stroke={color}
      strokeLinecap="round"
      strokeWidth="1.5"
    />
  </svg>
)
