import PropTypes from 'prop-types'
import React from 'react'
import { EMAILS } from 'shared-constants'
import { Button } from 'components/Button'
import { Error } from 'components/Error'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import { SPACE } from 'Theme'
import { useMedia } from 'useMedia'

export const RenderError = ({ resetErrorBoundary }) => {
  const media = useMedia()

  return (
    <Flex px={SPACE.PX_15} justifyContent="center">
      <Error
        description={
          <>
            <Text mb={SPACE.PX_10} textAlign="center">
              We are working to resolve the problem as quickly as we can.
            </Text>
            <Text mb={SPACE.PX_20} textAlign="center">
              Feel free to contact us via email{' '}
              <a href={`mailto:${EMAILS.CUSTOMER_SUPPORT}`}>
                {EMAILS.CUSTOMER_SUPPORT}
              </a>{' '}
              or if you&#39;re an Expert{' '}
              <a href={`mailto:${EMAILS.EXPERT_SUPPORT}`}>
                {EMAILS.EXPERT_SUPPORT}
              </a>
            </Text>
            <Button
              {...(!media.MOBILE && {
                width: 'initial',
              })}
              onClick={resetErrorBoundary}
            >
              Reload page
            </Button>
          </>
        }
      >
        Something went wrong
      </Error>
    </Flex>
  )
}

RenderError.propTypes = {
  resetErrorBoundary: PropTypes.func.isRequired,
}
