import { format } from 'date-fns'
import { get, toPath } from 'lodash'
import { AVAILABILITY_LABEL } from 'shared-constants'
import slugify from 'slugify'
import { DATE_DEFAULT } from 'constants/datetime'
import { type UserTypes, USER_TYPE } from 'providers/userAuthUtil'
import {
  type Expert,
  type OrderItem,
  type OrderShipment,
  type ProductBreakoutVariant,
  type ProductVariant,
  type Purchase,
} from 'types/graphql-generated'
import { add } from 'utils/money'
import {
  type CouponSummary,
  type FilterEventProps,
  GA4_AREAS,
  GA4_ECOM_CUSTOM_EVENTS,
  GA4_EVENTS,
  GA4_ITEM_CATEGORY,
  GA4_ITEM_NAME,
  GA4_ITEM_TYPES,
  GA4_PARAMS,
  GA4_USER_TYPES,
  type GoogleAnalyticsContextInterface,
  type MapItemToInput,
} from '../types'
import {
  prefixConsultation,
  prefixGiftCards,
  prefixProcurement,
  prefixShowroom,
} from './prefixes'

const UNKNOWN_TYPE = 'Unknown'
const GENERAL_TITLE = 'The Expert - '
export const NOT_SET_TITLE = 'Not set'
export const MADE_TO_ORDER_LABEL = 'Made to order'

interface FindPropertyInTreeProps {
  keyName: string | string[]
  contextData?: object
  parent?: GoogleAnalyticsContextInterface
}

/**
 * This is lodash get enhancement to recursively find a prop in a tree
 */
export const findPropertyInTree = <T = any>({
  keyName,
  contextData,
  parent,
}: FindPropertyInTreeProps): T => {
  const prop = get(contextData, keyName)
  // check type of prop instead of value (0 can be valid value)
  if (prop !== undefined) {
    return prop
  }

  if (!parent) {
    return undefined
  }

  return findPropertyInTree({
    keyName,
    contextData: parent.data.current,
    parent: parent.parent,
  })
}

export const getAllValuesOfPropertyInTree = ({
  keyName,
  contextData,
  parent,
}: FindPropertyInTreeProps) => {
  let result = []
  if (parent) {
    result = getAllValuesOfPropertyInTree({
      keyName,
      contextData: parent.data.current,
      parent: parent.parent,
    })
  }

  const prop = get(contextData, keyName)
  if (prop !== undefined) {
    result.push(prop)
  }

  return result
}

export const getRoot = (current: GoogleAnalyticsContextInterface) =>
  current.parent ? getRoot(current.parent) : current

export const getItemListName = ({
  contextData,
  parent,
}: Pick<FindPropertyInTreeProps, 'contextData' | 'parent'>) =>
  getAllValuesOfPropertyInTree({
    keyName: GA4_PARAMS.ITEM_LIST_NAME,
    contextData,
    parent,
  }).join(' / ')

export const slugifyString = (value: string) =>
  typeof value === 'string'
    ? slugify(value, { replacement: '_', lower: true, strict: true })
    : ''

interface GetVariantAvailabilityProps {
  leadTime?: string
  quantity: number
}

export const getVariantAvailability = ({
  leadTime,
  quantity,
}: GetVariantAvailabilityProps) => {
  if (leadTime) {
    return MADE_TO_ORDER_LABEL
  }
  return quantity > 0
    ? AVAILABILITY_LABEL.READY_TO_SHIP
    : AVAILABILITY_LABEL.OUT_OF_STOCK
}

export const mapProductVariantToItem = ({
  id,
  sku,
  quantity,
  managedCustomFields,
  product,
  price,
  discount = 0,
}: (ProductVariant | ProductBreakoutVariant) & { discount?: number }) => ({
  item_id: prefixShowroom(id),
  item_name: get(product, 'title'),
  item_variant: sku,
  item_brand: get(product, ['brand', 'id'], UNKNOWN_TYPE),
  item_category: GA4_ITEM_TYPES.SHOWROOM,
  item_category2: slugifyString(get(product, 'categoryOne.name', '')),
  item_category3: slugifyString(get(product, 'categoryTwo.name', '')),
  item_category4: slugifyString(get(product, 'categoryThree.name', '')),
  item_category5: slugifyString(
    getVariantAvailability({
      leadTime: get(managedCustomFields, 'leadTime'),
      quantity,
    })
  ),
  discount,
  price: get(price, 'base'),
  currency: get(price, 'currencyIsoCode'),
  item_tax: get(price, 'vatRate'),
})

export const mapExpertToItem = ({ id, profileName }: Expert) => ({
  item_id: prefixConsultation(id),
  item_name: profileName,
  item_category: GA4_ITEM_TYPES.CONSULTATIONS,
  item_category2: '',
  item_category3: '',
  item_category4: '',
})

export const mapPurchaseToItem = ({ id, retailPrice, tax }: Purchase) => ({
  item_id: prefixProcurement(id),
  item_name: GA4_ITEM_NAME.PROCUREMENT,
  currency: get(retailPrice, 'currencyIsoCode'),
  item_category: GA4_ITEM_CATEGORY.PROCUREMENT,
  price: get(retailPrice, 'total'),
  item_tax: get(tax, 'total'),
  quantity: 1,
})

export const mapCouponToItem = ({ price, currencyIsoCode }: CouponSummary) => ({
  item_id: prefixGiftCards(price),
  item_name: GA4_ITEM_NAME.GIFT_CARD,
  currency: currencyIsoCode,
  item_category: GA4_ITEM_CATEGORY.GIFT_CARD,
  item_list_id: null,
  item_list_name: null, // TODO: https://github.com/sudolabs-io/the-expert/issues/5220
})

interface GetUserTypeProps {
  isAdminLoggedInAsExpert: boolean
  isAdminLoggedInAsClient: boolean
  signedInUserType: UserTypes
}

/**
 * This function returns value for parameter `user_type` based on the values from `userAuth()` hook
 */
export const getUserType = ({
  isAdminLoggedInAsExpert,
  isAdminLoggedInAsClient,
  signedInUserType,
}: GetUserTypeProps) => {
  if (signedInUserType === USER_TYPE.NONE) {
    return GA4_USER_TYPES.GUEST
  }
  if (signedInUserType === USER_TYPE.ADMIN) {
    return GA4_USER_TYPES.INTERNAL
  }
  if (isAdminLoggedInAsClient) {
    return GA4_USER_TYPES.INTERNAL_AS_CLIENT
  }
  if (isAdminLoggedInAsExpert) {
    return GA4_USER_TYPES.INTERNAL_AS_EXPERT
  }
  if (signedInUserType === USER_TYPE.CLIENT) {
    return GA4_USER_TYPES.CLIENT
  }
  if (signedInUserType === USER_TYPE.EXPERT) {
    return GA4_USER_TYPES.EXPERT
  }
  return GA4_USER_TYPES.UNKNOWN
}

export const tranformPageTitle = (title: string) => {
  if (title.includes(GENERAL_TITLE)) {
    return title.replace(GENERAL_TITLE, '')
  }

  return NOT_SET_TITLE
}

/**
 * Reduce function that sums the values from array of objects
 */
export const getTotal = (
  data: Array<OrderItem> | Array<OrderShipment>,
  valuePath: string | string[]
) => {
  if (!data.length) {
    return null
  }

  return add(
    ...data.map((item: OrderItem | OrderShipment) =>
      get(item, toPath(valuePath), 0)
    )
  )
}

interface FormatPriceValueChangeProps {
  currencyIsoCode: string
  priceFrom: number | string
  priceTo: number | string
}

export const formatPriceValueChange = ({
  currencyIsoCode,
  priceFrom,
  priceTo,
}: FormatPriceValueChangeProps) =>
  `${currencyIsoCode} ${priceFrom} - ${priceTo}`

export const mapDataToFilterEvent = ({ type, action }: FilterEventProps) => ({
  event: GA4_EVENTS.FILTER,
  overrideData: {
    [GA4_PARAMS.TYPE]: type,
    [GA4_PARAMS.ACTION]: action,
  },
})

export const formatExpertAvailability = (date: Date) =>
  format(date, DATE_DEFAULT)

export const mapItemToInput =
  (withItemList = false) =>
  (item: MapItemToInput) => {
    const listNameFromItem = withItemList &&
      item.itemListName && {
        item_list_name: item.itemListName,
        index: item.itemListIndex,
      }

    if (item.__typename === 'CartItemProductVariant') {
      return {
        ...mapProductVariantToItem(item.productVariant as ProductVariant),
        ...(item.cartQuantity && { quantity: item.cartQuantity }),
        ...(withItemList &&
          item.metadata?.ga4?.listName && {
            item_list_name: item.metadata.ga4.listName,
            index: item.metadata.ga4.listIndex,
          }),
      }
    }
    if (item.__typename === 'ProductVariant') {
      return {
        ...mapProductVariantToItem(item as ProductVariant),
        ...listNameFromItem,
      }
    }
    if (item.__typename === 'ProductBreakoutVariant') {
      return {
        ...mapProductVariantToItem(item as ProductBreakoutVariant),
        ...listNameFromItem,
      }
    }
    if (item.__typename === 'PublicExpertSession') {
      return {
        ...mapExpertToItem(item.expert as Expert),
        currency: item.payPrice.currencyIsoCode,
        item_tax: 0,
        item_variant: item.lengthInMinutes,
        price: item.payPrice.total,
        quantity: 1,
        ...listNameFromItem,
      }
    }
    if (item.__typename === 'Expert') {
      return {
        ...mapExpertToItem(item as Expert),
        ...listNameFromItem,
      }
    }
    if (item.__typename === 'Purchase') {
      return mapPurchaseToItem(item as Purchase)
    }
    if (item.__typename === 'CouponSummary') {
      return mapCouponToItem(item as CouponSummary)
    }

    throw new Error('Unsupported entity passed to mapItemToInput function.')
  }

export const getEcomCustomEventFromArea = (area: string) => {
  if (area === GA4_AREAS.CONSULTATIONS) {
    return GA4_ECOM_CUSTOM_EVENTS.VIEW_LIST_CONSULTATIONS
  }

  return GA4_ECOM_CUSTOM_EVENTS.VIEW_LIST_SHOWROOM
}

export const prioritizedAnalyticsEvents: string[] = [
  GA4_EVENTS.PURCHASE,
  GA4_EVENTS.USER_DATA,
]
