import PropTypes from 'prop-types'
import React from 'react'

export const Pdf = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9999 18.3334H4.99992C4.07944 18.3334 3.33325 17.5872 3.33325 16.6667V7.70002C3.33384 7.0372 3.59761 6.40174 4.06659 5.93335L7.59992 2.40002C8.06831 1.93105 8.70377 1.66727 9.36658 1.66669H14.9999C15.9204 1.66669 16.6666 2.41288 16.6666 3.33335V16.6667C16.6666 17.5872 15.9204 18.3334 14.9999 18.3334ZM4.99992 16.6667H14.9999V3.33335H9.99992V7.50002C9.99992 7.96026 9.62682 8.33335 9.16658 8.33335H4.99992V16.6667Z"
      fill={color}
    />
  </svg>
)

Pdf.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
