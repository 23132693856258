import { CURRENCY_ISO_CODE } from 'constants/common'
import { validateCheckoutResponse } from 'providers/googleAnalytics/utils'
import { prefixGiftCards } from 'providers/googleAnalytics/utils/prefixes'
import {
  GA4_AREAS,
  GA4_ITEM_CATEGORY,
  GA4_SHIPPING_TIERS,
  GA4_SHIPPING_TYPES,
  type PurchaseTransformationProps,
} from '../../types'

export const CouponTransformation = ({
  contextData,
}: PurchaseTransformationProps) => {
  const { checkoutResponse, paymentMethod, extra } = contextData

  validateCheckoutResponse(checkoutResponse, 'coupon purchase')
  if (!extra.couponSummary) {
    throw new Error('Coupon summary is not provided in coupon purchase')
  }

  const { id: checkoutId, totalPrice } = checkoutResponse.data
  const { price, discount, discountPercentage } = extra.couponSummary
  const currencyIsoCode = totalPrice?.currencyIsoCode || CURRENCY_ISO_CODE.USD

  return {
    ecommerce: {
      area: GA4_AREAS.GIFT_CARD,
      transaction_id: prefixGiftCards(checkoutId),
      affiliation: null,
      value: price,
      tax: 0,
      shipping: 0,
      currency: currencyIsoCode,
      payment: price,
      payment_type: paymentMethod,

      discount_all: discount ?? 0,
      discount_rate: discountPercentage ?? 0,

      shipping_type: GA4_SHIPPING_TYPES.EMAIL,
      shipping_tier: GA4_SHIPPING_TIERS.STANDARD_SHIPPING,

      items: [
        {
          index: 0,
          item_id: prefixGiftCards(price),
          item_name: GA4_ITEM_CATEGORY.GIFT_CARD,
          item_variant: price,
          item_tax: 0,
          item_category: GA4_ITEM_CATEGORY.GIFT_CARD,
          item_list_id: null,
          item_list_name: null, // TODO: This is true until we will have existing list for gift cards
          discount: discount ?? 0,
          price,
          quantity: 1,
          currency: currencyIsoCode,
        },
      ],
    },
  }
}
