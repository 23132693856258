import React from 'react'
import { type IconProps } from '../IconPropsInterface'

export const ChevronLeftSmall: React.FC<IconProps> = ({
  color,
  size,
  fill,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 7L9 12L14 17"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
