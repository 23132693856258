import { KLAVIYO_ACCESSIBILITY_REPLACEMENT } from 'constants/common'

export const klaviyoIdentifyUser = (user) => {
  // eslint-disable-next-line no-underscore-dangle
  const learnq = window._learnq || []
  learnq.push([
    'identify',
    {
      $email: user.loginEmail ?? user.email,
      $first_name: user.firstName ?? user.expertFirstName,
      $last_name: user.lastName ?? user.expertLastName,
      userId: user.id,
    },
  ])
}

export const klaviyoSetUserLoginTime = ({ user, date }) => {
  // eslint-disable-next-line no-underscore-dangle
  const learnq = window._learnq || []
  learnq.push([
    'identify',
    {
      $email: user.loginEmail ?? user.email,
      loggedAt: date,
    },
  ])
}

export const klaviyoIdentifyByEmail = (email) => {
  // eslint-disable-next-line no-underscore-dangle
  const learnq = window._learnq || []
  learnq.push([
    'identify',
    {
      $email: email,
    },
  ])
}

export const klaviyoTrackToBookedSession = ({
  expert,
  session,
  startAtUtc,
}) => {
  // eslint-disable-next-line no-underscore-dangle
  const learnq = window._learnq || []
  learnq.push([
    'track',
    'BOOKED_SESSION',
    {
      ExpertUserEmail: session.expertUser.email,
      ExpertSessionId: session.id,
      ExpertSessionTitle: session.title,
      ExpertSessionType: session.sessionType,
      ExpertName: expert.profileName,
      ConsultationStartDatetime: startAtUtc,
    },
  ])
}

export const klaviyoTrackToPayPurchase = ({ purchase }) => {
  if (!purchase) {
    return
  }
  // eslint-disable-next-line no-underscore-dangle
  const learnq = window._learnq || []
  learnq.push([
    'track',
    'PAY_PURCHASE',
    {
      PurchaseId: purchase.id,
      VendorName: purchase.vendor?.name,
      ExpertName: purchase.expert?.profileName,
      ClientEmail: purchase.client?.contactEmail,
    },
  ])
}

export const klaviyoTrackPayOrder = ({ orderId, email }) => {
  // eslint-disable-next-line no-underscore-dangle
  const learnq = window._learnq || []
  learnq.push([
    'track',
    'PAY_ORDER',
    {
      OrderId: orderId,
      ClientEmail: email,
    },
  ])
}

export const klaviyoTrackAddToCartShowroom = ({
  productId,
  productVariantId,
  productVariantSku,
}) => {
  // eslint-disable-next-line no-underscore-dangle
  const learnq = window._learnq || []
  learnq.push([
    'track',
    'ADD_TO_CART_SHOWROOM',
    {
      ProductId: productId,
      ProductVariantId: productVariantId,
      ProductVariantSku: productVariantSku,
    },
  ])
}

export const klaviyoTrackViewedShowroom = () => {
  // eslint-disable-next-line no-underscore-dangle
  const learnq = window._learnq || []
  learnq.push(['track', 'VIEWED_SHOWROOM'])
}

export const klaviyoTrackViewedCollection = (slug) => {
  // eslint-disable-next-line no-underscore-dangle
  const learnq = window._learnq || []
  learnq.push([
    'track',
    'VIEWED_COLLECTION',
    {
      Slug: slug,
    },
  ])
}

export const klaviyoTrackViewedShowroomProduct = ({
  productId,
  productVariantId,
  productVariantSku,
}) => {
  // eslint-disable-next-line no-underscore-dangle
  const learnq = window._learnq || []
  learnq.push([
    'track',
    'VIEWED_SHOWROOM_PRODUCT',
    {
      ProductId: productId,
      ProductVariantId: productVariantId,
      ProductVariantSku: productVariantSku,
    },
  ])
}

export const klaviyoTrackCheckoutStartedShowroom = () => {
  // eslint-disable-next-line no-underscore-dangle
  const learnq = window._learnq || []
  learnq.push(['track', 'CHECKOUT_STARTED_SHOWROOM'])
}

export const klaviyoTrackCheckoutConfirmedShowroom = () => {
  // eslint-disable-next-line no-underscore-dangle
  const learnq = window._learnq || []
  learnq.push(['track', 'CHECKOUT_CONFIRMED_SHOWROOM'])
}

export const handleElementAppearance = (mutationsList) => {
  const replacementKeys = Object.keys(KLAVIYO_ACCESSIBILITY_REPLACEMENT)
  mutationsList.forEach((mutation) => {
    if (mutation.addedNodes.length > 0) {
      mutation.addedNodes.forEach((node) => {
        if (node.getAttribute) {
          const replacementKey = replacementKeys.find(
            (key) => node.getAttribute('aria-label') === key
          )
          if (replacementKey) {
            node.setAttribute(
              'aria-label',
              KLAVIYO_ACCESSIBILITY_REPLACEMENT[replacementKey]
            )
          }
        }
      })
    }
  })
}

export const handleKlaviyoAriaLabels = () => {
  const observer = new MutationObserver(handleElementAppearance)
  const observerOptions = { childList: true, subtree: true }
  observer.observe(document, observerOptions)
}
