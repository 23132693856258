import PropTypes from 'prop-types'
import React from 'react'

export const Checkmark = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.85 7.25036L9.25 17.8504C9.15612 17.945 9.02832 17.9983 8.895 17.9983C8.76169 17.9983 8.63389 17.945 8.54 17.8504L3.15 12.4604C3.05535 12.3665 3.00211 12.2387 3.00211 12.1054C3.00211 11.972 3.05535 11.8442 3.15 11.7504L3.85 11.0504C3.94389 10.9557 4.07169 10.9025 4.205 10.9025C4.33832 10.9025 4.46612 10.9557 4.56 11.0504L8.89001 15.3804L18.44 5.83036C18.6378 5.63858 18.9522 5.63858 19.15 5.83036L19.85 6.54036C19.9447 6.63424 19.9979 6.76204 19.9979 6.89536C19.9979 7.02868 19.9447 7.15648 19.85 7.25036Z"
      fill={color}
    />
  </svg>
)

Checkmark.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}
