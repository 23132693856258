import type { ParsedUrlQuery } from 'querystring'
import { type GraphQLError } from 'graphql'
import {
  type MutableRefObject,
  type ReactNode,
  type SetStateAction,
  type Dispatch,
} from 'react'
import { type CURRENCY_ISO_CODE, type PAYMENT_OPTIONS } from 'constants/common'
import { type ItemListStoreData } from 'providers/useItemListStore'
import {
  type CartFieldsFragment,
  type Checkout,
  type Expert,
  type ProductBreakoutVariant,
  type ProductVariant,
  type PublicExpertSession,
  type Purchase,
  type User,
} from 'types/graphql-generated'
// Types
export type Ga4UserTypes = (typeof GA4_USER_TYPES)[keyof typeof GA4_USER_TYPES]

export type EventTypes =
  | (typeof GA4_EVENTS)[keyof typeof GA4_EVENTS]
  | (typeof GA4_ECOM_CUSTOM_EVENTS)[keyof typeof GA4_ECOM_CUSTOM_EVENTS]

export type CurrencyTypes =
  (typeof CURRENCY_ISO_CODE)[keyof typeof CURRENCY_ISO_CODE]

export type FilterTypes =
  (typeof GA4_FILTER_TYPES)[keyof typeof GA4_FILTER_TYPES]

export type SearchTypes = (typeof GA4_SEARCH_TYPE)[keyof typeof GA4_SEARCH_TYPE]

export type PaymentMethodTypes =
  (typeof PAYMENT_OPTIONS)[keyof typeof PAYMENT_OPTIONS]

export type AreaTypes = (typeof GA4_AREAS)[keyof typeof GA4_AREAS]

export type ItemListNameTypes =
  (typeof GA4_ITEM_LIST_NAME)[keyof typeof GA4_ITEM_LIST_NAME]

export type PurchaseTypes =
  (typeof GA4_PURCHASE_TYPES)[keyof typeof GA4_PURCHASE_TYPES]

export type TransformationInputDataToGA4DataLayerInput<T extends object = any> =
  Pick<
    GoogleAnalyticsContextInterface,
    'userType' | 'user' | 'pathname' | 'parent' | 'asPath' | 'query'
  > &
    TransformationInput<T>

export type TransformationProps<
  T extends object,
  P extends keyof TransformationInputDataToGA4DataLayerInput<T>,
> = Pick<TransformationInputDataToGA4DataLayerInput<T>, P>

export type PurchaseTransformationProps = TransformationProps<
  PurchaseTransformationsContextData,
  'contextData'
>

export type CouponSummary = {
  __typename: 'CouponSummary'
  originalPrice: number
  discount: number
  discountApprox: number | null
  discountPercentage: number | null
  originalPriceApprox: number | null
  price: number
  priceApprox: number | null
  currencyIsoCode: CurrencyTypes
}

export type ItemListData = {
  itemListName?: string
  itemListIndex?: number
  metadata?: {
    ga4: never
  }
}

export type ItemListDataFromMetada = {
  metadata?: {
    ga4?: {
      listName?: string
      listIndex?: number
    }
  }
  itemListName?: never
  itemListIndex?: never
}

export type MapItemToInput = (
  | PublicExpertSession
  | Expert
  | CartFieldsFragment['items'][number]
  | ProductVariant
  | Purchase
  | ProductBreakoutVariant
  | (CouponSummary & {
      productVariant?: never
      cartQuantity?: never
      expert?: never
      payPrice?: never
      lengthInMinutes?: never
    })
) &
  (ItemListDataFromMetada | ItemListData)

interface GoogleAnalyticsRootLevelAccessibleData {
  dataLayerBuffer: MutableRefObject<unknown[]>
  isPageViewLoading: boolean
  setPageViewLoading: Dispatch<SetStateAction<boolean>>
}

export interface GoogleAnalyticsContextInterface
  extends GoogleAnalyticsRootLevelAccessibleData {
  userType: Ga4UserTypes
  user: User
  pathname: string
  asPath: string
  asPathWithoutQueryParams: string
  routerIsReady: boolean
  query: ParsedUrlQuery
  parent?: GoogleAnalyticsContextInterface
  data: Record<string, any>
  executeDataToDataLayer: (props: ExecuteDataToDataLayerProps<object>) => void
  aggregateData: (callback: (data: Record<string, unknown>) => unknown) => void
  getPropertyInTree: (keyName: string) => any
}

export interface ExecuteDataToDataLayerProps<T extends object> {
  event: EventTypes
  overrideData?: T
  includeStoredData?: boolean
}

interface TransformationInput<T extends object>
  extends Pick<ExecuteDataToDataLayerProps<T>, 'event'> {
  contextData: T
}

export interface FilterValues {
  price: {
    priceFrom: number
    priceTo: number
  }
  availability: Array<string> & {
    availabilityFrom: string | undefined
    availabilityTo: string | undefined
  }
  collections: Array<number> | undefined
  categories: Array<number> | undefined
  brands: Array<string> | undefined
  sessionLength: Array<string> | undefined
  tags: Array<string> | undefined
  regions: Array<string> | undefined
}

export interface FilterConfiguration {
  viewComponent: ReactNode
  label: string
  isActive: (value: unknown) => boolean
  viewComponentProps: {
    options: Array<unknown>
    columns?: number
    isCollapsible?: boolean
    maxPrice?: number
    priceFrom?: number
    priceTo?: number
    setPriceFrom?: () => void
    setPriceTo?: () => void
    currencyIsoCode?: CurrencyTypes
    isPriceUnlimited?: boolean
    sliderStep?: number
  }
}

export type CheckoutResponseType = {
  data?: Checkout
  error?: GraphQLError
}

export interface PurchaseTransformationsContextData {
  paymentMethod: PaymentMethodTypes
  checkoutResponse: CheckoutResponseType
  extra: {
    couponSummary?: CouponSummary
    expertSession?: PublicExpertSession
    itemListData?: ItemListStoreData
  }
}

export interface FilterEventProps {
  type: FilterTypes
  action: string
}

// Enums
export const PURCHASE_TYPES = {
  SHOWROOM: 'showroom',
  CONSULTATIONS: 'consultations',
  GIFT_CARD: 'giftcard',
  PROCUREMENT: 'procurement',
} as const

export const GA4_EVENTS = {
  PAGE_VIEW: 'virtualpageview',
  VIEW_CART: 'view_cart',
  COOKIES_DEFAULT: 'consent_default',
  COOKIES_UPDATE: 'consent_update',
  REMOVE_FROM_CART: 'remove_from_cart',
  ADD_TO_CART: 'add_to_cart',
  VIEW_ITEM: 'view_item',
  VIEW_ITEM_LIST: 'view_item_list',
  BEGIN_CHECKOUT: 'begin_checkout',
  ADD_SHIPPING_INFO: 'add_shipping_info',
  ADD_PAYMENT_INFO: 'add_payment_info',
  SELECT_ITEM: 'select_item',
  PURCHASE: 'purchase',
  SIGN_UP: 'sign_up',
  LOGIN: 'login',
  FILTER: 'filter',
  FORM: 'form',
  CLICK: 'click',
  NOTIFY_ME: 'notify_me',
  SORTING: 'sorting',
  DOWNLOAD: 'download',
  SEARCH: 'search',
  NEWSLETTER_SUBSCRIBE: 'nl_subscribe',
  ERROR_MESSAGE: 'error',
  USER_DATA: 'user_data',
  SELECT_PROMOTION: 'select_promotion',
  VIEW_PROMOTION: 'view_promotion',

  // Only temp event
  PAGE_VIEW_TEST2: 'virpagview2',
} as const

export const GA4_ECOM_CUSTOM_EVENTS = {
  // Consultations
  VIEW_LIST_CONSULTATIONS: 'view_list_consultations',
  VIEW_ITEM_CONSULTATIONS: 'view_item_consultations',
  VIEW_CALENDAR_CONSULTATIONS: 'view_calendar_consultations',
  SELECT_DATE_CONSULTATIONS: 'select_date_consultations',
  VIEW_CHECKOUT_CONSULTATIONS: 'view_checkout_consultations',
  PURCHASE_CONSULTATION: 'purchase_consultations',
  // Showroom
  VIEW_LIST_SHOWROOM: 'view_list_showroom',
  VIEW_ITEM_SHOWROOM: 'view_item_showroom',
  ADD_TO_CART_SHOWROOM: 'add_to_cart_showroom',
  VIEW_CART_SHOWROOM: 'view_cart_showroom',
  CHECKOUT_1_SHOWROOM: 'checkout_1_showroom',
  CHECKOUT_2_SHOWROOM: 'checkout_2_showroom',
  PURCHASE_SHOWROOM: 'purchase_showroom',
  VIEW_CHECKOUT_GIFTCARD: 'view_checkout_gift_card',
  PURCHASE_GIFTCARD: 'purchase_gift_card',
  VIEW_CHECKOUT_PROCUREMENT: 'view_checkout_procurement',
  PURCHASE_PROCUREMENT: 'purchase_procurement',
} as const

export const GA4_ECOMMERCE_EVENTS = {
  [GA4_EVENTS.VIEW_ITEM]: true,
  [GA4_EVENTS.SELECT_ITEM]: true,
  [GA4_EVENTS.PURCHASE]: true,
  [GA4_EVENTS.REMOVE_FROM_CART]: true,
  [GA4_EVENTS.ADD_TO_CART]: true,
  [GA4_EVENTS.VIEW_CART]: true,
  [GA4_EVENTS.ADD_SHIPPING_INFO]: true,
  [GA4_EVENTS.ADD_PAYMENT_INFO]: true,
  [GA4_EVENTS.BEGIN_CHECKOUT]: true,
  [GA4_EVENTS.VIEW_ITEM_LIST]: true,
  [GA4_EVENTS.SELECT_PROMOTION]: true,
  [GA4_EVENTS.VIEW_PROMOTION]: true,
} as const

export const GA4_PARAMS = {
  PAGE_TITLE: 'page_title',
  PAGE_ID: 'page_id',
  AREA: 'area',
  PAGE_AREA: 'page_area',
  TYPE: 'type',
  ACTION: 'action',
  ITEM_LIST_NAME: 'item_list_name',
} as const

export const GA4_ITEM_TYPES = {
  ...PURCHASE_TYPES,
} as const

export const GA4_AREAS = {
  ...PURCHASE_TYPES,
  USER_ACCOUNT: 'user_account',
} as const

export const GA4_PAGE_AREAS = {
  PDP: 'pdp',
  HP: 'hp',
  CDP: 'cdp',
  CCP: 'ccp',
  SCP: 'scp',
  ADD_TO_CART_POPUP: 'add to cart popup',
  CONSULTATION_RECAP: 'consultation recap',
  SESSION_CHECKOUT: 'session checkout',
  PROCUREMENT_CHECKOUT: 'procurement checkout',
  COUPON_CHECKOUT: 'coupon checkout',
  SHOWROOM_CHECKOUT: 'showroom checkout',
  SIGN_IN_CLIENT: 'sign-in client',
  SIGN_UP_CLIENT: 'sign-up client',
  SIGN_IN_EXPERT: 'sign-in expert',
  RESET_PASSWORD: 'reset password',
  EXPERT_PROFILE: 'expert profile',
  USER_PROFILE_SETTINGS: 'user profile settings',
} as const

export const GA4_USER_TYPES = {
  UNKNOWN: 'unknown',
  GUEST: 'guest',
  CLIENT: 'client',
  EXPERT: 'expert',
  INTERNAL: 'internal',
  INTERNAL_AS_CLIENT: 'internal_as_client',
  INTERNAL_AS_EXPERT: 'internal_as_expert',
} as const

export const GA4_PURCHASE_TYPES = {
  ...PURCHASE_TYPES,
} as const

export const GA4_ITEM_CATEGORY = {
  ...PURCHASE_TYPES,
} as const

export const GA4_SHIPPING_TYPES = {
  EMAIL: 'email',
  FREIGHT_CLUB: 'freight_club_shipping',
  ARTA: 'arta_shipping',
  VENDOR: 'vendor_owns_shipping',
} as const

export const GA4_SHIPPING_TIERS = {
  STANDARD_SHIPPING: 'standard_shipping',
} as const

export const GA4_SIGN_IN_AREAS = {
  ADD_TO_WISHLIST: 'Add to wishlist',
  MENU: 'Menu',
  CONSULTATION_CALENDAR: 'Consultation calendar',
  EMPTY_CART: 'Empty cart',
  SHOWROOM_CHECKOUT: 'Showroom checkout',
  SHOWROOM: 'Showroom',
  SIGN_IN_CLIENT: 'Client sign-in page',
  SIGN_IN_EXPERT: 'Expert sign-in page',
  SIGN_UP_CLIENT: 'Client sign-up page',
  SIGN_UP_EXPERT: 'Expert sign-up page',
  EMAIL_CONFIRMATION: 'Email confirmation',
  RESET_PASSWORD: 'Reset password',
  EXPERT_ONBOARDING: 'Expert onboarding',
  FOOTER: 'Footer',
} as const

export const GA4_NEWSLETTER_AREAS = {
  MENU: 'Menu',
  POPUP: 'Popup',
  FOOTER: 'Footer',
} as const

export const GA4_FILTER_TYPES = {
  COLLECTIONS: 'collections',
  CATEGORIES: 'categories',
  PRICE: 'price',
  AVAILABILITY: 'availability',
  BRANDS: 'brands',
  REGIONS: 'regions',
  SESSION_LENGTH: 'session_length',
  STYLE: 'style',
} as const

export const GA4_ITEM_LIST_NAME = {
  CATEGORY_PAGE: 'cp',
  HOME_PAGE: 'hp',
  LANDING_PAGE: 'lp',
  PRODUCT_DETAIL_PAGE: 'pdp',
  COLLECTION: 'collection',
  SHOWROOM: 'showroom',
  CONSULTATIONS: 'consultations',
  FEATURED_EXPERTS: 'featured experts',
  FAVORITE_EXPERTS: 'favorite experts',
  FEATURED_PRODUCTS: 'featured products',
  EXPERT_RECOMMENDS: 'expert recommends',
  YOU_MIGHT_ALSO_LIKE: 'you might also like',
  MORE_FROM_EXPERT_COLLECTION: 'more from expert collection',
} as const

export const GA4_ITEM_NAME = {
  GIFT_CARD: 'Gift Card',
  PROCUREMENT: 'Procurement',
} as const

export const GA4_SEARCH_TYPE = {
  FULLTEXT: 'fulltext',
  SUGGEST: 'suggest',
  LOST: 'lost',
} as const
