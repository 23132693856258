import React from 'react'
import { AnimatedPathWrapper } from '../AnimatedPathWrapper'
import { type IconProps } from '../IconPropsInterface'

export const MenuCross: React.FC<React.PWC<IconProps>> = ({
  color,
  size,
  hoveringColor,
}) => (
  <AnimatedPathWrapper
    hoveringColor={hoveringColor}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 6L18 18"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M18 6L6 18"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </AnimatedPathWrapper>
)
