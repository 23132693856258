import React from 'react'
import styled from 'styled-components'
import { Text, type TypographyProps } from 'components/Typography'
import { INPUT_SIZE, type InputSize, LINE_HEIGHT, SPACE } from 'Theme'

const TextWrapper = styled(Text)`
  ${({ isInline, size }) =>
    isInline &&
    `height: ${size};
    display: inline-flex;
    align-items: center;
    padding-right: ${SPACE.PX_30};
  `}
`

interface LabelTextProps extends TypographyProps {
  isInline?: boolean
  size?: InputSize
}

export const LabelText: React.FC<React.PWC<LabelTextProps>> = ({
  children,
  isInline = false,
  size = INPUT_SIZE.M,
  ...props
}) => (
  <>
    {children && (
      <TextWrapper
        mb={SPACE.PX_5}
        lineHeight={LINE_HEIGHT.XS}
        size={size}
        isInline={isInline}
        {...props}
      >
        {children}
      </TextWrapper>
    )}
  </>
)
